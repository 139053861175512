import { Avatar, Button, Card, CardActions, CardContent, CardHeader, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import GetAppIcon from '@mui/icons-material/GetApp';
import { purple } from '@mui/material/colors';
import { BASE_URL } from '../../../api/axios';

const SoftwareCard = ({ title, imagePath, description, handleDownload, software }) => {
    return (
        <Card sx={{ maxWidth: 345, height: 250 }}>
            <CardHeader
                sx={{ height: 72 }}
                title={title}
                avatar={
                    <Avatar
                        sx={{ bgcolor: purple[500] }}
                        alt={title[0]}
                        src={`${BASE_URL}/${imagePath}`} >
                    </Avatar>
                }
            />
            < CardContent sx={{ height: 100, overflow: "hidden" }}>
                <Typography variant="body2" color="text.secondary">
                    {description}
                </Typography>
            </CardContent >
            <CardActions disableSpacing>
                <Button endIcon={<GetAppIcon />} onClick={() => handleDownload(software)}>
                    Download
                </Button>
                {/* <IconButton onClick={() => handleDownload(software)}>
                    <GetAppIcon color='primary' />
                </IconButton> */}
            </CardActions>
        </Card >
    )
}

export default SoftwareCard