import React from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useForm } from "../../../../hooks/formHook";
import ListForm from "./ListForm";
import { addProjectApi } from "./api";
import { useNavigate } from "react-router-dom";
import paths from "../../../../api/paths.api.js";

export default function ProjectForm() {
  const { onChange, onSubmit, values, setValues } = useForm(
    createProjectCallback,
    {
      name: "EP-",
      projects: [
        // { id: "1", name: "No-vessel-12", type: "Pressure Vessel", folderUploaded: false },
      ],
    }
  );

  const navigate = useNavigate();

  async function createProjectCallback() {
    console.log({ values });
    const data = await addProjectApi(values);
    console.log("data", data);
    navigate("/projects/" + data.data.id + "/equipments");
  }

  function addEquip(equip) {
    values.projects.push(equip);
  }

  function editEquip(equip) {
    // let foundIndex = values.equipments.findIndex((obj) => obj.id === equip.id);
    // values.equipments[foundIndex] = equip;
    let newList = values.projects.filter((obj) => obj.id !== equip.id);
    newList.splice(equip.id - 1, 0, equip);
    setValues({
      ...values,
      projects: newList,
    });
    console.log({ values });
  }

  function deleteEquip(equip) {
    let newList = values.projects.filter((obj) => obj.id !== equip.id);

    setValues({
      ...values,
      projects: newList,
    });
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Paper elevation={3} sx={{ marginRight: "15%", marginLeft: "15%" }}>
        <Box sx={{ padding: 5 }}>
          {/* project form head */}
          <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
              <Typography
                variant="h6"
                gutterBottom
                sx={{ padding: 1, fontWeight: "bold" }}
              >
                New Project :
              </Typography>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                required
                id="name"
                name="name"
                label="Name"
                value={values.name}
                fullWidth
                size="small"
                autoComplete="off"
                variant="standard"
                onChange={onChange}
              />
            </Grid>
          </Grid>
          {/* project form list */}
          <ListForm
            equips={values.projects}
            addEquip={addEquip}
            editEquip={editEquip}
            deleteEquip={deleteEquip}
          />
          {/* project form next */}
          <Box sx={{ overflow: "hidden", padding: 2 }}>
            <Button
              variant="contained"
              sx={{ color: "#fff", float: "right" }}
              onClick={onSubmit}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
}
