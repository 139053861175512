import React, { useEffect, useMemo, useState } from 'react';
import {MaterialReactTable} from 'material-react-table';
// import { data } from './copy tabs/makeData';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useForm, useFieldArray, useFormContext, Controller, useWatch } from "react-hook-form";
import Grid from '@mui/material/Grid';
import CustomAlert from '../../../../../../components/widgets/CustomAlert';
import { Stack } from '@mui/material';
import { saddleTabData } from '../../calculation_tabs/inputFields/saddleInputFormat';
import CustomTextField from "../../../../../../components/widgets/CustomTextField"
import InputAdornment from '@mui/material/InputAdornment';

import axios from '../../../../../../api/axios';
import { estimationColumns } from './estimationColumns';



const GridStyle = {
    "&.MuiGrid-item": { p: "8px 0px 0px 16px" }
}
const tableProps = {
    muiTableHeadCellProps: {
        sx: {
            borderRight: '1px solid rgba(224,224,224,1)',
            borderTop: '1px solid rgba(224,224,224,1)',
            "& .Mui-TableHeadCell-Content-Wrapper": {
                whiteSpace: "normal",
                justifyContent: "center",
                marginBottom: "0.4px"
            },
            fontSize: "0.8rem",
            verticalAlign: "middle",
            height: "80.4px"
        }
    },
    muiTableProps: {
        size: "small",
        sx: {
            "& .MuiInputBase-root": {
                fontSize: '0.8rem',
                borderRadius: 0,

            },
            "& .MuiButtonBase-root": {
                fontSize: '0.8rem'
            },

        }
    },
    muiTableBodyCellProps: {
        sx: {
            borderRight: '1px solid rgba(224,224,224,1)',
            padding: "0rem",
            paddingLeft: "5px",
            height: "36.4px"
        },
    },
    initialState: { density: 'compact' }
}

//The Main Function
const SaddleTab = (props) => {


    const { reRender } = props;

    // const [tableData, setTableData] = useState(() => data);
    const [resultVisibility, setresultVisibility] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [estimationVisibility, setEstimationVisibility] = useState(false);
    const [showProgressBars, setShowProgressBars] = useState(false);
    const { control, handleSubmit, setValue, getValues } = useFormContext();
    const { fields: estimationFields, append: estimationAppend } = useFieldArray({
        control,
        name: 'DataGridView13'
    });

    const [openAlert, setOpenAlert] = useState(false)
    const [AlertContent, setAlertContent] = useState({
        severity: "",
        message: ""
    })

    useEffect(() => {
        setValue("TxtBx_A_1", getValues("TxtBx_A_1") || 600)
        setValue("TxtBx_DisBtwnSdls", getValues("TxtBx_DisBtwnSdls") || 2000)
        setValue("TxtBx_Wb_CntrLin", getValues("TxtBx_Wb_CntrLin") || 838.2)
        setValue("TxtBx_Wb_CntctAngl", getValues("TxtBx_Wb_CntctAngl") || 120)
        setValue("TxtBx_Wb_Thck", getValues("TxtBx_Wb_Thck") || 12.7)
    }, [])

    const showEstimation = async () => {
        const data = getValues();
        setShowProgressBars(true)
        setIsLoading(true)
        setEstimationVisibility(true)
        const result = await axios.post("/pressure_vessel/estimateSaddle", data)
            .then((response) => {
                console.log(response.data)
                setValue("DataGridView13", response.data)
                setIsLoading(false)
                setShowProgressBars(false)
                return response
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div style={{
            width: "100%", minWidth: "610px",

        }}>

            <CustomAlert open={openAlert} setOpen={setOpenAlert} severity={AlertContent.severity} message={AlertContent.message} />

            <Stack spacing={2} direction="row">
                <Button
                    color="secondary"
                    // marginBottom="20px"
                    onClick={() => showEstimation()}
                    variant="contained"
                >
                    Estimate
                </Button>
            </Stack>

            <br />
            <Stack spacing={2} direction="row">
                <Grid container item xs={4} lg={4} sx={{
                    ml: 2,
                    mb: 0.5,
                    p: 2,
                    pt: 1,
                    pb: 1,
                    borderRadius: "8px",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    backgroundColor: "#FFFFFF",
                }}>
                    {
                        saddleTabData.map(input => {
                            return (
                                <Grid ley={input.label} container item columns={6}>
                                    <Grid item sx={{ ...GridStyle }} xs={3} lg={3}>
                                        <Typography textAlign={"right"}>{input.label}</Typography>
                                    </Grid>
                                    <Grid item sx={{ ...GridStyle }} xs={3} lg={2}>
                                        <CustomTextField
                                            hiddenLabel={true}
                                            id={input.txtFieldName}
                                            name={input.txtFieldName}
                                            type={input.type}
                                            required={true}
                                            pairField={input.pairField || null}
                                            inputProps={{
                                                endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                                // style: { width: "20ch", fontSize: "0.875rem", height: "24px" }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
                {
                    estimationVisibility ?
                        <Grid item xs={5}>
                            <MaterialReactTable
                                columns={estimationColumns}
                                data={estimationFields}
                                enableColumnActions={false}
                                {...tableProps}
                                state={{
                                    isLoading
                                    // showProgressBars,
                                    // showSkeletons: false,
                                }}
                                enableTopToolbar={false}
                                enableBottomToolbar={false}
                            />
                        </Grid>
                        : <></>
                }
            </Stack>
            <br />
        </div >
    );
};

export default SaddleTab;
