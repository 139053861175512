import { MaterialReactTable } from 'material-react-table'
import React from 'react'
import { RACIColumns } from '../../../PCSTab/Table/utils'
import { fabricationColumns } from '../../../PCSTab/Fabrication/utils'
import NonEditableTable from '../../../../../../../components/Tables/NonEditableTable'
import { Box, Dialog, DialogContent, DialogContentText, Typography } from '@mui/material'

const TrackItemDialog = ({ open, onClose, item, data }) => {

    var cols
    if (item?.type === "Engineering")
        cols = RACIColumns
    else if (item?.type === "Fabrication")
        cols = fabricationColumns

    // TODO: Add the name of the task to the columnlist or to the title

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent>
                {
                    data ?
                        <NonEditableTable
                            columns={cols || []}
                            data={[]}
                        />
                        :
                        <DialogContentText>
                            <Typography variant="h6" marginTop={2}>
                                Data for Item not Available
                            </Typography>
                        </DialogContentText>
                }
            </DialogContent>
        </Dialog>
    )
}

export default TrackItemDialog