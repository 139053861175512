export const saddleTabData = [
    {
        label: "Support Distance From Ref",
        txtFieldName: "TxtBx_A_1",
        required: true,
        inputProps: "mm",
        type: "number",
        pairField: "true"
    },
    {
        label: " Distance between Saddles",
        txtFieldName: "TxtBx_DisBtwnSdls",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Vessel outer Diameter",
        txtFieldName: "TxtBx_VOD",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Vessel C.L. to B.P. 'B':",
        txtFieldName: "TxtBx_Wb_CntrLin",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Contact Angle 'Theta':",
        txtFieldName: "TxtBx_Wb_CntctAngl",
        required: true,
        inputProps: "deg",
        type: "number",
        pairField:"true"
    },
    {
        label: "Thickness 'D':",
        txtFieldName: "TxtBx_Wb_Thck",
        required: true,
        inputProps: "mm",
        type: "number"
    },

]


export const inputData = [
    {
        label: "Load on One Saddle",
        variable: "Q",
        txtFieldName: "txt_Q_SC",
        required: true,
        inputProps: "kg",
        type: "number"
    },
    {
        label: "Radius of the Shell",
        variable: "R",
        txtFieldName: "txt_R_SC",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Allowable Stress",
        variable: "S",
        txtFieldName: "txt_AS_SC",
        pairField: "true",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
    {
        label: "Length of the Shell",
        variable: "L",
        txtFieldName: "txt_LS_SC",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Length of the Head",
        variable: "H",
        txtFieldName: "txt_LH_SC",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Saddle Distance from Tan",
        variable: "A",
        txtFieldName: "txt_SD_SC",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Shell Thickness",
        variable: "ts",
        txtFieldName: "txt_TS_SC",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Head Thickness",
        variable: "th",
        txtFieldName: "txt_TH_SC",
        required: true,
        inputProps: "mm",
        type: "number"
    },
    {
        label: "Contact angle of saddle",
        variable: "\u03D1",
        txtFieldName: "txt_CA_SC",
        required: true,
        inputProps: "mm",
        type: "number"
    },
]

export const LongitudinalBending = [
    {
        label: "At Saddle",
        variable: "S1 = ",
        numerator: "Q * A ( 1 - ( A / L) + ( R^2 - H^2 / 2AL ) / 1 + ( 4H / 3L )) ",
        denominator: "K1 * R^2 * ts",
        txtFieldName: "TextBox143",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
    {
        label: "At Midspan",
        variable: "S1 = ",
        numerator: "QL/4 ( 1 + 2 ( R^2 - H^2 / L^2 ) / 1 + 4H / 3L ) - ( 4A / L)",
        denominator: "Pi * R^2 * ts",

        txtFieldName: "TextBox144",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
]

export const TangentialShear = [
    {
        label: "Saddle away from Head, A > R/2",
        variable: "S2 = ",
        numerator: "K2 * Q  ( L - 2A )",
        denominator: "R * ts  ( L + 4/3 H )",
        formula: `K2 * Q  ( L - 2A )\n------------------\nR * ts  ( L + 4/3 H )`,
        txtFieldName: "TextBox145",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
    {
        label: "Saddle close to Head, A <= R/2",
        variable: "S2 = ",
        numerator: "K4 * Q",
        denominator: "R * ts",
        formula: `K4 * Q \n------\nR * ts`,
        txtFieldName: "TextBox146",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
    {
        label: "",
        variable: "S3 = ",
        numerator: "K5 * Q",
        denominator: "R * th",
        formula: `K5 * Q \n------\nR * th`,
        txtFieldName: "TextBox147",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
]

export const circumferentialStress = [
    {
        label: "L >= 8R, Unstiffened",
        variable: "S4 = ",

        formula: `Q  \n
        ---------------------  - \n
        4 ts ( b + 1.56 SQRT(R * ts ))`,
        formula2: `3 * K6 * Q  \n
        ---------------- \n
        2 * ts^2`,
        numerator1: "Q",
        denominator1: "4 ts ( b + 1.56 SQRT(R * ts))",
        numerator2: "3 * K6 * Q ",
        denominator2: "2 * ts^2",
        txtFieldName: "TextBox148",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
    {
        label: "L < 8R, Unstiffened",
        variable: "S4 = ",
        formula: `Q  \n
        -------------------------------    - \n
        4 ts ( b + 1.56 SQRT(R * ts))`,
        formula2: `12 * K6 * Q * R  \n
        ------------------- \n
        L * ts^2`,
        numerator1: "Q",
        denominator1: "4 ts ( b + 1.56 SQRT(R * ts ))",
        numerator2: "12 * K6 * Q * R  ",
        denominator2: "L * ts^2",
        txtFieldName: "TextBox149",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
    {
        label: "Stiffened or Unstiffened",
        variable: "S5 = ",
        formula: `K7 * Q  \n
        -------------------------- \n
        ts ( b + 1.56 SQRT(R * ts))`,
        formula2: ``,
        numerator1: "Q",
        denominator1: "4 ts ( b + 1.56 SQRT(R * ts ))",
        numerator2: "12 * K6 * Q * R  ",
        denominator2: "L * ts^2",
        txtFieldName: "TextBox150",
        required: true,
        inputProps: "N/mm^2",
        type: "number"
    },
]