import React from "react";
import { estimationColumns, resultColumns } from "./columnsData/columns";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  compactTableProps,
  smallerHeadCellProps,
} from "../../../../../../styles/muiProps";
import { Button, Grid } from "@mui/material";
import {MaterialReactTable} from "material-react-table";
import axios from "../../../../../../api/axios";
import { createShellTasks } from "../../../../../../api/tasksApi";

const EstimationTable = (props) => {
  const { isLoading, projectId, equipId } = props;
  const { control, getValues } = useFormContext();
  const { fields: estimationFields } = useFieldArray({
    control,
    name: "DataGridView7",
  });

  const addToTasks = async () => {
    const input = getValues("DataGridView5");
    const estimation = getValues("DataGridView7");
    let subCategoryRequest = {
      subCategory: {
        name: "",
        estimatedHours: 0,
        remarks: "",
        invoiceable: false,
        // categoryId: row.id
      },
      users: [],
    };
    console.log(input);
    let categoryRequest = {
      name: "Shell Welding",
      mcsProjectId: projectId,
      // tasks: data
    };

    await createShellTasks(input, estimation, projectId, equipId);
  };

  return (
    <MaterialReactTable
      columns={estimationColumns}
      data={estimationFields ?? []}
      {...compactTableProps}
      {...smallerHeadCellProps}
      enableColumnActions={false}
      // enableRowActions={false}
      enableEditing={true}
      editDisplayMode={"cell"}
      initialState={{ density: "compact" }}
      state={{
        isLoading: isLoading,
      }}
      muiTableBodyCellProps={({ cell }) => ({
        sx: {
          textAlign: "center",
          borderRight: "1px solid #ddd",
        },
      })}
      
      renderTopToolbarCustomActions={() => (
        <Button
          color="secondary"
          onClick={() => addToTasks()}
          variant="contained"
          size="small"
        >
          Add to Tasks
        </Button>
      )}
    />
  );
};

export default EstimationTable;
