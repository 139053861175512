import React, { useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { IconButton } from "@mui/material";

export default function MoreOptions() {

  return (
    <IconButton>
      <MoreHorizIcon />
    </IconButton>
  );
}
