import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import { Typography } from '@mui/material';

const RevisionsDialog = (props) => {
    const { onClose, selectedValue, setSelectedValue, open, revisions } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
        setSelectedValue(value)
        onClose(value);
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Select a revision to preview</DialogTitle>
            <List dense sx={{
                overflow: 'auto',
                maxHeight: 300,
            }}>
                {
                    revisions ?
                        Object.keys(revisions)?.map((revision) => (
                            <ListItem disableGutters key={revision}>
                                <ListItemButton onClick={() => handleListItemClick(revision)}>
                                    <ListItemText primary={`Revision: ${revision}`} />
                                </ListItemButton>
                            </ListItem>
                        ))
                        :
                        <Typography>
                            No revisions found
                        </Typography>
                }
            </List>
        </Dialog>
    );
}

export default RevisionsDialog