import axios from "./axios.js"

const CreateSrvRoute = "/srv/create"
const getAllSrvsRoute = "/srv/all"
const EditSrvRoute = "/srv/edit"
const DeleteSrvRoute = "/srv/delete"

export const createSRV = async (srv) => {
    return await axios.post(CreateSrvRoute, srv)
}

export const getSrv = async (equipId) => {
    console.log("sendign to ", `${getAllSrvsRoute}/${equipId}`)
    return await axios.get(`${getAllSrvsRoute}/${equipId}`)
}

export const getAllSrv = async (equipId) => {
    return await axios.get(`${getAllSrvsRoute}`)
}

// export const updateSrv = async (srv) => {
//     return await axios.put(addSrvMaterial, srv)
// }