import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import {
  TypesIcon,
  equipTypesIcons,
} from "../../../../../../components/CustomIcons/EquipsTypesIcons";

export default function ListItemForm({
  formCase,
  index,
  currentItem,
  onChange,
  onSubmit,
}) {
  let isNew = formCase === "new";

  return (
    <Grid container>
      {/* Number of this item */}
      <Grid item xs={12} sm={1} sx={{ fontWeight: "bold", paddingTop: 1 }}>
        {index ? `${index} -` : "New"}
      </Grid>
      {/* Item itself */}

      <Grid item xs={12} sm={3} sx={{ paddingRight: 1 }}>
        <FormControl fullWidth size="small">
          <InputLabel id="equip-type-label">Eng. discipline</InputLabel>
          <Select
            labelId="equip-type-label"
            id="equip-type"
            name="type"
            value={currentItem.type}
            label="Eng. discipline"
            onChange={onChange}
          >
            {/* {equipsTypes.map((item) => (
              <MenuItem key={item} value={item}>
                {"- " + item}
              </MenuItem>
            ))} */}
            {equipTypesIcons.map((type) => {
              return (
                <MenuItem key={type.category} value={type.category}>
                  <TypesIcon type={type.category} />
                  <ListItemText sx={{ display: "inline-block" }}>
                    {type.category}
                  </ListItemText>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3} sx={{ fontWeight: "bold", paddingRight: 1 }}>
        <TextField
          required
          id="name"
          name="name"
          label="Equip/sub. Assembly Name"
          value={currentItem.name}
          fullWidth
          size="small"
          autoComplete="off"
          variant="outlined"
          onChange={onChange}
        />
      </Grid>

      <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
        <Button variant="contained" sx={{ color: "#fff" }} onClick={onSubmit}>
          {isNew ? "Add" : "Edit"}
        </Button>
      </Grid>
    </Grid>
  );
}
