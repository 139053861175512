import React, { useEffect } from "react";
import TabPanel from "../../../../components/widgets/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import CalculationTab from "./ParentTabs/Calculation";

// Form provider
import { useForm, FormProvider, useFormContext } from "react-hook-form";
// import MCSPage from './ParentTabs/MCS';
import { ThemeProvider } from "@emotion/react";
import NewViewProject from "../../../admin/ViewProject2";
import { useLocation } from "react-router-dom";
import axios from "../../../../api/axios";
import { Alert, Snackbar, Typography } from "@mui/material";
import FileDrawer from "../../../../components/Drawers/FileDrawer";
import { theme } from "../../../../styles/theme";
import DataTabs from "./StructuralData";
import { tabProps, tabScrollerProps } from "../../../../styles/muiProps";
import MCSPage from "../CommonFeatures/MCSTab";

//changed routes to get and post from and to sharepoint
const MainStructure = (props) => {
  // get the state
  const [currentTab, setCurrentTab] = React.useState(1); //value of the current tab
  const [openDrawer, setOpenDrawer] = React.useState(false); //file menu bar
  const [open, setOpen] = React.useState(false);
  const [key, setKey] = React.useState(Math.random());
  const [projectId, setProjectId] = React.useState();
  const { state } = useLocation();
  const { project, equipment } = state;
  console.log(state);
  const location = useLocation();
  const categorizedProjectId = location.pathname.split("/")[2];
  const subAssemblyRoute =
    project.number +
    "/" +
    equipment.type +
    "/" +
    equipment.name +
    "/" +
    equipment.rev;
  console.log(subAssemblyRoute);

  //form handler
  const methods = useForm();

  //opening the file menu
  const toggleDrawer = (state) => {
    setOpenDrawer(state);
  };

  //handles changes in tab
  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      toggleDrawer(true);
      return;
    }
    setCurrentTab(newValue);
  };

  const reRender = () => {
    setKey(Math.random());
  };

  useEffect(() => {
    let label = "Project No." + project.number + equipment.name;
    const getProjectFile = async () => {
      const read = await axios
        .get("/pressure_vessel/getFile/" + subAssemblyRoute)
        .then((response) => {
          console.log("retrieved:", response.data);
          let prevData = response.data.data;
          methods.reset(prevData);
          // return prevData
        })
        .catch((err) => {
          console.log("error", err);
          // return (0)
        });
    };
   // getProjectFile();
  }, []);

  // saving the data to the txt file
  const onSave = async () => {
    const data = methods.getValues();
    console.log("data", data);
    const result = await axios
      .post("/pressure_vessel/create/" + subAssemblyRoute, data)
      .then((response) => {
        setOpen(true);
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //closing the file menu
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const equipMainTabs = [
    "File",
    "Data",
    "Calc.",
    "Est.",
    "MCS",
    "DCS",
    "PCS",
    "PMS",
    "Software",
  ];
  return (
    <ThemeProvider theme={theme}>
      <FormProvider {...methods}>
        {" "}
        {/*  pass all methods into the context */}
        <Box sx={{ width: "100%" }}>
          <Box {...tabScrollerProps}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
              <Alert
                onClose={handleClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                File created successfully!
              </Alert>
            </Snackbar>
            <Tabs value={currentTab} onChange={handleTabChange} {...tabProps}>
              {equipMainTabs.map((tabName) => {
                return <Tab key={tabName} label={tabName} />;
              })}
              {/* <div >
              <Typography variant="h7" className="right"> 
                  Project No. <span>{project.number}</span> /{" "}
                  <span>{equipment.name}</span>
                </Typography>
              </div> */}
              {/* <Tab key="projectName" 
              label={`Project No. ${project.number} ${equipment.name}`} 
              onClick={false}
              /> */}
              <Typography variant="h5" color={"secondary"} m={"auto 10px"}>
                STRUCTURAL
              </Typography>
              <Typography variant="h6" color={"secondary"} m={"auto 0px"}>
                {props.isSkid ? ``:`: Project No. ${project.number} ${equipment.name}`}
              </Typography>
            </Tabs>

            <FileDrawer
              onSave={() => onSave()}
              openDrawer={openDrawer}
              setOpenDrawer={() => setOpenDrawer()}
            />

            <TabPanel
              backgroundColor="transparent"
              value={currentTab}
              index={1}
              padding={0}
            >
              <DataTabs projectId={project.id || ""} />
              {/* < PVDataPage projectId={projectId ?? ""} /> */}
            </TabPanel>

            <TabPanel value={currentTab} index={2} padding={1}>
              <CalculationTab
                key={123456}
                calculationResult={1}
                getCalculation={1}
              />
            </TabPanel>

            <TabPanel value={currentTab} index={3}>
            </TabPanel>

            <TabPanel value={currentTab} index={4} padding={1}>
            </TabPanel>

            <TabPanel value={currentTab} index={5} padding={1}>
            </TabPanel>

            <TabPanel value={currentTab} index={6} padding={1}>
            </TabPanel>

            <TabPanel value={currentTab} index={7} padding={1}>
            </TabPanel>
            <TabPanel value={currentTab} index={8} padding={1}>
            </TabPanel>
          </Box>
        </Box>
      </FormProvider>
    </ThemeProvider>
  );
};

export default MainStructure;
