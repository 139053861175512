export const inputData = [
    {
        label:"Diameter of Shell",
        variable: "D",
        txtFieldName:"txt_SD_NC1",
        required:true,
        inputProps:"mm",
        type: "number",
        pairField: "true"
    },
    {
        label:"Design Pressure",
        variable: "P",
        txtFieldName:"txt_DP_NC1",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        pairField: "true"
    },
    {
        label:"Shell Material",
        variable: "",
        txtFieldName:"txt_SM_NC1",
        required:true,
        // type: "number",
    },
    {
        label:"Shell Provided Thickness",
        variable: "t",
        txtFieldName:"txt_SPT_NC1",
        required:true,
        inputProps:"mm",
        // type: "string",
        pairField: "true"
    },
    {
        label:"Allowable Stress",
        variable: "S",
        txtFieldName:"txt_SAS_NC1",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
    },
    {
        label:"Nozzle Material",
        txtFieldName:"txt_NM_NC1",
        required:true,
        // type: "number",
        // pairField: "TextBox31"
    },
    {
        label:"Nozzle Allowable Stress",
        variable:"Sn",
        txtFieldName:"txt_NAS_NC1",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        pairField: "true"
    },
    {
        label:"Nozzle Provided Thickness",
        variable:"tn",
        txtFieldName:"txt_NPT_NC1",
        required:true,
        inputProps:"mm",
        type: "number",
        pairField: "true"
    },
    {
        label:"Nozzle Diameter",
        variable:"Dn",
        txtFieldName:"txt_ND_NC1",
        required:true,
        inputProps:"mm",
        // type: "number",
        pairField: "true"
    },
    {
        label:"Fillet Weld Size",
        txtFieldName:"txt_FWSI_NC1",
        required:true,
        inputProps:"mm",
        type: "number",
        // pairField:"txt_SF"
    },
    {
        label:"Inside Projection",
        variable:"h",
        txtFieldName:"txt_FWSO_NC1",
        required:true,
        inputProps:"mm",
        type: "number",
        // pairField:"TextBox92"
    },
    
]

export const outputData =[
    {
        label:"For Shell : tr = (P * R) / ((S * E) - (0.6 * P)),",
        labelId: "Label270",
        sublabelId: "Label43",
        variable:"tr=",
        txtFieldName:"txt_TR_NC1",
        required:true,
        inputProps:"mm",
        type: "number"
    },
    {
        label:"For Nozzle : trn = (P * Rn) / ((S * E) - (0.4 * P)),",
        labelId: "Label367",
        sublabelId: "Label44",
        variable:"trn=",
        txtFieldName:"txt_TRN_NC1",
        required:true,
        inputProps:"mm",
        type: "number"
    },
]

export const reinforcementRequired =[
    {
        label:"Ar = (DLR * TR + 2 * THK * TR * (1 - FFR1)",
        variable:"Ar=",
        txtFieldName:"txt_AR_NC1",
        required:true,
        inputProps:"mm^2",
        type: "number"
    },
    
]

export const reinforcementAvailable =[
    {
        label:"A1 = ( DL-DLR)*(ES*(T-CAS)-TR)-2*(THK-CAN)*(ES*(T-CAS)-TR)*(1-FFR1)",
        label2:"labelA1",
        variable:"Area Available in Shell,  A1 =",
        txtFieldName:"txt_A1_NC1",
        required:true,
        inputProps:"mm^2",
        type: "number"
    },
    {
        label:"A2 = ( 2 * MIN(TLNP,HO) ) * ( THK - CAN - TRN ) * FFR2",
        label2:"labelA2",
        variable:"Area Available in Nozzle Wall,  A2 =",
        txtFieldName:"txt_A2_NC1",
        required:true,
        inputProps:"mm^2",
        type: "number"
    },
    {
        label:"A3 = 2 * (TN-CAN-CAN) * MIN(H-CAN, TL, 2.5*(TN-CAN-CAN)) * FFR2",
        label2:"labelA3",
        variable:"Area Available in Nozzle Penetration, A3 =",
        txtFieldName:"txt_A3_NC1",
        required:true,
        inputProps:"mm^2",
        type: "number"
    },
    {
        label:"A4 = Wo^2*FFR2 + ( Wi-Can/0.707 )^2*FFR2",
        label2:"labelA4",
        variable:"Area Available in Weld, A4 =",
        txtFieldName:"txt_A4_NC1",
        required:true,
        inputProps:"mm^2",
        type: "number"
    },
    {
        label:"A5 = (MIN(DP,DL)-(DIA+2*THK))*(Min(TP,TLWP,TE))*FFR4",
        label2:"labelA5",
        variable:"Area Available in Pad, A5 =",
        txtFieldName:"txt_A5_NC1",
        required:true,
        inputProps:"mm^2",
        type: "number"
    },
    {
        label:"Total Area Available",
        label2:"labelTtl",
        variable:"Aa =",
        txtFieldName:"txt_AA_NC1",
        required:true,
        inputProps:"mm^2",
        type: "number"
    },
    
]
