export const MRQDetailsGrid = [
  {
    label: "Project Name",
    field: "project",
    grid: 0.8,
  },
  {
    label: "Made By",
    field: "drawnBy",
    grid: 0.6,
  },
  {
    label: "Checked By",
    field: "checkedBy",
    grid: 0.8,
  },
  {
    label: "Date",
    field: "date",
    grid: 0.5,
  },
  {
    label: "MRQ Number",
    field: "mrqNumber",
    grid: 0.8,
  },
  {
    label: "MRQ Rev",
    field: "rev",
    grid: 0.6,
  },
  {
    label: "Title",
    field: "title",
    grid: 0.8,
  },
  {
    label: "From",
    field: "from",
    grid: 0.8,
  },
  {
    label: "Rev",
    field: "rev",
    grid: 0.6,
  },
  {
    label: "Sheet No.",
    field: "sheetNumber",
    grid: 0.8,
  },
  // {
  //     label: "Rev",
  //     field: "rev",
  //     grid: 0.8
  // },
  // {
  //     label: "Sheet No.",
  //     field: "sheet",
  //     grid: 0.6
  // },
];

// interface mrqMaterialsTableProps {
//     setEditedMaterials: React.Dispatch<React.SetStateAction<Record<string, Materials>>>
// }
