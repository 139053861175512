import React from "react";
import { resultColumns } from "./columnsData/columns";
import { useFieldArray, useFormContext } from "react-hook-form";
import {
  compactTableProps,
  smallerHeadCellProps,
} from "../../../../../../styles/muiProps";
import {MaterialReactTable} from "material-react-table";

const ResultTable = (props) => {
  const { isLoading } = props;
  const { control } = useFormContext();
  const { fields: resultFields } = useFieldArray({
    control,
    name: "DataGridView6",
  });
  return (
    <MaterialReactTable
      columns={resultColumns}
      data={resultFields ?? []}
      {...compactTableProps}
      {...smallerHeadCellProps}
      enableColumnActions={false}
      initialState={{ density: "compact" }}
      state={{
        isLoading: isLoading,
      }}
      muiTableBodyCellProps={({ cell }) => ({
        sx: {
          textAlign: "center",
          borderRight: "1px solid #ddd",
        },
      })}
      muiEditTextFieldProps={({ cell }) => ({
        sx: {
          "& .MuiInputBase-input": {
            padding: "8.5px 8px",
            textAlign: "center",
          },
        },
      })}
      muiTableHeadCellProps={({ cell }) => ({
        sx: {
          borderRight: "1px solid rgba(224,224,224,1)",
          backgroundColor: "hsla(250, 86%, 97%, 1)",
          fontSize: "16px",
          pt: 2,
        },
      })}
    />
  );
};

export default ResultTable;
