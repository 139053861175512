import axios from "../../../api/axios";

const getExcelData = "/excel/getData";
const getMaterialDBApi = "/materialdb";
const getAllMaterialDBApi = "/materialdb/all";

export const getExcelSheetData = async () => {
  const { data } = await axios.get(getExcelData);
  return data;
};

export const getAllMaterialDB = async () => {
  const { data } = await axios.get(getAllMaterialDBApi);
  return data;
};
export const getMaterialDB = async ({ projectId, equipId }) => {
  let params = "";
  if ((projectId, equipId)) {
    params = `/${projectId}/${equipId}`;
  } else if (projectId) {
    params = `/${projectId}/`;
  } else {
    params = "";
  }

  const { data } = await axios.get(getMaterialDBApi + params);
  return data;
};
