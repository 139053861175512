import React from 'react'

import CustomTextField from "../../../../../components/widgets/CustomTextField"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import '@fontsource/roboto/300.css';
import InputAdornment from '@mui/material/InputAdornment';


const GridStyle = {
    "&.MuiGrid-item": { p: "3px 0px 0px 5px" }
}

const subHeadingStyle = {
    color: "#4e5fa2",
    fontWeight: "bold",
    mb: "5px"
}

const typographySize = {
    fontSize: "0.8rem"
}

const CalcTabTemplate = (props) => {
    const { inputData, outputData } = props;
    return (
        <div width='100%' >
            {/* Input data */}
            < Grid container spacing={0} sx={{ alignItems: "center" }
            } columns={{ xs: 12, lg: 11, xl: 12 }}>
                {
                    inputData.map(input => {
                        return (
                            <Grid item container >
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={2}>
                                    <Typography {...typographySize}>{ input.label}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={0.4} lg={0.3}>
                                    <Typography textAlign="right" sx={{ fontWeight: '100', fontSize: "0.75rem" }}>{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={2}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        required={true}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            // style: { width: "25ch", fontSize: "0.8rem", height: "18px" }
                                            // style: { width: "25ch"}
                                        }}
                                    />
                                    {/* <Typography>{input.label}</Typography> */}
                                </Grid>
                                {/* <Grid item sx={{ ...GridStyle }} xs={6} lg={6}></Grid> */}
                            </Grid>
                        )
                    })
                }
            </Grid >

            {/* Output */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Output</Typography>
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 12, xl: 12 }}>
                <Table sx={{ width: 580 }} size="small" aria-label="simple table" >
                    <TableBody>
                        {outputData.map(input => (
                            <TableRow
                                key={input.label}
                                sx={{ border: "none" }}
                            >
                                <TableCell component="th" scope="input" sx={{ padding: "5px", width: "150px", border: "none", ...typographySize }}>
                                    {input.label}
                                </TableCell>
                                <TableCell align="right" sx={{ padding: "5px", width: "60.5px", border: "none" }}>{input.variable}</TableCell>
                                <TableCell sx={{ padding: "5px", border: "none" }}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            style: {
                                                width: "25ch", fontSize: "0.7rem", height: "18px",
                                                backgroundColor: input.disabled ? "#e5e8f2" : "white", color: input.disabled ? "black" : "black"
                                            }
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid >
        </div>
    )
}

export default CalcTabTemplate