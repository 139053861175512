import { React, useState, useContext, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import sideImg from '../../../images/auth_form_eng.jpg'
import { AuthContext } from '../../../context/authContext'
import axios from "../../../api/axios"
import { GuestRegisterRoute, VerifyRegisterTokenRoute } from "../../../routes"


const GuestDetails = () => {
    const [input, setInput] = useState({
        email: "",
        username: "",
        password: "",
        confirmPassword: "",
    })

    const [error, setError] = useState("")


    const navigate = useNavigate()

    const location = useLocation()
    useEffect(() => {
        const verifyTokenRoute = async () => {

            await axios.get(VerifyRegisterTokenRoute + location.pathname.split('/')[3])
                .then((response) => {
                    setInput(prevData => ({
                        ...prevData,
                        email: response.data
                    })
                    )
                })
                .catch((error) => { setError(error.data) })

        }
        verifyTokenRoute()
    }, [])
    // const {login} = useContext(AuthContext)
    //Handle Changes in form
    function changeHandler(event) {
        const { name, value } = event.target
        setInput(prevData => ({
            ...prevData,
            [name]: value
        }))

    }

    {/**Might not be required with form 'required'? */ }
    const submitHandler = async (event) => {
        // event.preventDefault()
        // //console.log(event)
        if (!input.username) {
            setError("Username required!")
        }
        else if (!input.password) {
            setError("Password required!")
        }
        else if (input.password.length < 8) {
            setError("Password should be atleast 8 characters long!")
        }
        else if (input.confirmPassword !== input.password)
            setError("Passwords do not match!")
        else {
            await axios.post(GuestRegisterRoute, input)
                .then((response) => {
                    //console.log(response)
                    navigate("/guest/login")
                })
                .catch((err) => setError(err.data.message))
            // //console.log(err)
        }
    }


    //console.log(input)

    return (
        <div className="auth-container">
            <img src={sideImg}></img>
            <div className="auth">
                <h1>Register</h1>
                <form>
                    <p className="error">{error}</p>
                    <input required type="text"
                        placeholder="Username"
                        name="username"
                        value={input.username}
                        onChange={changeHandler} />
                    <input required type="password"
                        placeholder="Password"
                        name="password"
                        value={input.password}
                        onChange={changeHandler}
                    />
                    <input required type="password"
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={input.confirmPassword}
                        onChange={changeHandler}
                    />
                    <button onClick={submitHandler}>Submit</button>

                </form>
            </div>
        </div>
    )
}

export default GuestDetails