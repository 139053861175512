export const getCols = (mrqData, selectedBidVendors) => {
  let vendorsCols = [];

  mrqData?.vendors
    ?.map((v) => v.name)
    .map((v) => {
      return vendorsCols.push({
        id: v,
        label: v,
        minWidth: 170,
        align: "center",
        isVendor: true,
        format: (value) => value.toLocaleString("en-US"),
      });
    });

  const materialCols =
    mrqData?.mrqMaterials &&
    mrqData?.mrqMaterials[0]?.material.taggedMaterial?.id
      ? [
          {
            id: "tagNumber",
            label: "Tag Number",
            minWidth: 120,
            align: "center",
          },
        ]
      : [];

  return [
    { id: "id", label: "", minWidth: 10, align: "center" },
    ...materialCols,
    {
      id: "materialCode",
      label: "Material Code",
      minWidth: 120,
      align: "left",
    },
    { id: "description", label: "Material Description", minWidth: 200 },
    { id: "qty", label: "Quantity", minWidth: 100, align: "center" },
    ...vendorsCols,
    {
      id: "selected",
      label: "Selected Vendor",
      minWidth: 270,
      align: "center",
      isVendor: true,
    },
    {
      id: "po",
      label: "PO",
      minWidth: 140,
      align: "center",
      // isVendor: true,
    },
  ];
};
