import React, { useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { bulkedCols, taggedCols } from "./mrqFormColumns";
import { Alert, Button } from "@mui/material";
import { addQuotationData } from "./api";

const MrqFormTable = ({ data }) => {
  const [input, setInput] = useState([]);
  const [alert, setAlert] = useState("");
  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

  useEffect(() => {
    const tableFormatData =
      data?.mrqMaterials?.map((elem) => ({
        mrqMaterialId: elem?.id,
        description: elem?.description,
        tagNumber: elem?.material.taggedMaterial?.tagNumber,
        materialCode: elem?.materialCode,
        quantity: elem?.material.bulkMaterial?.quantity,
        time: elem?.time,
        cost: elem?.cost,
      })) ?? [];
    setInput(tableFormatData);
  }, [data]);

  console.log({ data });

  const handleSaveCell = (cell, value) => {
    input[cell.row.index][cell.column.id] = value;
    setInput([...input]); //re-render with new data
    setSubmitBtnDisabled(false);
  };

  const handleSubmitForm = async () => {
    const vendorData = input.map((m) => {
      return { ...m, mrqId: data.mrqId, vendorId: data.vendorId };
    });

    await addQuotationData({ vendorData })
      .then((res) => {
        setAlert("success");
      })
      .catch((err) => {
        setAlert("error");
      });
  };

  const cols =
    data?.mrqMaterials && data?.mrqMaterials[0]?.material.taggedMaterial?.id
      ? taggedCols
      : bulkedCols;
  return (
    <MaterialReactTable
      columns={cols}
      enableColumnActions={false}
      enableColumnFilters={false}
      enablePagination={false}
      enableSorting={false}
      enableTopToolbar={false}
      enableBottomToolbar
      // enableRowActions
      enableEditing
      editDisplayMode={"table"}
      data={input ?? []}
      muiEditTextFieldProps={({ cell }) => ({
        //onBlur is more efficient, but could use onChange instead
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value);
        },
      })}
      renderBottomToolbarCustomActions={() => {
        return (
          <div style={{ width: "100%", "& > div": { p: 0 } }}>
            {alert === "" ? (
              <Button
                variant="contained"
                onClick={handleSubmitForm}
                sx={{ float: "right" }}
                disabled={submitBtnDisabled}
              >
                Send Quotation
              </Button>
            ) : (
              <Alert
                severity={alert}
                sx={{ width: "100%" }}
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlert("");
                    }}
                  >
                    Edit
                  </Button>
                }
              >
                Thanks, Changes has been saved.
              </Alert>
            )}
          </div>
        );
      }}
    />
  );
};

export default MrqFormTable;
