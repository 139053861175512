import React, { useEffect, useState } from "react";
import EditableTable from "../../../../../../components/Tables/EditableTable";
import { FabInitialData, fabricationColumns } from "./utils";
import { useParams } from "react-router-dom";
import {
  createFabrication,
  deleteFabrication,
  getFabrication,
  updateFabrication,
} from "./api";
import { secondAxios } from "../../../../../../api/axios";

const FabricationTab = () => {
  const { equipId } = useParams();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const getAllUsers = async () => {
      const users = (await secondAxios.get("/user")).data;
      const usernames = users.map((u) => u.name);
      setUsers(usernames);
      console.log("users", users);
    };
    getAllUsers();
  }, users);
  return (
    <div>
      <EditableTable
        name="Fabrication"
        displayMode="row"
        columns={fabricationColumns(users)}
        enableEditing={true}
        enableCreate={true}
        initialData={FabInitialData}
        api={{
          getItems: getFabrication,
          createItem: createFabrication,
          updateItem: updateFabrication,
          deleteItem: deleteFabrication,
        }}
        parentId={equipId}
      />
    </div>
  );
};

export default FabricationTab;
