// ------Staff auth routes
export const StaffConfirmMailRoute = "/dev/confirm";
export const StaffVerifyRegisterTokenRoute = "/dev/register/";
export const StaffRegisterRoute = "/dev/register";

export const StaffLoginRoute = "/dev/login";
export const StaffLogoutRoute = "/dev/logout";

export const StaffResetPwdEmailRoute = "/dev/resetPassword";
export const VerifyStaffResetPwdRoute = "/dev/reset/";
export const StaffResetPwdRoute = "/dev/reset/";

//------Guest auth routes
export const GuestConfirmMailRoute = "/guest/confirm";
export const VerifyRegisterTokenRoute = "/guest/register/";
export const GuestRegisterRoute = "/guest/register";

export const GuestLoginRoute = "/guest/login";

export const GuestResetPwdEmailRoute = "/guest/resetPassword";
export const VerifyGuestResetPwdRoute = "/guest/reset/";
export const GuestResetPwdRoute = "/guest/reset/";
export const MsLogin = "/login/";

//------Project routes

export const CreateProjectRoute = "/project/create";
export const EditProjectRoute = "/project/edit/:id";
export const GetAllProjectsRoute = "/project/all";
export const GetAllProjectsDataRoute = "/project/allInfo";
export const GetOneProjectRoute = "/project/"; //+id

//------Category routes
export const ProjectRoute = "/project/";
export const CreateCategoryRoute = "/category/create";
export const EditCategoryRoute = "/category/edit/";

//------Subcategory routes
export const CategoryRoute = "/category/";
export const CreateSubCategoryRoute = "/subcategory/create";
export const EditSubCategoryRoute = "/subcategory/edit/";

//------Timesheet routes
export const GetTimesheetDataRoute = "/timesheet/new";
export const CreateTimesheetRoute = "/timesheet/create";
export const EditTimesheetRoute = "/timesheet/edit/:id";
export const GetAllTimesheetsRoute = "/timesheet/all";
export const GetUserTimesheetsRoute = "/timesheet/:id/all";

//-------Invoice Routes
export const CreateInvoiceRoute = "/invoice/create";
export const GetAllInvoiceRoute = "/invoice/all";
