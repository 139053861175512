import {MaterialReactTable} from 'material-react-table'
import React, { useEffect, useRef, useState } from 'react'
import { warehouseColumns } from '../MCSTables'
import { Box, Button, Tab, Tabs } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import MRVDialog from './InventoryTab/MRVDialog';
import { addToWarehouse, removeFromWarehouse } from '../../../../api/warehouseApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getBOM, getDrawings } from '../../../../api/drawingsApi';
import { getAllProjects } from '../../Project/allProjectsPage/api';
import SRVDialog from './InventoryTab/SRVDialog';
import SRVTab from '../SRVTab';
import VerticalTabPanel from '../../../../components/widgets/VerticalTabPanel';
import MCSFlowTable from '../MCSFlowTab/MCSFlowTable';
import InventoryTab from './InventoryTab';
import MasterMaterialTab from './MasterMaterialTab';
import NavTabs from '../../../../components/Tabs/NavTabs';
import { tabProps } from '../../../../styles/muiProps';

const WarehouseTab = (props) => {

    const { poList, poData, mrvData, srvData, warehouseData, materialsMasterData } = props
    const [uiData, setUiData] = useState({
        tabValue: 0
    })


    const handleTabChange = (event, newValue) => {
        setUiData(prev => ({ ...prev, tabValue: newValue }))
    }
    const tabs = [
        {
            label: "Material Receiving Vouchers",
            panelProps: {backgroundColor: "white"},
            panel: (
                <MCSFlowTable data={mrvData} name="MRV" />
            )
        },
        {
            label: "Shop Receiving Vouchers",
            panelProps: {backgroundColor: "white"},
            panel: (
                <MCSFlowTable data={srvData} name="SRV" />
            )
        },
        {
            label: "Inventory",
            panelProps: {backgroundColor: "white"},
            panel: (
                <InventoryTab poList={poList} poData={poData} warehouseData={warehouseData} />
            )
        },
        {
            label: "Materials Master",
            panelProps: {backgroundColor: "white"},
            panel: (
                <MasterMaterialTab data={materialsMasterData} />
            )
        },
    ]

    return (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <NavTabs tabs={tabs} tabProps={tabProps} navLabel="warehouse-tab" bgcolor="white"/>
            {/* <Tabs value={uiData.tabValue} onChange={handleTabChange} >
                <Tab label="Material Receiving Vouchers" />
                <Tab label="Shop Receiving Vouchers" />
                <Tab label="Inventory" />
                <Tab label="Materials Master" />
            </Tabs>
            <VerticalTabPanel value={uiData.tabValue} index={0}>
                <MCSFlowTable data={mrvData} name="MRV" />
            </VerticalTabPanel>
            <VerticalTabPanel value={uiData.tabValue} index={1}>
                <MCSFlowTable data={srvData} name="SRV" />
            </VerticalTabPanel>
            <VerticalTabPanel value={uiData.tabValue} index={2}>
                <InventoryTab poList={poList} poData={poData} warehouseData={warehouseData} />
            </VerticalTabPanel>
            <VerticalTabPanel value={uiData.tabValue} index={3}>
                <MasterMaterialTab data={poData} />
            </VerticalTabPanel> */}
        </Box>

    )
}

export default WarehouseTab