import React, { useState } from "react";
import ListItemForm from "./ListItemForm";
import ListItemData from "./ListFormData";
import { useForm } from "../../../../../../hooks/formHook";
import { Box } from "@mui/material";

export default function ListItems({ equips, editEquip, deleteEquip }) {
  const [currentItem, setCurrentItem] = useState({});
  return (
    <Box>
      {equips &&
        equips.map((equip, i) => {
          return (
            <ListItem
              key={equip.id}
              index={i + 1}
              equip={equip}
              currentItem={currentItem}
              setCurrentItem={setCurrentItem}
              editEquip={editEquip}
              deleteEquip={deleteEquip}
            />
          );
        })}
    </Box>
  );
}
function ListItem({
  index,
  equip,
  currentItem,
  setCurrentItem,
  editEquip,
  deleteEquip,
}) {
  // for updating existed one

  const { onChange, onSubmit, values } = useForm(
    updateItemCallback,
    equip,
    equip
  );

  function updateItemCallback() {
    editEquip(values);
    setCurrentItem({});
  }

  return currentItem.id === equip.id ? (
    <ListItemForm
      formCase="edit"
      index={index}
      currentItem={values}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  ) : (
    <Box sx={{ transform: "translateY(7px)" }}>
      <ListItemData
        index={index}
        equip={equip}
        deleteEquip={deleteEquip}
        setCurrentItem={setCurrentItem}
        editEquip={editEquip}
      />
    </Box>
  );
}
