import React, { useEffect, useMemo } from 'react'
import {MaterialReactTable} from 'material-react-table';
import { Box, Button, Typography } from '@mui/material';
import { MRQTableColumns, MRVTableColumns, POTableColumns, SRVTableColumns, expandMaterialTable } from '../MCSTables';
import { mcstableProps } from '../../../../styles/muiProps';


const DetailPanel = (row, name) => {

    let data = row?.original?.mrq_materials
    if (name === "MRV")
        data = row.original?.mcsPurchaseOrder?.mrq_materials
    else if (name === "SRV")
        data = row.original?.materials?.map(material => ({ material }))
    console.log("detail panel: ", data)
    return (
        <MaterialReactTable
            columns={expandMaterialTable ?? []}
            data={data ?? []}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            muiTableBodyRowProps={{ hover: false }}
        />
    )
}

const MCSFlowTable = (props) => {
    const { name, targetName, data, handleClickOpen, multiSelect } = props

    // const columns = useMemo(
    //     () => MRQTableColumns,
    //     [],
    // );
    console.log("data for table' ", data)
    var columns = []
    if (name === 'MRQ') {
        columns = MRQTableColumns
    }
    else if (name === 'PO') {
        columns = POTableColumns
    }
    else if (name === 'MRV') {
        columns = MRVTableColumns
    }
    else if (name === 'SRV') {
        columns = SRVTableColumns
    }

    return (
        <MaterialReactTable
            columns={columns ?? []}
            data={data ?? []}
            defaultColumn={{ size: 130 }}
            enableGrouping
            enableColumnPinning
            enableStickyHeader
            // enableMultiRowSelection={!multiSelect}
            muiTableHeadCellProps={{
                sx: {
                    borderRight: "0.1px solid rgba(0,0,0,0.1)"
                }
            }}
            // enableRowSelection
            // muiTableBodyRowProps={({ row }) => ({
            //     onClick: row.getToggleSelectedHandler(),
            //     sx: {
            //         cursor: 'pointer',
            //     },
            // })}
            {...mcstableProps}
            renderDetailPanel={({ row }) => DetailPanel(row, name)}
        // renderDetailPanel={({ row }) => (
        //     <Box
        //       sx={{
        //         display: 'grid',
        //         margin: 'auto',
        //         gridTemplateColumns: '1fr 1fr',
        //         width: '100%',
        //       }}
        //     >
        //       <Typography>Address: {row.original.address}</Typography>
        //       <Typography>City: {row.original.city}</Typography>
        //       <Typography>State: {row.original.state}</Typography>
        //       <Typography>Country: {row.original.country}</Typography>
        //     </Box>
        //   )}
        // initialState={{ columnPinning: { left: ['mrq_no'] }, grouping: ["mrq_no"] }}
        // renderTopToolbarCustomActions={({ table }) => {
        //     console.log("IsSelected", table.getGroupedSelectedRowModel())
        //     return (
        //         <div style={{ display: 'flex', gap: '0.5rem' }}>
        //             <Button
        //                 color="error"
        //                 disabled={table.getSelectedRowModel().rows.length === 0 ? true : false}
        //                 onClick={() => handleClickOpen(table.getSelectedRowModel().rows)}
        //                 variant="contained"
        //             >
        //                 Add to {targetName}
        //             </Button>
        //         </div>
        //     );
        // }}
        />
    )
}

export default MCSFlowTable