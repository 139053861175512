import axios from "./axios"

const createTimesheetRoute = "timesheet/create"
const getTimesheetRoute = "timesheet/get"
const getAllTimesheetRoute = "timesheet/all"
const editTimesheetRoute = "timesheet/edit"
const deleteTimesheetRoute = "timesheet/delete"

export const getTimesheets = async () =>{
    const res = await axios.get(getTimesheetRoute)
    return res.data
}

export const createTimesheet = async(timesheet)=>{
    return await axios.post(createTimesheetRoute, timesheet)
}

export const editTimesheet = async(timesheet)=>{
    return await axios.put(editTimesheetRoute, timesheet)
}

export const deleteTimesheet= async(TimesheetId) =>{
    //console.log("Delete Category ID: ", subCategoryId)
    return await axios.delete(`${deleteTimesheetRoute}/${TimesheetId}`)
}

export const getAllTimesheet = async()=>{
    const res = await axios.get(getAllTimesheetRoute)
    //console.log("Timesheets", res)
    return res.data
}
