import React, { useEffect, useRef, useState } from 'react'

import { Gantt, EventOption, StylingOption, ViewMode, DisplayOption } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import Typography from "@mui/material/Typography";
import { Box, Stack } from '@mui/material';
import {
    useFormContext,
} from "react-hook-form";

const TimelineComponent = ({ equipId, rows }) => {

    // const [dashboardData, setDashboardData] = useState({})
    // // const [dataFetched, setDataFetched] = useState(false)
    // const { getValues } = useFormContext();


    console.log(rows)

    const formattedData = rows.map(row => ({
        id: row.id,
        name: row?.plannedEnd ? row?.name : "",
        start: row?.plannedStart ? new Date(row?.plannedStart) : new Date(),
        end: row?.plannedEnd ? new Date(row?.plannedEnd) : new Date(),
        progress: row?.progress 
    }))

    console.log({ formattedData })


    return (
        <Box sx={{ width: "inherit" }}>
            <Box sx={{ backgroundColor: "#FFFF", p: 1, }}>
                <Typography variant="h6" color="text.secondary" sx={{ pb: 1, textAlign: "center" }}>
                    Planned Timeline
                </Typography >
                {
                    formattedData?.length > 0 ?
                        < Gantt
                            tasks={formattedData}
                            viewMode={"Month"}
                            columnWidth={150}
                            listCellWidth={""}
                            rowHeight={49.95}
                            headerHeight={55}
                            preStepsCount={0}
                        // ganttHeight
                        />
                        :
                        <>
                        </>
                }
            </Box>

        </Box>
    )
}

export default TimelineComponent