import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: "#4043ac",
        },
        secondary: {
            main: '#4043ac',
        },
    },
    typography: {
        fontSize: 12
    }
});
