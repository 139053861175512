import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/400.css';
import axios from '../../../api/axios';
import { VerifyGuestResetPwdRoute, GuestResetPwdRoute } from "../../../routes.js"
import Error from '../Error.jsx';

const GuestResetPwd = () => {
    const [newPassword, setNewPassword] = useState({
        "email":"",
        "password": "",
        "confirmPassword": "",
    })

    const [validToken, setValidToken] = useState(false)
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")


    const location = useLocation()
    const token = location.pathname.split('/')[3]

    const changeHandler = (event) => {
        setNewPassword({
            ...newPassword,
            [event.target.name]: event.target.value
        })
    }

    const submitHandler = async (event) => {
        if (newPassword.password.length < 8) {
            setError("Password must be at least 8 characters")
        }
        else if (!newPassword.password || !newPassword.confirmPassword) {
            setError("Please Enter required fields")
        }
        else {
            axios.post(GuestResetPwdRoute+token, newPassword)
                .then(response => {
                    // //console.log(response)
                    setMessage(response.data)
                })
                .catch(error => {
                    // //console.log(error)
                    setError(error?.response?.data)
                })
        }
    }

    useEffect(() => {
        const validateToken = async () => {
            await axios.get(VerifyGuestResetPwdRoute + token)
                .then(response => {
                    setNewPassword({
                        ...newPassword,
                        email: response.data
                    })
                    setValidToken(true)
                })
                .catch(error => console.error(error))
        }
        validateToken()

    }, [])

    if (!validToken)
        return <Error></Error>
    return (
        <div className="reset-form">
            <Box
                sx={{
                    width: 300,
                    height: "fit-content",
                    backgroundColor: '#eaecee',
                    borderRadius: 2,
                    padding: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: "0px 6px 10px rgba(182, 188, 218, 0.5)",
                }}>
                <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
                    Reset Password Form
                </Typography>
                <p>Enter your new password below.</p>
                <p className="error">{error}</p>
                <p className="success">{message}</p>
                <TextField
                    required
                    id="newpwd"
                    type="password"
                    label="New Password"
                    name="password"
                    onChange={changeHandler}
                    variant="outlined"
                    sx={{ marginTop: 3 }}
                />
                <TextField
                    required
                    id="confirmpwd"
                    type="password"
                    label="Confirm Password"
                    name="confirmPassword"
                    onChange={changeHandler}
                    variant="outlined"
                    sx={{ marginTop: 3, marginBottom: 3 }}
                />
                <Button sx={{ color: "#4e5fa2" }} onClick={submitHandler}>Reset Password</Button>
            </Box>
        </div>
    )
}

export default GuestResetPwd