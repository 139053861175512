const pairFields = [
    {
        name: "Internal Pressure",
        pairs: ["txt_DP_CSC", "txtDPressure", "txt_DP_EHC", "txt_DP_NC1"]
    },
    {
        name: "Design Temperature",
        pairs: ["txt_DT_CSC", "txtDTemp", "txt_DT_EHC"]
    },
    {
        name: "Joint Efficiency",
        pairs: ["txt_JE_CSC", "TextBox31", "txt_JE_EHC"]
    },
    {
        name: "Inside/Outside Diameter",
        pairs: ["txtVIDia", "txt_ID_CSC", "txt_ID_EHC", "DataGridView5_Dia", "txt_SD_NC1", "txt_R_SC","TxtBx_VOD" ]
    },
    {
        name: "Design Length of Shell",
        pairs: ["txt_DL_CSC", "txt_SF", "DataGridView5_Can"]
    },
    {
        name: "External Pressure",
        pairs: ["TextBox6", "TextBox92", "TextBox13"]
    },
    {
        name: "Nozzle Stress",
        pairs: ["TextBox78", "txt_NAS_NC1"]
    },
    {
        name: "Liquid Head Pressure",
        pairs: ["txt_LH_CSC", "txt_LH_EHC"]
    },
    {
        name: "Material Specification",
        pairs: ["txt_MS_CSC", "txt_MS_EHC"]
    },
    {
        name: "Corrosion Allowance",
        pairs: ["txt_CA_CSC", "txt_CA_EHC", "txtVCA"]
    },
    {
        name: "Allowable Stress", //shell
        pairs: ["txt_AS_CSC", "txt_AS_EHC", "txt_SAS_NC1", "TextBox68", "txt_AS_SC"]
    },
    {
        name: "Provided Thickness",
        pairs: ["txt_PT_CSC", "txt_PT_EHC", "DataGridView5_thickness", "txt_SPT_NC1", "TxtBx_Wb_Thck"]
    },
    {
        name: "Vessel Length: TT",
        pairs: ["txt_LS_SC", "txtVLenTT"]
    },
    {
        name: "Support Distance From Ref",
        pairs: ["txt_SD_SC.Text", "TxtBx_A_1.Text"]
    },
    {
        name: "Material",
        pairs: ["Material", "ComboBox9", "ComboBox11", "txt_MS_CSC", "txt_MS_EHC"]
    },
    {
        name: "Nozzle Material",
        pairs: ["Nozzle Material", "ComboBox12"]
    },
    {
        name: "Flange Material",
        pairs: ["Flange Material", "ComboBox13"]
    },
    {
        name: "Pad Material",
        pairs: ["Pad Material", "ComboBox14"]
    },
    // {
    //     name: "Head Material",
    //     pairs: ["Head Material", "ComboBox11"]
    // },
    // {
    //     name: "Shell Material",
    //     pairs: ["Shell Material", "ComboBox9"]
    // },
    {
        name: "Saddle Dist",
        pairs: ["TxtBx_A_1", "txt_SD_SC"]
    },
    {
        name: "Contact Angle",
        pairs: ["txt_CA_SC", "TxtBx_Wb_CntctAngl"]
    },

]

export const pairFields2 = {
    "Internal Pressure": ["txt_DP_CSC", "txtDPressure", "txt_DP_EHC", "txt_DP_NC1"],
    "Design Temperature": ["txt_DT_CSC", "txtDTemp", "txt_DT_EHC"],
    "Joint Efficiency": ["txt_JE_CSC", "TextBox31", "txt_JE_EHC"],
    "Inside/Outside Diameter": ["txtVIDia", "txt_ID_CSC", "txt_ID_EHC", "DataGridView5_Dia", "txt_SD_NC1"],
    "Design Length of Shell": ["txt_DL_CSC", "txt_SF"],
    "Design Length of Head": [ "txt_LH_SC"],
    "External Pressure": ["TextBox6", "TextBox92", "TextBox13"],
    "Provided Thickness": ["txt_PT_CSC", "txt_PT_EHC", "DataGridView5_thickness", "txt_SPT_NC1"],


}

export default pairFields
