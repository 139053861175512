import { secondAxios } from "../../../api/axios";

export const getCompanyVendors = async () => {
  const getCompanyVendorsApi = "/vendor";
  const { data } = await secondAxios.get(getCompanyVendorsApi);
  return data;
};

export const addNewVendor = async (companyId, vendor) => {
  const addVendorsApi = `/vendor/add-vendors`;
  const { data } = await secondAxios.post(addVendorsApi, [vendor]);
  return data;
};
