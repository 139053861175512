import React from "react";
import Grid from "@mui/material/Grid";
import CustomTextField from "../../../../../../components/widgets/CustomTextField";
import { Box, InputAdornment, Typography, Button } from "@mui/material";
import { CustomSelect } from "../../../../../../components/widgets/CustomSelect";
import {
  GridStyle,
  designTabBoxProps,
} from "../../../../../../styles/muiProps";
import { GeometricInputs } from "./FieldsData/DesignTabFields";

const GeometricParamsGrid = () => {
  return (
    <Grid item xs={2} sm={2.6} md={3} lg={3} xl={2.6}>
      <Box {...designTabBoxProps} noValidate>
        {GeometricInputs.map((elem) => (
          <Grid id="inner-container" item container columns={12}>
            <Grid item sx={{ ...GridStyle }} xs={6}>
              <Typography fontSize={"0.75rem"}>{elem.label}</Typography>
            </Grid>
            <Grid item sx={{ ...GridStyle }} xs={6}>
              {elem.select ? (
                <CustomSelect
                  label=""
                  name={elem.name}
                  required
                  menuItems={elem.select}
                />
              ) : (
                <CustomTextField
                  label=""
                  id={elem.name}
                  name={elem.name}
                  type={elem.type}
                  pairField={elem.pairField}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment
                        disableTypography
                        sx={{
                          fontSize: "0.65rem",
                          color: "black",
                          fontFamily: "Roboto",
                        }}
                        position="end"
                      >
                        {elem.endAdornment}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
    </Grid>
  );
};

export default GeometricParamsGrid;
