export const inputData = [
    {
        label:"Internal Pressure",
        variable: "Pi",
        txtFieldName:"txt_DP_CSC",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        pairField: "txtDPressure"
    },
    {
        label:"Liquid Head Pressure",
        variable: "",
        txtFieldName:"txt_LH_CSC",
        required:true,
        inputProps:"N/mm^2",
        type: "number"
    },
    {
        label:"Design Temperature",
        variable: "T",
        txtFieldName:"txt_DT_CSC",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        pairField: "txtDTemp"
    },
    {
        label:"Material Specification",
        variable: "",
        txtFieldName:"txt_MS_CSC",
        required:true,
        inputProps:"",
        type: "string",
    },
    {
        label:"Allowable Stress",
        variable: "S",
        txtFieldName:"txt_AS_CSC",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
    },
    {
        label:"Joint Efficiency",
        variable: "E",
        txtFieldName:"txt_JE_CSC",
        required:true,
        inputProps:"ul",
        type: "number",
        pairField: "TextBox31"
    },
    {
        label:"Inside/Outside Diameter",
        variable:"D",
        txtFieldName:"txt_ID_CSC",
        required:true,
        inputProps:"mm",
        type: "number",
        pairField: "txtVIDia"
    },
    {
        label:"Provided Thickness",
        variable:"T",
        txtFieldName:"txt_PT_CSC",
        required:true,
        inputProps:"mm",
        type: "number",
        pairField: "true"
    },
    {
        label:"Corrosion Allowance",
        variable:"CA",
        txtFieldName:"txt_CA_CSC",
        required:true,
        inputProps:"mm",
        type: "number"
    },
    {
        label:"Design Length of Shell",
        variable:"L",
        txtFieldName:"txt_DL_CSC",
        required:true,
        inputProps:"mm",
        type: "number",
        pairField:"txt_SF"
    },
    {
        label:"External Pressure",
        variable:"Pe",
        txtFieldName:"TextBox6",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        pairField:"TextBox92"
    },
    
]

export const ExternalPressureResults=[
    {
        label:"Geometry Factor",
        variable:"A",
        txtFieldName:"txt_AF_CSC",
        required:false,
        type: "number",
    },
    {
        label:"Materials Factor",
        variable:"B",
        txtFieldName:"txt_BF_CSC",
        required:false,
        type: "number",
    },
    {
        label:"Diameter/Thickness Ratio",
        variable:"Do/t",
        txtFieldName:"TextBox75",
        disabled:true,
        // required:false,
        type: "number",
    },
    {
        label:"Length/Diameter Ratio",
        variable:"L/Do",
        txtFieldName:"TextBox74",
        disabled:true,
        // required:false,
        type: "number",
    },
    {
        label:"EMAWP = ( 4 * B ) / ( 3 * D / T )",
        variable:"EMAWP",
        txtFieldName:"TextBox63",
        disabled:true,
        // required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
]

export const Summary=[
    {
        label:"Req Thk + Corrosion Allowance for Int. Pressure",
        variable:"tr",
        txtFieldName:"TextBox106",
        required:false,
        type: "number",
        inputProps:"mm",
    },
    {
        label:"Maximum Allowable Working Pressure",
        variable:"MAWP",
        txtFieldName:"TextBox85",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
    {
        label:"Maximum Allowable Pressure",
        variable:"MAPNC",
        txtFieldName:"TextBox107",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
    {
        label:"Hydrotest pressure UG-99 (b)",
        variable:"1.3 * MAWP * Sa/S",
        txtFieldName:"TextBox182",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
    {
        label:"Hydrotest pressure UG-99 (c)",
        variable:"1.3 * MAPNC - Head",
        txtFieldName:"TextBox183",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
    {
        label:"Percent Elongation",
        variable:"(50*t/Rf * (1-Rf/Ro)",
        txtFieldName:"TextBox184",
        required:false,
        type: "number",
        inputProps:"%",
    },
    {
        label:"MDMT w/o impact test per UCS-66",
        txtFieldName:"TextBox185",
        required:false,
        type: "number",
        inputProps:"F",
    },
    {
        label:"Diameter/Thickness Ratio",
        variable:"Do/t",
        txtFieldName:"TextBox5",
        disabled:true,
        type: "number",
        pairField:"TextBox74"
    },
    {
        label:"Length/Diameter Ratio",
        variable:"L/Do",
        txtFieldName:"TextBox4",
        disabled:true,
        type: "number",
        pairField:"TextBox75"
    },
    {
        label:"Maximum Allowable External Pressure",
        variable:"EMAWP",
        txtFieldName:"TextBox24",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
]
