import React from "react";

// import EstimationColumns from './estimationColumns';
import {MaterialReactTable} from "material-react-table";
import { Button, IconButton, ListItemIcon, MenuItem, Stack, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EstimationColumns from "./estimationColumns";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import Edit from "@mui/icons-material/Edit";
import CloseIcon from '@mui/icons-material/Close';

const tableProps = {
  muiTableHeadCellProps: {
    sx: {
      backgroundColor: "hsla(250, 86%, 97%, 1)",
      borderRight: "1px solid rgba(224,224,224,1)",
      borderTop: "1px solid rgba(224,224,224,1)",
      "& .Mui-TableHeadCell-Content-Wrapper": {
        whiteSpace: "normal",
        justifyContent: "center",
        marginBottom: "0.4px",
      },
      fontSize: "0.7rem",
      verticalAlign: "middle",
      height: "40.4px",
    },
  },
  muiTableProps: {
    size: "small",
    sx: {
      "& .MuiInputBase-root": {
        fontSize: "0.7rem",
        borderRadius: 0,
      },
      "& .MuiButtonBase-root": {
        fontSize: "0.7rem",
      },
    },
  },
  muiTableBodyCellProps: {
    sx: {
      borderRight: "1px solid rgba(224,224,224,1)",
      padding: "0rem",
      paddingLeft: "5px",
      // height: "30.4px"
    },
  },
};

const EstimationTable = (props) => {
  const {
    fields,
    reRender,
    handleSaveCell,
    handleCreateNewRow,
    handleDeleteRow,
    isLoading,
    getValues,
    handleCompareCost,
    handleCheckStock,
    isEditTable,
    setIsEditTable
  } = props;
  return (
    <MaterialReactTable
      // style={{ tableLayout: "auto" }}
      columns={EstimationColumns(getValues)}
      data={fields}
      editDisplayMode="table"
      enableEditing={isEditTable}
      enableColumnActions={false}
      enableRowActions
      positionActionsColumn="last"
      // enableColumnResizing
      // initialState={{ density: 'compact' }}
      {...tableProps}
      muiTableHeadCellProps={({ cell }) => ({
        sx: {
          borderRight: "1px solid rgba(224,224,224,1)",
          backgroundColor: "hsla(250, 86%, 97%, 1)",
          fontSize: "14px",
          pt: 2,
        },
      })}
      muiTableBodyCellProps={{
        sx: {
          padding: "8.5px 8px",
          textAlign: "center",
          borderRight: "1px solid rgba(224,224,224,1)"
        }
      }}
      muiEditTextFieldProps={({ cell }) => ({
        //onBlur is more efficient, but could use onChange instead
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value);
        },
        sx: {
          "& .MuiInputBase-input": {
            padding: "8.5px 8px",
            textAlign: "center",
          },
        },
      })}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 5,
          muiTableHeadCellProps: {
            align: "center",
          },
          muiTableBodyCellProps: {
            align: "center",
            sx: {
              p: 0,
              "& button": {
                ml: 0,
              },
            },
          },
        },
      }}
      renderBottomToolbarCustomActions={() => (
        <Button
          color="secondary"
          size="small"
          onClick={() => handleCreateNewRow()}
          variant="contained"
        >
          Add row
        </Button>
      )}
      renderTopToolbarCustomActions={() => {
        const editTable = () => {
          setIsEditTable(true)
        };
        const exitEditMode = () => {
          setIsEditTable(true)
          reRender()
        };
        return <div>
          {
            isEditTable ?

              <Tooltip title="Exit Edit Mode">
                <IconButton onClick={exitEditMode} color="warning">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              :
              <Tooltip title="Edit Table values">
                <IconButton onClick={editTable} color="primary">
                  <Edit />
                </IconButton>
              </Tooltip>
          }
        </div>;
      }}
      renderRowActionMenuItems={({ row }) => [
        <MenuItem
          key={0}
          sx={{ m: 0 }}
          onClick={() => handleDeleteRow(row.index)}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
        <MenuItem
          key={0}
          sx={{ m: 0 }}
          onClick={() => handleCompareCost(row.original)}
        >
          <ListItemIcon>
            <CompareArrowsIcon />
          </ListItemIcon>
          Compare Cost
        </MenuItem>,
        <MenuItem
          key={0}
          sx={{ m: 0 }}
          onClick={() => handleCheckStock(row.original)}
        >
          <ListItemIcon>
            <FactCheckIcon />
          </ListItemIcon>
          Check availability
        </MenuItem>,
      ]}
      defaultColumn={{
        minSize: 60, //allow columns to get smaller than default
        // maxSize: 160, //allow columns to get larger than default
        size: 80, //make columns wider by default
      }}
      initialState={{
        pagination: { pageSize: 30 },
        columnVisibility: { Component: false },
      }}
      state={{
        isLoading,
      }}
    />
  );
};

export default EstimationTable;
