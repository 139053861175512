import React from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const VerticalTabPanelFormat = (props) => {
    const { children, padding, backgroundColor, ...other } = props;

    return (
        <div
            role="tabpanel"
            {...other}
        >
            <Box sx={{ padding: padding ?? 1, backgroundColor: backgroundColor ?? "", minHeight: "80vh", width: "100%" }}>
                <Box>{children}</Box>
            </Box>
        </div>
    );
}

VerticalTabPanelFormat.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default VerticalTabPanelFormat