import axios from "./axios";

const getAllUsersRoute = "/dev/all"
const EditUserRoute = "/dev/edit" //+id

export const getAllUsers =async()=>{
    const res = await axios.get(getAllUsersRoute)
    return res.data
} 

export const editUser = async(user) =>{
    return await axios.put(EditUserRoute, user)
}

