export const expandMRPTable = [
    {
        accessorKey: "material.tagNumber",
        header: "Tag Number",
        size: 100,
        enableEditing: false,
    },
    {
        accessorKey: "material.materialCode",
        header: "Material Code",
        size: 100,
        enableEditing: false,

    },
    {
        accessorKey: "material.qty",
        header: "Qty",
        size: 60,
        enableEditing: false,

    },
    {
        accessorKey: "material.brand",
        header: "Brand",
        size: 100,
        enableEditing: false,

    },
    {
        accessorKey: "material.pn",
        header: "P/N",
        size: 100,
        enableEditing: false,

    },
    {
        accessorKey: "po",
        header: "PO #",
        size: 80

    },
    {
        accessorKey: "po_date",
        header: "PO Date",
        size: 100,
        muiEditTextFieldProps: {
            type: "date",
        }

    },
    {
        accessorKey: "mrv",
        header: "MRV #",
        size: 80

    },
    {
        accessorKey: "mrv_date",
        header: "MRV Date",
        size: 100,
        muiEditTextFieldProps: {
            type: "date",
        }

    },
    {
        accessorKey: "mrv_heat",
        header: "Heat #",
        size: 80

    },
    {
        accessorKey: "srv",
        header: "SRV #",
        size: 80
    },
    {
        accessorKey: "srv_date",
        header: "SRV Date",
        size: 100,
        muiEditTextFieldProps: {
            type: "date",
        }
    },
];
