import { Box, Button, Grid, ListItemIcon, MenuItem } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function NonEditableTable({ columns, data, additionalProps }) {
  return (
    <Box sx={{ "& td": { p:1,pl:1.5, borderRight: "1px solid #ddd" } }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        editDisplayMode="table"
        enableEditing={false}
        enableColumnActions={false}
        enableRowActions={false}
        // positionActionsColumn="last"
        // enableTopToolbar={enableTopToolbar ?? true}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "hsla(250, 86%, 97%, 1)",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                textAlign: "center",
                "& td": {
                  borderRight: "1px solid #ddd",
                },
                "& button": {
                  m: 1,
                },
              },
            },
          },
        }}
        state={
          {
            //   isLoading
            // showProgressBars: isLoading ? true : false
          }
        }
        muiTableHeadCellProps={({ cell }) => ({
          sx: {
            borderRight: "1px solid rgba(224,224,224,1)",
            backgroundColor: "hsla(250, 86%, 97%, 1)",
            // fontSize: "16px",
            // display: "flex",
            // alignItems: "center",
            // px: 0,
            pt: 2,
          },
        })}
        {...additionalProps}
      />
    </Box>
  );
}
