import { Box, Tab, Tabs, ThemeProvider } from "@mui/material";
import React, { useState } from "react";

import { QueryClient, useQuery } from "@tanstack/react-query";
import MRQTab from "./MRQTab";
import { getAllPo } from "../../../api/poApi";
import { getAllMrv, getMrv } from "../../../api/mrvApi";
import { getAllMrq } from "../../../api/mrqApi";
import {
  fullPageBoxStyle,
  tabProps,
  verticalTabStyle,
} from "../../../styles/muiProps";
import TabPanel from "../../../components/widgets/TabPanel";
import MCSFlowTab from "./MCSFlowTab";
import { theme } from "../../../styles/theme";
import { getAllSrv } from "../../../api/srvApi";
// import SRVTab from './SRVTab';
import WarehouseTab from "./WarehouseTab";
import { getMaterialsMaster, getWarehouseMaterials } from "../../../api/warehouseApi";
import PurchaseOrderTab from "./PurchaseOrderTab";
import NavTabs from "../../../components/Tabs/NavTabs";

const SCMPage = () => {

  document.title = "Supply Chain Management";
  const [uiState, setUiState] = useState({
    tab: 0,
  });

  //handle change of tabs
  const handleTabChange = (event, newValue) => {
    setUiState((prev) => ({ ...prev, tab: newValue }));
  };

  // MRQ DATA
  const mrqQuery = useQuery({
    queryKey: ["mrqs"],
    queryFn: () => getAllMrq(),
  });

  const { data: mrqData } = mrqQuery;

  if (mrqQuery.isSuccess) console.log("mrq", mrqData);

  // Purchase Order Data
  const poQuery = useQuery({
    queryKey: ["po"],
    queryFn: () => getAllPo(),
  });

  const { data: poData } = poQuery;
  if (poQuery.isSuccess) {
    var poList = poData?.data?.map((po) => ({
      label: po.po_name,
      id: po.id,
      hasMrv: po.materialReceivingVouchers.length ? true : false,
    }));

    console.log("pos", poData?.data, poList);
  }

  // Material Receiving Voucher DAta
  const mrvQuery = useQuery({
    queryKey: ["mrv"],
    queryFn: () => getAllMrv(),
  });

  const { data: mrvData } = mrvQuery;
  if (mrvQuery.isSuccess) {
    var mrvList = mrvData?.data?.map((mrv) => ({
      value: mrv.mrv_name,
      id: mrv.id,
    }));
  }
  // Warehouse DATA
  const warehouseQuery = useQuery({
    queryKey: ["warehouse"],
    queryFn: () => getWarehouseMaterials(),
  });

  const { data: warehouseData } = warehouseQuery;

  if (warehouseQuery.isSuccess) console.log("warehouse", warehouseData);

  // Materials Master data
  const materialsMasterQuery = useQuery({
    queryKey: ["materialsMaster"],
    queryFn: () => getMaterialsMaster(),
  });

  const { data: materialsMasterData } = materialsMasterQuery;

  if (materialsMasterQuery.isSuccess) console.log("mmaster", materialsMasterData);

  // Material Receiving Voucher DAta
  const srvQuery = useQuery({
    queryKey: ["srv"],
    queryFn: () => getAllSrv(),
  });

  const { data: srvData } = srvQuery;

  const tabs = [
    // {
    //   label: "Material Requisiton",
    //   panel: (
    //     <MRQTab data={mrqData?.data ?? []} />
    //   )
    // },
    {
      label: "Purchase Order",
      panel: (
        <PurchaseOrderTab data={poData?.data ?? []} />
      )
    },
    {
      label: "Warehouse",
      panel: (
        <WarehouseTab
          materialsMasterData={materialsMasterData?.data}
          warehouseData={warehouseData?.data}
          srvData={srvData?.data}
          mrvData={mrvData?.data}
          poList={poList}
          poData={poData?.data}
        />
      )
    }

  ]
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: "100%", padding: 1 }}>
        <NavTabs tabs={tabs}
          tabProps={verticalTabStyle}
          navLabel="scm-tab"
          vertical={true} />
      </Box>
    </ThemeProvider>
  );
};

export default SCMPage;
