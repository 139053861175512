import React from "react";
import {
  GridStyle,
  designTabBoxProps,
  greyedOutFields,
} from "../../../../../../styles/muiProps";
import { CustomSelect } from "../../../../../../components/widgets/CustomSelect";
import CustomTextField from "../../../../../../components/widgets/CustomTextField";
import { Box, Button, Grid, InputAdornment, Typography } from "@mui/material";
import { materialData } from "./FieldsData/DesignTabFields";
import { useFormContext } from "react-hook-form";
import axios from "../../../../../../api/axios";

const MaterialsGrid = () => {
  const { getValues, reset } = useFormContext();
  const [loading, setLoading] = React.useState(false);

  const fetchMaterialData = async () => {
    let data = getValues();
    console.log(data);
    setLoading(true);
    console.log("fetcher", data);
    const res = await axios
      .post("/pressure_vessel/materialData", data)
      .then((res) => {
        console.log("single mat", res);
        reset(res.data);
      });
    setLoading(false);
  };

  // xs={2} sm={2.6} md={3} lg={3} xl={2.6}
  //  xs={2} sm={2.6} md={3} lg={3} xl={2.6}
  return (
    // <Grid item xs={3} sm={4} md={6} lg={5} xl={4} >
    <Grid item xs={4} sm={5.2} md={6} lg={6} xl={5.2}>
      <Box {...designTabBoxProps} noValidate>
        <Grid
          id="inner-container"
          item
          container
          columnSpacing={0.5}
        // columns={24}
        >
          <Grid item sx={{ ...GridStyle }} xs={3}>
            <Typography></Typography>
          </Grid>

          {/* Column 1 */}
          <Grid item sx={{ ...GridStyle }} xs={3}>
            <Typography fontSize={"0.75rem"} textAlign={"center"}>
              Material
            </Typography>
          </Grid>

          {/* Column 2 */}
          <Grid item sx={{ ...GridStyle }} xs={1.5}>
            <Typography fontSize={"0.75rem"} textAlign={"center"}>
              Stress (N/mm^2)
            </Typography>
          </Grid>

          {/* Column 3 */}
          <Grid item sx={{ ...GridStyle }} xs={1.5}>
            <Typography fontSize={"0.75rem"} textAlign={"center"}>
              Curve
            </Typography>
          </Grid>

          {/* Column 3 */}
          <Grid item sx={{ ...GridStyle }}>
            <Typography fontSize={"0.75rem"} textAlign={"center"}>
              Requirement
            </Typography>
          </Grid>

          {materialData.map((elem) =>
            elem.label !== "Gasket" ? (
              <Grid
                id="inner-container2"
                item
                key={elem.label}
                container
                columns={12}
                columnSpacing={0.5}
              >
                <Grid item sx={{ ...GridStyle }} xs={3}>
                  <Typography fontSize={"0.75rem"}>{elem.label}</Typography>
                </Grid>

                {/* TXT FIELD 1 */}
                <Grid item sx={{ ...GridStyle }} xs={3}>
                  <CustomSelect
                    label=""
                    name={elem.materialFieldName}
                    required
                    pairField={elem.pairField ?? false}
                    onChange={fetchMaterialData}
                    menuItems={elem.select}
                  />
                </Grid>

                {/* TXT FIELD 2 */}
                <Grid item sx={{ ...GridStyle }} xs={1.5}>
                  <CustomTextField
                    label=""
                    id={elem.name}
                    name={elem.stressFieldName}
                    type={elem.type}
                    required
                    inputProps={{
                      endAdornment: <InputAdornment position="end">{elem.endAdornment}</InputAdornment>,
                      style: greyedOutFields
                    }}
                  />
                </Grid>

                {/* TXT FIELD 3 */}
                <Grid item sx={{ ...GridStyle }} xs={1.5}>
                  <CustomTextField
                    label=""
                    id={elem.curveFieldName}
                    name={elem.curveFieldName}
                    type={elem.type}
                    required
                    disabled
                    inputProps={{
                      endAdornment: <InputAdornment position="end">{elem.endAdornment}</InputAdornment>,
                      style: greyedOutFields
                    }}
                  />
                </Grid>

                <Grid item sx={{ ...GridStyle }} xs={3}>
                  <CustomSelect
                    label=""
                    name={elem.requirementFieldName}
                    required
                    pairField={false}
                    menuItems={elem.requirementItems ?? []}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                id="inner-container2"
                item
                container
                columns={12}
                columnSpacing={0.5}
              >
                <Grid item sx={{ ...GridStyle }} xs={3}>
                  <Typography fontSize={"0.75rem"}>{elem.label}</Typography>
                </Grid>

                {/* TXT FIELD 1 */}
                <Grid item sx={{ ...GridStyle, paddingRight: "0px" }} xs={6}>
                  <CustomSelect
                    label=""
                    name={elem.materialFieldName}
                    required
                    pairField={elem.pairField ?? false}
                    onChange={fetchMaterialData}
                    menuItems={elem.select}
                  />
                </Grid>

                <Grid item sx={{ ...GridStyle }} xs={3}>
                  <CustomSelect
                    label=""
                    name={elem.requirementFieldName}
                    required
                    pairField={false}
                    menuItems={elem.requirementItems ?? []}
                  />
                </Grid>
              </Grid>
            )
          )}

          <Button variant="contained" onClick={fetchMaterialData}>
            {" "}
            Get Material Data
          </Button>
        </Grid>
      </Box>
    </Grid>
  );
};

export default MaterialsGrid;
