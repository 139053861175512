import React from "react";
import {MaterialReactTable} from "material-react-table";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Button, ListItemIcon } from "@mui/material";
import { Delete as DeleteIcon, Watch } from "@mui/icons-material";
import { columns, handleComponentChange,handleSizeChange,handleDimensionalStanderdChange} from "./columnsData/columns";
import { useFieldArray, useFormContext } from "react-hook-form";
import { compactTableProps } from "../../../../../../styles/muiProps";
import {getCodes} from '../getCode'

const InputTable = (props) => {
  const { reRender, isLoading } = props;
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
    resetField,
  } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "DataGridView25",
  });

  //save cell changes in table
  const handleSaveCell = (cell, value) => {
    console.log('a cell has changed')
    console.log('the value is ',value)
    console.log('the cell is',cell.row.getAllCells())
    console.log('the requirements is',getValues(`DataGridView25.${cell.row.id}.Requirements`))
    console.log('the MaterialDescription is',getValues(`DataGridView25.${cell.row.id}.MaterialDescription`))
    setValue(`DataGridView25.${cell.row.index}.${cell.column.id}`, value)
    if(cell.column.id==="Component"){
      console.log("component")
    setValue(`DataGridView25.${cell.row.index}.Requirements`, 'Not')
    handleComponentChange()
    update()
    }else if(cell.column.id==="Size"){
    setValue(`DataGridView25.${cell.row.index}.Requirements`, 'Not')
   handleSizeChange()
    update()
    }else if(cell.column.id==="DimensionalStanderd"){
    setValue(`DataGridView25.${cell.row.index}.Requirements`, 'Not')
    handleDimensionalStanderdChange()
    update()
    }
   
   
   
  };

  //delete row
  const handleDeleteRow = (index) => {
    remove(index);
  };

const handleObtainedCells =async (index)=>{
  const rowValues = getValues(`DataGridView25.${index}`);
  // console.log(index)
  console.log("row values",rowValues)
  if(rowValues.Component&&rowValues.Types&&rowValues.Material&&rowValues.Schedule&&rowValues.DimensionalStanderd&&rowValues.Requirements){
    console.log('al values are filled')
    setValue(`DataGridView25.${index}.Requirements`, 'Not')
    // setValue(`DataGridView25.${index}.MaterialDescription`, `test`)
    setValue(`DataGridView25.${index}.MaterialDescription`, `${rowValues.Component} ${rowValues.Size},${rowValues.Schedule},${rowValues.DimensionalStanderd},${rowValues.Types},${rowValues.Material}`)
    const code = await getCodes(rowValues.Component,rowValues.Size,rowValues.DimensionalStanderd,rowValues.Material,rowValues.Schedule,rowValues.Types,rowValues.Requirements)
    setValue(`DataGridView25.${index}.MaterialCode`, code)

    rowValues.Component==="Pipe"&&setValue(`DataGridView25.${index}.Family Table`,"ASME B36.10M Pipe")
    rowValues.Component==="Pipe Threaded"&&setValue(`DataGridView25.${index}.Family Table`,"CB_SIZED TOE")
    rowValues.Component==="Bulk Pipe"&&setValue(`DataGridView25.${index}.Family Table`,"BULK_PIPE_TBE")
    rowValues.Component==="Nipple"&&setValue(`DataGridView25.${index}.Family Table`,"CB Nipple")
    update()
  }
}

  //create new row in table
  const handleCreateNewRow = (values) => {
    append({
      Index: fields.length,
      Component:"",
      Requirements:"",
      Material:"",
      Types:"",
      Schedule:"",
      DimensionalStanderd:"",
      Size:"",
      MaterialDescription:""
    });
  };
  const handleInputsChnage = (cell, value) => {
    console.log('the cell is ',cell);
    console.log('the value is',value)
    // setValue(`DataGridView1.${cell.row.index}.Requirements`, "NOT");
    update(cell.row.id);
    reRender();
  };
  const handleObtain = (event)=>{
    // console.log(event)
    // setValue(`DataGridView25.0.Requirements`, 'Not')
    console.log('the fields is',fields)
    fields.forEach((field)=>{
      console.log('the field is',field)
      handleObtainedCells(field.Index)
    })
  }
  const handleEstimation = (event)=>{
    console.log('estimation handling')
    props.changeParentTab(event,3)
  }
  return (
    <Grid item xs={12}>
      <MaterialReactTable
        columns={columns}
        data={fields}
        editDisplayMode="table"
        {...compactTableProps}
        enableEditing
        enableColumnActions={false}
        enableRowActions
        positionActionsColumn="last"
        state={{
          isLoading,
          // showProgressBars: isLoading ? true : false
        }}
        muiEditTextFieldProps={({ cell }) => ({
          // //onBlur is more efficient, but could use onChange instead
          onChange: (event) => {
            handleSaveCell(cell, event.target.value);
           // handleInputsChnage(cell, event.target.value);
          },
          // onChange: (event) => {
            
          // },
          // variant: 'outlined',
          sx: {
            "& .MuiInputBase-input": {
              padding: "8.5px 8px",
            },
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <>
          <Button
            color="secondary"
            size="small"
            onClick={() => handleCreateNewRow()}
            variant="contained"
          >
            Add row
          </Button>
          <Button
          color="secondary"
          size="small"
          onClick={(event) => handleObtain(event)}
          variant="contained"
        >
          Obtain
        </Button>
        <Button
          color="secondary"
          size="small"
          onClick={(event) => handleEstimation(event)}
          variant="contained"
        >
          Estimation
        </Button>
        </>
          
        )}
        renderRowActionMenuItems={({ row }) => [
          <MenuItem
            key={0}
            sx={{ m: 0 }}
            onClick={() => handleDeleteRow(row.index)}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete
          </MenuItem>,
        ]}
      />
    </Grid>
  );
};

export default InputTable;
