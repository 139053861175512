import {MaterialReactTable} from 'material-react-table'
import React, { useEffect, useRef, useState } from 'react'
import { warehouseColumns } from '../../MCSTables'
import { Box, Button, Tab, Tabs } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import MRVDialog from './MRVDialog';
import { addToWarehouse, removeFromWarehouse } from '../../../../../api/warehouseApi';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getBOM, getDrawings } from '../../../../../api/drawingsApi';
import { getAllProjects } from '../../../Project/allProjectsPage/api';
import SRVDialog from './SRVDialog';
// import SRVTab from '../SRVTab';
// import VerticalTabPanel from '../../../../components/widgets/VerticalTabPanel';
// import MCSFlowTable from '../MCSFlowTab/MCSFlowTable';

const InventoryTab = (props) => {
    const { poList, poData, warehouseData } = props
    const queryClient = useQueryClient()

    const [uiData, setUiData] = useState({
        openMRVDialog: false,
        openSRVDialog: false,
        allMaterials: [],
    })

    const initialMRVRequest = {
        mcsPurchaseOrderId: "",
        materials: [],
        mrv_name: "",
        mrv_issueDate: "",
        mrv_approvalStatus: "",
        mrv_notes: ""
    }

    const initialSrvRequestData = {
        materialDataRequest: {
            drawing: "",
            rev: "",
            sheet: "",
            id: 0
        },
        equipmentDataRequest: {
            project: "",
            equipId: ""
        },
        srvRequest: {
            srv_name: "",
            srv_issueDate: "",
            srv_notes: "",
            drawingId: "",
            materials: []
            // shuold include drawingId 
        }
    }

    const [requestData, setRequestData] = useState({
        mrvRequest: {
            ...initialMRVRequest
        },
        ...initialSrvRequestData
    })

    const tableInstanceRef = useRef(null);

    const { data: materialsData, isError, isSuccess, isFetching, isLoading, refetch } = useQuery({
        queryKey: [
            'drawing',
            requestData?.materialDataRequest?.id, //refetch when sheet value changes
        ],
        queryFn: () => getBOM(requestData.materialDataRequest),
        enabled: requestData?.materialDataRequest?.id !== 0,
        keepPreviousData: true,
    });

    useEffect(() => {
        setUiData(prev => ({ ...prev, allMaterials: materialsData?.materials || [] }))
    }, [materialsData]);

    console.log("UI DATA: ", uiData, materialsData?.materials)

    // fetch all drawings
    const drawingsQuery = useQuery({
        queryKey: [
            'all_drawings',
            requestData?.equipmentDataRequest?.equipId
        ],
        queryFn: () => getDrawings(requestData?.equipmentDataRequest?.equipId),
        enabled: requestData?.equipmentDataRequest?.equipId !== "",
    })

    const { data: drawingsData } = drawingsQuery


    const projectsQuery = useQuery({
        queryKey: ['all_projects'],
        queryFn: () => getAllProjects(),
    })
    const { data: projectsData } = projectsQuery
    if (projectsQuery.isSuccess) {
        console.log(projectsData)
    }

    const handleEquipmentSelect = (event, newVal) => {
        console.log("event: ", newVal)
        if (newVal)
            setRequestData(prev => ({ ...prev, equipmentDataRequest: { equipId: newVal.equipId } }))
        else
            setRequestData(initialSrvRequestData)
    }

    const handleSheetSelect = (event) => {
        const value = event.target.value;
        setRequestData(prev => ({
            ...prev,
            materialDataRequest: { id: value },
            srvRequest: {
                ...prev.srvRequest,
                drawingId: value
            }
        }))
    };


    const openSRVDialog = () => {
        setUiData(prev => ({ ...prev, openSRVDialog: true }))
    }

    const closeSRVDialog = () => {
        setUiData(prev => ({ ...prev, openSRVDialog: false, allMaterials: [] }))
        tableInstanceRef.current = null;
        setRequestData(prev => ({ ...prev, ...initialSrvRequestData }))
    }

    const openMRVDialog = () => {
        setUiData(prev => ({ ...prev, openMRVDialog: true }))
    }

    const closeMRVDialog = () => {
        setUiData(prev => ({ ...prev, openMRVDialog: false }))
    }

    const onSelectPo = (event, newValue) => {
        console.log("change", newValue)
        if (newValue)
            setRequestData(prev => ({
                ...prev,
                mrvRequest: {
                    ...prev.mrvRequest,
                    mcsPurchaseOrderId: newValue.id,
                    materials: poData.find(po => po.id === newValue.id).mrq_materials
                }
            }))
        else
            setRequestData(prev => ({
                ...prev,
                mrvRequest: {
                    ...initialMRVRequest
                }
            }))
    }

    const onChangeMRVForm = (event) => {
        const { name, value } = event.target
        setRequestData(prev => ({
            ...prev,
            mrvRequest: {
                ...prev.mrvRequest,
                [name]: value
            }
        }))
    }

    const onChangeSRVForm = (event) => {
        const { name, value } = event.target
        setRequestData(prev => ({
            ...prev,
            srvRequest: {
                ...prev.srvRequest,
                [name]: value
            }
        }))
    }

    const onSubmitMRV = async () => {
        await addToWarehouse(requestData.mrvRequest)
            .then((res) => {
                queryClient.invalidateQueries("mrv")
            })
        closeMRVDialog()
    }

    const onSubmitSRV = async () => {
        const selectedRows = tableInstanceRef.current.getSelectedRowModel().rows

        const accumulatedQty = selectedRows ?
            selectedRows.reduce(
                (totalQty, selectedRow) =>
                    totalQty + selectedRow.original?.qty,
                0
            ) : 0
        const req = {
            ...requestData,
            srvRequest: {
                ...requestData.srvRequest,
                srv_quantity: accumulatedQty,
                materials: selectedRows?.map(row => row.original) || []
            },
        }
        await removeFromWarehouse(req.srvRequest)
            .then(response => {
                console.log("Successfully removed from warehouse ")
                queryClient.invalidateQueries("srv")
            })
        closeSRVDialog()
    }
    return (
        <div>
            <MaterialReactTable
                columns={warehouseColumns}
                data={warehouseData ?? []}
                defaultColumn={{ minSize: 40 }}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
                        <Button
                            sx={{ backgroundColor: "#f3f4f8", color: "black 0 0" }}
                            onClick={openMRVDialog}
                            variant="outlined"
                            endIcon={<AddIcon />}
                        >
                            Add materials
                        </Button>

                    </Box>
                )}

                //customize built-in buttons in the top-right of top toolbar
                renderToolbarInternalActions={({ table }) => (
                    <Box>
                        <Button
                            color="secondary"
                            onClick={openSRVDialog}
                            variant="contained"
                            endIcon={<ShoppingCartCheckoutIcon />}
                        >
                            Move to Shop
                        </Button>
                    </Box>
                )}
            />
            <MRVDialog
                open={uiData.openMRVDialog}
                onClose={closeMRVDialog}
                poList={poList}
                onSelectPo={onSelectPo}
                mrvRequest={requestData.mrvRequest}
                onChangeMRVForm={onChangeMRVForm}
                onSubmitMRV={onSubmitMRV}
            />
            <SRVDialog
                open={uiData.openSRVDialog}
                handleClose={closeSRVDialog}

                materialDataRequest={requestData.materialDataRequest}
                materials={uiData.allMaterials}

                projects={projectsData}
                equipmentDataRequest={requestData.equipmentDataRequest}
                drawingsData={drawingsData?.data}

                handleSheetSelect={handleSheetSelect}
                handleEquipmentSelect={handleEquipmentSelect}

                onChangeSRVForm={onChangeSRVForm}
                srvRequest={requestData.srvRequest}
                onSubmitSRV={onSubmitSRV}
                tableInstanceRef={tableInstanceRef}
            />
        </div>
    )
}

export default InventoryTab