export const estimationColumns = [
    //column definitions...
    {
        accessorKey: 'Item',
        header: 'Item',
        size: "50px",
    },
    {
        accessorKey: 'Qty',
        header: 'Qty',
        size: "50px"
    },
    {
        accessorKey: 'Weight',
        header: 'Weight',
        size: "40px",
    },
    {
        accessorKey: 'Material Cost/KG',
        header: 'Material Cost/KG',
        size: "50px",
    },
    {
        accessorKey: 'Man-Hours',
        header: 'Man-Hours',
        size: "50px",
    },
    {
        accessorKey: 'Material Cost',
        header: 'Material Cost',
        size: "50px",
    },
]