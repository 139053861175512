export const columns = (specsArray) => [
  { id: "num", label: "", minWidth: 20 },

  { id: "component_type", label: "Components", minWidth: 100 },
  ...specsArray,
  {
    id: "material",
    label: "Material",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "requirements",
    label: "Requirements",
    minWidth: 100,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "material_code",
    label: "Material Code",
    minWidth: 100,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "description",
    label: "Material Description",
    minWidth: 170,
    align: "center",
  },
  // {
  //   id: "qty",
  //   label: "qty",
  //   minWidth: 30,
  //   align: "center",
  //   format: (value) => value.toLocaleString("en-US"),
  // },
  // {
  //   id: "spes",
  //   label: "Engineering Specs",
  //   minWidth: 200,
  //   align: "center",
  //   format: (value) => "",
  // },
  // {
  //   id: "srvId",
  //   label: "SRV",
  //   minWidth: 100,
  //   align: "center",
  //   format: (value) => value.toFixed(2),
  // },
];
