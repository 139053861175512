import React from 'react'
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, Grid } from '@mui/material';

const CreateDialog = ({ openDialog, formData, handleClose, handleChange, handleUpload, handleFileChange }) => {
    return (
        <Dialog
            open={openDialog}
            onClose={handleClose}
            fullWidth={true}
            maxWidth='sm'
            
        >
            <DialogTitle>Manage Softwares</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Create New Software
                </DialogContentText>
                <form>
                    <Grid container spacing={2}>

                        <Grid item xs={12}>
                            <TextField
                                name="name"
                                label="Name"
                                value={formData.name}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="description"
                                label="Description"
                                value={formData.description}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                name="repository"
                                label="Repository"
                                value={formData.repository}
                                onChange={handleChange}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <input
                                type="file"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="imageInput"
                            />
                            <label htmlFor="imageInput">
                                <Button
                                    variant="outlined"
                                    component="span"
                                    fullWidth
                                >
                                    Choose Image
                                </Button>
                            </label>
                        </Grid>
                        <Grid item xs={12}>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleUpload}
                    variant="contained"
                    color="primary"
                >
                    Create Software
                </Button>
            </DialogActions>
        </Dialog>

    )
}

export default CreateDialog