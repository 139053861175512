import { React, useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import sideImg from '../../../images/auth_form_eng.jpg'
import { AuthContext } from '../../../context/authContext'
import axios from "../../../api/axios"
import { GuestLoginRoute } from "../../../routes"

const StaffLogin = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
  })

  const [error, setError] = useState("")

  const navigate = useNavigate()

  // const {login} = useContext(AuthContext)

  //Handle Changes in form
  function changeHandler(event) {
    const { name, value } = event.target
    setInput(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  {/**Might not be required with form 'required'? */ }
  const submitHandler = async (event) => {
    event.preventDefault()
    // //console.log(event)
    if (!input.email) {
      setError("Email required!")
    }
    else if (!input.password) {
      setError("Password required!")
    }
    else {
      const user = await axios.post(GuestLoginRoute, input)
        .then(() => navigate("/"))
        .catch((err) => {
          setError(err.response.data.error)

        })
    }
  }

  return (
    <div className="auth-container">
      <img src={sideImg}></img>
      <div className="auth">
        <h1>Login</h1>
        <form>
          <p className="error">{error}</p>
          <input required type="text"
            placeholder="Email"
            name="email"
            value={input.email}
            onChange={changeHandler} />
          <input required type="password"
            placeholder="Password"
            name="password"
            value={input.password}
            onChange={changeHandler}
          />
          <span className="forgotpwd"><Link to="/guest/resetPassword">Forgot Password?</Link></span>
          <button type="submit" onClick={submitHandler}>Login</button>
          {/* <span >Don't have an Account? <Link to="/guest/register" dis>Sign Up!</Link></span> */}
          <span >Don't have an Account? <a>Sign Up!</a></span>
          <br />
          <span>Click here for <Link to="/dev/login">Staff Login</Link></span>
        </form>
      </div>
    </div>
  )
}

export default StaffLogin