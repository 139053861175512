import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material'
import Selection from './Selection'
import SRVDialogTable from './SRVDialogTable'

const formItems = [
    { label: "SRV Number", name: "srv_name" },
    { label: "Issue Date", name: "srv_issueDate", type: "date" },
    { label: "Notes", name: "srv_notes" },
]

const SRVDialog = (props) => {
    const { open,
        handleClose,
        materialDataRequest,
        materials,
        projects,
        onChangeSRVForm,
        equipmentDataRequest,
        drawingsData,
        handleEquipmentSelect,
        handleSheetSelect,
        srvRequest,
        onSubmitSRV,
        tableInstanceRef
    } = props
    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth={"lg"}
            onClose={handleClose}
            sx={{
                width: "100%",
                "& .MuiTypography-root": { fontSize: "0.875rem" },
            }}
        >
            <DialogTitle
                sx={{
                    alignSelf: "center",
                    fontWeight: "500",
                }}
            >
                Shop Receiving Voucher
            </DialogTitle>
            <Divider sx={{ width: "90%", alignSelf: "center" }} color="grey" />
            <DialogContent>
                <Grid container>

                    <Grid item xs={8} paddingRight={1}>
                        <Selection projects={projects}
                            drawings={drawingsData}
                            materialDataRequest={materialDataRequest}
                            equipmentDataRequest={equipmentDataRequest}
                            handleEquipmentSelect={handleEquipmentSelect}
                            handleSheetSelect={handleSheetSelect}
                        />
                        <SRVDialogTable
                            data={materials}
                            selectedMaterial={srvRequest.materials} 
                            tableInstanceRef={tableInstanceRef}
                            />
                    </Grid>

                    <Grid item xs={4} sx={{ backgroundColor: "#eee", padding: 2, display: "FLEX", flexDirection: "column" }}>
                        <Typography textAlign={"center"}>SRV Details</Typography>
                        <br />
                        {
                            formItems.map(field => (
                                <>
                                    <Typography >{field.label}: &emsp;</Typography>
                                    <TextField
                                        size='small'
                                        name={field.name}
                                        fullWidth
                                        onChange={onChangeSRVForm}
                                        value={srvRequest[field.name]}
                                        variant="outlined"
                                        type={field.type || "string"}
                                        sx={{
                                            '& input': {
                                                bgcolor: '#f5f7fa'
                                            },
                                        }}
                                    />
                                </>
                            ))
                        }
                        <Button variant='contained' sx={{ marginTop: 2 }} onClick={onSubmitSRV}>
                            Create SRV
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog >
    )
}

export default SRVDialog