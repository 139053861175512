import axios from "./axios.js"

const CreatePoRoute = "/po/create"
const CreatePoWithMaterialRoute = "/po/create_with_materials"
const getAllPosRoute = "/po/all"
const EditPoRoute = "/po/edit"
const DeletePoRoute = "/po/delete"
const addPoMaterial = "/po/addMaterialtoPo"

export const createPO = async (po) => {
    return await axios.post(CreatePoRoute, po)
}

export const getPo = async (equipId) => {
    console.log("sendign to ", `${getAllPosRoute}/${equipId}`)
    return await axios.get(`${getAllPosRoute}/${equipId}`)
}

export const getAllPo = async () => {
    console.log("sendign to ", `${getAllPosRoute}`)
    return await axios.get(`${getAllPosRoute}`)
}

export const updatePo = async (po) => {
    return await axios.put(addPoMaterial, po)
}

export const createPoWithMaterials = async (req) => {
    return await axios.post(CreatePoWithMaterialRoute, req)
}