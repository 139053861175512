import * as React from "react";
import { Paper } from "@mui/material";
import MRQTab from "./MRQTab";
import BidTab from "./BidTab";
import NavTabs from "../../../../../components/Tabs/NavTabs";
import { verticalTabStyle } from "../../../../../styles/muiProps";
import { MCSContextProvider } from "./MCS.context";
import MasterMaterialTab from "../../../SCMPage/WarehouseTab/MasterMaterialTab";
import InventoryTab from "../../../SCMPage/WarehouseTab/InventoryTab";
import DrawingRegister from "./DrawingRegisterTab";

export default function MCS3() {
  const tabs = [
    {
      label: "MRQ",
      panel: <MRQTab />,
    },
    {
      label: "Bid Evaluation",
      panel: <BidTab />,
    },
    {
      label: "BOM status",
      panel: <DrawingRegister />,
    },
    {
      label: "Materials Record",
      panel: <MasterMaterialTab />,
    },
  ];

  return (
    <MCSContextProvider>
      <Paper sx={{ width: "100%", display: "flex" }}>
        <NavTabs
          tabs={tabs}
          // tabProps={verticalTabStyle}
          navLabel="mcs-tab"
        // vertical={true}
        />
      </Paper>
    </MCSContextProvider>
  );
}
