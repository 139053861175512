import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import MaterialDBTable from "./MaterialDBTable";
import { StyleContext } from "../../../../context/styleContext";

import { getCurrentEquipComponents } from "../utils/getCurrentEquipComponents";
import { getCurrentEquipTabs } from "../utils/getCurrentEquipTabs";

import DrawerListItem from "./DrawerListItem";

const drawerWidth = 240;

function ComponentsDrawer({ filter, setFilter, data, drawersNum }) {
  const { sideNavOpen } = React.useContext(StyleContext);

  const [currentDrawerTab, setCurrentDrawerTab] = React.useState({
    name: "Shell",
    type: "Cylindrical Shell",
  });

  React.useEffect(() => {
    setFilter({
      ...filter,
      component_nameTab: currentDrawerTab.name,
      component_typeTab: currentDrawerTab.type,
    });
    console.log({ filter });
  }, [currentDrawerTab.name, currentDrawerTab.type]);

  const currentEquips = getCurrentEquipTabs(filter.eng_categoryTab);
  const currentEquipComponents = getCurrentEquipComponents(
    filter.eng_categoryTab
  )[currentEquips[filter.equipment_typeTab]];

  const drawer = (
    <div>
      <List>
        {currentEquipComponents.map((component, index) => (
          <DrawerListItem
            component={component}
            key={index}
            currentDrawerTab={currentDrawerTab}
            setCurrentDrawerTab={setCurrentDrawerTab}
          />
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex", pt: 2 }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              maxHeight: 780,

              marginLeft:
                drawersNum === 2
                  ? sideNavOpen
                    ? "500px"
                    : "310px"
                  : sideNavOpen
                  ? "240px"
                  : "65px",
              marginTop: "165px",
              backgroundColor: "",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <MaterialDBTable allData={data} filter={filter} />
      </Box>
    </Box>
  );
}

ComponentsDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ComponentsDrawer;
