import React, { useState } from "react";
import { getDrawingData } from "./api";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";
import { Box } from "@mui/material";
import DrawingSelection from "./components/DrawingSelection";
import BOMTable from "./components/BOMTable";

const DrawingRegister = () => {
  const [selectedDrawing, setSelectedDrawing] = useState(null);
  const [materials, setMaterials] = useState([]);

  const handleDrawingChange = async (drawing) => {
    setMaterials([]);
    setSelectedDrawing(drawing);
    await getDrawingData(drawing.id).then((res) => setMaterials(res));
  };
  console.log({ selectedDrawing, materials });

  return (
    <Box>
      <LabelledBox label="Drawing Manager">
        <DrawingSelection onDrawingChange={handleDrawingChange} />
      </LabelledBox>

      {/* <MrqDetails /> */}

      <BOMTable data={materials || []} />
      {/* <MrqMaterialsTable
                data={mrqData?.mrqMaterials || []}
                pos={mrqData?.pos}
            /> */}

      {/* <AddDataDialog open={dialog.open} onClose={onClose} /> */}
    </Box>
  );
};

export default DrawingRegister;
