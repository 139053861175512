import axios, { secondAxios } from "../../../../api/axios";

const getPlants = "/plant/";

export const getAllProjects = async () => {
  try {
    const { data } = await secondAxios.get(getPlants);
    console.log({ data });
    return { data, error: null };
  } catch (error) {
    console.error('Error fetching data:', error);
    return { data: null, error };
  }
};
