import React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function TabPanel2(props) {
    const { children, value, index,  padding, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            overflow="auto"
            // width="100%"
            style={{ width: '100%' }}
        >
            {value === index && (
                <Box sx={{ p: padding ?? 3, minHeight: "100vh", overflow: "auto" }} width="inherit">
                    <div id="tab-div" style={{ width: '100%', display: 'flex', flexDirection: 'column', overflow: "auto" }}>
                        {children}
                    </div>
                </Box>
            )}
        </div>
    );
}

TabPanel2.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default TabPanel2;
