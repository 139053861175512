import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Box, Dialog, DialogContent, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { Send as SendIcon } from '@mui/icons-material';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

const CommentsDialog = ({ open, handleClose, id, api }) => {
    const [commentsData, setCommentsData] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [newComment, setNewComment] = useState('');
    const [newAttachment, setNewAttachment] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const comments = await api.getComments(id);
                console.log(comments);
                setCommentsData(comments);
            } catch (error) {
                // Handle error
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [refresh]);

    const handleAddComment = async () => {
        if (newComment.trim() || newAttachment) {
            const formData = new FormData();
            formData.append('comment', newComment.trim());
            formData.append('taskId', id);
            if (newAttachment) {
                formData.append('attachment', newAttachment);
            }

            await api.createComment(formData);
            setCommentsData((prevComments) => [
                ...prevComments,
                { comment: newComment.trim(), attachment: newAttachment ? URL.createObjectURL(newAttachment) : null },
            ]);
            setNewComment('');
            setNewAttachment(null);
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setNewAttachment(file);
        setImagePreview(URL.createObjectURL(file));
    };

    const handleAddAttachmentClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogContent>
                <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                    <Typography variant="h5" gutterBottom>
                        Comments
                    </Typography>
                    <List>
                        {commentsData?.length ? (
                            commentsData?.map((comment, index) => (
                                <React.Fragment key={index}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemAvatar>
                                            <Avatar>{comment?.user?.name.charAt(0)}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={comment?.user?.name || "Unknown"}
                                            secondary={
                                                <>
                                                    {comment.comment}
                                                    {comment.attachment && (
                                                        <div>
                                                            <img
                                                                src={`${process.env.REACT_APP_STATIC}/uploads/${comment.attachment}`}
                                                                alt="attachment"
                                                                style={{ maxWidth: '100%', marginTop: '10px' }}
                                                            />
                                                        </div>
                                                    )}
                                                </>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        ) : (
                            <>Add a comment on the task</>
                        )}
                    </List>
                    <Box mt={2}>
                        {imagePreview && (
                            <Box mt={2}
                                sx={{
                                    display: "flex", width: "90%", justifyContent: "space-between", alignItems: "center",
                                    backgroundColor: "#f2f2f24d", padding: "8px", borderRadius: "5px 5px 0px 0px"
                                }}
                            >
                                <Typography variant="body1">Image Preview:</Typography>
                                <img src={imagePreview} alt="Preview" style={{ maxWidth: '10%' }} />
                            </Box>
                        )}
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', }}
                        >
                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Add a comment"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                inputProps={{ 'aria-label': 'add comment' }}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="attachment" onClick={handleAddAttachmentClick}>
                                <AddPhotoAlternateIcon />
                            </IconButton>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton
                                color="primary"
                                sx={{ p: '10px' }}
                                onClick={handleAddComment}
                                aria-label="send">
                                <SendIcon />
                            </IconButton>
                        </Paper>
                        {/* Hidden element to atatch images. */}
                        <input
                            type="file"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />

                        {/* <TextField
                            fullWidth
                            label="Add a comment"
                            variant="outlined"
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />
                        <input
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ marginTop: '10px' }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddComment}
                            endIcon={<SendIcon />}
                            style={{ marginTop: '10px' }}
                        >
                            Add Comment
                        </Button> */}
                    </Box>
                </Paper>
            </DialogContent>
        </Dialog >
    );
};

export default CommentsDialog;
