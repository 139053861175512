import { Button, Paper } from "@mui/material";
import React from "react";
import ChatBody from "../../../components/Chat/Chat/ChatBody";
const msgs1 = [
  {
    id: 1,
    from: "me",
    body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis quis nesciunt harum autem adipisci vel consectetur, doloribus et. Porro iste id impedit corporis repellat expedita labore unde aut illo tempore.",
    seen: true,
    createdAt: "2024-2-27 : 4:45pm",
  },
  {
    id: 2,
    from: "vendor",
    body: "xpedita labore unde aut illo tempore.",
    createdAt: "2024-2-28 : 1:16pm",
  },
  {
    id: 3,
    from: "me",
    body: " Porro iste id impedit corporis repellat expedita labore unde aut illo tempore.",
    seen: true,
    createdAt: "2024-2-27 : 2:50am",
  },
  {
    id: 4,
    from: "me",
    body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis quis nesciunt harum autem adipisci vel consectetur, ",
    seen: true,
    createdAt: "2024-2-27 : 2:50am",
  },

  {
    id: 5,
    from: "vendor",
    body: "Lorem ipsum  ",
    seen: false,
    createdAt: "2024-2-27 : 2:50am",
  },
  {
    id: 6,
    from: "vendor",
    body: " Porro iste id impedit corporis repellat expedita labore unde aut illo tempore.",
    seen: false,
    createdAt: "2024-2-27 : 2:50am",
  },
  {
    id: 7,
    from: "vendor",
    body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis quis nesciunt harum autem adipisci vel consectetur, ",
    seen: false,
    createdAt: "2024-2-27 : 2:50am",
  },

  {
    id: 8,
    from: "me",
    body: "Lorem ipsum  ",
    seen: false,
    createdAt: "2024-2-27 : 2:50am",
  },
];
export default function VendorBidChat() {
  return (
    <Paper
      sx={{
        minWidth: "400px",
        maxWidth: "700px",
        maxHeight: "700px",
        mx: "auto",
        my: 5,
        overflow: "auto",
        position: "relative",
      }}
    >
      <ChatBody msgs={msgs1} />
      <Button
        variant="contained"
        sx={{ position: "absolute", bottom: 10, right: 10, zIndex: 50 }}
      >
        Go to MRQ
      </Button>
    </Paper>
  );
}
