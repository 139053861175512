import React from 'react'
import { MaterialReactTable } from 'material-react-table';
import { lightTable, normalTableProps, tableWithBorderedCells } from '../../../../../../../styles/muiProps';

import { drawingColumns } from './defaultColumns';
const BOMTable = (props) => {
    const { data } = props
    // console.log("heeere",data)
    return (
        <MaterialReactTable
            columns={drawingColumns}
            // {...normalTableProps}
            // {...tableWithBorderedCells}
            data={data ?? []}
            defaultColumn={{ minSize: 20, size: 100, maxSize: 300 }}
            // muiTableBodyRowProps={({
            //     row
            // }) => ({
            //     sx: {
            //         backgroundColor: row.getValue('mrv_name') !== "" ? 'rgba(183, 233, 193, 0.18)' : '',
            //     }
            // })}
            {...lightTable}
            enableTopToolbar={true}
            enableSelectAll={false}
            enableGrouping
            enableColumnDragging={false}
            enableColumnActions
            muiSelectCheckboxProps={{ color: 'secondary' }}
            enablePagination
            enableBottomToolbar

        />
    )
}

export default BOMTable