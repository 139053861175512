import React from "react";
import EquipsTable from "../../../Project/ProjectForm/EquipsForm/EquipsTable";
import { Box, Typography } from "@mui/material";

export default function EquipmentsPage({ project }) {
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" sx={{ fontWeight: "bold", paddingBottom: 2, pl: 10 }}>
        Project Equipments :
      </Typography>
      <EquipsTable project={project} />
    </Box>
  );
}
