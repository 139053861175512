import { secondAxios } from "../../../../../../api/axios";

export const addMrqVendors = async ({ mrqId, selectedVendorsIds }) => {
  const { data } = await secondAxios.post("/mrq/add-vendors/" + mrqId, {
    vendorsId: selectedVendorsIds,
  });
  return data;
};

export const getMrqBid = async (mrqId) => {
  const { data } = await secondAxios.get(`/bid/mrq/${mrqId}`);
  return data;
};

export const postBidSelection = async ({ mrqId, bidsSelection }) => {
  const { data } = await secondAxios.post(
    "/bid/selection/mrq/" + mrqId,
    bidsSelection
  );
  return data;
};
