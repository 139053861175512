import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@mui/material";
import { getDrawings } from "../api";
import { useParams } from "react-router-dom";
import { BootstrapInput } from "../../../../../../../components/widgets/BootstrapInput";

const DrawingSelection = ({ onDrawingChange }) => {
  const { equipId } = useParams();
  const [data, setData] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [selectedRevision, setSelectedRevision] = useState("");
  const [selectedSheet, setSelectedSheet] = useState("");
  const [revisions, setRevisions] = useState([]);
  const [sheets, setSheets] = useState([]);

  useEffect(() => {
    // Fetch data from the API
    const fetchDrawings = async () => {
      await getDrawings(equipId)
        .then((response) => {
          setData(response);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };
    fetchDrawings();
  }, []);

  const handleNameChange = (event) => {
    const name = event.target.value;
    setSelectedName(name);
    setSelectedRevision("");
    setSelectedSheet("");
    const revs = [
      ...new Set(
        data
          .filter((d) => d.name === name && d.revision !== "")
          .map((d) => d.revision)
      ),
    ];
    setRevisions(revs);
    setSheets([]);
    console.log("revs", revs);

    // if no revisions, fetch drawing
    if (!revs.length) {
      const selectedDrawing = data.find((d) => d.name === name);
      onDrawingChange(selectedDrawing);
    }
  };

  const handleRevisionChange = (event) => {
    const revision = event.target.value;
    setSelectedRevision(revision);
    setSelectedSheet("");
    const sheetlist = [
      ...new Set(
        data
          .filter(
            (d) =>
              d.name === selectedName &&
              d.revision === revision &&
              d.sheetNumber !== ""
          )
          .map((d) => d.sheetNumber)
      ),
    ];
    setSheets(sheetlist);

    console.log("sheets", sheetlist);
    // if no sheets, fetch drawing
    if (!sheetlist.length) {
      const selectedDrawing = data.find(
        (d) => d.name === selectedName && d.revision === revision
      );
      onDrawingChange(selectedDrawing);
    }
  };

  const handleSheetChange = (event) => {
    const sheetNumber = event.target.value;
    setSelectedSheet(sheetNumber);
    const selectedDrawing = data.find(
      (d) =>
        d.name === selectedName &&
        d.revision === selectedRevision &&
        d.sheetNumber === sheetNumber
    );
    onDrawingChange(selectedDrawing);
  };

  // console.log(data);
  return (
    <Stack direction={"row"}>
      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>
          Number
        </InputLabel>
        <Select
          value={selectedName}
          onChange={handleNameChange}
          input={<BootstrapInput />}
        >
          {Array.from(new Set(data?.map((d) => d.name))).map((name) => (
            <MenuItem key={name} value={name}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
        disabled={!selectedName}
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>
          Revision
        </InputLabel>
        <Select
          value={selectedRevision}
          onChange={handleRevisionChange}
          input={<BootstrapInput />}
        >
          {revisions?.map((rev) => (
            <MenuItem key={rev} value={rev}>
              {rev}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
        margin="normal"
        disabled={!selectedRevision}
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>
          Sheet
        </InputLabel>
        <Select
          value={selectedSheet}
          onChange={handleSheetChange}
          input={<BootstrapInput />}
        >
          {sheets?.map((sheet) => (
            <MenuItem key={sheet} value={sheet}>
              {sheet}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default DrawingSelection;
