import React from 'react'
import NavTabs from '../../../../../../components/Tabs/NavTabs'

const MaterialReconciliationTab = () => {
    return (
        <div>
            <NavTabs />
        </div>
    )
}

export default MaterialReconciliationTab