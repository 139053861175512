import { Box, Typography } from '@mui/material';
import React, { ReactNode } from 'react';

const LabelledBox = ({ label, children }) => {
    return (
        <Box sx={{
            "backgroundColor": "#ffffff",
            "borderRadius": "4px",
            display: "flex",
            flexDirection: "column",
            padding: 2,
            pt: 1,
            mb: 2,
            width: "max-content",
            border: "0.5px solid #d5d6db80",
            // boxShadow: "rgba(99, 99, 99, 0.05) 0px 2px 8px 0px;"
            // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
            boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);"
        }}>
            <Typography sx={{ fontWeight: "bold", color: "#565D6DFF", pb: 1 }}>{label}</Typography>
            <div>{children}</div>
        </Box>
    );
};

export default LabelledBox;