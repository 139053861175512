import React from 'react'
import CustomTextField from "../../../../../components/widgets/CustomTextField"
import { inputData, ExternalPressureResults, Summary } from "./inputFields/cylindricalShellInputFormat"
import { useFormContext } from "react-hook-form";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import '@fontsource/roboto/300.css';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';

const GridStyle = {
    "&.MuiGrid-item": { p: "3px 0px 0px 5px" }
}

const subHeadingStyle = {
    color: "#4e5fa2",
    fontWeight: "bold",
    mb: "5px"
}

const typographySize = {
    fontSize: "0.8rem"
}

const CylindricalShellTab = (props) => {
    const { calculationResult } = props
    const { getValues } = useFormContext();

    const getLabel = () => {
        let Dia = getValues("ComboBox7")
        if (Dia === "ID")
            return "Inside Diameter"
        else if (Dia === "OD")
            return "Outside Diameter"
        else
            return "Diameter"
    }

    console.log("calculationResults - Cylindrical:", calculationResult)
    return (
        <div width='100%' >
            {/* <Typography variant="h6" color="primary" marginLeft = "10rem">Cylindrical Shell</Typography> */}
            {/* <br /> */}
            <Typography sx={{ ...subHeadingStyle }} >Input Data</Typography>
            {/* <br /> */}
            {/* <br /> */}

            {/* Input data */}
            <Grid container spacing={0} sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 12, xl: 12 }}>
                <Table sx={{ width: 580 }} size="small" aria-label="simple table" >
                    <TableBody>
                        {inputData.map(input => (
                            <TableRow
                                key={input.label}
                                sx={{ border: "none" }}
                            >
                                <TableCell component="th" scope="input" sx={{ padding: "5px", width: "281px", border: "none" }}>
                                    {input.label === "Inside/Outside Diameter" ? getLabel() : input.label}
                                </TableCell>
                                <TableCell align="right" sx={{ padding: "5px", width: "124.5px", border: "none" }}>{input.variable}</TableCell>
                                <TableCell sx={{ padding: "5px", border: "none" }}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        required={true}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,

                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                
            </Grid>

            {/* Thickness */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Thickness due to Internal Pressure (Tr)</Typography>
            {/* <Typography>&emsp;Tr = (P * (D / 2 + CA)) / (S * E - 0.6 * P)   per UG-27  (c)  (1)</Typography> */}
            <Typography {...typographySize} sx={{ mb: "3px" }}>{calculationResult?.Label1003 || "Tr = ( P* (D/2+CA)) / ( S * E - 0.6 * P)   per UG-27  (c)  (1)"}</Typography>
            <Typography {...typographySize} sx={{ mb: "3px", ml: "2.1ch" }}>{calculationResult?.Label6 || ""}</Typography>
            <Typography {...typographySize} sx={{ mb: "3px", ml: "2.1ch" }}>{calculationResult?.Label6a || ""}</Typography>
            <Stack direction="row" spacing={0.2} sx={{ alignItems: "end" }}>
                <Typography sx={{ backgroundColor: "white", width: "fit-content", ml: "2.1ch" }}>
                    =
                </Typography>
                <CustomTextField
                    hiddenLabel={true}
                    id="TextBox108"
                    name="TextBox108"
                    type="number"
                    disabled
                    // pairField={input.pairField || null}
                    inputProps={{
                        style: { backgroundColor: "e5e8f2", width: "15ch", fontSize: "0.7rem", height: "18px" },
                        endAdornment: <InputAdornment position="end">mm</InputAdornment>

                    }}
                />
            </Stack>


            {/* MAWP */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Max. All Working Pressure at Thickness (MAWP)</Typography>
            <Typography {...typographySize} sx={{ mb: "3px", }}>{calculationResult?.Label1001 || "MAWP = (S*E*(T-CA-CAE)) / ((D/2+CA) 0.6*(T-CA-CAE)) per UG-27  (c)  (1)"}</Typography>
            <Typography {...typographySize} sx={{ mb: "3px", ml: "6ch" }}>{calculationResult?.Label7 || ""}</Typography>
            <Stack direction="row" spacing={0.2} sx={{ alignItems: "end" }}>
                <Typography sx={{ backgroundColor: "white", width: "fit-content", ml: "6ch" }}>
                    =
                </Typography>
                <CustomTextField
                    hiddenLabel={true}
                    id="TextBox113"
                    name="TextBox113"
                    type="number"
                    disabled
                    // pairField={input.pairField || null}
                    inputProps={{
                        style: { backgroundColor: "e5e8f2", width: "20ch", fontSize: "0.7rem", height: "18px" },
                        endAdornment: <InputAdornment position="end">N/mm^2</InputAdornment>
                    }}
                />
            </Stack>

            {/* MAPNC */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Max. All Pressure New and Cold (MAPNC)</Typography>
            <Typography {...typographySize} sx={{ mb: "3px" }}>{calculationResult?.Label1002 || "MAPNC = ( SA * E * T ) / ( D/2 * 0.6 * T ) per UG-27 (c) (1)"}</Typography>
            <Typography {...typographySize} sx={{ mb: "3px", ml: "6.8ch" }}> {calculationResult?.Label8 || ""}</Typography>
            <Stack direction="row" spacing={0.2} sx={{ alignItems: "end" }}>
                <Typography sx={{ backgroundColor: "white", width: "fit-content", ml: "6.8ch" }}>
                    =
                </Typography>
                <CustomTextField
                    hiddenLabel={true}
                    id="TextBox114"
                    name="TextBox114"
                    type="number"
                    disabled
                    // pairField={input.pairField || null}
                    inputProps={{
                        style: { backgroundColor: "e5e8f2", width: "20ch", fontSize: "0.7rem", height: "18px" },
                        endAdornment: <InputAdornment position="end">N/mm^2</InputAdornment>
                    }}
                />
            </Stack>

            {/* External Pressure Results */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>External Pressure Results</Typography>
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 12, xl: 12 }}>
                <Table sx={{ width: 580 }} size="small" aria-label="simple table" >
                    <TableBody>
                        {ExternalPressureResults.map(input => (
                            <TableRow
                                key={input.label}
                                sx={{ border: "none" }}
                            >
                                <TableCell component="th" scope="input" sx={{ padding: "5px", width: "281px", border: "none" }}>
                                    {input.label}
                                </TableCell>
                                <TableCell align="right" sx={{ padding: "5px", width: "124.5px", border: "none" }}>{input.variable}</TableCell>
                                <TableCell sx={{ padding: "5px", border: "none" }}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            style: {
                                                width: "25ch", fontSize: "0.7rem", height: "18px",
                                                backgroundColor: input.disabled ? "#e5e8f2" : "white", color: input.disabled ? "black" : "black"
                                            }
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid >

            {/* Summary */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Summary</Typography>
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, md: 12, lg: 12, xl: 12 }}>


                {/* <TableContainer overflow="hidden"> */}
                <Table sx={{ width: 580 }} size="small" aria-label="simple table" >
                    <TableBody>
                        {Summary.map(input => (
                            <TableRow
                                key={input.label}
                                sx={{ border: "none" }}
                            >
                                <TableCell component="th" scope="input" sx={{ padding: "5px", border: "none" }}>
                                    {input.label}
                                </TableCell>
                                <TableCell align="right" sx={{ padding: "5px", border: "none" }}>{input.variable}</TableCell>
                                <TableCell sx={{ padding: "5px", border: "none" }}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            style: {
                                                width: "25ch", fontSize: "0.7rem", height: "18px",
                                                backgroundColor: input.disabled ? "#e5e8f2" : "white", color: input.disabled ? "black" : "black"
                                            }
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {/* </TableContainer> */}

            </Grid>
        </div >
    )
}

export default CylindricalShellTab