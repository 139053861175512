import React, { useState } from 'react'
import { srvDialogColumns } from '../../MCSTables'
import {MaterialReactTable} from 'material-react-table'
import { normalTableProps } from '../../../../../styles/muiProps'

const SRVDialogTable = (props) => {

    const { data, selectedMaterial, tableInstanceRef } = props

    const [selecteds, setSelected] = useState({})
    console.log("materials:", data, selectedMaterial, selecteds)
    return (
        <MaterialReactTable
            columns={srvDialogColumns}
            data={data ?? []}
            {...normalTableProps}
            defaultColumn={{ size: 100, maxSize: 190 }}
            muiTableBodyRowProps={({
                row
            }) => ({
                sx: {
                    backgroundColor:
                        row.getValue('mrv_name') && row.getValue('srv_name') ? 'rgba(0, 0, 20, 0.1)'
                            : row.getValue('mrv_name') && !row.getValue('srv_name') ? 'rgba(183, 233, 193, 0.18)'
                                : '',
                    cursor: row.getValue('mrv_name') && !row.getValue('srv_name') ? 'pointer' : ""
                }
            })}

            enableSelectAll={false}
            enableRowSelection={row => row.getValue('mrv_name') && !row.getValue('srv_name')}
            getRowId={(originalRow) => originalRow.id}
            tableInstanceRef={tableInstanceRef}
            initialState={{ columnVisibility: { id: false } }}
        />
    )
}

export default SRVDialogTable