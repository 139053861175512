import React from "react";
import { MCSContext } from "../../MCS.context";
import { Box, Button } from "@mui/material";

export const VendorSelectionBtns = ({ handleSelectVendorSubmit }) => {
  const { selectedBidVendors, setSelectedVendors, mrqData } =
    React.useContext(MCSContext);

  const isSubmitBtnDisabled = () => {
    let disabled = true;

    mrqData.vendors
      ?.map((v) => v.name)
      .forEach((name) => {
        if (!selectedBidVendors.includes(name)) {
          disabled = false;
        }
      });

    selectedBidVendors.forEach((name) => {
      if (!mrqData.vendors?.map((v) => v.name).includes(name)) {
        disabled = false;
      }
    });

    return disabled;
  };

  const onReset = () => {
    setSelectedVendors(mrqData.vendors?.map((v) => v.name));
  };

  const onSubmit = () => {
    handleSelectVendorSubmit();
  };
  return (
    <Box sx={{ p: 1, pb: 0, clear: "right" }}>
      <Button onClick={onReset} disabled={isSubmitBtnDisabled()}>
        Reset
      </Button>

      <Button
        variant="contained"
        sx={{ float: "right" }}
        disabled={isSubmitBtnDisabled()}
        onClick={onSubmit}
      >
        Submit Changes
      </Button>
    </Box>
  );
};
