import {
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import React from "react";
import ForumIcon from "@mui/icons-material/Forum";
import TaskIcon from "@mui/icons-material/Assignment";
import VendorIcon from "@mui/icons-material/HomeRepairService";
import styled from "@emotion/styled";

export default function NavChatBtn() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [allMsgsNumber, setAllMsgsNumber] = React.useState(1);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setAllMsgsNumber(0);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title="Messages">
        <IconButton
          sx={{
            color: "#4043ac",
            width: "40px",
            height: "40px",
            transform: "translateY(10px)",
          }}
          aria-label="messages"
          onClick={handleClick}
        >
          <Badge badgeContent={allMsgsNumber} color="warning">
            <ForumIcon color="action" />
          </Badge>
        </IconButton>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: "52%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar>
            <TaskIcon />
          </Avatar>
          <Box sx={{ pl: 1, minWidth: "410px" }}>
            <p style={{ fontWeight: "500" }}>Task-001</p>
            <p
              style={{
                fontSize: "14px",
                color: "#777",
                maxWidth: "400px",
                overflowX: "hidden",
              }}
            >
              quam exercitationem amet.
            </p>
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={handleClose}
          sx={{
            backgroundColor: "hsla(250, 86%, 97%, 1)",
          }}
        >
          <Avatar>
            <VendorIcon />
          </Avatar>
          <Box sx={{ pl: 1 }}>
            <p style={{ fontWeight: "500" }}>
              <StyledBadge badgeContent={4} color="warning">
                vendor one
              </StyledBadge>
            </p>
            <p
              style={{
                fontSize: "14px",
                color: "#777",
                maxWidth: "400px",
                overflowX: "hidden",
              }}
            >
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste
              repudiandae amet totam similique alias placeat rem, dolore esse
              deserunt sed labore unde sequi eos nostrum? Porro ad quam
              exercitationem amet.
            </p>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
}

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -12,
    top: 13,
    padding: "0 4px",
  },
}));
