import axios, { BASE_URL, SECOND_URL, secondAxios } from "../../../../api/axios";

// const CreateProjectRoute = "/mcs_project/create";
const CreateProjectRoute = SECOND_URL + "/plant";

export const addProjectApi = async (plant) => {
  console.log("project", { plant });

  const form = new FormData();

  // for (let i = 0; i < files.length; i++) {
  //   const file = files[i];
  //   form.append("files", file, file.webkitRelativePath);
  // }
  plant.projects.forEach(project => {
    delete project.id;
    project.revision = "0";
  });
  // form.equipments("project", JSON.stringify(project));
  console.log({ form })
  try {
    const { data } = await secondAxios.post(CreateProjectRoute, plant)
    return data
  }
  catch (err) {
    console.log(err)
  }
  // ********The below code is when we upload custom templates for folders**********// 



  // plant.projects.forEach((equip, index) => {
  //   const equipFiles = equip.folderData?.getAll(index + 1);
  //   console.log("equipfiles", equipFiles);
  //   equipFiles &&
  //     equipFiles.forEach((file) => {
  //       form.append(index + 1, file, file.webkitRelativePath);
  //     });
  // });
  // form.append("project", JSON.stringify(plant));


  // try {
  //   console.log("trying", form);
  //   const data = await fetch(CreateProjectRoute, {
  //     method: "post",
  //     body: form,
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data) => {
  //       console.log("Response data:", data);
  //       return data;
  //     });
  //   console.log({ data });
  //   return data;
  //   // const { data } = await axios.post(CreateProjectRoute, project, {
  //   //   headers: formData.getHeaders()
  //   // });
  //   // console.log({ data });
  //   // TODO: here we can :
  //   //    - extend our logic with our response data.
  // } catch (error) {
  //   console.log({ error });
  // }
};
