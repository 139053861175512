import { Box, Paper } from '@mui/material';
import React from 'react'
import SharepointTab from './SharepointTab';
import NavTabs from '../../../../../components/Tabs/NavTabs';
import { useParams } from 'react-router-dom';
import PCSTable from '../PCSTab/Table';

const DCSTab = (props) => {

    const { projectId } = useParams();

    console.log({ projectId })
    const tabs = [
        {
            label: "Sharepoint ",
            panel: <SharepointTab />,
        },
        {
            label: "RACI",
            panel: <PCSTable projectId={projectId || ""} type="RACI" />,
        },
        {
            label: "Document Transmittal",
            panel: <></>,
        },
    ];

    return (
        <Paper sx={{ width: "100%", display: "flex" }}>
            <NavTabs
                tabs={tabs}
                navLabel="dcs-tab"
            />
        </Paper>
    );
}

export default DCSTab