import { Box, Button } from "@mui/material";
import AddTaskIcon from "@mui/icons-material/AddTask";
import LoadingAnimation from "../../../components/Loading/LoadingAnimation";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Alert from "@mui/material/Alert";

import axios from "../../../api/axios";

const PidBox = (props) => {
  const connectPid = async () => {
    props.setPidState(1);
    const res = await axios.get("/pressure_vessel_table/connect-pid");
    res.status === 200 ? props.setPidState(2) : props.setPidState(3);
  };
  const disconnectPid = () => {
    props.setPidState(4);
  };
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      {props.pidState === 0 || props.pidState === 4 ? (
        <Button
          color="secondary"
          onClick={() => connectPid()}
          variant="contained"
          size="meduim"
          endIcon={<AddTaskIcon />}
        >
          Connect to P&ID
        </Button>
      ) : null}
      {props.pidState === 1 ? (
        <Button
          color="secondary"
          variant="contained"
          size="meduim"
          endIcon={<LoadingAnimation />}
        >
          Connecting to P&ID
        </Button>
      ) : null}
      {props.pidState === 2 ? (
        <Button
          color="success"
          onClick={() => disconnectPid()}
          variant="contained"
          size="meduim"
          endIcon={<CheckCircleIcon />}
        >
          Connected Click to Save
        </Button>
      ) : null}
      {props.pidState === 3 ? (
        <Button
          color="error"
          // onClick={() => handleGetPID()}
          variant="contained"
          size="meduim"
          // endIcon = {<CheckCircleIcon/>}
        >
          can't connect to P&ID
        </Button>
      ) : null}
      {props.pidState === 4 ? (
        <Alert severity="success">changes has been saved</Alert>
      ) : null}
    </Box>
  );
};

export default PidBox;
