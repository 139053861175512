import MenuItem from "@mui/material/MenuItem";

const Materials = [
  "SA 516 70",
  "SA 516 60",
  "SA 36",
  "SA 240 304",
  "SA 240 304L",
  "SA 240 316",
  "SA 240 316L",
];

const Components = [
  "2:1 S.E Head (L/B)",
  "Cylindrical Shell",
  "Conical Shell",
  "2:1 S.E Head (R/T)",
];

export const columns = [
  //column definitions...

  {
    accessorKey: "Component",
    header: "Component",
    size: 150,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiEditTextFieldProps: () => ({
      children: Components.map((Component) => (
        <MenuItem key={Component} value={Component}>
          {Component}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Diameter",
    header: "Diameter mm",
    Header: (
      <div>
        <div>Diameter</div>
        <div>(mm)</div>
      </div>
    ),
    size: 20,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
  },

  {
    accessorKey: "Material",
    header: "Material",
    size: 70,
    muiTableHeadCellProps: {
      align: "center",
    },
    muiEditTextFieldProps: () => ({
      children: Materials.map((Material) => (
        <MenuItem key={Material} value={Material}>
          {Material}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Can Length",
    header: "Can Length",
    Header: (
      <div>
        <div>Can Length</div>
        <div>(mm)</div>
      </div>
    ),
    size: 15,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Long Seam Angle",
    header: "Long Seam",
    Header: (
      <div>
        <div>Long Seam</div>
        <div>(deg)</div>
      </div>
    ),
    size: 25,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Provided Thickness",
    header: "Provided Thk",
    Header: (
      <div>
        <div>Provided Thk</div>
        <div>(mm)</div>
      </div>
    ),
    size: 35,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Weight (Kg)",
    header: "Weight (Kg)",
    Header: (
      <div>
        <div>Weight</div>
        <div>(Kg)</div>
      </div>
    ),
    size: 20,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
];

export const resultColumns = [
  //column definitions...
  {
    accessorKey: "Allowable_Stress",
    header: "Allowable Stress  ",
    Header: (
      <div style={{ width: "100px" }}>
        <div>Allowable Stress</div>
        <div>
          (N/mm<sup>2</sup>)
        </div>
      </div>
    ),
    size: "50px",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Required_Thickness",
    header: "Required Thickness (mm)",
    Header: (
      <div style={{ width: "70px" }}>
        <div>Reqd. Thk</div>
        <div>(mm)</div>
      </div>
    ),
    size: "60px",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "UCS_Curve",
    header: "UCS Curve",
    size: "40px",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "MDMT",
    header: "MDMT (DegC)",
    Header: (
      <div>
        <div>MDMT</div>
        <div>(DegC)</div>
      </div>
    ),
    size: "50px",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "MAWP",
    header: "MAWP (N/mm^2)",
    Header: (
      <div>
        <div>MAWP</div>
        <div>
          (N/mm<sup>2</sup>)
        </div>
      </div>
    ),
    size: "50px",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Hydrotest_Pressure",
    header: "Hydrotest Pr. (UG-99(b))",
    Header: (
      <div>
        <div>Hydrotest Pr.</div>
        <div>(UG-99(b))</div>
      </div>
    ),
    size: "50px",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
];

export const estimationColumns = [
  //column definitions...
  {
    accessorKey: "Circ_Weld_Hours",
    header: "Circ Weld Hours",
    size: "50px",
    enableEditing: false,
  },
  {
    accessorKey: "Long_Weld_Hours",
    header: "Long Weld Hours",
    size: "40px",
    enableEditing: false,
  },
  {
    accessorKey: "Rolling_Hours",
    header: "Rolling Hours",
    size: "50px",
    enableEditing: false,
  },
  {
    accessorKey: "Dishing_Hours",
    header: "Dishing Hours",
    size: "50px",
    enableEditing: false,
  },
  {
    accessorKey: "NDT_Cost",
    header: "NDT Cost",
    size: "50px",
    type: "number",
  },
];
