import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/400.css';
import axios from '../../../api/axios';
import { GuestResetPwdEmailRoute } from "../../../routes.js"

const GuestResetPwdEmail = () => {
    const [email, setEmail] = useState({
        "email": ""
    })

    const [error, setError] = useState("")
    const [message, setMessage] = useState("")


    const changeHandler = (event) => {
        setEmail({
            ...email,
            [event.target.name]: event.target.value
        })
    }

    const submitHandler = async (event) => {
        if (!email) {
            setError("Email is required")
        }
        else {
            axios.post(GuestResetPwdEmailRoute, email)
                .then(response => setMessage(response?.data))
                .catch(error => setError(error?.response?.data))
        }
    }

    return (
        <div className="reset-form">
            <Box
                sx={{
                    width: 300,
                    height: "fit-content",
                    backgroundColor: '#eaecee',
                    borderRadius: 2,
                    padding: 4,
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: "0px 6px 10px rgba(182, 188, 218, 0.5)",
                }}>
                <Typography variant="h4" component="h2" sx={{ marginBottom: 2 }}>
                    Reset Password Form
                </Typography>
                <p>Enter Your Email below and we will send you a link to reset your password.</p>
                <p className="error">{error}</p>
                <p className="success">{message}</p>
                <TextField
                    required
                    id="outlined-basic"
                    label="Email"
                    name="email"
                    onChange={changeHandler}
                    variant="outlined"
                    sx={{ marginTop: 3, marginBottom: 3 }}
                />
                <Button sx={{ color: "#4e5fa2" }} onClick={submitHandler}>Send Email</Button>
            </Box>
        </div>
    )
}

export default GuestResetPwdEmail