import * as React from "react";
import Box from "@mui/material/Box";
import { engCategories } from "./utils";
import TabPanel from "../../../components/Tabs/TabPanel.component";
import HorizontalTabs from "../../../components/Tabs/HorizontalTabs.Component";
import { getAllMaterialDB, getMaterialDB } from "./api";
import ComponentsDrawer from "./ComponentsDrawer";
import { getCurrentEquipTabs } from "./utils/getCurrentEquipTabs";
import { useLocation } from "react-router";

export default function MaterialsDBPage({ project, equip, drawersNum }) {
  const defaultTabs = {
    eng_categoryTab: 1,
    equipment_typeTab: 1,
    component_nameTab: "Shell",
    component_typeTab: "Cylindrical Shell",
  };

  const [filter, setFilter] = React.useState(defaultTabs);

  const [data, setData] = React.useState({});

  let { pathname } = useLocation();
  // console.log({ pathname });
  // project: "/projects/1/materials"
  // company: '/materials-database'
  // equip: "projects/1/equipments/main_pv/1"

  React.useEffect(() => {
    const func = async () => {
      const result = await getMaterialDB({
        projectId: project?.id,
        equipId: equip?.id,
      });

      setData(result);
      console.log(result);
    };
    func();
  }, []);
  return (
    <Box sx={{ width: "100%", backgroundColor: "white" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <HorizontalTabs
          name="eng_categoryTab"
          tabs={engCategories}
          value={filter}
          setValue={setFilter}
        />
      </Box>
      <TabPanel value={filter.eng_categoryTab} index={filter.eng_categoryTab}>
        <Box>
          <HorizontalTabs
            name="equipment_typeTab"
            tabs={getCurrentEquipTabs(filter.eng_categoryTab)}
            value={filter}
            setValue={setFilter}
          />
          {getCurrentEquipTabs(filter.eng_categoryTab).map((tab, i) => {
            return (
              <TabPanel
                value={filter.equipment_typeTab}
                index={i}
                key={tab + i}
              >
                {data.materials && data.materials[0]?.id ? (
                  <ComponentsDrawer
                    filter={filter}
                    setFilter={setFilter}
                    data={data.materials}
                    drawersNum={drawersNum}
                  />
                ) : (
                  "loading.."
                )}
              </TabPanel>
            );
          })}
        </Box>
      </TabPanel>
    </Box>
  );
}
