import React from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import TabPanel from "../../../../../components/widgets/TabPanel";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DataTabs = (props) => {
  const [currentTab, setCurrentTab] = React.useState(0); //value of the current tab
  const [open, setOpen] = React.useState(false); //for alert open
  const [key, setKey] = React.useState(Math.random());
  const { projectId } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const reRender = () => {
    setKey(Math.random());
  };

  //handle change of tabs
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            File created successfully!
          </Alert>
        </Snackbar>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          textcolor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab label="Beams" />
          <Tab label="Channels" />
          <Tab label="Angels" />
          <Tab label="Plate" />
          <Tab label="Flate bar gratings" />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index={0}>
        {/* <PipeTab reRender={reRender} /> */}
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        {/* <FlangesTab reRender={reRender} /> */}
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        {/* <FittingsTab reRender={reRender} /> */}
      </TabPanel>
      <TabPanel value={currentTab} index={3}>
        {/* <ValvesTab reRender={reRender} /> */}
      </TabPanel>
      <TabPanel value={currentTab} index={4}>
        {/* <GasketTab reRender={reRender} /> */}
      </TabPanel>
    </Box>
  );
};

export default DataTabs;
