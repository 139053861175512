import React from 'react'
import Grid from '@mui/material/Grid';
import { Box, InputAdornment, Paper, Stack, Typography } from '@mui/material';
import CustomTextField from "../../../../../../components/widgets/CustomTextField";
import {MaterialReactTable} from 'material-react-table';
import { columns } from '../utils';
import { minimalMuiTableProps } from '../../../../../../styles/muiProps';

const SummaryBox = ({ data }) => {
    return (
        <Paper sx={{ width: "20rem", }}>
            <Box sx={{ backgroundColor: "white", width: "30rem", padding: 1 }}>
                <Stack direction={"row"} gap={2} sx={{ width: "25rem", mb: 1 }}>
                    <Typography>
                        Fabrication Manhour Rate/Hr
                    </Typography>
                    <div style={{ width: "10rem" }}>
                        <CustomTextField
                            hiddenLabel={true}
                            name={"TextBox35"}
                            type={"number"}
                            fullWidth={false}
                            // required={true}
                            inputProps={{
                                endAdornment: <InputAdornment position="start">$/hr</InputAdornment>,
                                style: { fontSize: "0.700rem", height: "22px" }
                                // height: "24px"
                            }}
                        />
                    </div>
                </Stack>

                <MaterialReactTable data={data || []} columns={columns} {...minimalMuiTableProps} />
            </Box>
        </Paper>
    )
}

export default SummaryBox