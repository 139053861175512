import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import ConstructionIcon from "@mui/icons-material/Construction";
import EquipsTable from "./EquipsTable";

export default function EquipForm() {
  const { state } = useLocation();

  // console.log({ state });
  return (
    <Box sx={{ mt: 2 }}>
      <Paper
        elevation={3}
        sx={{
          marginRight: "15%",
          marginLeft: "15%",
          padding: 1,
          paddingBottom: 3,
          paddingRight: 3,
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{ padding: 1, fontWeight: "bold" }}
        >
          <HomeRepairServiceIcon
            sx={{ marginRight: 1, transform: "translateY(4px)" }}
          />
          Project No.:{" "}
          <Typography
            variant="h7"
            gutterBottom
            sx={{ padding: 1, fontWeight: "500" }}
          >
            {state.name}.
          </Typography>
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          sx={{ padding: 1, fontWeight: "bold" }}
        >
          <ConstructionIcon
            sx={{ marginRight: 1, transform: "translateY(4px)" }}
          />
          Equipments :
        </Typography>
        <Box sx={{ paddingLeft: 5 }}>
          <EquipsTable project={state} />
        </Box>
      </Paper>
    </Box>
  );
}
