import { Box, Stack } from "@mui/material";

const EstimationColumns = (getValues) => [
  {
    accessorKey: "Code",
    header: "Code",
    size: 100,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Component",
    header: "Component",
    size: 180,
    enableHiding: true,
  },
  {
    accessorKey: "Description",
    header: "Description",
    size: 250,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
    Footer: () => (
      <Stack>
        <Box
          color="warning.main"
          sx={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}
        >
          Total
        </Box>
      </Stack>
    ),
  },
  {
    accessorKey: "BOM",
    header: "BOM",
    Header: (
      <div>
        <div>BOM</div>
        <div>Adder</div>
      </div>
    ),
    size: 50,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Qty",
    header: "Qty",
    size: 50,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Measure/Wt",
    header: "Value",
    size: 70,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
    // Footer: () => (
    //   <Stack>
    //     <Box color="warning.main" sx={{ textAlign: "center" }}>
    //       {/* :TODO */}
    //       {getValues("totalMaterialCost") || 0}
    //     </Box>
    //   </Stack>
    // ),
  },
  {
    accessorKey: "Unit",
    header: "Unit",
    size: 70,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
    Footer: () => (
      <Stack>
        <Box sx={{ textAlign: "center" }}>kg</Box>
      </Stack>
    ),
  },
  {
    accessorKey: "Material Cost/ Unit",
    header: "Material Cost/ Unit",
    Header: (
      <div>
        <div>Material</div>
        <div>Cost/ Unit</div>
      </div>
    ),
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
    // size: "40px"
  },
  {
    accessorKey: "Material Cost",
    header: "Est. Material Cost $",
    Header: (
      <div>
        <div>Est.</div>
        <div>Material</div>
        <div>Cost $</div>
      </div>
    ),
    size: 30,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
    Footer: () => (
      <Stack>
        <Box color="warning.main" sx={{ textAlign: "center" }}>
          {getValues("totalMaterialCost") || 0}
        </Box>
      </Stack>
    ),
  },
  // {
  //   accessorKey: "actual_cost",
  //   header: "Actual Material Cost $",
  //   Header: (
  //     <div>
  //       <div>Actual</div>
  //       <div>Material</div>
  //       <div>Cost $</div>
  //     </div>
  //   ),
  //   size: 30,
  //   enableSorting: false,
  //   muiTableHeadCellProps: { align: "center" },
  //   Footer: () => (
  //     <Stack>
  //       <Box color="warning.main" sx={{ textAlign: "center" }}>
  //         {getValues("totalMaterialCost") || 0}
  //       </Box>
  //     </Stack>
  //   ),
  // },
  {
    accessorKey: "FAB Hours",
    header: "Est. FAB Hrs",
    Header: (
      <div>
        <div>Est.</div>
        <div>FAB</div>
        <div>Hours</div>
      </div>
    ),
    size: 20,
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
    Footer: () => (
      <Stack>
        <Box color="warning.main" sx={{ textAlign: "center" }}>
          {getValues("totalHours") || 0}
        </Box>
      </Stack>
    ),
  },
  // {
  //   accessorKey: "actual_hrs",
  //   header: "Actual FAB Hrs",
  //   Header: (
  //     <div>
  //       <div>Actual</div>
  //       <div>FAB</div>
  //       <div>Hours</div>
  //     </div>
  //   ),
  //   size: 20,
  //   enableSorting: false,
  //   muiTableHeadCellProps: { align: "center" },
  //   Footer: () => (
  //     <Stack>
  //       <Box color="warning.main" sx={{ textAlign: "center" }}>
  //         {getValues("totalHours") || 0}
  //       </Box>
  //     </Stack>
  //   ),
  // },
  //   {
  //     accessorKey: "FAB Rate/Hr",
  //     header: "FAB Rate/Hr",
  //   },
  //   {
  //     accessorKey: "FAB Cost",
  //     header: "FAB Cost",
  //     // size: 120,
  //     Footer: () => (
  //       <Stack>
  //         Total Fab Cost:
  //         <Box color="warning.main" sx={{textAlign:"center"}}>{getValues("totalFabCost") || 0}</Box>
  //       </Stack>
  //     ),
  //   },
  {
    accessorKey: "Remarks",
    header: "Remarks",
    enableSorting: false,
    muiTableHeadCellProps: { align: "center" },
    // size: "40px"
  },
  // ...othercolumns
];

export default EstimationColumns;
