import axios from "../../../../api/axios";

export const postMaterials = async (equipWithMaterial) => {
  const postMaterials = "/materialdb/postEquipMaterial";
  const { data } = await axios.post(postMaterials, equipWithMaterial);

  console.log({ data });
  return data;
};

export const getProjectWithEquip = async (projectId, equipId) => {
  const getProjectWithEquipApi = "/mcs_project"
  const { data } = await axios.get(`${getProjectWithEquipApi}/${projectId}/${equipId}`);
  
  console.log({ data });
  return data;
}