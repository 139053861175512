import { Button, ListItemIcon, MenuItem } from "@mui/material";
import React from "react";
import { getColumns } from "./columnNames/NozzleColumns";
import {MaterialReactTable} from "material-react-table";
import { Delete as DeleteIcon } from "@mui/icons-material";

const InputTable = (props) => {
  const { handleCreateNewRow, handleDeleteRow, handleSaveCell, fields } = props;

  return (
    <MaterialReactTable
      columns={getColumns()}
      data={fields}
      enableSorting={false}
      editDisplayMode="table"
      enableEditing
      enableRowActions
      positionActionsColumn="last"
      enableColumnActions={false}
      defaultColumn={{ minSize: 20, size: 40 }}
      muiTableHeadCellProps={({ cell }) => ({
        sx: {
          borderRight: "1px solid rgba(224,224,224,1)",
          backgroundColor: "hsla(250, 86%, 97%, 1)",
          pt: 2,
          height: "95px",
          "& .Mui-TableHeadCell-Content-Wrapper": {
            whiteSpace: "normal",
            justifyContent: "center",
          },
        },
      })}
      initialState={{ density: "compact" }}
      muiTableProps={{
        size: "small",
        sx: {
          "& .MuiInputBase-root": {
            fontSize: "0.7rem",
            borderRadius: 0,
          },
          "& .MuiButtonBase-root": {
            fontSize: "0.7rem",
          },
        },
      }}
      muiTableBodyCellProps={{
        sx: {
          padding: "0rem",
        },
      }}
      muiBottomToolbarProps={{
        sx: {
          "& .css-1llu0od": {
            padding: "0rem 0.5rem",
          },
        },
      }}
      muiEditTextFieldProps={({ cell }) => ({
        //onBlur is more efficient, but could use onChange instead
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value, "DataGridView1");
        },
        sx: {
          "& .MuiInputBase-input": {
            padding: "4.5px 4px",
            textAlign: "center",
          },
          borderRight: "1px solid rgba(224,224,224,1)",
        },
      })}
      renderBottomToolbarCustomActions={() => (
        <Button
          color="secondary"
          onClick={() => handleCreateNewRow()}
          variant="contained"
        >
          Add row
        </Button>
      )}
      renderRowActionMenuItems={({ row }) => [
        <MenuItem
          key={0}
          sx={{ m: 0 }}
          onClick={() => handleDeleteRow(row.index)}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      ]}
    />
  );
};

export default InputTable;
