export const itpFuncs = ["R", "I", "V", "W", "H"];

export const manufacturingTabs = [
  "Production Impact test (Production Charpy Test) shell side",
  "PMI of SS weld consumables",
  "PMI of Raw Materials (SS materials)",
  '"Dimensional Inspection and Fit-Up Check of Shell assembly',
  "Check: ID/OD, Out of roundness",
  "Dimensional Check including Nozzle projection, orientation and elevation",
  "Dimensional Check of Glycol Coil & Baffle assembly (Internals)",
  "Random verification of Welding (as required by WPS/PQR)",
  "Radiography RT-1",
  'Full UT on Cat ""D"" Joints(Before Reinforcing pads welding)',
  '100% MPI of Cat ""D"" welds (External surfaces)(Before and after Reinforcing pads welding)',
  "100% MPI of L/S welds, C/S Welds (External Surfaces)",
  "100% MPI of Temps, Arc-Strikes & attachment welds (External Surfaces)",
  '100% WFMT of Cat "D" welds (Internal Surfaces)',
  "100% WFMT of L/S welds, C/S Welds (Internal Surfaces)",
  "100% WFMT of Temps, Arc-Strikes & attachment welds (Internal Surfaces)",
  "Welding Repair as required",
  "Nozzle Flange Face Surface finish and gasket face inspeciton",
  "Final Visual Inspection - Shell side Internals",
  "Final Visual Inspection - Shell side Externals",
];

export const preFab = [
  "Pre-Fabrication Meeting",
  "Customer Specs Reviewed & accepted",
  "IFC drawings and design calculations to shop",
  "Registered Drawings to Shop",
  "AI Hold Points Established",
  "Customer Hold Points Established",
  "WPS/PQR Approval",
  "Welder Qualification Records",
  "NDE Procedures Approval",
  "NDE Personnel Qualification",
  "Material Teast Reports (MTR)",
  "Material Identification/examination + Heat # Check, record and tracability",
  "Receiving Inspection all Dished Ends/Heads",
  "Receiving and Dimensional Inspection of Shell",
];

export const pwht = [
  "Post Weld Heat Treatment (PWHT) (Complete equipment)",
  "Radiography RT-1 (After PWHT)",
  `Full UT on Cat "D" Joints(After PWHT)`,
  `100% MPI of Cat "D" welds (External surfaces)(After PWHT)`,
  "100% MPI of L/S welds, C/S Welds (External Surfaces)  (After PWHT)",
  "100% MPI of Temps, Arc-Strikes & attachment welds (External Surfaces)  (After PWHT)",
  '100% WFMT of Cat "D" welds (Internal Surfaces)  (After PWHT)',
  "100% WFMT of L/S welds, C/S Welds (Internal Surfaces)  (After PWHT)",
  "100% WFMT of Temps, Arc-Strikes & attachment welds (Internal Surfaces)  (After PWHT)",
  "Hardness Test after PWHT  on process side whereever accessible",
  "Nozzle Flange Face Surface finish check",
  "Closure of  any open NCR",
  "Final Visual Inspection - After PWHT",
  "Final Visual Inspection - After PWHT",
];

export const hydrostaticTest = [
  "Potable water Chloride content report (Water Quality Certificate)",
  "Hydrotest",
  "Drain and Dry",
];

export const finalPreparation = [
  "Baseline UT (Thickness survey)",
  "TSA coating on External Surfaces (Separate ITP)",
  "Electrical Heat Tracing (EHT) (Separate ITP)",
  "Electrical Heat Tracing (EHT) Reports",
  "Insulation & Jacketing (Separate ITP)",
  "UT Inspection Port",
  "Stencling on Top of Insulation Jacketing",
  "Nameplate Complete   ",
  "Manufacturers Data Report Complete",
  "Nozzle Flange Gasket Face condition check & Protection for Shipping",
  "Preparation for Shipment/Ship Loose Items Check ",
  "Final Inspection Release",
  "Final Shipping Release",
  "Shipping Loadout and Shipping photo",
  "Docs with Shipment",
];

export const itpProcedures = [
  "Radiography Testing (RT) Procedure",
  "Ultrasonic Testing (UT) General Weld Procedure",
  "Magnetic Particle Testing (MT) Procedure",
  "Hardness Testing Procedure for Portable Equipment",
  "Visual Examination Procedure",
  "Post Weld Heat Treatment (PWHT) Procedure",
  "Hydrostatic Testing Procedure",
  "Blasting & Painting Procedure",
  "Storage & Preservation Procedure",
  "Weld Repair Procedure",
  "PMI Procedure",
];

export const Notes = [
  "Refer to CDT Drawing Number ",

  "Record to be maintained in QC Job file (EI / Sub-Vendor) for incorporation into MRB",

  "Acceptance criteria is as per Codes / Standards / Client Specfications & / or as per EI Drg.: ",

  "NDE Tech Qualifications = Level-II (CGSB or ASNT).",

  "Refer to project specific approved WPS/PQR, NDE Procedures as applicable.",

  `Tolerances: As per CDT "000004-STANDARD FABRICATION TOLERANCES REV.2" OR as per IFC Drawings, whichever is stringent.`,

  "Receiving of all raw materials, parts, partially/fully fabricated components in accordance with CDT Quality Control Manual.",

  "If Black White contrast MPI is to be performed, the paint shall be removed by buffing after MPI testing.",

  "",

  "Docs with Shipment to Client : Inspection Release, Signed ITP, MDR, Packing List, Bill of Lading (BOL), Storage and Preservation procedure.MSDS's: Rust Preventative",

  "* Customer/Vendor/AI/TPI (as applicable) to mark their intervention points (R/I/V/W/H) at their respective column.",
];

export const definitions = [
  {
    key: "R",
    content:
      "Documentation Review is defined as an inspection process in which the Purchasers Inspector checks documents for accuracy, relationship to object or items to which they pertain, and conformance with Specifications, Codes or Standards. The reviews are performed during standard inprogress inspection visits. The review, initialing and dating of the documents by the Purchasers Inspector does not constitute acceptance.",
  },
  {
    key: "I",
    content:
      "Inspect is defined as survCDTlance activities during standard in-progress inspection visits, including detailed observations and measurements, verifying compliance with the Specifications and Purchase Order requirements.",
  },
  {
    key: "V",
    content:
      "Verification Points are defined as important steps, processes or tests in the Seller’s manufacturing cycle that the Purchasers Inspector will monitor during standard in-progress inspection visits. Results of these observations are required to be included in the inspection report for the specific visit when the observation was made.",
  },
  {
    key: "W",
    content:
      "Witness Points are defined as critical steps, processes or tests in the Seller’s manufacturing cycle that the Purchasers Inspector will witness. If the Purchasers Inspector does not attend the witness point, the Seller can only proceed with the work past the witness point when the Purchasers Inspector waives his right to attend. The Seller shall give the Purchasers Inspector Five (5) working days advance notice (OR AS AGREED) of the activity each time the activity is to be performed so that it can be witnessed.",
  },
  {
    key: "H",
    content:
      "Hold Points are defined to be the same as Witness Points except the Seller may not proceed with the work beyond the hold point without the Purchasers Inspector in attendance to witness the activity. The Seller shall give the Purchasers Inspector Five (5) working days advance notice (OR AS AGREED) of the activity each time the activity is to be performed, so that it can be witnessed.",
  },
];
