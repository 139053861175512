import MenuItem from "@mui/material/MenuItem";
import {
  Type,
  Service,
  Size,
  Flange_Rating,
  Sch_thk,
  Sch_thk24,
} from "./select_options/NozzleTab_Select";
import {
  Sch_thk1_2,
  Sch_thk3_4,
  Sch_thk1,
  Sch_thk1_1_4,
  Sch_thk1_1_2,
  Sch_thk2,
  Sch_thk2_1_2,
  Sch_thk3,
  Sch_thk3_1_2,
  Sch_thk4,
  Sch_thk5,
  Sch_thk6,
  Sch_thk8,
  Sch_thk10,
  Sch_thk12,
  Sch_thk14,
  Sch_thk16,
  Sch_thk18,
} from "./select_options/NozzleTab_Select";
export const getColumns = (fields) => {
  const getSchThk = (cell, row) => {
    // console.log("Got here")
    const colSize = row?.original?.Size;
    // console.log(cell.row.index, row?.original?.Size)
    if (colSize === "1 / 2") return Sch_thk1_2;
    else if (colSize === "3 / 4") return Sch_thk3_4;
    else if (colSize === "1") return Sch_thk1;
    else if (colSize === "1 1 / 4") return Sch_thk1_1_4;
    else if (colSize === "1 1 / 2") return Sch_thk1_1_2;
    else if (colSize === "2") return Sch_thk2;
    else if (colSize === "2 1 / 2") return Sch_thk2_1_2;
    else if (colSize === "3") return Sch_thk3;
    else if (colSize === "3 1 / 2") return Sch_thk3_1_2;
    else if (colSize === "4") return Sch_thk4;
    else if (colSize === "5") return Sch_thk5;
    else if (colSize === "6") return Sch_thk6;
    else if (colSize === "8") return Sch_thk8;
    else if (colSize === "10") return Sch_thk10;
    else if (colSize === "12") return Sch_thk12;
    else if (colSize === "14") return Sch_thk14;
    else if (colSize === "16") return Sch_thk16;
    else if (colSize === "18") return Sch_thk18;
    else if (colSize === "24") return Sch_thk24;
    else return [""];
  };
  // const changeschThkVals = (cell)=>{
  //     console.log("cahnged")
  //     console.log("cell", cell)
  // }
  const columns = [
    {
      accessorKey: "Mark",
      header: "Mark",
      minSize: "150px",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Service",
      header: "Service",
      size: "76px",
      muiEditTextFieldProps: () => ({
        children: Service.map((Component) => (
          <MenuItem key={Component} value={Component}>
            {Component}
          </MenuItem>
        )),
        select: true,
      }),
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },

    {
      accessorKey: "Size",
      header: "Size",
      size: "76px",
      muiEditTextFieldProps: ({ cell }) => ({
        children: Size.map((Material) => (
          <MenuItem key={Material} value={Material}>
            {Material}
          </MenuItem>
        )),
        select: true,
        SelectProps: {
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 200,
              },
            },
          },
        },
      }),
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Type",
      header: "Type",
      size: "60px",
      muiEditTextFieldProps: () => ({
        children: Type.map((Material) => (
          <MenuItem key={Material} value={Material}>
            {Material}
          </MenuItem>
        )),
        select: true,
      }),
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Flange Rating",
      header: "Flange Rating",
      size: "60px",
      muiEditTextFieldProps: () => ({
        children: Flange_Rating.map((Material) => (
          <MenuItem key={Material} value={Material}>
            {Material}
          </MenuItem>
        )),
        select: true,
      }),
    }, //end
    {
      accessorKey: "Sch/Thk",
      header: "Sch/ Thk",
      size: "75px",
      muiEditTextFieldProps: ({ cell, column, row }) => ({
        // children: Sch_thk.map((Material) => (
        children: getSchThk(cell, row).map((Material) => (
          <MenuItem key={Material} value={Material}>
            {Material}
          </MenuItem>
        )),
        select: true,
      }),
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Weld Detail",
      header: "Weld Detail",
      size: "75px",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Nozzle Weld (A)",
      header: "Nozzle Weld (A) mm",
      Header: (
        <div>
          <div style={{ width: "45px" }}>Nozzle</div>
          <div>Weld</div>
          <div>(A) mm</div>
        </div>
      ),
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Inside Weld (B)",
      header: "Inside Weld (B)",
      Header: (
        <div>
          <div style={{ width: "45px" }}>Inside</div>
          <div>Weld</div>
          <div>(b) mm</div>
        </div>
      ),
      size: "60px",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Pad Weld (C)",
      header: "Pad Weld (C)",
      Header: (
        <div>
          <div style={{ width: "45px" }}>Pad</div>
          <div>Weld</div>
          <div>(c) mm</div>
        </div>
      ),
      size: "60px",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Outside Proj",
      header: "Outside Proj (mm)",
      maxSize: "50px",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Inside Proj",
      header: "Inside Proj (mm)",
      size: "60px",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Pad OD",
      header: "Pad OD (mm)",
      size: "60px",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
    {
      accessorKey: "Pad Thk",
      header: "Pad Thk (mm)",
      size: "60px",
      enableSorting: false,
      muiTableHeadCellProps: {
        align: "center",
      },
    },
  ];

  return columns;
};

//DGV1
export const columns = [
  {
    accessorKey: "Mark",
    header: "Mark",
    minSize: "150px",
  },
  {
    accessorKey: "Service",
    header: "Service",
    size: "76px",
    muiEditTextFieldProps: () => ({
      children: Service.map((Component) => (
        <MenuItem key={Component} value={Component}>
          {Component}
        </MenuItem>
      )),
      select: true,
    }),
  },

  {
    accessorKey: "Size",
    header: "Size",
    size: "76px",
    muiEditTextFieldProps: () => ({
      children: Size.map((Material) => (
        <MenuItem key={Material} value={Material}>
          {Material}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        },
      },
      // onchange: () => {

      // }
    }),
  },
  {
    accessorKey: "Type",
    header: "Type",
    size: "60px",
    muiEditTextFieldProps: () => ({
      children: Type.map((Material) => (
        <MenuItem key={Material} value={Material}>
          {Material}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Flange Rating",
    header: "Flange Rating",
    size: "60px",
    muiEditTextFieldProps: () => ({
      children: Flange_Rating.map((Material) => (
        <MenuItem key={Material} value={Material}>
          {Material}
        </MenuItem>
      )),
      select: true,
    }),
  }, //end
  {
    accessorKey: "Sch/Thk",
    header: "Sch/Thk",
    size: "75px",
    muiEditTextFieldProps: () => ({
      children: Sch_thk.map((Material) => (
        // children: getSchThk().map((Material) => (
        <MenuItem key={Material} value={Material}>
          {Material}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Weld Detail",
    header: "Weld Detail",
    size: "75px",
  },
  {
    accessorKey: "Nozzle Weld (A)",
    header: "Nozzle Weld (A)",
    size: "60px",
  },
  {
    accessorKey: "Inside Weld (B)",
    header: "Inside Weld (B)",
    size: "60px",
  },
  {
    accessorKey: "Pad Weld (C)",
    header: "Pad Weld (C)",
    size: "60px",
  },
  {
    accessorKey: "Outside Proj",
    header: "Outside Proj",
    maxSize: "50px",
  },
  {
    accessorKey: "Inside Proj",
    header: "Inside Proj",
    size: "60px",
  },
  {
    accessorKey: "Pad OD",
    header: "Pad OD",
    size: "60px",
  },
  {
    accessorKey: "Pad Thk",
    header: "Pad Thk",
    size: "60px",
  },
];

//DGV10
const minSize = "50px";
const Direction = ["Radial", "Tangential"];
const BlindFlange = ["Yes", "No"];
const Location = ["Shell", "Left/Bottom Head", "Right/Top Head"];
export const GeometryDataColumns = [
  {
    accessorKey: "Dist From DL",
    header: "Dist From DL (mm)",
    Header: (
      <div>
        <div>Dist</div>
        <div>From DL</div>
        <div>(mm)</div>
      </div>
    ),
    size: "50px",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Orientation",
    header: "Orientation (Deg)",
    size: 40,
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Dist From CL (+/-)",
    header: "Dist(+/-) From CL (mm)",
    Header: (
      <div>
        <div>Dist (+/-)</div>
        <div>From CL</div>
        <div>(mm)</div>
      </div>
    ),
    size: "50px",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Direction",
    header: "Direction",
    size: "50px",
    muiEditTextFieldProps: () => ({
      children: Direction.map((elem) => (
        <MenuItem key={elem} value={elem}>
          {elem}
        </MenuItem>
      )),
      select: true,
    }),
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    // size: minSize
  },
  {
    accessorKey: "Location",
    header: "Location",
    size: "50px",
    muiEditTextFieldProps: () => ({
      children: Location.map((elem) => (
        <MenuItem key={elem} value={elem}>
          {elem}
        </MenuItem>
      )),
      select: true,
    }),
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
    // size: minSize
  },
  {
    accessorKey: "Nozzle Material",
    header: "Nozzle Material",
    size: "76px",
    muiEditTextFieldProps: () => ({
      children: NozzleMaterial.map((elem) => (
        <MenuItem key={elem} value={elem}>
          {elem}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        },
      },
    }),
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Flange Material",
    header: "Flange Material",
    size: "76px",
    muiEditTextFieldProps: () => ({
      children: FlangeMaterial.map((elem) => (
        <MenuItem key={elem} value={elem}>
          {elem}
        </MenuItem>
      )),
      select: true,
    }),
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
  {
    accessorKey: "Pad Material",
    header: "Pad Material",
    size: "76px",
    muiEditTextFieldProps: () => ({
      children: PadMaterial.map((elem) => (
        <MenuItem key={elem} value={elem}>
          {elem}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        },
      },
    }),
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  },
];

const NozzleMaterial = [
  "SA 516 70",
  "SA 516 60",
  "SA 36",
  "SA 240 304",
  "SA 240 304L",
  "SA 240 316",
  "SA 240 316L",
  "SA 106 B",
  "SA 333 6",
  "SA 312 TP304",
  "SA 312 TP316",
  "SA 312 TP316L",
];
const FlangeMaterial = [
  "SA 105",
  "SA 350 LF2",
  "SA 182 F304",
  "SA 182 F304L",
  "SA 182 F316",
  "SA 182 F316L",
];
const PadMaterial = [
  "SA 516 70",
  "SA 516 60",
  "SA 36",
  "SA 240 304",
  "SA 240 304L",
  "SA 240 316",
  "SA 240 316L",
];
//DGV11
export const MaterialDataColumns = [
  {
    accessorKey: "Nozzle Material",
    header: "Nozzle Material",
    size: "76px",
    muiEditTextFieldProps: () => ({
      children: NozzleMaterial.map((elem) => (
        <MenuItem key={elem} value={elem}>
          {elem}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        },
      },
    }),
  },
  {
    accessorKey: "Nozzle Allowable Stress",
    header: "Nozzle Allowable Stress",
    minSize: minSize,
  },
  {
    accessorKey: "Flange Material",
    header: "Flange Material",
    size: "76px",
    muiEditTextFieldProps: () => ({
      children: FlangeMaterial.map((elem) => (
        <MenuItem key={elem} value={elem}>
          {elem}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Material Group",
    header: "Material Group",
    minSize: minSize,
  },
  {
    accessorKey: "Pad Material",
    header: "Pad Material",
    size: "76px",
    muiEditTextFieldProps: () => ({
      children: PadMaterial.map((elem) => (
        <MenuItem key={elem} value={elem}>
          {elem}
        </MenuItem>
      )),
      select: true,
      SelectProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: 200,
            },
          },
        },
      },
    }),
  },
  {
    accessorKey: "Pad Allowable Stress",
    header: "Pad Allowable Stress",
    minSize: minSize,
  },
  {
    accessorKey: "Nozzle MDMT Curve",
    header: "Nozzle MDMT Curve",
    minSize: minSize,
  },
  {
    accessorKey: "Flange MDMT Curve",
    header: "Flange MDMT Curve",
    minSize: minSize,
  },
  {
    accessorKey: "Pad MDMT Curve",
    header: "Pad MDMT Curve",
    minSize: minSize,
  },
];

//DGV3
const ResultColumnsList = [
  "Nozzle Reqd. Thk",
  `Area Reqd (mm²)`,
  "Area Available (mm²)",
  "Nozzle Weld(A) Reqd.mm",
  "Nozzle Weld(B) Reqd.mm",
  "Nozzle Weld(C) Reqd.mm",
  "Flange MAWP (N/mm²)",
  "Nozzle MDMT (Deg C)",
  "Flange MDMT (Deg C)",
  "Pad MDMT (DegC)",
];
const ResultAccessoryKeyList = [
  "Nozzle Thk Reqd",
  "Area Reqd",
  "Area Available",
  "Nozzle Weld (A) Reqd",
  "Nozzle Weld (B) Reqd",
  "Nozzle Weld (C) Reqd",
  "Flange MAWP",
  "Nozzle MDMT",
  "Flange MDMT",
  "Pad MDMT"
];

export const ResultColumns = ResultColumnsList.map((elem, index) => {
  return {
    // id: index,
    accessorKey: ResultAccessoryKeyList[index],
    header: elem,
    size: "30",
    enableSorting: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  };
});

//DGV17
const EstimationList = [
  "Flange Weight (kg)",
  "Pipe Weight (kg)",
  "Pad Weight (kg)",
  "Nozzle Weld Hours",
  "Pipe Weld Hours",
  "Pad Weld Hours",
  "Bolt-up Hours",
  "NDT Cost",
];
const EstimationAccessorList = [
  "Flange Weight",
  "Pipe Weight",
  "Pad Weight",
  "Nozzle Weld Hours",
  "Pipe Weld Hours",
  "Pad Weld Hours",
  "Bolt-up Hours",
  "NDT Cost"
]

const EstimationHeadersCellsList = [
  <div>
    <div>Flange</div>
    <div>Weight</div>
    <div>(kg)</div>
  </div>,
  <div>
    <div>Pipe</div>
    <div>Weight</div>
    <div>(kg)</div>
  </div>,
  <div>
    <div>Pad</div>
    <div>Weight</div>
    <div>(kg)</div>
  </div>,
  <div>
    <div>Nozzle</div>
    <div>Weld</div>
    <div>(Hours)</div>
  </div>,
  <div>
    <div>Pipe</div>
    <div>Weld</div>
    <div>(Hours)</div>
  </div>,
  <div>
    <div>Pad</div>
    <div>Weld</div>
    <div>(Hours)</div>
  </div>,
  <div>
    <div>Bolt-up</div>
    <div>(Hours)</div>
  </div>,
  <div>
    <div>NDT</div>
    <div>Cost</div>
  </div>,
];

export const EstimationColumns = EstimationList.map((elem, i) => {
  if (elem === "NDT Cost")
    return {
      accessorKey: EstimationAccessorList[i],
      Header: EstimationHeadersCellsList[EstimationHeadersCellsList.length - 1],
      size: "25px",
      muiTableHeadCellProps: {
        align: "center",
      },
    };

  return {
    accessorKey: EstimationAccessorList[i],
    Header: EstimationHeadersCellsList[i],
    size: "22px",
    enableEditing: false,
    muiTableHeadCellProps: {
      align: "center",
    },
  };
});
