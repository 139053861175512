import React, { useState } from "react";
import CLientVendorsPageTable from "./Table";
import { useForm } from "../../../hooks/formHook";
import { Dialog, DialogContent, Paper, Typography } from "@mui/material";
import { getCompanyVendors } from "./api";
import { cols } from "./utils";
import VendorMrqForm from "../../Vendor/Mrqs/VendorMrqForm";

const companyId = 1;

export default function CLientVendorsPage() {
  const { onChange, onSubmit, values, setValues } = useForm(
    updateVendorsCallback,
    []
  );

  React.useEffect(() => {
    const func = async () => {
      const vendors = await getCompanyVendors();
      setValues(vendors);
    };
    func();
  }, []);

  async function updateVendorsCallback() {
    console.log("first");
  }

  return (
    <Paper sx={{ m: 2, pb: 4 }}>
      <Typography variant="h6" sx={{ m: 2 }}>
        Company Vendors :
      </Typography>
      <div style={{ textAlign: "center" }}>
        <CLientVendorsPageTable
          rows={values}
          onChange={onChange}
          cols={cols}
          setValues={setValues}
        />
      </div>
    </Paper>
  );
}
