import React from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BrandingWatermarkIcon from "@mui/icons-material/BrandingWatermark";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Icon from "../../../../components/Icons";

export default function DrawerListItem({
  component,
  currentDrawerTab,
  setCurrentDrawerTab,
}) {
  const [openTypes, setOpenTypes] = React.useState(false);

  return (
    <>
      <ListItem
        sx={{
          background:
            component.name === currentDrawerTab.name &&
            "linear-gradient(135deg, hsla(250, 86%, 97%, 1) 0%, hsla(217, 100%, 93%, 1) 50%)",
        }}
        onClick={() => {
          setCurrentDrawerTab({ name: component.name });
          setOpenTypes(!openTypes);
        }}
        disablePadding
      >
        <ListItemButton>
          <ListItemIcon>
            {component.icon ? (
              <Icon iconName={component.icon} />
            ) : (
              <BrandingWatermarkIcon />
            )}
          </ListItemIcon>
          <ListItemText primary={component.name} />
          {component.subTabs ? openTypes ? <ExpandLess /> : <ExpandMore /> : ""}
        </ListItemButton>
      </ListItem>
      {component.subTabs && (
        <Collapse in={openTypes} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {component.subTabs.map((type) => (
              <ListItemButton
                sx={{
                  pl: 4,
                  borderRight:
                    component.name === currentDrawerTab.name &&
                    type === currentDrawerTab.type &&
                    "2px solid #00b",
                }}
                onClick={() => {
                  setCurrentDrawerTab({ name: component.name, type });
                }}
                key={type}
              >
                {/* <ListItemIcon
          sx={{
              minWidth: 0,
              
            justifyContent: "center",
          }}
        >
          {icon}
      </ListItemIcon> */}
                <ListItemText primary={type} sx={{ paddingLeft: 2 }} />
              </ListItemButton>
            ))}
          </List>
          <Divider />
        </Collapse>
      )}
    </>
  );
}
