import { secondAxios } from "../../../../../../api/axios";

export const getComments = async (taskId) => {
  const response = await secondAxios.get('/task/comments/' + taskId);
  return response.data;
};

export const createComment = async (comment) => {
  console.log({ comment })
  await secondAxios.post('/task/comments', comment);
};

export const updateComment = async (comment) => {
  await secondAxios.put(`/comments/${comment.id}`, comment);
};

export const deleteComment = async (commentId) => {
  await secondAxios.delete(`/comments/${commentId}`);
};
