import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { useForm } from "../../../../../../hooks/formHook";
import axios from "../../../../../../api/axios";

const updateEquip = "/equipment/update";

export default function UpdateEquipNameForm({
  equip,
  setEditedEquip,
  project,
}) {
  const { values, onChange, onSubmit } = useForm(callbackFunc, equip);

  async function callbackFunc() {
    try {
      const { data } = await axios.put(updateEquip, {
        id: equip.id,
        name: values.name,
        project: project.number
      });

      equip.name = values.name;
      setEditedEquip({});
    } catch (error) {
      console.log({ error });
    }
  }
  return (
    <div>
      <Grid container>
        <Grid item xs={9}>
          <TextField
            required
            id="name"
            name="name"
            label="Equip/sub. Assembly Name"
            value={values.name}
            fullWidth
            size="small"
            autoComplete="off"
            variant="outlined"
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant="contained"
            sx={{ color: "#fff", ml: 1 }}
            onClick={onSubmit}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}
