import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Stack, Tabs, Typography } from "@mui/material";
import { RACIColumns } from "./utils";
import { useEffect, useState } from "react";
import { createRaci, deleteRaci, getProjectRACI, updateRaci } from "./api";
import { useParams } from "react-router-dom";
import RowEditableTable from "../../../../../../components/Tables/RowEditableTable";
import { useFieldArray, useForm } from "react-hook-form";
import UserListModal from "./UsersListDialog/UsersModal";
import UserDialog from "./UsersDialog";
import ForgeViewerModal from "./ViewerModal";
import { AuthContext } from "../../../../../../context/authContext";
import RaciDashboard from "./Dashboard";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { secondAxios } from "../../../../../../api/axios";
import { createTimesheet, deleteTimesheet, getTimesheets, updateTimesheet } from "./timesheetApi";
import TimesheetDialog from "../../../../../../components/Dialogs/TimesheetDialog";
import CommentsDialog from "../../../../../../components/Dialogs/CommentsDialog";
import { createComment, getComments } from "./commentsApi";

export default function RACI(props) {
  // const [tab, setTab] = React.useState(0);
  const [refresh, setRefresh] = React.useState(false);
  const { control, setValue, getValues } = useForm();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "RACITable",
  });
  const { equipId } = useParams();
  // const equipId = "22fd09b0-b9e2-40d6-94fb-12cec2ef66d5";
  const { auth, logout } = React.useContext(AuthContext);
  const currentUser = auth;
  // const currentUser = 'currentUser';
  const [allUsers, setAllUsers] = useState([]);
  const [dialogeType, setDialogType] = useState("");
  const [sharepointDocuments, setSharepointDocuments] = useState([]);
  const [viewerUrn, setViewerUrn] = useState("");
  const [racis, setRacis] = useState([]);

  const [dialogState, setDialogState] = React.useState({
    mail: false,
    userDialog: false,
    timesheet: false,
    preview: false,
    comments: false
  });
  const [selectedItem, setSelectedItem] = React.useState("");
  const [urnLoaded, setUrnLoaded] = useState(false);

  useEffect(() => {
    const getAllUsers = async () => {
      const users = (await secondAxios.get("/user")).data;
      setAllUsers(users);
    };
    const getDocuments = async () => {
      if (equipId) {
        const racisFromApi = await getProjectRACI(equipId);
        console.log("racis", racisFromApi);
        setRacis(racisFromApi);
      }
      const documentsData = (await secondAxios.get("/sharepoint")).data;
      console.log(documentsData);

      const documents = documentsData.map((d) => ({
        name: d.name.split("-")[1],
        urn: d.urn,
      }));
      console.log("documentsss ", documents);

      setSharepointDocuments(documents);
      setUrnLoaded(true);
    };
    getAllUsers();
    getDocuments();
  }, []);

  const handleButtonClick = (type, row) => {
    setSelectedItem(row.original);
    console.log("row", type);

    if (type === "mail") {
      setDialogState((prev) => ({ ...prev, mail: true }));
    } else if (type === "acc") {
      setDialogType("acc");
      setDialogState((prev) => ({ ...prev, userDialog: true }));
    } else if (type === "cons") {
      setDialogType("cons");

      setDialogState((prev) => ({ ...prev, userDialog: true }));
    } else if (type === "inf") {
      setDialogType("inf");

      setDialogState((prev) => ({ ...prev, userDialog: true }));
    } else if (type === "timesheet") {
      setDialogState((prev) => ({ ...prev, timesheet: true }));
    } else if (type === "comments") {
      setDialogState((prev) => ({ ...prev, comments: true }));
    } else if (type === "preview") {
      console.log("row", row.original);
      console.log("doc", sharepointDocuments);
      let prevUrn;
      // if (row.original.document === "2D") {
      //   prevUrn = sharepointDocuments.find((d) => d.name === "2d")?.urn;
      // } else {
      //   prevUrn = sharepointDocuments.find((d) => d.name === "3d")?.urn;
      // }
      prevUrn = sharepointDocuments.find((d) => d.name === "2d")?.urn;

      setViewerUrn(prevUrn);
      setDialogState((prev) => ({ ...prev, preview: true }));
    }
  };

  console.log({ viewerUrn })

  const handleDialogClose = () => {
    setDialogState({
      mail: false,
      userDialog: false,
      timesheet: false,
      preview: false,
      comments: false
    });
    console.log(dialogState);
  };


  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    // console.log("v", values);
    if (row.original.isNew) {
      const newValues = {
        document: values.document,
        docLink: values.docLink,
        revNum: values.revNum,
        responsible: values.responsible,
      };
      // const newValues = { ...row._valuesCache, type: row.original.type }
      // create new row call
      await createRaci(equipId, newValues);
      exitEditingMode();
      setRefresh((prev) => !prev);
    } else {
      const newValues = { ...row._valuesCache, dbId: row.original.dbId };
      // update the values
      await updateRaci(row.id, newValues);
      exitEditingMode();
      setRefresh((prev) => !prev);
    }
  };

  const handleCreateRow = (type) => {
    console.log("iam here");
    const newRACIS = [
      ...racis,
      {
        document: "",
        revNum: "",
        docLink: "",
        responsible: "",
        accountables: "",
        consulted: "",
        informed: "",
        mail: "",
        prev: "",
        timesheet: "",
        equipmentId: equipId,
        isNew: true,
      },
    ];
    setRacis(newRACIS);
  };

  const additionalProps = {
    positionGlobalFilter: "left",
  };

  return (
    <Box
      sx={{ width: "100%", typography: "body1", overflow: "auto", padding: 2 }}
    >
      {/* <typography>{props.type}</typography> */}

      <Stack
        direction={"row"}
        paddingBottom={1}
        spacing={0.5}
        sx={{ alignItems: "center" }}
      >
        <DashboardOutlinedIcon color={"secondary"} />
        <Typography variant="h5" color={"darkBlue"}>
          Overview
        </Typography>
      </Stack>

      <RaciDashboard documents={racis} />

      <Stack
        direction={"row"}
        paddingBottom={1}
        spacing={0.5}
        sx={{ alignItems: "center" }}
      >
        <DashboardOutlinedIcon color={"secondary"} />
        <Typography variant="h5" color={"darkBlue"}>
          Documents
        </Typography>
      </Stack>
      <RowEditableTable
        columns={RACIColumns({
          allUsers,
          handleButtonClick: handleButtonClick,
          currentUser,
          sharepointDocuments,
          urnLoaded,
        })}
        data={racis}
        handleCreateRow={() => handleCreateRow()}
        handleSaveRow={handleSaveRow}
        additionalProps={additionalProps}
      />

      <UserDialog
        open={dialogState.userDialog}
        handleClose={handleDialogClose}
        type={dialogeType}
        allUsers={allUsers}
        rowId={selectedItem?.id}
      />


      <CommentsDialog
        open={dialogState.comments}
        handleClose={handleDialogClose}
        id={selectedItem?.id}
        api={{
          getComments: getComments,
          createComment: createComment
        }}
      />
      <TimesheetDialog
        open={dialogState.timesheet}
        handleClose={handleDialogClose}
        parentId={selectedItem?.id}
        parentName={selectedItem?.document}
        api={{
          getItems: getTimesheets,
          createItem: createTimesheet,
          updateItem: updateTimesheet,
          deleteItem: deleteTimesheet,
        }}
      />

      <ForgeViewerModal
        open={dialogState.preview}
        onClose={handleDialogClose}
        urn={viewerUrn}
      />
    </Box>
  );
}
