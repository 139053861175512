const mainCols = [

  {
    accessorKey: "time",
    header: "Time (in Days)",
    // minWidth: 120,
    // align: "left"
  },
  {
    accessorKey: "cost",
    header: "Cost / Unit (in Dollar$)",
    // minWidth: 120,
    // align: "left",
  },
];
export const taggedCols = [
  {
    accessorKey: "tagNumber",
    header: "TagNumber",
  },
  {
    accessorKey: "materialCode",
    header: "Material Code",
  },
  ...mainCols,
];

export const bulkedCols = [
  {
    accessorKey: "materialCode",
    header: "Material Code",
  },
  { accessorKey: "description", header: "Material Description" },
  {
    accessorKey: "quantity",
    header: "Quantity",
    // minWidth: 100,
    enableEditing: false,
  },
  ...mainCols,
];
