export const inputData = [
    {
        label:"Internal Pressure",
        variable: "Pi",
        txtFieldName:"txt_DP_EHC",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        pairField: "txtDPressure"
    },
    {
        label:"Liquid Head Pressure",
        variable: "",
        txtFieldName:"txt_LH_EHC",
        required:true,
        inputProps:"N/mm^2",
        type: "number"
    },
    {
        label:"Design Temperature",
        variable: "T",
        txtFieldName:"txt_DT_EHC",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        pairField: "txtDTemp"
    },
    {
        label:"Material Specification",
        variable: "",
        txtFieldName:"txt_MS_EHC",
        required:true,
        inputProps:"",
        type: "string",
        // pairField: "txtDTemp"
    },
    {
        label:"Allowable Stress",
        variable: "S",
        txtFieldName:"txt_AS_EHC",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        // pairField: "txtDTemp"
    },
    {
        label:"Joint Efficiency",
        variable: "E",
        txtFieldName:"txt_JE_EHC",
        required:true,
        inputProps:"ul",
        type: "number",
        pairField: "TextBox31"
    },
    {
        label:"Inside/Outside Diameter",
        variable:"D",
        txtFieldName:"txt_ID_EHC",
        required:true,
        inputProps:"mm",
        type: "number",
        pairField: "txtVIDia"
    },
    {
        label:"Provided Thickness",
        variable:"T",
        txtFieldName:"txt_PT_EHC",
        required:true,
        inputProps:"mm",
        type: "number",
        pairField: "true"
    },
    {
        label:"Corrosion Allowance",
        variable:"CA",
        txtFieldName:"txt_CA_EHC",
        required:true,
        inputProps:"mm",
        type: "number"
    },
    {
        label:"External Pressure",
        variable:"Pe",
        txtFieldName:"TextBox13",
        required:true,
        inputProps:"N/mm^2",
        type: "number",
        pairField:"TextBox92"
    },
    
]

export const ExternalPressureResults=[
    {
        label:"Geometry Factor",
        variable:"A",
        txtFieldName:"txt_AF_EHC",
        required:false,
        type: "number",
    },
    {
        label:"Diameter/Thickness Ratio",
        variable:"Do/t",
        txtFieldName:"txt_D_T_EHC",
        disabled:true,
        // required:false,
        type: "number",
    },
    {
        label:"EMAWP = B / ( K0 * ( D / T )) ",
        variable:"EMAWP",
        txtFieldName:"txt_EMAWP_EHC",
        disabled:true,
        // required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
]

export const Summary=[
    {
        label:"Req Thk + Corrosion Allowance for Int. Pressure",
        variable:"tr",
        txtFieldName:"TextBox118",
        required:false,
        type: "number",
        inputProps:"mm",
    },
    {
        label:"Maximum Allowable Working Pressure",
        variable:"MAWP",
        txtFieldName:"TextBox119",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
    {
        label:"Maximum Allowable Pressure",
        variable:"MAPNC",
        txtFieldName:"TextBox120",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
    {
        label:"Hydrotest pressure UG-99 (b)",
        variable:"1.3 * MAWP * Sa/S",
        txtFieldName:"TextBox121",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
    {
        label:"Hydrotest pressure UG-99 (c)",
        variable:"1.3 * MAPNC - Head",
        txtFieldName:"TextBox122",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
    {
        label:"Percent Elongation",
        variable:"(50*t/Rf * (1-Rf/Ro)",
        txtFieldName:"TextBox123",
        required:false,
        type: "number",
        inputProps:"%",
    },
    {
        label:"MDMT w/o impact test per UCS-66",
        txtFieldName:"TextBox124",
        required:false,
        type: "number",
        inputProps:"F",
    },
    {
        label:"Diameter/Thickness Ratio",
        variable:"Do/t",
        txtFieldName:"text_Do_2",
        disabled:true,
        type: "number",
        pairField:"TextBox74"
    },
    {
        label:"Maximum Allowable External Pressure",
        variable:"EMAWP",
        txtFieldName:"text_MAP",
        required:false,
        type: "number",
        inputProps:"N/mm^2",
    },
]
