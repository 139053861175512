export const vendorsData = [
  {
    id: "1",
    name: "vendor1",
    msgs: [
      {
        id: 1,
        from: "me",
        body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis quis nesciunt harum autem adipisci vel consectetur, doloribus et. Porro iste id impedit corporis repellat expedita labore unde aut illo tempore.",
        seen: true,
        createdAt: "2024-2-27 : 4:45pm",
      },
      {
        id: 2,
        from: "vendor",
        body: "xpedita labore unde aut illo tempore.",
        createdAt: "2024-2-28 : 1:16pm",
      },
      {
        id: 3,
        from: "me",
        body: " Porro iste id impedit corporis repellat expedita labore unde aut illo tempore.",
        seen: false,
        createdAt: "2024-2-27 : 2:50am",
      },
      {
        id: 4,
        from: "me",
        body: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis quis nesciunt harum autem adipisci vel consectetur, ",
        seen: false,
        createdAt: "2024-2-27 : 2:50am",
      },

      {
        id: 5,
        from: "me",
        body: "Lorem ipsum  ",
        seen: false,
        createdAt: "2024-2-27 : 2:50am",
      },
    ],
  },
  {
    id: "2",
    name: "vendor2",
    msgs: [
      {
        id: 1,
        from: "me",
        body: "Lorem nde aut illo tempore.",
        seen: true,
        createdAt: "2024-2-27 : 4:45pm",
      },
      {
        id: 2,
        from: "vendor",
        body: "xpedita labore unde aut illo tempore.",
        createdAt: "2024-2-28 : 1:16pm",
      },
    ],
  },
];
