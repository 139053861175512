export const mainPipingTabs = () => {
  let res = [];

  Object.entries(pipingCategories).map((entry) => res.push(entry[0]));

  return res;
};

export const pipingCategories = {
  Pipe: [
    {
      name: "Pipe SMLS",
      abbr: "PS",
    },
    {
      name: "Pipe Threaded",
      abbr: "PT",
    },
    {
      name: "Bulk Pipe",
      abbr: "BP",
    },
    {
      name: "Nipple",
      abbr: "NP",
    },
  ],
  Flanges: [
    {
      name: "Flange WN 150#",
      abbr: "W1",
    },
    {
      name: "Flange WN 300#",
      abbr: "W2",
    },
    {
      name: "Flange WN 400#",
      abbr: "W3",
    },
    {
      name: "Flange WN 600#",
      abbr: "W4",
    },
    {
      name: "Flange WN 900#",
      abbr: "W5",
    },
    {
      name: "Flange WN 1500#",
      abbr: "W6",
    },
    {
      name: "Flange WN 2500#",
      abbr: "W7",
    },
    {
      name: "Flange SW 150#",
      abbr: "S1",
    },
    {
      name: "Flange SW 300#",
      abbr: "S2",
    },
    {
      name: "Flange SW 400#",
      abbr: "S3",
    },
    {
      name: "Flange SW 600#",
      abbr: "S4",
    },
    {
      name: "Flange SW 900#",
      abbr: "S5",
    },
    {
      name: "Flange SW 1500#",
      abbr: "S6",
    },
    {
      name: "Flange SW 2500#",
      abbr: "S7",
    },
    {
      name: "Flange Threaded",
      abbr: "F3",
    },
    {
      name: "Flange Blind",
      abbr: "F4",
    },
    {
      name: "Flange SO 150#",
      abbr: "F2",
    },
    {
      name: "Flange SO 600#",
      abbr: "SC",
    },
    {
      name: "Flange SO 300#",
      abbr: "SD",
    },
    {
      name: "Flange Lapped 150#",
      abbr: "FL",
    },
  ],
  Fittings: [
    { name: "Elbow 45", abbr: "E4" },
    { name: "Elbow 90", abbr: "E9" },
    { name: "Tee Equal", abbr: "TE" },
    { name: "Tee Reducer", abbr: "TR" },
    { name: "Reducer Concentric", abbr: "RC" },
    { name: "Reducer Eccentric", abbr: "RE" },
    { name: "Cap", abbr: "CA" },
    { name: "Coupling", abbr: "CF" },
    { name: "Coupling Half", abbr: "CH" },
    { name: "Mass Flow Meter", abbr: "MA" },
    { name: "Sockolet", abbr: "SO" },
    { name: "Pressure Relief Valve", abbr: "PV" },
    { name: "Union", abbr: "UI" },
    { name: "Plug", abbr: "PL" },
    { name: "Couplet", abbr: "CL" },
    { name: "Olet", abbr: "OL" },
    { name: "Threadolet", abbr: "TH" },
    { name: "Weldolet", abbr: "WO" },
  ],
  Valve: [
    { name: "Gate Valve", abbr: "GV" },
    { name: "Globe Valve", abbr: "OV" },
    { name: "Check Valve", abbr: "CV" },
    { name: "Y Strainer", abbr: "YA" },
    { name: "Control Valve", abbr: "AV" },
  ],
  Gasket: [
    { name: "Gasket", abbr: "GA" },
    { name: "Spectacle Blind", abbr: "SB" },
  ],
  "Stud Bolt": [{ name: "Stud bolt", abbr: "SB" }],
  "SP Items": [
    { name: "Y-Type Strainer", abbr: "YS" },
    { name: "Vacuum Breaker ", abbr: "VB" },
    { name: "Steam Trap ", abbr: "ST" },
  ],
};
