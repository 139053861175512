import React from "react";
import { EstimationColumns } from "./columnNames/NozzleColumns";
import { compactTableProps, greyTable } from "../../../../../../styles/muiProps";
import { useFieldArray, useFormContext } from "react-hook-form";
import {MaterialReactTable} from "material-react-table";

export const EstimationTable = (props) => {
  const { isLoading, isError } = props;
  const { control } = useFormContext();
  const { fields: estimationFields } = useFieldArray({
    control,
    name: "DataGridView2",
  });

  return (
    <MaterialReactTable
      columns={EstimationColumns}
      data={estimationFields ?? []}
      {...compactTableProps}
      {...greyTable}
      enableEditing={true}
      editDisplayMode="cell"
      enableSorting={false}
      enableColumnActions={false}
      defaultColumn={{ minSize: 20, size: 60 }}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Estimation failed. Please check Input Data",
            }
          : undefined
      }
      state={{
        isLoading,
        showAlertBanner: isError,
      }}
      muiTableHeadCellProps={({ cell }) => ({
        sx: {
          borderRight: "1px solid rgba(224,224,224,1)",
          backgroundColor: "hsla(250, 86%, 97%, 1)",
          pt: 2,
          height: "95px",
          "& .Mui-TableHeadCell-Content-Wrapper": {
            whiteSpace: "normal",
            justifyContent: "center",
          },
        },
      })}
      muiTableBodyCellProps={({ cell }) => ({
        sx: {
          textAlign: "center",
          borderRight: "1px solid rgba(224,224,224,1)",
        },
      })}
    />
  );
};
