import React from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const VerticalTabPanel = (props) => {
    const { children,padding,backgroundColor, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Box sx={{ p: 1, backgroundColor: "red" }}>
                 <Box sx={{padding:padding?? 1, backgroundColor: backgroundColor??"", minHeight: "80vh", width:"100%"}}> 
                    <Box>{children}</Box>
                </Box>
            )}
        </div>
    );
}

VerticalTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default VerticalTabPanel