import { React, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import sideImg from '../../../images/auth_form_eng.jpg'
import axios from "../../../api/axios"
import {GuestConfirmMailRoute} from "../../../routes"
const Register = () => {
  const [input, setInput] = useState({
    email: "",
  })

  const [error, setError] = useState("")
  const [message, setMessage] = useState("")

  const navigate = useNavigate()

  //Handle Changes in form
  function changeHandler(event) {
    const { name, value } = event.target
    setInput(prevData => ({
      ...prevData,
      [name]: value
    }))
    setMessage("")
  }

  //Handle submit of form
  const submitHandler = async (event) => {
    event.preventDefault()
    // //console.log(event.target.form)
    if (!input.email)
      setError("Please Enter your Email!")
    //do below code if u get a way to check if the email is valid...
    // else if (input.email === "include email verification here") {
    //   setError("Not a Valid Email Address")
    // }
    else {
      setError("")
      try {
        const res = await axios.post(GuestConfirmMailRoute, input)
        // setMessage("Please complete your registration by setting up your password through the link sent to your Email ")
        setMessage(res.data)
      } catch (err) {
        setMessage("")
        setError(err.response.data.message)
      }
    }

  }

  return (
    <div className="auth-container ">
      <img src={sideImg}></img>
      <div className="auth register">
        <h1>Register</h1>
        <form>
          <p className="msg">{message}</p>
          <p>{error}</p>
          <input required type="email"
            name="email"
            value={input.email}
            placeholder="Email"
            onChange={changeHandler} />
          
          <button onClick={submitHandler}>Register</button>
          <span>Already have an Account? <Link to="/guest/login">Login!</Link></span>
        </form>
      </div>
    </div>
  )
}

export default Register