import { useState, useEffect } from "react";

export const useForm = (
  callback,
  initialState = {},
  ValueIfNotEqualToInitial
) => {
  const [values, setValues] = useState(initialState);

  useEffect(() => {
    ValueIfNotEqualToInitial && setValues(ValueIfNotEqualToInitial);
  }, [ValueIfNotEqualToInitial]);

  const onChange = (e) => {
    e.preventDefault();
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setValues(initialState);
    callback();
  };

  return {
    onChange,
    onSubmit,
    values,
    setValues,
  };
};
