import * as React from "react";
import { useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import ProjectPageSideNav from "./ProjectPageSideNav";
import ProjectPageMainArea from "./ProjectPageMainArea";
import { StyleContext } from "../../../../context/styleContext";
import { getProjectById } from "./project.api";
import { ProjectSpecsOpts } from "./ProjectPageSideNav/NavContent/ContentArray";

const drawerWidth = "240px";

export default function ProjectPage() {
  const { sideNavOpen } = React.useContext(StyleContext);
  let { projectId, tab } = useParams();
  const [project, setProject] = React.useState({});
  const [label, setLabel] = React.useState(ProjectSpecsOpts[0].label);

  React.useEffect(() => {
    const getData = async () => {
      const { data } = await getProjectById(projectId);
      console.log({ data })
      setProject(data);
    };
    getData();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", transition: "all .2s" }}>
        <CssBaseline />
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        {/* <Box
          component="nav"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
          }}
          aria-label="mailbox folders"
        >
          <ProjectPageSideNav
            project={project}
            label={label}
            setLabel={setLabel}
          />
        </Box> */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            width: { sm: `calc(100% - ${drawerWidth})` },
            height: { sm: `calc(100% - 50px)` },

            marginLeft: sideNavOpen ? 0 : "0",
          }}
        >
          {project?.id ? (
            <ProjectPageMainArea project={project} tab={tab} />
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
}
