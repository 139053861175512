import React from "react";
import { GeometryDataColumns } from "./columnNames/NozzleColumns";
import { compactTableProps } from "../../../../../../styles/muiProps";
import {MaterialReactTable} from "material-react-table";

const GeometryDataTable = (props) => {
  const { geometryDataFields, handleSaveCell } = props;
  return (
    <MaterialReactTable
      columns={GeometryDataColumns}
      data={geometryDataFields ?? []}
      enableColumnActions={false}
      editDisplayMode="table"
      enableEditing
      enableSorting={false}
      {...compactTableProps}
      muiEditTextFieldProps={({ cell }) => ({
        //onBlur is more efficient, but could use onChange instead
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value, "DataGridView10");
        },
        sx: {
          "& .MuiInputBase-input": {
            padding: "4.5px 4px",
            textAlign: "center",
          },
          borderRight: "1px solid rgba(224,224,224,1)",
        },
      })}
      muiTableHeadCellProps={({ cell }) => ({
        sx: {
          borderRight: "1px solid rgba(224,224,224,1)",
          backgroundColor: "hsla(250, 86%, 97%, 1)",
          // fontSize: "16px",
          // display: "flex",
          // alignItems: "center",
          // px: 0,
          pt: 2,
          height: "95px",
          "& .Mui-TableHeadCell-Content-Wrapper": {
            whiteSpace: "normal",
            justifyContent: "center",
          },
        },
      })}
    />
  );
};

export default GeometryDataTable;
