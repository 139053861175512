// ViewerComponent.js
import React, { useEffect, useState } from "react";
import { initViewer, loadModel } from "./viewer.js";
import axios from "../../../../../../api/axios.js";
import { getHashdata } from "../../../../../../components/Tabs/Utils/hashFunctions.jsx";
import { Box, Button, Typography } from "@mui/material";
import "../../../../../../styles/autodesk.css";
// C:\Users\aysha\OneDrive - Enerfac Projects Inc\Frontend\client\src\styles
const AutodeskViewer = ({ prepUrn }) => {
  const [viewer, setViewer] = useState(null);
  const [selectedUrn, setSelectedUrn] = useState(null);

  useEffect(() => {
    let initializedViewer;
    const initializeViewer = async () => {
      initializedViewer = await initViewer(
        document.getElementById("autodesk-preview")
      );
      setViewer(initializedViewer);
      //   if (!prepUrn) {
      //     const urn = getHashdata(window.location.hash, "urn") || "";
      //     // const urn = window.location.hash?.substring(1);
      //     setupModelSelection(initializedViewer, urn);
      //     setupModelUpload(initializedViewer);
      //   }
    };

    initializeViewer().then(() => {
      prepUrn && loadModel(initializedViewer, prepUrn);
    });
  }, []);

  // async function setupModelSelection(viewer, selectedUrn) {
  //   const dropdown = document.getElementById("autodesk-models");
  //   dropdown.innerHTML = "";
  //   try {
  //     const resp = await axios.get("/autodeskviewer/api/models");
  //     if (!resp.status === 200) {
  //       throw new Error(await resp.text());
  //     }
  //     console.log("models", resp);
  //     const models = await resp.data;
  //     dropdown.innerHTML = models
  //       .map(
  //         (model) =>
  //           `<option value=${model.urn} ${
  //             model.urn === selectedUrn ? "selected" : ""
  //           }>${model.name}</option>`
  //       )
  //       .join("\n");
  //     dropdown.onchange = () => onModelSelected(viewer, dropdown.value);
  //     if (dropdown.value) {
  //       onModelSelected(viewer, dropdown.value);
  //     }
  //   } catch (err) {
  //     // alert('Could not list models. See the console for more details.');
  //     console.error(err);
  //   }
  // }

  // async function setupModelUpload(viewer) {
  //   const upload = document.getElementById("autodesk-upload");
  //   const input = document.getElementById("autodesk-input");
  //   const models = document.getElementById("autodesk-models");
  //   upload.onclick = () => input.click();
  //   input.onchange = async () => {
  //     const file = input.files[0];
  //     let data = new FormData();
  //     data.append("model-file", file);
  //     if (file.name.endsWith(".zip")) {
  //       // When uploading a zip file, ask for the main design file in the archive
  //       const entrypoint = window.prompt(
  //         "Please enter the filename of the main design inside the archive."
  //       );
  //       data.append("model-zip-entrypoint", entrypoint);
  //     }
  //     upload.setAttribute("disabled", "true");
  //     models.setAttribute("disabled", "true");
  //     showNotification(
  //       `Uploading model <em>${file.name}</em>. Do not reload the page.`
  //     );
  //     try {
  //       const resp = await axios.post("autodeskviewer/api/models", data, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       });
  //       // const resp = await fetch('/api/models', { method: 'POST', body: data });
  //       if (!resp.status === 200) {
  //         throw new Error(await resp.text());
  //       }
  //       const model = await resp.data;
  //       setupModelSelection(viewer, model.urn);
  //     } catch (err) {
  //       // alert(`Could not upload model ${file.name}. See the console for more details.`);
  //       console.error(err);
  //     } finally {
  //       clearNotification();
  //       upload.removeAttribute("disabled");
  //       models.removeAttribute("disabled");
  //       input.value = "";
  //     }
  //   };
  // }

  // async function onModelSelected(viewer, urn) {
  //   if (window.onModelSelectedTimeout) {
  //     clearTimeout(window.onModelSelectedTimeout);
  //     delete window.onModelSelectedTimeout;
  //   }
  //   window.location.hash += `&urn=${urn}`;
  //   try {
  //     // const resp = await fetch(`/api/models/${urn}/status`);
  //     const resp = await axios.get(`/autodeskviewer/api/models/${urn}/status`);
  //     if (!resp.status === 200) {
  //       throw new Error(await resp.text());
  //     }
  //     const status = await resp.status;
  //     switch (status.status) {
  //       case "n/a":
  //         showNotification(`Model has not been translated.`);
  //         break;
  //       case "inprogress":
  //         showNotification(`Model is being translated (${status.progress})...`);
  //         window.onModelSelectedTimeout = setTimeout(
  //           onModelSelected,
  //           5000,
  //           viewer,
  //           urn
  //         );
  //         break;
  //       case "failed":
  //         showNotification(
  //           `Translation failed. <ul>${status.messages
  //             .map((msg) => `<li>${JSON.stringify(msg)}</li>`)
  //             .join("")}</ul>`
  //         );
  //         break;
  //       default:
  //         clearNotification();
  //         loadModel(viewer, urn);
  //         break;
  //     }
  //   } catch (err) {
  //     // alert('Could not load model. See the console for more details.');
  //     console.error(err);
  //   }
  // }

  // function showNotification(message) {
  //   const overlay = document.getElementById("autodesk-overlay");
  //   overlay.innerHTML = `<div class="autodesk-notification">${message}</div>`;
  //   overlay.style.display = "flex";
  // }

  // function clearNotification() {
  //   const overlay = document.getElementById("autodesk-overlay");
  //   overlay.innerHTML = "";
  //   overlay.style.display = "none";
  // }

  return (
    <div width="100%">
      <div id="autodesk-div">
        <Box
          id="autodesk-header"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <img
            id="autodesk-logo"
            height={"25px"}
            src="https://cdn.autodesk.io/logo/black/stacked.png"
            alt="Autodesk Platform Services"
          />
          {!prepUrn && <Typography variant="h2">Loading...</Typography>}
        </Box>
        <div id="autodesk-preview"></div>
        <div id="autodesk-overlay"></div>
      </div>
      {/* Add other React components or UI elements here */}
    </div>
  );
};

export default AutodeskViewer;
