import React, { useEffect } from 'react'
import CustomTextField from "../../../../../components/widgets/CustomTextField"
import { circumferentialStress, inputData, LongitudinalBending, TangentialShear } from "./inputFields/saddleInputFormat"

import '@fontsource/roboto/300.css';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment';
import { useFormContext } from "react-hook-form";

const GridStyle = {
    "&.MuiGrid-item": { p: "3px 0px 0px 5px" }
}

const subHeadingStyle = {
    color: "#4e5fa2",
    fontWeight: "bold",
    mb: "5px"
}

const typographySize = {
    fontSize: "0.8rem"
}

const SaddleAnalysisTab = (props) => {
    const { calculationResult } = props
    const { getValues, setValue } = useFormContext();

    useEffect(() => {
        // get the first head and shell components
        let headComponent = getValues("DataGridView5")
            .find(value => (value?.Component?.includes("Head")))
        let shellComponent = getValues("DataGridView5")
            .find(value => (value?.Component?.includes("Shell")))
        console.log({ headLength: headComponent })

        // set the data to txtfields
        setValue("txt_LH_SC", headComponent?.["Can Length"])
        setValue("txt_TS_SC", shellComponent?.["Provided Thickness"])
        setValue("txt_TH_SC", headComponent?.["Provided Thickness"])
    }, [])

    console.log("calculationResults - Nozzle:", calculationResult)
    return (
        <div width='100%' style={{
            "&. MuiTypography-root": {
                color: "red",
            },
            fontSize: "0.775rem"
        }}>
            {/* <br /> */}
            <Typography sx={{ ...subHeadingStyle }} >Input Data</Typography>
            {/* <br /> */}

            {/* Input data */}
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 11, xl: 12 }}>
                {
                    inputData.map(input => {
                        return (
                            <Grid key={input.label} container item>
                                <Grid item sx={{ ...GridStyle }} xs={4} lg={3}>
                                    <Typography {...typographySize}>{input.label}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={true}>
                                    <Typography textAlign="right" sx={{ fontWeight: '100', fontSize: "0.75rem" }}>{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={2}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        required={true}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            // style: { width: "25ch", fontSize: "0.875rem", height: "24px" }
                                        }}
                                    />
                                    {/* <Typography>{input.label}</Typography> */}
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={4}></Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>

            {/* LongitudinalBending */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Longitudinal Bending</Typography>
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 12, xl: 12 }}>
                {
                    LongitudinalBending.map(input => {
                        return (
                            <Grid container item>
                                <Grid item sx={{ ...GridStyle }} xs={12} lg={12}>
                                    <Typography >{input.label}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={1} lg={1}>
                                    <Typography textAlign="right">{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={true}>
                                    {/* <Grid item sx={{ ...GridStyle }}> */}
                                    <span class="Fraction" style={{ display: "table-cell", textAlign: "center", whiteSpace: "nowrap" }}>
                                        <span class="Numerator">{input?.numerator}</span>
                                        <span class="Denominator" style={{ borderTop: "1px solid #000", display: "block" }}>
                                            {input?.denominator}
                                        </span>
                                    </span>
                                    {/* <Typography style={{ whiteSpace: 'pre-wrap', lineHeight: "0.7rem", textAlign: "center" }}>{input.formula}</Typography> */}
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={0.5} lg={0.5}>
                                    <Typography textAlign="right">=</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={2}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        // required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,

                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={4}></Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>

            {/* TangentialShear */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Tangential Shear</Typography>
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 12, xl: 12 }}>
                {
                    TangentialShear.map(input => {
                        return (
                            <Grid container item>
                                <Grid item sx={{ ...GridStyle, marginBottom: "1rem", marginTop: "0.2rem", fontWeight: "bold", textDecoration: "underline" }} xs={12} lg={12}>
                                    <Typography >{input.label}</Typography>
                                </Grid>
                                <br></br>
                                <Grid item sx={{ ...GridStyle }} xs={1} lg={1}>
                                    <Typography textAlign="right">{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={true}>
                                    {/* <Grid item sx={{ ...GridStyle }}> */}
                                    <span class="Fraction" style={{ display: "table-cell", textAlign: "center" }}>
                                        <span class="Numerator">{input?.numerator}</span>
                                        <span class="Denominator" style={{ borderTop: "1px solid #000", display: "block" }}>
                                            {input?.denominator}
                                        </span>
                                    </span>
                                    {/* <Typography style={{ whiteSpace: 'pre-wrap', lineHeight: "0.8rem", textAlign: "center" }}>{input.formula}</Typography> */}
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={1} lg={1}>
                                    <Typography textAlign="right">=</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={2}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        // required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={4}></Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>

            {/* Circumferential Stess */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Circumferential Stess</Typography>
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 12, xl: 12 }}>
                {
                    circumferentialStress.map(input => {
                        return (
                            <Grid container item>
                                <Grid item sx={{ ...GridStyle, marginBottom: "1rem", marginTop: "0.2rem", fontWeight: "bold", textDecoration: "underline" }} xs={12} lg={12}>
                                    <Typography >{input.label}</Typography>
                                </Grid>
                                <br></br>
                                <Grid item sx={{ ...GridStyle }} xs={1} lg={1}>
                                    <Typography textAlign="right">{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={true}>
                                    {/* <Grid item sx={{ ...GridStyle }}> */}
                                    <span class="Fraction" style={{ display: "table-cell", textAlign: "center" }}>
                                        <span class="Numerator">{input?.numerator1}</span>
                                        <span class="Denominator" style={{ borderTop: "1px solid #000", display: "block" }}>
                                            {input?.denominator1}
                                        </span>
                                    </span>
                                    {/* <Typography style={{ whiteSpace: 'pre-wrap', lineHeight: "0.8rem", textAlign: "center" }}>{input.formula}</Typography> */}
                                </Grid>
                                <Grid item >
                                    _&emsp;
                                </Grid>
                                {/* <Grid item sx={{ ...GridStyle }} xs={0.5} lg={0.5}>
                                    <Typography textAlign="right">=</Typography>
                                </Grid> */}

                                <Grid item sx={{ ...GridStyle }} xs={true}>
                                    {/* <Grid item sx={{ ...GridStyle }}> */}
                                    <span class="Fraction" style={{ display: "table-cell", textAlign: "center", whiteSpace: "nowrap" }}>
                                        <span class="Numerator">{input?.numerator2}</span>
                                        <span class="Denominator" style={{ borderTop: "1px solid #000", display: "block", whiteSpace: "nowrap" }}>
                                            {input?.denominator2}
                                        </span>
                                    </span>
                                    {/* <Typography style={{ whiteSpace: 'pre-wrap', lineHeight: "0.8rem", textAlign: "center" }}>{input.formula2}</Typography> */}
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={0.5} lg={0.5}>
                                    <Typography textAlign="right">=</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={2}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        // required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            style: {
                                                fontSize: "0.875rem", height: "20px",
                                                backgroundColor: input.disabled ? "#e5e8f2" : "white", color: input.disabled ? "black" : "black"
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={4}></Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>

        </div >
    )
}

export default SaddleAnalysisTab