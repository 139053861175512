export const getAvailableActions = (selectedRows) => {
  let btns = [];
  const hasPo = () => {
    if (selectedRows?.rows?.every((row) => row.original.poNumber)) return true;
    else return false;
  };
  const hasMrv = () => {
    if (selectedRows?.rows?.every((row) => row.original.mrvNumber)) return true;
    else return false;
  };

  if (!hasPo()) {
    btns.push("PO");
  } else {
    if (!hasMrv()) {
      btns.push("PO", "MRV");
    } else {
      btns.push("PO", "MRV", "SRV");
    }
  }

  return btns;
};

export const mrqMaterialsColumns = [
  // {
  //   accessorKey: "id",
  //   header: "ID",
  //   enableEditing: false,
  //   grow: false, //don't allow this column to grow to fill in remaining space
  //   size: 50, //small column
  // },
  // {
  //   accessorKey: "md",
  //   header: "Material Description",
  //   enableEditing: false,
  //   // grow: false, //don't allow this column to grow to fill in remaining space
  //   size: 200, //small column
  // },
  {
    accessorKey: "materialCode",
    header: "Material Code",
    enableEditing: false,
    // grow: false, //don't allow this column to grow to fill in remaining space
    size: 120, //small column
  },
  {
    accessorKey: "description",
    header: "Description",
    enableEditing: false,
    // grow: false, //don't allow this column to grow to fill in remaining space
    size: 120, //small column
  },

  // {
  //     accessorKey: 'mrpNumber',
  //     header: 'MRP #',
  //     grow: false, //don't allow this column to grow to fill in remaining space
  //     size: 100, //small column
  // },
  {
    accessorKey: "poNumber",
    header: "PO #",
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 100, //small column
  },
  {
    accessorKey: "poDate",
    header: "PO Date",
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 100, //small column
  },
  {
    accessorKey: "vendor",
    header: "PO Vendor",
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 100, //small column
  },
  {
    accessorKey: "mrvNumber",
    header: "MRV #",
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 100, //small column
  },
  {
    accessorKey: "mrvDate",
    header: "MRV Date",
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 100, //small column
  },

  {
    accessorKey: "srvNumber",
    header: "SRV #",
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 100, //small column
  },
  {
    accessorKey: "srvDate",
    header: "SRV Date",
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 100, //small column
  },
];

export const bulkCols = [
  {
    accessorKey: "item",
    header: "Item",
    enableEditing: false,
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 50, //small column
  },
  {
    accessorKey: "quantity",
    header: "Quantity",
    enableEditing: false,
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 50, //small column
  },
  {
    accessorKey: "unit",
    header: "Unit",
    enableEditing: false,
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 50, //small column
  },
  ...mrqMaterialsColumns,
];

export const taggedCols = [
  {
    accessorKey: "tagNumbers",
    header: "Tag Number",
    enableEditing: false,
    grow: false, //don't allow this column to grow to fill in remaining space
    size: 50, //small column
    Cell: ({ cell }) => <div>{cell.getValue().join(", ")}</div>,
  },

  ...mrqMaterialsColumns,
];
