import { Box, Button, Grid, ListItemIcon, MenuItem } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";

export default function Table({
  columns,
  data,
  handleSaveCell,
  additionalMenuItems,
  handleClickAdditionalMenuItems,
  enableTopToolbar,
}) {
  return (
    <Box sx={{ "& td": { p: 0, borderRight: "1px solid #ddd" } }}>
      <MaterialReactTable
        columns={columns}
        data={data}
        editDisplayMode="table"
        enableEditing
        enableColumnActions={false}
        enableSorting={false}
        enableRowActions={true}
        positionActionsColumn="last"
        // enableTopToolbar={enableTopToolbar ?? true}
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              sx: {
                backgroundColor: "hsla(250, 86%, 97%, 1)",
              },
            },
            muiTableBodyCellProps: {
              sx: {
                textAlign: "center",
                "& td": {
                  borderRight: "1px solid #ddd",
                },
                "& button": {
                  m: 0,
                },
              },
            },
          },
        }}
        state={
          {
            //   isLoading
            // showProgressBars: isLoading ? true : false
          }
        }
        muiTableHeadCellProps={({ cell }) => ({
          sx: {
            borderRight: "1px solid rgba(224,224,224,1)",
            backgroundColor: "hsla(250, 86%, 97%, 1)",
            // fontSize: "16px",
            // display: "flex",
            // alignItems: "center",
            // px: 0,
            pt: 2,
          },
        })}
        muiEditTextFieldProps={({ cell }) => ({
          //onBlur is more efficient, but could use onChange instead

          onBlur: (event) => {
            handleSaveCell(cell, event.target.value);
          },
          // variant: 'outlined',
          sx: {
            "& .MuiInputBase-input": {
              padding: "8.5px 8px",
              textAlign:
                cell.column.id === columns[0].accessorKey ? "left" : "center",

              borderRight: "1px solid rgba(224,224,224,1)",
            },
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <Button
            color="secondary"
            size="small"
            // onClick={() => handleCreateNewRow()}
            variant="contained"
          >
            Add row
          </Button>
        )}
        renderRowActionMenuItems={({ row }) => {
          const menuItems = [
            <MenuItem key={0} sx={{ m: 0 }}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              Delete
            </MenuItem>,
          ];

          if (additionalMenuItems) {
            menuItems.push(
              additionalMenuItems(row, handleClickAdditionalMenuItems)
            );
          }
          return menuItems;
        }}
      />
    </Box>
  );
}
