import axios from "./axios.js";

const CreateMrvRoute = "/mrv/create";
const getAllMrvsRoute = "/mrv/all";
const EditMrvRoute = "/mrv/edit";
const DeleteMrvRoute = "/mrv/delete";

export const createMRV = async (mrv) => {
  return await axios.post(CreateMrvRoute, mrv);
};

export const getMrv = async (equipId) => {
  // console.log("sendign to ", `${getAllMrvsRoute}/${equipId}`)
  return await axios.get(`${getAllMrvsRoute}/${equipId}`);
};

export const getAllMrv = async () => {
  // console.log("sendign to ", `${getAllMrvsRoute}/${equipId}`)
  return await axios.get(`${getAllMrvsRoute}`);
};


// export const updateMrv = async (mrv) => {
//     return await axios.put(addMrvMaterial, mrv)
// }
