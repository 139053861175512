import MenuItem from "@mui/material/MenuItem";
import DropDownItems from "../../DropDownItems";

const ComponentsItems = ["Pipe", "Pipe Threaded", "Bulk Pipe", "Nipple"];
let DimStd = [];
let sizeItems = [];
let SchedItems = [];
let materialItems = []
let typesItems = []
export const handleComponentChange = ()=>{
   sizeItems = [
    "1/2",
    "3/4",
    "1",
    "1 1/4",
    "1 1/2",
    "2",
    "2 1/2",
    "3",
    "3 1/2",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "14",
    "16",
    "18",
    "20",
    "24",
  ];
 DimStd = ["ASME B36.10"];

}
export const handleSizeChange = ()=>{
   SchedItems = [
    "Sch. 5",
    "Sch. 10",
    "Sch. 30",
    "Sch. 40",
    "Sch. 80",
    "Sch. 160",
    "STD",
    "XS",
    "XXS",
  ];
}

export const handleDimensionalStanderdChange = ()=>{
   materialItems = ["SA 106 B",
"SA 53 B ERW",
"ASTM A333",
]
 typesItems = ["BE",
"PE",
]
}

export const columns = [
  //column definitions...

  {
    accessorKey: "Component",
    header: "Component",
    minSize: "150px",
    muiEditTextFieldProps: () => ({
      defaultValue:"",
      children: ComponentsItems.map((Component) => (
        <MenuItem key={Component} value={Component}>
          {Component}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Size",
    header: "Size",
    size: "76px",
    muiEditTextFieldProps: () => ({
      defaultValue:"",
      children: sizeItems.map((size) => (
        <MenuItem key={size} value={size}>
          {size}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "DimensionalStanderd",
    header: "Dimensional Standerd",
    minSize: "150px",
    muiEditTextFieldProps: () => ({
      defaultValue:"",
      children: DimStd.map((dim) => (
        <MenuItem key={dim} value={dim}>
          {dim}
        </MenuItem>
      )),
      select: true,
    }),
  },

  {
    accessorKey: "Schedule",
    header: "Schedule",
    size: "121px",
    muiEditTextFieldProps: () => ({
      defaultValue:"",
      children: SchedItems.map((sched) => (
        <MenuItem key={sched} value={sched}>
          {sched}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Types",
    header: "Types",
    size: "121px",
    muiEditTextFieldProps: () => ({
      defaultValue:"",
      children:typesItems.map((type) => (
        <MenuItem key={type} value={type}>
          {type}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Material",
    header: "Material",
    size: "121px",
    muiEditTextFieldProps: () => ({
      defaultValue:"",
      children: materialItems.map((Material) => (
        <MenuItem key={Material} value={Material}>
          {Material}
        </MenuItem>
      )),
      select: true,
    }),
  },
  {
    accessorKey: "Requirements",
    header: "Requirements",
    size: "60px",
    enableEditing:false
  },
  {
    accessorKey: "MaterialDescription",
    header: "Material Description",
    size: "45px",
    enableEditing:false
  },
  {
    accessorKey: "MaterialCode",
    header: "Material Code",
    size: "75px",
    enableEditing:false
  },
  {
    accessorKey: "Family Table",
    header: "Family Table",
    size: "65px",
    enableEditing:false

  },
];

export const resultColumns = [
  //column definitions...
  {
    accessorKey: "Allowable_Stress",
    header: "Allowable Stress FT (N/mm^2) ",
    size: "50px",
  },
  {
    accessorKey: "Required_Thickness",
    header: "Required Thickness (mm)",
    size: "50px",
  },
  {
    accessorKey: "UCS_Curve",
    header: "UCS Curve",
    size: "40px",
  },
  {
    accessorKey: "MDMT",
    header: "MDMT (DegC)",
    size: "50px",
  },
  {
    accessorKey: "MAWP",
    header: "MAWP (N/mm^2)",
    size: "50px",
  },
  {
    accessorKey: "Hydrotest_Pressure",
    header: "Hydrotest Pressure (UG-99(b))",
    size: "50px",
  },
];

export const estimationColumns = [
  //column definitions...
  {
    accessorKey: "Circ_Weld_Hours",
    header: "Circ Weld Hours",
    size: "50px",
  },
  {
    accessorKey: "Long_Weld_Hours",
    header: "Long Weld Hours",
    size: "40px",
  },
  {
    accessorKey: "Rolling_Hours",
    header: "Rolling Hours",
    size: "50px",
  },
  {
    accessorKey: "Dishing_Hours",
    header: "Dishing Hours",
    size: "50px",
  },
];
