import ConstructionIcon from "@mui/icons-material/Construction";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonIcon from "@mui/icons-material/Person";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import BrowserUpdatedOutlinedIcon from '@mui/icons-material/BrowserUpdatedOutlined';

import paths from "../../../api/paths.api";
import { ReactComponent as SpLogo } from "../../../images/svgs/sharepoint-icon.svg";
import { Box } from "@mui/material";
import SharePointIcon from "../../CustomIcons/SharePointIcon";
import WidgetsIcon from "@mui/icons-material/Widgets";
import { Inventory } from "@mui/icons-material";
import Icon from "../../Icons";

export const addNewProjectSideNavList = {
  header: "Add new Project.",
  array: [
    {
      label: "Add new Project",
      icon: <CreateNewFolderIcon />,
      path: paths.projectFormApi,
    },
  ],
};

export const SharePointSideNavList = {
  header: "Add new Project.",
  array: [
    {
      label: "SharePoint",
      icon: (
        <Box sx={{ height: 30, width: 30 }}>
          <SpLogo />
        </Box>
      ),
      path: "",
    },
  ],
};
export const myCompanyList = {
  header: "My Company :",
  array: [
    {
      label: "Company Projects",
      icon: <FolderCopyIcon />,
      path: paths.myProjects,
    },
    // {
    //   label: "Equipment",
    //   icon: <ConstructionIcon />,
    //   path: paths.projectEquipsApi,
    // },
    // {
    //   label: "Stock Mat. DB",
    //   icon: <Icon iconName="materialDBIcon" size={28} sx={{ opacity: 0.6 }} />,
    //   path: paths.materialsDB,
    // },
    {
      label: "Members",
      icon: <PeopleAltIcon />,
      path: paths.allstaff,
    },
    {
      label: "Tasks",
      icon: <AssignmentIcon />,
      path: paths.mytasks,
    },
    {
      label: "Software",
      icon: <BrowserUpdatedOutlinedIcon />,
      path: paths.softwares,
    },
  ],
};

export const vendorList = {
  header: "My Vendors :",
  array: [
    {
      label: "Vendors",
      icon: <PrecisionManufacturingIcon />,
      path: paths.clientVendors,
    },
    {
      label: "SCM",
      icon: <Inventory />,
      path: paths.scm,
    },
    // {
    //   label: "MRQs",
    //   icon: <AssignmentIcon />,
    //   path: paths.vendorMrqs,
    // },
  ],
};

export const bottomSideNavList = {
  header: "",
  array: [
    {
      label: "SharePoint",
      icon: <SharePointIcon size={30} />,
      path: "",
    },
    {
      label: "my Account",
      icon: <PersonIcon />,
      path: "",
    },
    {
      label: "App Settings",
      icon: <SettingsIcon />,
      path: "",
    },
  ],
};
