import { Box, Button, Card, CardContent, Icon, Stack, Typography } from '@mui/material'
import React from 'react'
import { cards } from './utils'

const RaciDashboard = ({ documents }) => {
    return (
        <Stack direction="row" spacing={2} paddingBottom={2} >
            {cards(documents).map((card, index) => (
                <Card key={index} sx={{ width: "288px" }}>
                    <CardContent>
                        <Typography fontSize={"12px"} fontWeight={600} component="div">
                            {card.title}
                        </Typography>
                        <Stack direction="row" paddingTop={1} sx={{ justifyContent: "space-between" }}>
                            <Typography variant="h5">
                                {card.valueLabel}
                            </Typography>
                            <Button sx={{
                                backgroundColor: card.color, cursor: 'default', cursorEvents: 'none', '&:hover': {
                                    backgroundColor: card.color, // Set the background color to be the same as the default
                                }
                            }}>
                                {card.icon}
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            ))
            }
        </Stack >
    )
}

export default RaciDashboard