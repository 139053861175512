import { Box } from "@mui/material";
import { LinearProgressWithLabel } from "../../../../../../components/widgets/LinearProgressWithLabel";
import { formatDate } from "../../../../../../utils/formatDate";

export const table4d5dColumns = [
    {
        accessorKey: "name",
        header: "Name",
        size: 200,
        enableEditing: false
    },
    {
        accessorKey: "plannedStart",
        header: "Planned Start",
        size: 100,
        muiEditTextFieldProps: {
            type: 'date',
        },
        Cell: ({ cell }) => (
            <>{formatDate(cell?.getValue())}</>
        )
    },
    {
        accessorKey: "plannedEnd",
        header: "Planned Finish",
        size: 100,
        muiEditTextFieldProps: {
            type: 'date',
        },
        Cell: ({ cell }) => (
            <>{formatDate(cell?.getValue())}</>
        )
    },
    {
        accessorKey: "actualStart",
        header: "Actual Start",
        size: 100,
        enableEditing: false,
        muiEditTextFieldProps: {
            type: 'date',
        },
        Cell: ({ cell }) => (
            <>{formatDate(cell?.getValue())}</>
        )
    },
    {
        accessorKey: "actualEnd",
        header: "Actual Finish",
        size: 100,
        enableEditing: false,
        muiEditTextFieldProps: {
            type: 'date',
        },
        Cell: ({ cell }) => (
            <>{formatDate(cell?.getValue())}</>
        )
    },
    {
        accessorKey: "progress",
        header: "Progress",
        muiEditTextFieldProps: {
            type: 'number',
        },
        size: 50,
        Cell: ({ cell }) => (
            <>
                {/* <LinearProgressWithLabel value={cell.getValue() * 100} /> */}
                {cell.getValue()}
            </>
        )
        // muiTableHeadCellProps: {
        //   align: "center",
        // },
    },
    // {
    //     accessorKey: "cost",
    //     header: "Cost $",
    //     size: 100,
    //     // muiTableHeadCellProps: {
    //     //   align: "center",
    //     // },
    // },
    // {
    //     accessorKey: "manhours",
    //     header: "Manhours",
    //     size: 100,
    //     // muiTableHeadCellProps: {
    //     //   align: "center",
    //     // },
    // },
];
