import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import TabPanel from "../../../../components/widgets/TabPanel";
import DesignTab from "./DesignTabs";
// import NozzleTab from "./NozzleTab";
// import ShellTab from "./ShellTab";

const dataPanelTabs = [
  "Design",
  "Shell",
  "Nozzles",
  "Attachments",
  "Notes",
  "Tables",
  "Material Code",
];

export default function DataPanel() {
  const [currentTab, setCurrentTab] = React.useState(0); //value of the current tab
  const [key, setKey] = React.useState(Math.random());
  const { projectId } = 11;

  const reRender = () => {
    setKey(Math.random());
  };
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const dataPanelComponentsArray = [
    // <DataTabs project={project} equip={equipment} projectId={project.id || ""} />
    // <ShellTab key={key} reRender={reRender} projectId={projectId} />,
    // <NozzleTab key={key} reRender={reRender} />,
    // <AttachmentTab key={key} reRender={reRender} />,
    // <TableTab />,
    // <McTab />,
  ];

  return (
    <Box>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        textcolor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
        xs={{ textAlign: "center", backgroundColor: "red" }}
      >
        {dataPanelTabs.map((tabName) => {
          return <Tab label={tabName} key={tabName} sx={{ py: 0 }} />;
        })}
      </Tabs>

      {dataPanelComponentsArray.map((component, index) => {
        return (
          <TabPanel value={currentTab} index={index} key={index}>
            {component}
          </TabPanel>
        );
      })}
    </Box>
  );
}
