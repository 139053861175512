import React from 'react'
import {MaterialReactTable} from 'material-react-table';
import { Box, Button } from '@mui/material';
import {  POTableColumns, expandMaterialTable } from '../MCSTables';
import { mcstableProps } from '../../../../styles/muiProps';

const DetailPanel = (row, name) => {

    let data = row?.original?.mrq_materials
    if (name === "MRV")
        data = row.original?.mcsPurchaseOrder?.mrq_materials
    return (
        <MaterialReactTable
            columns={expandMaterialTable ?? []}
            data={data ?? []}
            enableColumnActions={false}
            enableColumnFilters={false}
            enablePagination={false}
            enableSorting={false}
            enableBottomToolbar={false}
            enableTopToolbar={false}
            enableColumnDragging={false}
            muiTableBodyRowProps={{ hover: false }}
        />
    )
}

const PurchaseOrderTab = (props) => {
    const { name, data } = props
    console.log("data for table' ", data)
    return (
        <MaterialReactTable
            columns={POTableColumns ?? []}
            data={data ?? []}
            defaultColumn={{ size: 130 }}
            enableGrouping
            enableColumnPinning
            enableStickyHeader
            muiTableHeadCellProps={{
                sx: {
                    borderRight: "0.1px solid rgba(0,0,0,0.1)"
                }
            }}
            
            muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: {
                    cursor: 'pointer',
                },
            })}
            {...mcstableProps}
            renderDetailPanel={({ row }) => DetailPanel(row, name)}
            // renderTopToolbarCustomActions={({ table }) => {
            //     console.log("IsSelected", table.getGroupedSelectedRowModel())
            //     return (
            //         <div style={{ display: 'flex', gap: '0.5rem' }}>
            //             <Button
            //                 color="error"
            //                 disabled={table.getSelectedRowModel().rows.length === 0 ? true : false}
            //                 // onClick={() => handleClickOpen(table.getSelectedRowModel().rows)}
            //                 variant="contained"
            //             >
            //                 Add to
            //             </Button>
            //         </div>
            //     );
            // }}
        />
    )
}

export default PurchaseOrderTab