
export const projectDetails = [
    {
        label: "Client",
        name: "TextBox1"
    },
    {
        label: "Project No.",
        name: "TextBox2"
    },
    {
        label: "Vessel",
        name: "TextBox11"
    }, {
        label: "Tag No.",
        name: "TextBox48"
    }, {
        label: "Rev No.",
        name: "TextBox10"
    }
]

export const designDetails = [
    {
        label: "Design Code",
        name: "ComboBox24",
        select: [
            { value: "ASME VII DIV 1", label: "ASME VII DIV 1" },
            { value: "ASME VII DIV 2", label: "ASME VII DIV 2" },
        ],
        required: true
    },
    {
        label: "Edition/ Addenda",
        name: "TextBox91",
        disabled: true
    },
    {
        label: "U Stamp",
        name: "ComboBox2",
        select: [
            { value: "Yes", label: "Yes" },
            { value: "No", label: "No" },
        ]
    },
    {
        label: "CRN",
        name: "TextBox89"
    },

]

export const designInputs = [
    {
        label: "Int. Pressure",
        name: "txtDPressure",
        type: "number",
        pairField: "txt_DP_CSC",
        endAdornment: "N/mm\xB2",
        required: true

    },
    {
        label: "Ext. Pressure ",
        name: "TextBox92",
        type: "number",
        required: true,
        pairField: "TextBox6",
        endAdornment: "N/mm\xB2"
    },
    {
        label: "Design Temp.",
        name: "txtDTemp",
        type: "number",
        required: true,
        pairField: "txt_DT_CSC",
        endAdornment: "\u00B0C"
    },
    {
        label: "MDMT",
        name: "TextBox94",
        type: "number",
        required: true,
        endAdornment: "\u00B0C" // \u00B0 degree symbol 
    },
    {
        label: "Corr. Allow.",
        name: "txtVCA",
        pairField: "true",
        type: "number",
        required: true,
        endAdornment: "mm"
    },
    // {
    //     label: "CA Nozzle",
    //     name: "TextBox28",
    //     type: "number",
    //     required: true,
    //     endAdornment: "mm"
    // },
    {
        label: "Joint Eff. Long.",
        name: "TextBox31",
        type: "number",
        pairField: "txt_JE_CSC",
        required: true,
    },
    {
        label: "Joint Eff. Circ.",
        name: "TextBox32",
        type: "number",
        required: true,
    },
    {
        label: "Radiography",
        name: "ComboBox20",
        required: true,
        select: [
            { value: "", label: "Select Radiography" },
            { value: "RT1", label: "RT1" },
            { value: "RT2", label: "RT2" },
            { value: "RT3", label: "RT3" },
            { value: "RT4", label: "RT4" },
        ]
    },
    {
        label: "Wind Speed",
        name: "TextBox49",
        endAdornment: "Km/hr"
    },
    {
        label: "Wind Design Code",
        name: "TextBox50"
    },
    {
        label: "Seismic Zone",
        name: "TextBox_Seismic_Spec"
    },
    {
        label: "Seismic Spec",
        name: "TextBox_Seismic_Spec"
    },
    // {
    //     label: "Flange rating ",
    //     name: "TextBox52"
    // },
    // {
    //     label: "ASME B16.5",
    //     name: "TextBox64"
    // },
    {
        label: "Impact Test ",
        name: "ComboBox6",
        select: [
            { value: "YES", label: "YES" },
            { value: "NO", label: "NO" }
        ]
    }
]

export const GeometricInputs = [
    {
        label: "Orientation",
        name: "ComboBox5",
        required: true,
        select: [
            { value: "Horizontal", label: "Horizontal" },
            { value: "Vertical", label: "Vertical" },
        ],

        // pairField: "txt_DP_CSC",
        // endAdornment: "N/mm	\xB2"
    },
    {
        label: "Diameter Basis ",
        name: "ComboBox7",
        select: [
            { value: "ID", label: "ID" },
            { value: "OD", label: "OD" },
        ],
        required: true

    },
    {
        label: "Vessel Diameter",
        name: "txtVIDia",
        required: true,
        pairField: "true",
        type: "number",
        endAdornment: "mm"
    },
    {
        label: "Vessel Length T-T:",
        name: "txtVLenTT",
        required: true,
        endAdornment: "mm",
        pairField: "txt_LS_SC"

    },
    {
        label: "Vessel Length S-S:",
        name: "txt_SF",
        required: true,
        endAdornment: "mm",
        pairField: "true"
    },
    {
        label: "Head Type ",
        name: "ComboBox3",
        required: true,
        select: [
            { value: "2:1 S.E Head", label: "2:1 S.E Head" },
            { value: "Spherical", label: "Spherical" },
            { value: "Torispherical", label: "Torispherical" },
            { value: "Flat", label: "Flat" },
        ]
    },
    // {
    //     label: "Support Type ",
    //     name: "ComboBox1",
    //     required: true,
    //     select: [
    //         { value: "Saddle Support", label: "Saddle Support" },
    //         { value: "Leg Support", label: "Leg Support" },
    //         { value: "Lifting Lug", label: "Lifting Lug" },
    //     ]
    // },
    {
        label: "Hydrotest Design ",
        name: "ComboBox8",
        required: true,
        select: [
            { value: "UG-99b", label: "UG-99b" },

        ]
    },
    {
        label: "Hydrotest Pressure ",    //Calculated value
        name: "TextBox67",
        endAdornment: "N/mm\xB2"

    },
    {
        label: "Liquid Height ",
        name: "TextBox66",
        required: true,
        type: "number",
        endAdornment: "m"
    },
    {
        label: "Liquid Density ",
        name: "TextBox30",
        required: true,
        type: "number",
        endAdornment: "Kg/m\xB3"
    },
    {
        label: "Insulation Thk ",
        name: "TextBox80",
        required: true,
        type: "number",
        endAdornment: "mm"
    },
    {
        label: "Insulation Density ",
        name: "TextBox29",
        required: true,
        type: "number",
        endAdornment: "Kg/m\xB3"
    },
    {
        label: "PWHT ",
        name: "ComboBox18",
        select: [
            { value: "YES", label: "YES" },
            { value: "NO", label: "NO" }
        ]
    },

]

export const materialData = [
    {
        label: "Shell ",
        materialFieldName: "ComboBox9",
        pairField: "true",
        select: [
            { value: "SA 516 70", label: "SA 516 70" },
            { value: "SA 516 60", label: "SA 516 60" },
            { value: "SA 36", label: "SA 36" },
            { value: "SA 240 304", label: "SA 240 304" },
            { value: "SA 240 304L", label: "SA 240 304L" },
            { value: "SA 240 316", label: "SA 240 316" },
            { value: "SA 240 316L", label: "SA 240 316L" }
        ],
        stressFieldName: "TextBox68",
        curveFieldName: "TextBox88",
        requirementFieldName: "ComboBox_ShellReq",
        requirementItems: [
            { value: "NOT", label: "NOT" },
            { value: "NACE MR 0175", label: "NACE MR 0175" },
        ]
    },
    {
        label: "Head",
        materialFieldName: "ComboBox11",
        pairField: "true",
        select: [
            { value: "SA 516 70", label: "SA 516 70" },
            { value: "SA 516 60", label: "SA 516 60" },
            { value: "SA 36", label: "SA 36" },
            { value: "SA 240 304", label: "SA 240 304" },
            { value: "SA 240 304L", label: "SA 240 304L" },
            { value: "SA 240 316", label: "SA 240 316" },
            { value: "SA 240 316L", label: "SA 240 316L" }
        ],
        stressFieldName: "TextBox73",
        curveFieldName: "TextBox90",
        requirementFieldName: "ComboBox_HeadReq",
        requirementItems: [
            { value: "NOT", label: "NOT" },
            { value: "NACE MR 0175", label: "NACE MR 0175" },
        ]

    },
    {
        label: "Nozzle Pipe ",
        materialFieldName: "ComboBox12",
        pairField: "true",
        select: [
            { value: "SA 516 70", label: "SA 516 70" },
            { value: "SA 516 60", label: "SA 516 60" },
            { value: "SA 36", label: "SA 36" },
            { value: "SA 240 304", label: "SA 240 304" },
            { value: "SA 240 304L", label: "SA 240 304L" },
            { value: "SA 240 316", label: "SA 240 316" },
            { value: "SA 240 316L", label: "SA 240 316L" },
            { value: "SA 106 B", label: "SA 106 B" },
            { value: "SA 333 6", label: "SA 333 6" },
            { value: "SA 312 TP304", label: "SA 312 TP304" },
            { value: "SA 312 TP316", label: "SA 312 TP316" },
            { value: "SA 312 TP316L", label: "SA 312 TP316L" },

        ],
        stressFieldName: "TextBox78",
        curveFieldName: "TextBox95",
        requirementFieldName: "ComboBox_NozzleReq",
        requirementItems: [
            { value: "NOT", label: "NOT" },
            { value: "NACE MR 0175", label: "NACE MR 0175" },
        ]

    },
    {
        label: "Flange/Forging",
        materialFieldName: "ComboBox13",
        pairField: "true",
        select: [
            { value: "SA 105", label: "SA 105" },
            { value: "SA 350 LF2", label: "SA 350 LF2" },
            { value: "SA 182 F304", label: "SA 182 F304" },
            { value: "SA 182 F304L", label: "SA 182 F304L" },
            { value: "SA 182 F316", label: "SA 182  F316" },
            { value: "SA 182 F316L", label: "SA 182 F316L" },

        ],
        stressFieldName: "TextBox79",
        curveFieldName: "TextBox96",
        requirementFieldName: "ComboBox_FlangeReq",
        requirementItems: [
            { value: "NOT", label: "NOT" },
            { value: "NACE MR 0175", label: "NACE MR 0175" },
        ]

    },
    {
        label: "RF Pad",
        materialFieldName: "ComboBox14",
        pairField: "true",
        select: [
            { value: "SA 516 70", label: "SA 516 70" },
            { value: "SA 516 60", label: "SA 516 60" },
            { value: "SA 36", label: "SA 36" },
            { value: "SA 240 304", label: "SA 240 304" },
            { value: "SA 240 304L", label: "SA 240 304L" },
            { value: "SA 240 316", label: "SA 240 316" },
            { value: "SA 240 316L", label: "SA 240 316L" }
        ],
        stressFieldName: "TextBox81",
        curveFieldName: "TextBox102",
        requirementFieldName: "ComboBox_PadReq",
        requirementItems: [
            { value: "NOT", label: "NOT" },
            { value: "NACE MR 0175", label: "NACE MR 0175" },
        ]

    },
    {
        label: "Stud Bolt/Nut",
        materialFieldName: "ComboBox15",
        select: [
            { value: "SA 193-B7 / SA194-2H", label: "SA 193-B7 / SA194-2H" }

        ],
        stressFieldName: "TextBox82",
        curveFieldName: "TextBox103",
        requirementFieldName: "ComboBox_StudReq",
        requirementItems: [
            { value: "NOT", label: "NOT" },
            { value: "NACE MR 0175", label: "NACE MR 0175" },
        ]

    },
    {
        label: "Gasket",
        materialFieldName: "ComboBox16",
        select: [
            { value: "Spiral wound SS304 with Non Asbestos filler", label: "Spiral wound SS304 with Non Asbestos filler" }

        ],
        stressFieldName: "TextBox86",
        curveFieldName: "TextBox104",
        requirementFieldName: "ComboBox_GaskReq",
        requirementItems: [
            { value: "Galvanized", label: "Galvanized" },
            { value: "NOT", label: "NOT" },
            { value: "NACE MR 0175", label: "NACE MR 0175" },
            { value: "PTFE Coated or Galvanised", label: "PTFE Coated or Galvanised" },
        ]
    },

]

//All select values ARE SA 516 70, SA 516 60, SA 36, SA 240 304, SA 240 304L, SA 240 316, SA 240 316L

//Stress col;TextBox68, 73, 78, 79, 81, 82, 86

//Curve: TextBox88, 90, 95, 96, 102, 103, 104