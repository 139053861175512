import React, { useMemo } from 'react'
import { consolidatedBOMColumns } from './column';
import {MaterialReactTable} from 'material-react-table';
import { mcstableProps } from '../../../../../../styles/muiProps';
import { Button } from '@mui/material';

const summedBy = "description" //grouped on description or code or whatever it is...
const quantityAccessorKey = "qty"
// const cutLength = "cutLength"

//qty calculator with quantity and cut length  
const quantityCalc = (qty, cutLength) => {
    console.log(qty, cutLength)
    if (cutLength === "~") {
        return qty
    }
    else {
        cutLength = cutLength.replace(/(\d+)\s*(\d+\/)/g, "$1+$2").replace(/x/g, "*")
        let res = eval(cutLength) * parseInt(qty)
        // console.log("RES:", res)
        return res
    }
}

const ConsolidatedTable = (props) => {
    const { data, setMrq, setOpenMrqDialog, setSelectedMaterials, handleClickOpenDrawings } = props

    const formattedData = data?.materials?.map(material => (
        material
    ))
    const columns = useMemo(
        () => consolidatedBOMColumns,
        [],
    );
    console.log("form", data, formattedData)

    return (
        <MaterialReactTable
            columns={columns}
            // data={[]}/
            data={formattedData ?? []}
            enableColumnResizing
            defaultColumn={{ size: 100 }}
            enableGrouping
            enableColumnDragging={false}
            enableColumnPinning
            enableStickyHeader
            muiTableHeadCellProps={{
                sx: {
                    borderRight: "0.1px solid rgba(0,0,0,0.1)"
                }
            }}
            enableRowSelection
            muiTableBodyRowProps={({ row }) => ({
                onClick: row.getToggleSelectedHandler(),
                sx: {
                    cursor: 'pointer',
                },
            })}
            {...mcstableProps}
            initialState={{ columnPinning: { left: ['material_code'] }, grouping: ["description"] }}
            renderTopToolbarCustomActions={({ table }) => {

                const handleClickOpenMrqDialog = (selectedRows) => {
                    console.log("selectedRows", selectedRows);

                    //extracts original - jsut row elements out
                    let selectedMaterials = selectedRows.map(elem => (elem.original))
                    console.log("selectedMaterials", selectedMaterials)

                    // get the material ids and qty
                    let materials = selectedMaterials.map(material => ({ id: material.id, quantity: material.qty, drawingId: material.drawingId}))
                    //gets a list of chosen items with its dwg and corresp. ITEM no. 
                    // let drawing_items = selectedMaterials.map(elem => ({
                    //     drawing: elem.drawing,
                    //     item: elem.ITEM
                    // }))
                    // console.log("item_numbers", drawing_items)

                    setMrq((prevValues) => (
                        {
                            ...prevValues,
                            selectedItems: materials
                        }
                    ))

                    //gets a consolidated data for the table in dialog
                    let consolidatedData = []
                    selectedMaterials.forEach(bom => {
                        const index = consolidatedData?.findIndex((element) => {
                            return (element[summedBy] === bom[summedBy])
                        })
                        // console.log("index", index)
                        // let quantity = parseFloat(quantityCalc(bom[quantityAccessorKey], bom[cutLength]))
                        let quantity = parseFloat((bom[quantityAccessorKey]))
                        if (index === -1) {
                            consolidatedData.push(
                                {
                                    [summedBy]: bom[summedBy],
                                    "Total Quantity": quantity,
                                }
                            )
                        }
                        else {
                            consolidatedData[index]["Total Quantity"] = consolidatedData[index]["Total Quantity"] + quantity
                        }
                    })
                    setSelectedMaterials(consolidatedData)
                    setOpenMrqDialog(true);
                };

                return (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <Button
                            color="error"
                            disabled={table.getSelectedRowModel().rows.length === 0 ? true : false}
                            onClick={() => handleClickOpenMrqDialog(table.getSelectedRowModel().rows)}
                            variant="contained"
                        >
                            Add to MRQ
                        </Button>
                        <Button variant="contained"
                            onClick={handleClickOpenDrawings}
                        >
                            Consolidated Drawings
                        </Button>
                    </div>
                );
            }}
        />
    )
}

export default ConsolidatedTable