import { Box, Tab, Tabs, Typography } from "@mui/material";
import React from "react";
import { verticalTabStyle } from "../../../styles/muiProps";
import { Link } from "react-router-dom";

const equipMainTabs = [
  "File",
  "Data",
  "Calc.",
  "Est.",
  "MCS",
  "DCS",
  "PCS",
  "PMS",
  "Software",
];
export default function EquipPageSideNav({ tab, setTab, state }) {
  const { project, equipment } = state;

  const handleChange = (event, newTab) => {
    setTab(newTab);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
      sx: {
        "&:hover": {
          background:
            "linear-gradient(135deg, hsla(250, 86%, 97%, 1) 0%, hsla(217, 100%, 93%, 1) 100%)",
        },
      },
    };
  }

  return (
    <div style={{ display: "inline-block" }}>
      <Tabs
        orientation="vertical"
        value={tab}
        onChange={handleChange}
        {...verticalTabStyle}
      >
        <Box
          sx={{
            background:
              "linear-gradient(135deg, hsla(250, 86%, 97%, 1) 0%, hsla(217, 100%, 93%, 1) 100%)",
            borderTopLeftRadius: 10,
            padding: 1,
          }}
        >
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Project No.
          </Typography>
          <Link className="link" to={"/projects/" + project.id}>
            <Typography
              variant="h7"
              sx={{
                display: "block",
                cursor: "pointer",
                ml: 2,
                "&:hover": { textDecoration: "underline" },
              }}
            >
              {project.number}
            </Typography>
          </Link>
          {/* //////////////////////////// */}
          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
            Equip. Name:
          </Typography>
          <Typography
            variant="h7"
            sx={{
              display: "block",
              ml: 2,
            }}
          >
            {equipment.name}
          </Typography>
        </Box>
        {equipMainTabs.map((tabName, index) => {
          return <Tab key={tabName} label={tabName} {...a11yProps(index)} />;
        })}
      </Tabs>
    </div>
  );
}
