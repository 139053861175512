import axios, { secondAxios } from '../../../api/axios'

export const createSoftware = async (formData) => {
    console.log({ formData })
    const createSoftware = "software/create"
    const response = await axios.post('software/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
};


export const getSoftwares = async () => {
    const getSoftware = "software"
    const { data } = await secondAxios.get(getSoftware);
    // console.log({ data });
    return data;
}

export const downloadSoftware = async ({ id }) => {
    const downloadSoftware = "software/download"
    const { data } = await axios.get(`${downloadSoftware}/${id}`);
    console.log({ data });
    return data;
}