export const mainMechTabs = () => {
  let res = [];

  Object.entries(mechCategories).map((entry) => res.push(entry[0]));

  return res;
};

export const mechCategories = {
  Tanks: [],
  "Pressure Vessel": [
    {
      name: "Shell",
      abbr: "SH",
      subTabs: ["Cylindrical Shell", "Conical Shell"],
      icon: "shellIcon",
    },
    {
      name: "Head",
      abbr: "H",
      // subTabs: [
      //   "Elliptical Head",
      //   "Sphere Hemispherical Head",
      //   "Torispherical Head",
      //   "Flat Head",
      // ],
      icon: "headIcon",
    },
    {
      name: "Pipe",
      abbr: "P",
      icon: "pipeIcon",
    },
    {
      name: "Flange",
      abbr: "F",
      icon: "flangeIcon",
    },
    {
      name: "RF Pad",
      abbr: "F",
      icon: "plateIcon",
    },
    {
      name: "Plate",
      abbr: "P",
      icon: "plateIcon",
    },
    {
      name: "MisCellaneous",
      abbr: "M",
      icon: "miscellaneousIcon",
    },
  ],
};
