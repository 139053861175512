import { Box, Paper } from "@mui/material";
import React from "react";
import NavTabs from "../../../../../components/Tabs/NavTabs";
import {
  fullPageBoxStyle,
  verticalTabStyle,
} from "../../../../../styles/muiProps";
import AutodeskViewerTab from "./AutodeskViewerTab";
import Project4d from "./4d/Project4d";
import Project5d from "./5d";
import { useParams } from "react-router-dom";
// import TimelineComponent from './4d/TimelineComponent'

const PMSTab2 = () => {
  const { equipId } = useParams();
  const tabs = [
    // {
    //     label: "2D-3D",
    //     panelProps: { backgroundColor: "transparent", padding: 1 },
    //     panel: (
    //         <AutodeskViewerTab />
    //     )
    // },
    {
      label: "4D",
      // panelProps: { backgroundColor: "transparent", padding: 1 },
      panel: (
        // <TimelineTab equipId={equipId} />
        <Project4d />
      ),
    },
    {
      label: "5D",
      // panelProps: { backgroundColor: "transparent", padding: 1 },
      panel: <Project5d />,
    },
    {
      label: "S-Curve",
      // panelProps: { backgroundColor: "transparent", padding: 3 },
      panel: <></>,
    },
    {
      label: "Change Order",
      // panelProps: { backgroundColor: "transparent", padding: 3 },
      panel: <></>,
    },
  ];

  return (
    // <Box {...fullPageBoxStyle}>
    <Paper sx={{ width: "100%", display: "flex" }}>
      <NavTabs
        tabs={tabs}
        // tabProps={verticalTabStyle}
        navLabel="pms-tab"
        // vertical={true}
      />
    </Paper>
    //  </Box >
  );
};

export default PMSTab2;
