import axios, { secondAxios } from "./axios.js";

const CreateMrqRoute = "/mrq/create";
const getAllMrqsRoute = "/mrq/all";
const EditMrqRoute = "/mrq/edit";
const DeleteMrqRoute = "/mrq/delete";
const addMrqMaterial = "/mrq/addMaterial";

export const createMRQ = async (mrq) => {
  return await axios.post(CreateMrqRoute, mrq);
};

export const getMrq = async (equipId) => {
  return await secondAxios.get(`${getAllMrqsRoute}/${equipId}`);
};

export const getAllMrq = async () => {
  return await axios.get(`${getAllMrqsRoute}`);
};

export const updateMrq = async (mrq) => {
  const res = await axios.post(addMrqMaterial, mrq);
  console.log("res", res);
  return res;
};
