import React from "react";

import GeneralTab from "./Tabs/GeneralTab";
import AccordionGroup from "../../../../../../components/AccordionGroup";
import Table from "../../../../../../components/Tables/BasicEditableTable";
import { Notes, definitions, itpProcedures } from "./utils";

import { itpColumns } from "./cols";
import {
  finalPreparationMockData,
  hydrostaticTestMockData,
  manufacturingMockData,
  preFabMockData,
  pwhtMockData,
} from "./data.test";
import ITPRowsTab from "./Tabs/ITPRowsTab";
import { SelectRev } from "./Tabs/Components/SelectRev";
import { Box, Typography } from "@mui/material";

const itpKeys = [
  "GENERAL",
  "PRE-FABRICATION",
  "MANUFACTURING & NDE",
  "PWHT & NDE POST PWHT",
  "HYDROSTATIC TEST",
  "FINAL PREPARATION",
  "STANDARD PROCEDURES",
  "PROJECT SPECIFICATION",
  "NOTES",
  "INSPECTOR FUNCTION DEFINITION",
];
const revs = ["Rev-00", "Rev-01"];

export default function ITPTable() {
  const [itpData, setItpData] = React.useState({
    preFabData: preFabMockData,
    manufacturingData: manufacturingMockData,
    pwhtData: pwhtMockData,
    hydrostaticTestData: hydrostaticTestMockData,
    finalPreparationData: finalPreparationMockData,
  });

  const [rev, setRev] = React.useState(revs[0]);

  const handleRevChange = (event) => {
    setRev(event.target.value);
  };

  const itpPanels = [
    <GeneralTab generalData={{ rev }} />,
    <Table columns={itpColumns} data={itpData.preFabData} />,
    <Table columns={itpColumns} data={itpData.manufacturingData} />,
    <Table columns={itpColumns} data={itpData.pwhtData} />,
    <Table columns={itpColumns} data={itpData.hydrostaticTestData} />,
    <Table columns={itpColumns} data={itpData.finalPreparationData} />,
    <ITPRowsTab rows={itpProcedures} />,
    "PROJECT SPECIFICATION",
    <ITPRowsTab rows={Notes} />,
    <ITPRowsTab rows={definitions} />,
  ];
  return (
    <AccordionGroup
      keys={itpKeys}
      panels={itpPanels}
      //   defaultExpandedArray={[itpKeys[1]]}
      keysAsComponents={{
        GENERAL: (
          <Box sx={{ m: 0 }}>
            <Box sx={{ display: "inline-block" }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  pr: 2,
                  transform: "translateY(8px)",
                }}
              >
                {" "}
                GENERAL{" "}
              </Typography>
            </Box>

            <SelectRev
              rev={rev}
              handleRevChange={handleRevChange}
              revs={revs}
            />
          </Box>
        ),
      }}
    />
  );
}
