import { Controller, useFormContext } from "react-hook-form";

import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import InputLabel from '@mui/material/InputLabel';
import Grid from '@mui/material/Stack';
import { Typography } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import pairFields from "../../pages/client/AllEquipments/PressureVessel/PressureVesselData/pairFields";



const fontStyle = {
    style: { fontSize: "0.700rem", height: "18px" }
}

export const CustomSelect = (props) => {
    const { label, name, required, menuItems, onChange, pairField } = props
    // console.log(onChange)
    const { register, control, setValue, getValues, formState: { errors } } = useFormContext();

    const setPairFieldsValues = (value, name) => {
        console.log("PairId: ", name)
        pairFields.forEach((field) => {
            if (field.pairs.includes(name)) {
                field.pairs.forEach((pair) => {
                    //If the pair elements are among a datagrid then loop and setValues.
                    if (pair.includes("Material") && pair !== "Material") {
                        let datagrid = "DataGridView10"
                        let fields = getValues("DataGridView10")
                        if (!fields)
                            return
                        let column = field.name
                        fields.forEach((field, index) => {
                            console.log(`${datagrid}.${index}.${column}`)
                            setValue(`${datagrid}.${index}.${column}`, value)
                        })
                    }
                    if (pair.includes("Material") && pair === "Material") {
                        let datagrid = "DataGridView5"
                        let fields = getValues("DataGridView5")
                        if (!fields)
                            return
                        let column = field.name
                        fields.forEach((field, index) => {
                            console.log(`${datagrid}.${index}.${column}`)
                            setValue(`${datagrid}.${index}.${column}`, value)
                        })
                    }

                    else {
                        console.log("setting", pair, value)
                        const isRegistered = Object.keys(errors).some((error) => error === pair);
                        if (!isRegistered) {
                            register(pair);
                        }
                        setValue(pair, value)
                    }
                    console.log(getValues())
                })
                // return
            }
        })
    }


    return (
        <FormControl fullWidth error={Boolean(errors[name])} size="small">
            <InputLabel shrink /*required*/>{label}</InputLabel>
            <Controller
                name={name}
                control={control}
                defaultValue=""
                InputProps={{ ...fontStyle }}
                rules={{ required: "field required" }}
                render={({ field }) =>
                    <Select
                        {...field}
                        value={field.value}
                        // onChange={onChange ?? field.onChange}
                        // onChange={(e) => {
                        //     field.onChange(e.target.value);
                        //     console.log("Onchange is there not");
                        //     if (onChange) {
                        //         console.log("Onchange is there");
                        //         onChange(name)
                        //         // setPairFieldsValues(e.target.value, id);
                        //         // setValue(pairField, e.target.value)
                        //     }
                        // }}
                        onChange={(e) => {
                            field.onChange(e.target.value);
                            if (pairField) {
                                console.log("PariFIEdl found");
                                setPairFieldsValues(e.target.value, name);
                                // setValue(pairField, e.target.value)
                            }
                        }}

                        {...fontStyle}
                        label="Vessel Diameter"
                    >
                        {
                            menuItems.map(menuItem => {
                                return (

                                    <MenuItem value={menuItem.value}  {...fontStyle}>{menuItem.label}</MenuItem>
                                )
                            })
                        }
                        {/* <MenuItem value="">ID/OD</MenuItem>
                        <MenuItem value="ID">ID</MenuItem>
                        <MenuItem value="OD">OD</MenuItem> */}
                    </Select>}
            />
            <FormHelperText>{errors[name]?.message}</FormHelperText>
        </FormControl>
    )
}
