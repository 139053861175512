import axios from "./axios.js";

const addToWarehouseRoute = "/warehouse/addToWarehouse";
const removeFromWarehouseRoute = "/warehouse/removeFromWarehouse";
const getWarehouseMaterialsRoute = "/warehouse/all";
const getMaterialsMasterRoute = "/warehouse/materialsMaster";

export const getWarehouseMaterials = async () => {
    return await axios.get(getWarehouseMaterialsRoute)
}
export const getMaterialsMaster = async () => {
    return await axios.get(getMaterialsMasterRoute)
}

export const addToWarehouse = async (mrvWithMaterials) => {
    return await axios.post(addToWarehouseRoute, mrvWithMaterials)
}
export const removeFromWarehouse = async (srvWithMaterials) => {
    return await axios.post(removeFromWarehouseRoute, srvWithMaterials)
}