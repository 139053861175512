import React, { useMemo, useState } from "react";

import {
  useForm,
  useFieldArray,
  useFormContext,
  Controller,
  useWatch,
} from "react-hook-form";
import Grid from "@mui/material/Grid";
import CustomAlert from "../../../../../../components/widgets/CustomAlert";
import PipeInputTable from "./PipeInputTable";

//The Main Function
const PipingTab = (props) => {
  const { reRender, projectId } = props;
  const [resultVisibility, setresultVisibility] = useState(false);
  const [estimationVisibility, setEstimationVisibility] = useState(false);
  const { control, setValue, getValues } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showProgressBars, setShowProgressBars] = useState(false);

  // const { fields: estimationFields } = useFieldArray({
  //   control,
  //   name: "DataGridView7",
  // });
  const [openAlert, setOpenAlert] = useState(false);
  const [AlertContent, setAlertContent] = useState({
    severity: "",
    message: "",
  });

  // //calculation results
  // const showResults = async () => {
  //   const data = getValues();
  //   console.log(data);
  //   setEstimationVisibility(false);

  //   const result = checkRequiredFields(data);
  //   console.log(result);
  //   if (result[0] === false) {
  //     setAlertContent({
  //       message: "Following values are missing: " + result[1],
  //       severity: "error",
  //     });
  //     setOpenAlert(true);
  //   } else {
  //     setresultVisibility(true);
  //     setIsLoading(true);
  //     const res = await axios
  //       .post("/pressure_vessel/calculateShell", data)
  //       .then((response) => {
  //         return response;
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //     setIsLoading(false);
  //     setValue("DataGridView6", res.data.DataGridView6);
  //   }
  // };

  // const showEstimation = async () => {
  //   const data = getValues();
  //   setIsLoading(true);
  //   setShowProgressBars(true);
  //   setresultVisibility(false);
  //   setEstimationVisibility(true);
  //   const result = await axios
  //     .post("/pressure_vessel/estimate", data)
  //     .then((response) => {
  //       console.log(response.data);
  //       setValue("DataGridView7", response.data.DataGridView7);
  //       setValue("DataGridView5", response.data.DataGridView5);
  //       setIsLoading(false);
  //       setShowProgressBars(false);
  //       return response;
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  return (
    <div style={{ width: "100%", minWidth: "610px" }}>
      <CustomAlert
        open={openAlert}
        setOpen={setOpenAlert}
        severity={AlertContent.severity}
        message={AlertContent.message}
      />
      {/* <Stack spacing={2} direction="row">
        <Button
          color="secondary"
          onClick={() => showResults()}
          variant="contained"
        >
          Calculate
        </Button>
        <Button
          color="secondary"
          onClick={() => showEstimation()}
          variant="contained"
        >
          Estimate
        </Button>
      </Stack> */}
      <br />
      <br />
      <Grid container rowSpacing={1} spacing={1} columns={12}>
        {/* Input Grid */}
        <PipeInputTable reRender={reRender} isLoading={isLoading} />
        {/* {
          // Result Grid
          resultVisibility ? <ResultTable isLoading={isLoading} /> : <></>
        }
        {estimationVisibility ? (
          <EstimationTable isLoading={isLoading} projectId={projectId} />
        ) : (
          <></>
        )} */}
      </Grid>
    </div>
  );
};

export default PipingTab;
