import React from "react";
import {MaterialReactTable} from "material-react-table";
import MenuItem from "@mui/material/MenuItem";
import { Button, ListItemIcon } from "@mui/material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { columns } from "./columnsData/columns";
import { useFieldArray, useFormContext } from "react-hook-form";
import { pairFields2 } from "../pairFields";
import {
  compactTableProps,
  smallerHeadCellProps,
} from "../../../../../../styles/muiProps";

const InputTable = (props) => {
  const { reRender, isLoading } = props;
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "DataGridView5",
  });

  //save cell changes in table
  const handleSaveCell = (cell, value) => {
    console.log(cell.column.id);
    setValue(`DataGridView5.${cell.row.index}.${cell.column.id}`, value);
    // Changes in Diameter applied throughout Table. And in the other tabs
    if (
      cell.column.id === "Diameter" ||
      cell.column.id === "Provided Thickness"
    ) {
      fields.forEach((field, index) => {
        setValue(`DataGridView5.${index}.${cell.column.id}`, value);
      });
      if (cell.column.id === "Diameter") {
        pairFields2["Inside/Outside Diameter"].forEach((field, index) => {
          const isRegistered = Object.keys(errors).some(
            (error) => error === field
          );
          if (
            !isRegistered &&
            (field.includes("txt") || field.includes("Text"))
          ) {
            register(field);
          }
          if (field.includes("txt") || field.includes("Text"))
            setValue(field, value);
        });
      } else {
        setValue("txt_PT_CSC", value);
        setValue("txt_PT_EHC", value);
        setValue("txt_SPT_NC1", value);
      }
      reRender();
    } else if (cell.column.id === "Component") {
      let material = "";
      if (value.includes("Head")) material = getValues("ComboBox9");
      else material = getValues("ComboBox11");
      setValue(`DataGridView5.${cell.row.index}.Material`, material);
      // setValue(`DataGridView5.${cell.row.index}.${cell.column.id}`, value)
      reRender();
    }
    // else
    //     setValue(`DataGridView5.${cell.row.index}.${cell.column.id}`, value)
  };

  //delete row
  const handleDeleteRow = (index) => {
    console.log({ index });
    remove(index);
    reRender();
  };

  //create new row in table
  const handleCreateNewRow = (values) => {
    append({
      Component: "",
      Diameter: getValues("txtVIDia"),
      Material: "",
      "Can Length": 0,
      "Long Seam Angle": 0,
      "Provided Thickness": getValues("txt_PT_CSC"),
      "Weight (Kg)": 0,
    });
  };

  return (
    <MaterialReactTable
      columns={columns}
      data={fields}
      editDisplayMode="table"
      {...compactTableProps}
      {...smallerHeadCellProps}
      enableEditing
      enableColumnActions={false}
      enableRowActions
      positionActionsColumn="last"
      state={{
        isLoading,
        // showProgressBars: isLoading ? true : false
      }}
      muiTableHeadCellProps={({ cell }) => ({
        sx: {
          borderRight: "1px solid rgba(224,224,224,1)",
          backgroundColor: "hsla(250, 86%, 97%, 1)",
          fontSize: "16px",
          // display: "flex",
          // alignItems: "center",
          // px: 0,
          py: 1.4,
        },
      })}
      muiEditTextFieldProps={({ cell }) => ({
        //onBlur is more efficient, but could use onChange instead
        onBlur: (event) => {
          handleSaveCell(cell, event.target.value);
        },
        sx: {
          "& .MuiInputBase-input": {
            padding: "8.5px 8px",
            textAlign: "center",
          },
        },
      })}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 5,
          muiTableHeadCellProps: {
            align: "center",
          },
        },
      }}
      renderBottomToolbarCustomActions={() => (
        <Button
          color="secondary"
          size="small"
          onClick={() => handleCreateNewRow()}
          variant="contained"
        >
          Add row
        </Button>
      )}
      renderRowActionMenuItems={({ row }) => [
        <MenuItem
          key={0}
          sx={{ m: 0 }}
          onClick={() => handleDeleteRow(row.index)}
        >
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          Delete
        </MenuItem>,
      ]}
    />
  );
};

export default InputTable;
