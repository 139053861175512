import React, { useState, useEffect } from 'react'
import CustomTextField from "../../../../../components/widgets/CustomTextField"
import { inputData, outputData, reinforcementRequired, reinforcementAvailable } from "./inputFields/NozzleInputFormat"
import { Controller, useFormContext } from "react-hook-form";

import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import '@fontsource/roboto/300.css';
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';

const GridStyle = {
    "&.MuiGrid-item": { p: "3px 0px 0px 5px" }
}

const typographySize = {
    fontSize: "0.8rem"
}

const subHeadingStyle = {
    color: "#4e5fa2",
    fontWeight: "bold",
    mb: "5px"
}
const NozzleTab = (props) => {
    const { calculationResult } = props
    const { getValues, control, setValue, formState: { errors } } = useFormContext();
    const [nozzles, setNozzles] = useState("")

    const nozzlelist = getValues("DataGridView1")
    

    useEffect(() => {
        setNozzles(nozzlelist?.map(elem => (
            elem?.Mark
        )))
        let selected_nozzle = getValues("Cmb_Nz_Mark") || 0
        console.log(
            "Trying: ", getValues(`DataGridView1.${getValues("Cmb_Nz_Mark")}.Inside Proj`)
        )
        setValue("txt_FWSI_NC1", getValues(`DataGridView1.${getValues("Cmb_Nz_Mark")}.Inside Proj`))
        setValue("txt_FWSO_NC1", getValues(`DataGridView1.${getValues("Cmb_Nz_Mark")}.Nozzle Weld (A)`))
        setValue("txt_SM_NC1", getValues("ComboBox9"))
        setValue("txt_NM_NC1", getValues("ComboBox12"))
    }, [nozzlelist])

    console.log("nozzles", nozzles)
    const getLabel = () => {
        let Dia = getValues("ComboBox7")
        if (Dia === "ID")
            return "Inside Diameter"
        else if (Dia === "OD")
            return "Outside Diameter"
        else
            return "Diameter"
    }

    console.log("calculationResults - Nozzle:", calculationResult)
    return (
        <div width='100%' >
            {/* <Typography variant="h6" color="primary" marginLeft="10rem">Nozzle</Typography> */}
            {/* <br /> */}
            <Grid container>
                <Grid item xs={6} lg={6}>
                    <Stack direction={"row"} alignItems={"center"}>
                        <Typography sx={{ ...subHeadingStyle }} >Input Data</Typography>

                    </Stack>
                </Grid>
                <Grid item>
                    <FormControl
                        // fullWidth
                        error={Boolean(errors.Cmb_Nz_Mark)}
                        // helperText={errors.ComboBox24?.message}
                        size="small"
                    >
                        <InputLabel shrink required>Nozzle</InputLabel>
                        <Controller
                            name="Cmb_Nz_Mark"
                            control={control}
                            defaultValue=""
                            // InputProps={{ ...fontStyle }}
                            rules={{ required: "Please select a Nozzle" }}
                            render={({ field }) =>
                                <Select
                                    value={field.value}
                                    {...field}

                                    style={{ width: "10ch", height: "1.7rem" }}
                                    // {...fontStyle}
                                    label="Nozzle"
                                >
                                    {/* <MenuItem value="">Select Nozzle</MenuItem> */}
                                    {
                                        nozzles?.length > 0 ?
                                            nozzles?.map((nozzle, index) => (
                                                <MenuItem key={nozzle} value={index}>{nozzle}</MenuItem>
                                            ))
                                            :
                                            <MenuItem value="">Select Nozzle</MenuItem>
                                    }
                                </Select>}
                        />
                        <FormHelperText>{errors.Cmb_Nz_Mark?.message}</FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>

            {/* <br /> */}
            {/* <br /> */}

            {/* Input data */}
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 12, xl: 12 }}>
                {
                    inputData.map(input => {
                        return (
                            <Grid container item>
                                <Grid item sx={{ ...GridStyle }} xs={4} lg={4.5}>
                                    <Typography {...typographySize}>{input.label === "Inside/Outside Diameter" ? getLabel() : input.label}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={1} lg={1}>
                                    <Typography textAlign="right" sx={{ fontWeight: '100', fontSize: "0.75rem" }}>{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={3}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        // required={true}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            style: {
                                                width: "25ch", fontSize: "0.7rem", height: "18px",
                                                backgroundColor: input.disabled ? "#e5e8f2" : "white", color: input.disabled ? "black" : "black"
                                            }
                                        }}
                                    />
                                    {/* <Typography>{input.label}</Typography> */}
                                </Grid>
                                {/* <Grid item sx={{ ...GridStyle }} xs={6} lg={6}></Grid> */}
                            </Grid>
                        )
                    })
                }
            </Grid>

            {/* Wall Thickness */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Wall Thickness Required</Typography>
            <Grid container sx={{ alignItems: "center" }} columns={{ xs: 12, lg: 12, xl: 12 }}>
                {
                    outputData.map(input => {
                        return (
                            <Grid container item>
                                {/* <Grid item sx={{ ...GridStyle }} xs={5.2} lg={5.2}> */}
                                <Grid item sx={{ ...GridStyle }} xs={4} lg={4.5}>
                                    <Typography {...typographySize}  >{calculationResult ? calculationResult[input.labelId] : input.label}</Typography>
                                    <Typography {...typographySize}  >{calculationResult ? `\u2003\u2003\u2003\u2003\u2003${calculationResult[input.sublabelId]}` : '='}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={1} lg={1}>
                                    <Typography {...typographySize} textAlign="right">{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={3}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        // required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            style: {
                                                width: "25ch", fontSize: "0.7rem", height: "18px",
                                                // backgroundColor: input.disabled ? "#e5e8f2" : "white", color: input.disabled ? "black" : "black"
                                            }
                                        }}
                                    />
                                    {/* <Typography>{input.label}</Typography> */}
                                </Grid>
                                {/* <Grid item sx={{ ...GridStyle }} xs={3} lg={3}></Grid> */}

                            </Grid>
                        )
                    })
                }
            </Grid>


            {/* reinforcementRequired */}
            <br />
            {/* 10 &divide; 2 = 5 */}
            <Typography sx={{ ...subHeadingStyle }}>Area of Reinforcement Required</Typography>
            <Grid container sx={{ alignItems: "center" }} spacing={2} columns={{ xs: 12, lg: 12, xl: 12 }}>
                {
                    reinforcementRequired.map(input => {
                        return (
                            <Grid container item>
                                <Grid item sx={{ ...GridStyle }} xs={4} lg={4.5}>
                                    <Typography {...typographySize}  >{input.label}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={1} lg={1}>
                                    <Typography {...typographySize} textAlign="right">{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={3}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        // required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            style: {
                                                width: "25ch", fontSize: "0.7rem", height: "18px",
                                                backgroundColor: input.disabled ? "#e5e8f2" : "white", color: input.disabled ? "black" : "black"
                                            }
                                        }}
                                    />
                                    {/* <Typography>{input.label}</Typography> */}
                                </Grid>
                                {/* <Grid item sx={{ ...GridStyle }} xs={3} lg={3}></Grid> */}

                            </Grid>
                        )
                    })
                }
            </Grid>

            {/* Reinforcement available */}
            <br />
            <Typography sx={{ ...subHeadingStyle }}>Area of Reinforcement Available</Typography>
            <Grid container sx={{ alignItems: "center" }} spacing={2} columns={{ xs: 12, lg: 12, xl: 12 }}>
                {
                    reinforcementAvailable.map(input => {
                        return (
                            <Grid container item columns={{ xs: 12, lg: 12, xl: 12 }}>
                                <Grid item sx={{ ...GridStyle }} xs={11} lg={11}>
                                    <Typography {...typographySize}  >{input.label}</Typography>
                                    <Typography {...typographySize}  >{calculationResult ? `\u2003\u00A0\u00A0${calculationResult[input.label2]}` : '='}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={5.5} lg={5.5}>
                                    <Typography {...typographySize} textAlign="right" fontWeight={"380"}>{input.variable}</Typography>
                                </Grid>
                                <Grid item sx={{ ...GridStyle }} xs={3} lg={3}>
                                    <CustomTextField
                                        hiddenLabel={true}
                                        id={input.txtFieldName}
                                        name={input.txtFieldName}
                                        type={input.type}
                                        // required={input.required}
                                        disabled={input.disabled || false}
                                        pairField={input.pairField || null}
                                        inputProps={{
                                            endAdornment: <InputAdornment position="end">{input.inputProps}</InputAdornment>,
                                            style: {
                                                width: "25ch", fontSize: "0.7rem", height: "18px",
                                                backgroundColor: input.disabled ? "#e5e8f2" : "white", color: input.disabled ? "black" : "black"
                                            }
                                        }}
                                    />
                                    {/* <Typography>{input.label}</Typography> */}
                                </Grid>
                                {/* <Grid item sx={{ ...GridStyle }} xs={3} lg={3}></Grid> */}

                            </Grid>
                        )
                    })
                }
            </Grid>

        </div>
    )
}

export default NozzleTab