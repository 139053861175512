const widths = {
  id: 30,
  name: 170,
  email: 200,
  pos: 100,
  more: 120,
};
export const cols = [
  { id: "id", label: "", minWidth: widths.id, align: "center" },
  { id: "name", label: "Name", minWidth: widths.name },
  { id: "email", label: "Email", minWidth: widths.email, align: "left" },
  {
    id: "pos",
    label: "No. of Purchase Orders",
    minWidth: widths.pos,
    align: "center",
  },
  {
    id: "more",
    label: "",
    minWidth: widths.more,
    align: "right",
  },
];

export const formCols = [
  { id: "id", minWidth: widths.id, align: "center", value: "New" },
  { id: "name", minWidth: widths.name },
  { id: "email", minWidth: widths.email, align: "left" },
  { id: "actions", minWidth: widths.email + widths.pos, align: "center" },
];
