import { secondAxios } from "../../../../../../api/axios";

export const getDrawings = async (equipId) => {
    const response = await secondAxios.get(`/drawing/project/` + equipId);
    // console.log({ response });
    return response.data;
};

export const getDrawingData = async (drawingId) => {
    const response = await secondAxios.get(`/drawing/` + drawingId);
    // console.log({ response });
    return response.data;
};
