import { Box } from "@mui/material"

export const initialSummaryData = [
    {
        title: "Total Material Cost",
        estimation: 0,
        percentage: 0,
        total: 0
    },
    {
        title: "Total FAB Cost",
        estimation: 0,
        percentage: 0,
        total: 0
    },
]

export const columns = [
    {
        accessorKey: "title",
        header: "Summary",
        size: 150,
        Footer: () => (
            <Box
                // color="warning.main"
                sx={{ fontWeight: "bold" }}
            >
                Proposal Cost:
            </Box>
        ),
    },
    {
        accessorKey: "estimation",
        header: "Est.",
        size: 50,
    },
    {
        accessorKey: "percentage",
        header: "% Added",
        size: 50,
    },
    {
        accessorKey: "total",
        header: "Total",
        size: 50,
        Footer: ({ table }) => {
            const totalQty = table.getRowModel().rows.reduce((total, row) => {
                return total + parseFloat(row.original.total || 0);
            }, 0);

            return (
                <>
                    {totalQty}
                </>
            );
        }
    },
]