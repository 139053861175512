import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { verifyVendor } from "./api";
import { useLocation } from "react-router-dom";
import MrqFormTable from "./MrqFormTable";

export default function VendorMrqForm() {
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const verifyToken = async () => {
      await verifyVendor(location.pathname.split("/")[2])
        .then((response) => {
          setData(response);
          console.log({ data });
        })
        .catch((error) => {
          console.log(error);
        });
    };
    verifyToken();
  }, []);

  const cols = [
    { id: "id", label: "", minWidth: 20, align: "center" },
    { id: "description", label: "Material Description", minWidth: 200 },
    { id: "qty", label: "Quantity", minWidth: 100, align: "center" },
    { id: "time", label: "Time (in Days)", minWidth: 120, align: "left" },
    {
      id: "cost",
      label: "Cost / Unit (in Dollar$)",
      minWidth: 120,
      align: "left",
    },
  ];

  // const { onChange, onSubmit, values, setValues } = useForm(
  //   putVendorMrqCallback,
  //   data.materials
  // );

  // async function putVendorMrqCallback() {
  //   console.log("first");
  // }

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <Box
          sx={{
            display: "inline-block",
            textAlign: "left",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              p: 2,
              background:
                "linear-gradient(135deg, hsla(250, 86%, 97%, 1) 0%, hsla(217, 100%, 93%, 1) 100%)",
            }}
          >
            MRQ : {data?.vendorName} / {data?.mrqNumber} - {data?.rev} (from:{" "}
            {data?.companyName})
          </Typography>
          {data ? <MrqFormTable data={data ? data : []} /> : ""}
          {/* <VendorMrqFormTable rows={values} onChange={onChange} cols={cols} /> */}
        </Box>
      </div>
    </div>
  );
}
