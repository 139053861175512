import React, { useEffect, useMemo, useState } from "react";
// import { data } from './copy tabs/makeData';
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import {
  useForm,
  useFieldArray,
  useFormContext,
  Controller,
  useWatch,
} from "react-hook-form";
import Grid from "@mui/material/Grid";
import axios from "../../../../../api/axios";
import CustomAlert from "../../../../../components/widgets/CustomAlert";
import { Stack } from "@mui/material";
import EstimationTable from "./EstimationTable";
import { useNavigate } from "react-router-dom";
import { getCostComparison, getMaterialAvailability } from "./api";
import DetailsDialog from "./DetailsDialog";
import { costComparisonColumns } from "./dialogTableColumns";
import { warehouseColumns } from "../../../SCMPage/MCSTables";
import SummaryBox from "./SummaryBox";
import { initialSummaryData } from "./utils";

//The Main Function
const EstimationTab = (props) => {
  document.title = "Pressure Vessel - Estimation";

  const { reRender } = props;
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "DataGridView8",
  });
  const [summaryData, setSummaryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditTable, setIsEditTable] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [AlertContent, setAlertContent] = useState({
    severity: "",
    message: "",
  });

  useEffect(() => {
    const total_cost = roundToTwo(getValues("totalMaterialCost") || 0);
    const totalHours = getValues("totalHours");
    const fabRate = getValues("TextBox35");
    const fab_cost = roundToTwo(totalHours * fabRate || 0);
    console.log({ totalHours, total_cost, fabRate, fab_cost });
    if (total_cost && totalHours)
      setSummaryData([
        {
          title: "Total Material Cost",
          estimation: total_cost,
          percentage: 10,
          total: roundToTwo(total_cost + total_cost / 10), // *10% is the same as /10
        },
        {
          title: "Total FAB Cost",
          estimation: fab_cost,
          percentage: 10,
          total: roundToTwo(fab_cost + fab_cost / 10),
        },
      ]);
  }, [getValues("TextBox35")]);

  //save chnges in table cell
  const handleSaveCell = (cell, value) => {
    setValue(`DataGridView8.${cell.row.index}.${cell.column.id}`, value);
  };
  const initialDialogDetails = {
    open: false,
    title: "",
    description: "",
    columns: [],
    data: {},
    // compare cost , title, description, available table data
  };
  const [dialogDetails, setdialogDetails] = useState(initialDialogDetails);

  //create new row
  const handleCreateNewRow = (values) => {
    // tableData.push(values);
    console.log("appending");
    append({
      Code: "code",
      Component: "Custom Item",
      Description: "Custom Item",
      Qty: 0,
      "Measure/Wt": 0,
      "Material Cost/ Unit": 0,
      "Material Cost": 0,
      "FAB Hours": 0,
      Remarks: "",
    });
    reRender();
  };

  //delete row
  const handleDeleteRow = (index) => {
    remove(index);
  };

  const handleClose = () => {
    setdialogDetails(initialDialogDetails);
  };

  const handleCompareCost = async (row) => {
    console.log(row);
    await getCostComparison(row?.Code).then((res) => {
      setdialogDetails({
        open: true,
        title: "Comparing Cost of Material in Past Orders",
        columns: costComparisonColumns,
        data: res || [],
      });
    });
    // navigate("/supply-chain-management#scm-tab=Warehouse&warehouse-tab=Materials%20Master")
  };
  const handleCheckStock = async (row) => {
    console.log(row);
    await getMaterialAvailability(row?.Code).then((res) => {
      setdialogDetails({
        open: true,
        title: "Availability of Material",
        columns: warehouseColumns,
        data: res || [],
      });
    });
    // navigate("/supply-chain-management#scm-tab=Warehouse&warehouse-tab=Inventory")
  };
  console.log("fields", fields);

  //call the estimateTable function from backend
  const getTableData = async () => {
    const data = getValues();
    setIsLoading(true);
    const result = await axios
      .post("/pressure_vessel/estimateAll", data)
      .then((response) => {
        console.log("result", response.data);
        setValue("DataGridView8", response.data.data);
        setValue("TextBox41", response.data.cost);
        setIsLoading(false);
        return response;
      })
      .catch((err) => {
        console.log("rrror:", err);
      });
    updateTableData();
  };

  //rounds numbers to n(default - two) decimals
  const roundToTwo = (num, decimalPlaces = 2) => {
    num = Math.round(num + "e" + decimalPlaces);
    return Number(num + "e" + -decimalPlaces);
  };

  const updateTableData = () => {
    const data = getValues("DataGridView8");
    //call the estimateTable function from backend
    // const getTableData = async () => {
    //   const data = getValues();
    //   setIsLoading(true);
    //   const result = await axios
    //     .post("/pressure_vessel/estimateAll", data)
    //     .then((response) => {
    //       console.log("result", response.data);
    //       setValue("DataGridView8", response.data.data);
    //       setValue("TextBox41", response.data.cost);
    //       setIsLoading(false);

    //       return response;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //   updateTableData();
    // };

    //update the material cost
    const newValues = data.map((elem) => {
      console.log(elem["FAB Rate/Hr"] > 0);
      return elem["FAB Rate/Hr"] > 0
        ? {
            ...elem,
            "FAB Cost": roundToTwo(
              parseFloat(elem["FAB Hours"]) * parseFloat(elem["FAB Rate/Hr"])
            ),
            "Material Cost": roundToTwo(
              elem["Material Cost/ Unit"] * elem["Measure/Wt"]
            ),
          }
        : {
            ...elem,
            "FAB Cost": 0,
            "Material Cost": roundToTwo(
              elem["Material Cost/ Unit"] * elem["Measure/Wt"]
            ),
            // "Total Cost": roundToTwo(parseFloat(elem["Material Cost"]) + parseFloat(elem["Other Cost"])),
          };
    });

    console.log("calculated: ", newValues);
    setValue("DataGridView8", newValues);

    // reRender()
    //update the total material cost and total fab hours in table
    const totals = newValues?.reduce(
      (accumulator, currentValue) => [
        accumulator[0] + parseFloat(currentValue["Material Cost"]),
        accumulator[1] + parseFloat(currentValue["FAB Hours"]),
        accumulator[2] + parseFloat(currentValue["FAB Cost"]),
      ],
      [0.0, 0.0, 0.0]
    );

    console.log("calculated: ", newValues);
    setValue("DataGridView8", newValues);

    // reRender()

    //update the Fab Cost in the small box
    const fabRate = getValues("TextBox35");
    let fabcost = totals[1] * fabRate;
    setValue("TextBox41", fabcost);

    //to keep the values in store - make it part of the react hook form
    const isRegistered = Object.keys(errors).some(
      (error) => error === "totalCost"
    );
    if (!isRegistered) {
      register("totalMaterialCost");
      register("totalCost");
      register("totalHours");
      register("totalFabCost");
    }
    setValue("totalMaterialCost", roundToTwo(totals[0]));
    setValue("totalHours", roundToTwo(totals[1]));
    setValue("totalFabCost", roundToTwo(totals[2]));

    //update the total Cost
    setValue("totalCost", roundToTwo(totals[0] + totals[2]));
    reRender();
  };

  console.log({ summaryData });

  return (
    <div style={{ width: "100%", minWidth: "900px", padding: "10px" }}>
      <CustomAlert
        open={openAlert}
        setOpen={setOpenAlert}
        severity={AlertContent.severity}
        message={AlertContent.message}
      />
      <Stack spacing={2} direction="row">
        <Button
          color="secondary"
          // marginBottom="20px"
          onClick={() => getTableData()}
          variant="contained"
        >
          Estimate
        </Button>
        <Button
          color="secondary"
          // marginBottom="20px"
          onClick={() => updateTableData()}
          variant="contained"
        >
          Update
        </Button>
      </Stack>
      <br />
      {/* <br /> */}
      <Grid container rowSpacing={1} spacing={1} columns={12}>
        {/* Input Grid */}
        <Grid item xs={11}>
          <EstimationTable
            fields={fields}
            reRender={reRender}
            handleSaveCell={handleSaveCell}
            handleCreateNewRow={handleCreateNewRow}
            handleDeleteRow={handleDeleteRow}
            isLoading={isLoading}
            getValues={getValues}
            handleCompareCost={handleCompareCost}
            handleCheckStock={handleCheckStock}
            isEditTable={isEditTable}
            setIsEditTable={setIsEditTable}
          />
        </Grid>
      </Grid>
      <br />
      <SummaryBox data={summaryData} />
      <DetailsDialog dialogDetails={dialogDetails} handleClose={handleClose} />
      <br />
    </div>
  );
};

export default EstimationTab;
