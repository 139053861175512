import React from "react";

import { Box, Typography } from "@mui/material";
import ListItemForm from "./ListItems/ListItemForm";
import { useForm } from "../../../../../hooks/formHook";
import ListItems from "./ListItems";

export default function ListForm({ equips, addEquip, editEquip, deleteEquip }) {
  // for adding new one
  const { onChange, onSubmit, values } = useForm(addItemCallback, {
    id: "",
    name: "",
    type: "",
    folderUploaded: false,
  });

  function addItemCallback() {
    const newEquip = { ...values, id: equips.length + 1 };
    addEquip(newEquip);
  }

  return (
    <Box sx={{}}>
      <Typography variant="h6" gutterBottom sx={{ padding: 1 }}>
        Project Equipments/ Sub Assemblies:
      </Typography>
      <Box sx={{}}>
        <ListItems
          equips={equips}
          editEquip={editEquip}
          deleteEquip={deleteEquip}
        />
        <br />

        <ListItemForm
          formCase="new"
          index=""
          currentItem={values}
          onChange={onChange}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
}
