import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BootstrapInput } from "../../../../../../../components/widgets/BootstrapInput";
import { MCSContext } from "../../MCS.context";

const MrqSelector = () => {
  const { mrqList, mrqSelection, handleMrqChange, handleRevisionChange } =
    React.useContext(MCSContext);

  return (
    <>
      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
      >
        <InputLabel id="mrq-label" shrink sx={{ fontSize: "1.1rem" }}>
          MRQ Number
        </InputLabel>
        <Select
          labelId="mrq-label"
          value={mrqSelection.selectedMrq}
          onChange={handleMrqChange}
          label="Mrq"
          size="small"
          input={<BootstrapInput />}
        >
          {mrqList.map((mrq) => (
            <MenuItem key={mrq.id} value={mrq.id}>
              {mrq.mrqNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        variant="standard"
        size="small"
        sx={{ m: 1, ml: 0, mt: 0, minWidth: 120 }}
      >
        <InputLabel id="revision-label" shrink sx={{ fontSize: "1.1rem" }}>
          Revision
        </InputLabel>
        <Select
          labelId="revision-label"
          value={mrqSelection.selectedRevision}
          // TODO: defaultValue={mrqSelection.revisions[0]?.revId}
          onChange={handleRevisionChange}
          disabled={!mrqSelection.selectedMrq}
          label="Revision"
          input={<BootstrapInput />}
        >
          {mrqSelection.revisions.map((rev) => (
            <MenuItem key={rev.revId} value={rev.revId}>
              {rev.revName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default MrqSelector;
