import {
  Notes,
  finalPreparation,
  hydrostaticTest,
  itpFuncs,
  manufacturingTabs,
  preFab,
  pwht,
} from "./utils";

const getRandomInitials = () => {
  // const length = Math.floor(Math.random() * 2) + 6;
  const length = 1;
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
};

const itpMockData = (tabArray) =>
  tabArray.map((t) => {
    const rand1 = [...Array(Notes.length).keys()]
      .sort(() => Math.random() - 0.5)
      .slice(0, 4);

    const rand2 = [...Array(Notes.length).keys()]
      .sort(() => Math.random() - 0.5)
      .slice(0, 4);

    return {
      "Inspection/Test Activities": t,
      procedureNo: rand1,
      SpecificInstruction: rand2,
      authorizedInspectionDate: new Date().toJSON().slice(0, 10),
      authorizedInspectionFunc:
        itpFuncs[Math.floor(Math.random() * itpFuncs.length)],
      authorizedInspectionInitials: getRandomInitials(),
      representativeDate: new Date().toJSON().slice(0, 10),
      representativeFunc: itpFuncs[Math.floor(Math.random() * itpFuncs.length)],
      representativeInitials: getRandomInitials(),
      cdtDate: new Date().toJSON().slice(0, 10),
      cdtFunc: itpFuncs[Math.floor(Math.random() * itpFuncs.length)],
      cdtInitials: getRandomInitials(),
    };
  });

export const preFabMockData = itpMockData(preFab);
export const manufacturingMockData = itpMockData(manufacturingTabs);
export const pwhtMockData = itpMockData(pwht);
export const hydrostaticTestMockData = itpMockData(hydrostaticTest);
export const finalPreparationMockData = itpMockData(finalPreparation);
