const paths = {
  projectFormApi: "/new-project",
  projectEquipsApi: "/project-equipments",
  myProjects: "/projects",
  getAProject: "/projects/:projectId/:tab",
  EquipmentsApi: "equipments",
  vendorScreen: "/vendor",
  vendorMrqs: "/vendor-mrqs/:token",
  vendorBidChat: "/vendor-bid-chat/:token",
  clientVendors: "/client-vendors-page",
  materialsDB: "/materials-database",
  scm: "/supply-chain-management",
  mytasks: "/mytasks",
  allstaff: "/allstaff",
  main_pv: "/projects/:id/equipments/main_pv/:equipId",
  main_pv_tabs: "/projects/:id/equipments/main_pv/:equipId/*",
  softwares: "/softwares",
};

export default paths;
