// import * as Autodesk from "forge-viewer";

import { secondAxios } from "../../../../../../api/axios";
const Autodesk = window.Autodesk;

async function getAccessToken(callback) {
  try {
    console.log("GEtting token:::");
    const resp = await secondAxios.get("/raci/viewer-token");
    console.log("token", resp);
    // if (!resp.status === 200) {
    //     throw new Error(await resp.text());
    // }
    const { access_token, expires_in } = await resp.data;
    callback(access_token, expires_in);
  } catch (err) {
    // alert('Could not obtain access token. See the console for more details.');
    console.error(err);
  }
}

export function initViewer(container) {
  return new Promise(function (resolve, reject) {
    Autodesk.Viewing.Initializer({ getAccessToken }, function () {
      const config = {
        extensions: ["Autodesk.DocumentBrowser"],
      };
      const viewer = new Autodesk.Viewing.GuiViewer3D(container, config);
      viewer.start();
      viewer.setTheme("light-theme");
      console.log("viewer", viewer);
      resolve(viewer);
    });
  });
}

export function loadModel(viewer, urn) {
  return new Promise(function (resolve, reject) {
    function onDocumentLoadSuccess(doc) {
      resolve(viewer.loadDocumentNode(doc, doc.getRoot().getDefaultGeometry()));
    }
    function onDocumentLoadFailure(code, message, errors) {
      reject({ code, message, errors });
    }
    viewer.setLightPreset(0);
    Autodesk.Viewing.Document.load(
      "urn:" + urn,
      onDocumentLoadSuccess,
      onDocumentLoadFailure
    );
  });
}
