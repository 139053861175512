import axios, { secondAxios } from "../../../../api/axios";

const getProjectByIdApi = "/mcs_project/";
const deleteProjectApi = "/mcs_project/delete/";
const getPlantApi = "/plant/"

export const getProjectById = async (projectId) => {
  const data = await secondAxios.get(getPlantApi + projectId);
  return data;
};

export const deleteProject = async (projectId) => {
  return await axios.delete(deleteProjectApi + projectId);
};
