import React from "react";

import { Box, Grid, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { TypesIcon } from "../../../../../../components/CustomIcons/EquipsTypesIcons";
import UploadFolderBtn from "../../UploadFolder";
export default function ListItemData({
  equip,
  index,
  setCurrentItem,
  deleteEquip,
  editEquip,
}) {
  return (
    <Grid container>
      {/* Number of this item */}
      <Grid item xs={12} sm={1}>
        {index} -
      </Grid>

      {/* Item itself */}
      <Grid item xs={12} sm={3}>
        <TypesIcon type={equip.type} />
        {equip.type}
      </Grid>
      <Grid item xs={12} sm={3} sx={{ fontWeight: "bold" }}>
        {equip.name}
      </Grid>

      {/* Control btns */}
      <Grid item xs={12} sm={2} sx={{ paddingRight: 1, textAlign: "center" }}>
        {/* uploading */}
        <UploadFolderBtn equip={equip} editEquip={editEquip}/>

        <IconButton
          edge="end"
          aria-label="delete"
          sx={{ marginRight: 1 }}
          onClick={() => {
            console.log("from Edit", { equip });
            setCurrentItem(equip);
          }}
        >
          <EditIcon color="success" />
        </IconButton>

        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => {
            deleteEquip(equip);
          }}
        >
          <DeleteIcon color="error" />
        </IconButton>
      </Grid>
    </Grid>
  );
}
