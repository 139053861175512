import '../../styles/loadingAnimation.css'

const LoadAnimation = ()=>{
    return <div class="loading-wave">
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
    <div class="loading-bar"></div>
  </div>
}

export default LoadAnimation