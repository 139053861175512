import React, { useMemo, useState, useEffect } from "react";
import {MaterialReactTable} from "material-react-table";
// import { data } from './copy tabs/makeData';
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {
  useForm,
  useFieldArray,
  useFormContext,
  Controller,
  useWatch,
} from "react-hook-form";
import Grid from "@mui/material/Grid";
import axios from "../../../../../../api/axios";
import CustomAlert from "../../../../../../components/widgets/CustomAlert";
import { Stack, ListItemIcon, Box } from "@mui/material";

// import { Type, Service, Size, Flange_Rating, Sch_thk } from "./select_options/NozzleTab_Select"
import {
  getColumns,
  ResultColumns,
  columns,
  GeometryDataColumns,
  MaterialDataColumns,
  EstimationColumns,
} from "./columnNames/NozzleColumns";
import InputTable from "./InputTable";
import GeometryDataTable from "./GeometryDataTable";
import ResultTable from "./ResultTable";
import { EstimationTable } from "./EstimationTable";

const initialErrorState = {
  calculationError: false,
  estimationError: false,
};

//The Main Function
const NozzleTab = (props) => {
  const { reRender } = props;

  console.log(ResultColumns, columns);
  const [isLoading, setIsLoading] = useState(false);

  const [error, setError] = useState(initialErrorState);
  const [resultVisibility, setresultVisibility] = useState(false);
  const [geometryDataVisibility, setGeometryDataVisibility] = useState(false);
  const { control, setValue, getValues } = useFormContext();
  const [estimationVisibility, setEstimationDataVisibility] = useState(false);
  const { fields, append, remove } = useFieldArray({
    control,
    name: "DataGridView1",
  });
  const {
    fields: geometryDataFields,
    append: geometryDataAppend,
    remove: geometryDataRemove,
  } = useFieldArray({
    control,
    name: "DataGridView10",
  });

  const [openAlert, setOpenAlert] = useState(false);
  const [AlertContent, setAlertContent] = useState({
    severity: "",
    message: "",
  });

  const handleCreateNewRow = (values) => {
    append({
      Mark: "",
      Service: "",
      Size: "",
      Type: "",
      "Flange Rating": "",
      "Sch/Thk": "",
      "Weld Detail": "",
      "Nozzle Weld (A)": "",
      "Inside Weld (B)": "",
      "Pad Weld (C)": "",
      "Outside Proj": "",
      "Inside Proj": "",
      "Pad OD": "",
      "Pad Thk": "",
    });
    geometryDataAppend({
      "Dist. From D.L": "",
      Orientation: "",
      "Dist. From C.L. (+/-)": "",
      Direction: "",
      Location: "",
      "Nozzle Material": getValues("ComboBox12"),
      "Flange Material": getValues("ComboBox13"),
      "Pad Material": getValues("ComboBox14"),
    });
  };

  const handleDeleteRow = (index) => {
    remove(index);
    geometryDataRemove(index);
  };

  console.log("fields", fields, geometryDataFields);

  const handleSaveCell = (cell, value, datagrid) => {
    console.log(`${datagrid}.${cell.row.index}.${cell.column.id}`, value);

    // Changes in Diameter applied throughout Table. And in the other tabs
    setValue(`${datagrid}.${cell.row.index}.${cell.column.id}`, value);
    if (datagrid === "DataGridView1" && cell.column.id === "Size") {
      reRender();
    }
  };

  const toggleVisibility = (makeVisibile) => {
    setGeometryDataVisibility(false);
    setEstimationDataVisibility(false);
    setresultVisibility(false);
    makeVisibile();
  };

  const showResults = async () => {
    const data = getValues();
    setIsLoading(true);
    toggleVisibility(() => setresultVisibility(true));
    const result = await axios
      .post("/pressure_vessel/calculateNozzle", data)
      .then((response) => {
        console.log(response.data.DataGridView3);
        setValue("DataGridView3", response.data.DataGridView3);
        setIsLoading(false);
        setError(initialErrorState);
        // return response?.data?.DataGridView3
      })
      .catch((error) => {
        setError((prev) => ({ ...prev, calculationError: true }));
      });
  };

  const showEstimation = async () => {
    const data = getValues();
    toggleVisibility(() => setEstimationDataVisibility(true));
    setIsLoading(true);
    const result = await axios
      .post("/pressure_vessel/estimateNozzle", data)
      .then((response) => {
        console.log(response.data);
        setIsLoading(false);
        setError(initialErrorState);
        setValue("DataGridView2", response.data);
      })
      .catch((error) => {
        setError((prev) => ({ ...prev, estimationError: true }));
      });
  };

  const showGeometryData = async () => {
    toggleVisibility(() => setGeometryDataVisibility(true));
  };
  useEffect(() => {
    props.pidState === 2 && handleGetPID();
    props.pidState === 4 && handleSavePID();
  }, [props]);
  const handleGetPID = async () => {
    const result = await axios.get("/pressure_vessel_table/nozzle-pid");
    console.log(result.data.dataNozzles);
    const nozzlesPID = result.data.dataNozzles;
    nozzlesPID.forEach((nozzle) => {
      append({
        Mark: nozzle.Tag,
        Service: nozzle.Service,
        Size: nozzle.Size.replace('"', ""),
        Type: "WN RF",
        "Flange Rating": "",
        "Sch/Thk": nozzle.Schedule,
        "Weld Detail": "",
        "Nozzle Weld (A)": "",
        "Inside Weld (B)": "",
        "Pad Weld (C)": "",
        "Outside Proj": "",
        "Inside Proj": "",
        "Pad OD": "",
        "Pad Thk": "",
        PnID: nozzle.PnPID,
      });
    });
  };
  const handleSavePID = () => {
    const reqBody = fields.map((field) => {
      return {
        PnID: field.PnID,
        Tag: field.Mark,
        Size: field.Size.concat("", '"'),
      };
    });
    axios.post("/pressure_vessel_table/nozzle-pid", reqBody);
  };

  return (
    <div
      style={{
        width: "100%",
        minWidth: "610px",
      }}
    >
      <CustomAlert
        open={openAlert}
        setOpen={setOpenAlert}
        severity={AlertContent.severity}
        message={AlertContent.message}
      />
      <br />
      <Stack spacing={2} direction="row">
        <Button
          size="small"
          color="secondary"
          // marginBottom="20px"
          onClick={() => showGeometryData()}
          variant="contained"
        >
          Geometry Data
        </Button>
        <Button
          size="small"
          color="secondary"
          // marginBottom="20px"
          onClick={() => showResults()}
          variant="contained"
        >
          Nozzle Cal
        </Button>

        <Button
          size="small"
          color="secondary"
          // marginBottom="20px"
          onClick={() => showEstimation()}
          variant="contained"
        >
          Estimation
        </Button>
      </Stack>

      <br />
      <Grid container sx={{ width: 1600 }} spacing={1}>
        <Grid item xs={6.5}>
          <InputTable
            fields={fields}
            handleCreateNewRow={handleCreateNewRow}
            handleDeleteRow={handleDeleteRow}
            handleSaveCell={handleSaveCell}
          />
        </Grid>
        {/* Geometry Data */}
        {geometryDataVisibility ? (
          <Grid item xs={5.5}>
            <GeometryDataTable
              geometryDataFields={geometryDataFields}
              handleSaveCell={handleSaveCell}
            />
          </Grid>
        ) : (
          <></>
        )}

        {resultVisibility ? (
          <Grid item xs={5.5}>
            <ResultTable
              isLoading={isLoading}
              isError={error?.calculationError}
            />
          </Grid>
        ) : (
          <></>
        )}

        {estimationVisibility ? (
          <Grid item xs={5.5}>
            <EstimationTable
              isLoading={isLoading}
              isError={error?.estimationError}
            />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
    </div>
  );
};

export default NozzleTab;
