export const Service = [
    "Inlet",
    "Outlet",
    "Manway",
    "Other"
]

export const Size = [
    "1 / 2",
    "3 / 4",
    "1",
    "1 1 / 4",
    "1 1 / 2",
    "2",
    "2 1 / 2",
    "3",
    "3 1 / 2",
    "4",
    "5",
    "6",
    "8",
    "10",
    "12",
    "14",
    "16",
    "18",
    "24"
]

export const Type = [
    "WN RF",
    "SO RF",
    "SW RF",
    "Blind RF",
    "Coupling",
    "Half Coupling"
]

export const Flange_Rating = [
    "150",
    "300",
    "400",
    "600",
    "900",
    "1500",
    "3000",
    "6000"
]

export const Sch_thk = [
    ""
]

export const Sch_thk1_2 = ["5", "10", "30", "40", "80", "160", "ID=0.252"]
export const Sch_thk3_4 = ["5", "10", "30", "40", "80", "160", "ID=0.434"]
export const Sch_thk1 = ["5", "10", "30", "40", "80", "160", "ID=0.599"]
export const Sch_thk1_1_4 = ["5", "10", "30", "40", "80", "160", "ID=0.896"]
export const Sch_thk1_1_2 = ["5", "10", "30", "40", "80", "160", "ID=1.1"]
export const Sch_thk2 = ["5", "ID=2.209", "10", "30", "ID=2.093", "40", "ID=2.031", "ID=1.999", "80", "ID=1.875", "ID=1.813", "160"]
export const Sch_thk2_1_2 = ["5", "ID=2.657", "10", "ID=2.625", "ID=2.593", "ID=2.563", "ID=2.531", "30", "40", "ID=2.443", "ID=2.375", "80", "160", "ID=1.771"]
export const Sch_thk3 = ["5", "ID=3.282", "10", "ID=3.25", "ID=3.218", "ID=3.188", "ID=3.156", "30", "40", "ID=3", "ID=2.938", "80", "160", "ID=2.3"]
export const Sch_thk3_1_2 = ["5", "ID=3.782", "10", "ID=3.75", "ID=3.718", "ID=3.688", "ID=3.656", "30", "40", "ID=3.5", "ID=3.438", "80"]
export const Sch_thk4 = ["5", "ID=4.282", "10", "ID=4.25", "ID=4.218", "ID=4.188", "ID=4.156", "30", "ID=4.094", "ID=4.062", "40", "ID=4", "ID=3.938", "ID=3.876", "80", "120", "160", "ID=3.152"]
export const Sch_thk5 = ["ID=5.397", "5", "ID=5.313", "10", "ID=5.251", "ID=5.187", "ID=5.125", "40", "ID=5.001", "ID=4.939", "ID=4.875", "80", "120", "160", "ID=4.063"]
export const Sch_thk6 = ["ID=6.459", "5", "ID=6.375", "10", "ID=6.343", "ID=6.313", "ID=6.281", "ID=6.249", "ID=6.219", "ID=6.187", "ID=6.125", "40", "ID=6.001", "ID=5.937", "ID=5.875", "80", "ID=5.625", "120", "ID=5.375", "160", "ID=5.125", "ID=4.897", "ID=4.875"]
export const Sch_thk8 = ["5", "ID=8.375", "10", "ID=8.313", "ID=8.249", "ID=8.219", "ID=8.187", "20", "30", "ID=8.001", "40", "ID=7.937", "ID=7.875", "60", "ID=7.749", "80", "ID=7.501", "100", "ID=7.375", "120", "ID=7.125", "140", "ID=6.875", "160", "ID=6.625"]
export const Sch_thk10 = ["5", "ID=10.438", "10", "ID=10.374", "ID=10.344", "ID=10.312", "20", "ID=10.192", "30", "ID=10.062", "40", "ID=9.874", "60", "ID=9.626", "80", "ID=9.5", "100", "ID=9.126", "120", "ID=9", "ID=8.874", "140", "160", "ID=8.25"]
export const Sch_thk12 = ["5", "ID=12.406", "10", "ID=12.374", "ID=12.344", "ID=12.312", "20", "ID=12.188", "ID=12.126", "30", "ID=12.062", "ID=12", "40", "ID=11.874", "ID=11.75", "60", "ID=11.5", "80", "ID=11.25", "ID=11.126", "100", "ID=11", "ID=10.874", "120", "ID=10.626", "140", "ID=10.25", "160"]
export const Sch_thk14 = ["5", "ID=13.624", "ID=13.594", "ID=13.58", "ID=13.562", "10", "ID=13.438", "20", "ID=13.312", "30", "ID=13.188", "40", "ID=13.062", "ID=13", "ID=12.876", "60", "ID=12.75", "ID=12.624", "80", "ID=12.376", "ID=12.25", "100", "ID=12", "ID=11.876", "120", "ID=11.75", "140", "160", "ID=10", "ID=9.75", "ID=9.6", "ID=9"]
export const Sch_thk16 = ["5", "ID=15.624", "ID=15.594", "ID=15.562", "10", "ID=15.438", "20", "ID=15.312", "30", "ID=15.188", "ID=15.124", "ID=15.062", "40", "ID=14.876", "ID=14.75", "60", "ID=14.624", "ID=14.5", "ID=14.376", "80", "ID=14.25", "ID=14.124", "ID=14", "100", "ID=13.876", "ID=13.75", "ID=13.624", "120", "ID=13.5", "140", "160"]
export const Sch_thk18 = ["5", "ID=17.624", "ID=17.562", "10", "ID=17.438", "20", "ID=17.312", "ID=17.25", "ID=17.188", "30", "ID=17.062", "ID=17", "40", "ID=16.75", "ID=16.624", "60", "ID=16.376", "ID=16.25", "80", "ID=16", "ID=15.876", "ID=15.75", "100", "ID=15.624", "ID=15.5", "120", "140", "160"]
export const Sch_thk24 = ["5", "10", "30", "40", "60", "80", "160", "STD", "XS", "XXS"]

