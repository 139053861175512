import React from "react";
import { uploadFolder } from "./handleUploadApi";
import { IconButton } from "@mui/material";
import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import CloudDoneIcon from "@mui/icons-material/CloudDone";

export default function UploadFolderBtn({ equip, editEquip }) {
  const uploaded = () => {
    editEquip({ ...equip, folderUploaded: true });
  };

  const handleChange = async (e) => {
    const files = Array.from(e.target.files);

    const form = new FormData()
    const folderData = files.map((folder) => ({
      name: folder.name,
      path: folder.webkitRelativePath,
      folder: folder
    }));

    files.forEach(file => {
      form.append(equip.id, file, file.webkitRelativePath);
    });
    console.log("form", form.getAll(equip.id))

    editEquip({ ...equip, folderUploaded: true, folderData: form });
    // const msg = await uploadFolder(e);
    // msg && uploaded();
    // console.log({ msg });
  };

  return (
    <label htmlFor={`icon-button-file-${equip.id}`}>
      <input
        type="file"
        directory=""
        webkitdirectory=""
        mozdirectory=""
        multiple
        onChange={(e) => {
          console.log("from input file", { equip });
          handleChange(e);
        }}
        style={{ display: "none" }}
        id={`icon-button-file-${equip.id}`}
      />

      <IconButton
        color="primary"
        aria-label="upload picture"
        component="span"
        sx={{ marginRight: 1 }}
      >
        {equip.folderUploaded === true ? (
          <CloudDoneIcon color="success" />
        ) : (
          <DriveFolderUploadIcon color="primary" />
        )}
      </IconButton>
    </label>
  );
}
