import React from 'react'

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';

const actions = [
    { name: 'Day Off' },
    { name: 'Training' },
];

const TimesheetFormDialog = (props) => {
    const { open, handleClose, error, timesheetData, handleChange, handleSliderChange, submitHandler,
        dialogTitle, nonTaskSubmitHandler, edit } = props;

    let isNonTask = false;
    if (actions.some(action => action.name === dialogTitle.title)) {
        isNonTask = true
    }
    // if (edit){
    //     console.log("prev",dialogTitle.prevprogress)
    //     console.log("total",(100 - dialogTitle.progress) + dialogTitle.prevprogress)
    // }
    // else {
    //     // console.log(100 - dialogTitle.progress)
    // }
    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle >Today's Progress ({dialogTitle.title})</DialogTitle>
            <DialogContent dividers >
                <DialogContentText >
                    <Typography sx={{ color: "red", textAlign: "center" }}>{error}</Typography>
                </DialogContentText>

                <Typography>Date</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="date"
                    name="date"
                    value={timesheetData.date}
                    onChange={handleChange}
                    type="date"
                    fullWidth
                    variant="standard"
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="hours"
                    label="Hours"
                    name="hours"
                    value={timesheetData.hours}
                    disabled={isNonTask ? true : false}
                    type="number"
                    onChange={handleChange}
                    fullWidth
                    variant="standard"
                />
                <Typography sx={{ mt: 2, mb: 2 }}>Progress: {dialogTitle.progress} % --  Today's Progress: {timesheetData.progress}%</Typography>
                <Slider
                    max={edit ? (100 - dialogTitle.progress) + dialogTitle.prevprogress : (100 - dialogTitle.progress)}
                    // min=
                    disabled={isNonTask ? true : false}
                    name="progress"
                    value={timesheetData.progress}
                    aria-label="Default"
                    size="small"
                    valueLabelDisplay="auto"
                    onChange={handleSliderChange} />
                <TextField
                    autoFocus
                    margin="dense"
                    id="remarks"
                    label="Remarks"
                    fullWidth
                    variant="standard"
                    name="remarks"
                    value={timesheetData.remarks}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={!isNonTask ? () => submitHandler() : () => nonTaskSubmitHandler()}>Submit</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TimesheetFormDialog