import { secondAxios } from "../../../../../../api/axios";

export const getTimesheets = async (taskId) => {
  // const response = await secondAxios.get('/timesheet/' + taskId);
  console.log(taskId)
  const response = await secondAxios.get('/task/' + taskId);
  console.log(response.data)
  return response.data?.timesheets;
};

export const createTimesheet = async (taskId, timesheet) => {
  const postRequestData = {
    ...timesheet,
    hours: parseInt(timesheet.hours) || 0, //TODO: Check why MRT is returning strings though its set to number
    progress: parseInt(timesheet.progress) || 0,
    taskId
  };
  console.log({ postRequestData })
  await secondAxios.post('/timesheet', postRequestData);
};

export const updateTimesheet = async (timesheet) => {
  await secondAxios.put(`/timesheet/${timesheet.id}`, timesheet);
};

export const deleteTimesheet = async (timesheetId) => {
  await secondAxios.delete(`/timesheet/${timesheetId}`);
};
