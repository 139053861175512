import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {MaterialReactTable} from 'material-react-table';

export default function DetailsDialog({ dialogDetails, handleClose }) {

    return (
        <Dialog
            open={dialogDetails.open}
            onClose={handleClose}
            maxWidth="lg"
            fullWidth={true}
        >
            <DialogTitle sx={{ alignSelf: "center" }}>
                {dialogDetails.title}
            </DialogTitle>
            <DialogContent>
                {
                    !dialogDetails?.data ??
                    (
                        <DialogContentText id="alert-dialog-description">
                            "No records were found"
                        </DialogContentText>
                    )
                }
                <MaterialReactTable
                    enableBottomToolbar={false}
                    enableTopToolbar={false}
                    columns={dialogDetails?.columns || []}
                    data={dialogDetails?.data || []}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}
