import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CylindricalShellTab from './CylindricalShellTab';
import { useFormContext } from "react-hook-form";
import EllipticalHeadTab from './EllipticalHeadTab';
import Button from '@mui/material/Button';

import axios from '../../../../../api/axios'
import checkRequiredFields from '../../../../../utils/checkRequiredFields';
import CustomAlert from '../../../../../components/widgets/CustomAlert';
import NozzleTab from './NozzleTab';
import SaddleAnalysisTab from './SaddleAnalysisTab';
import { Stack } from '@mui/material';
import { verticalTabStyle } from '../../../../../styles/muiProps';
import CalcTabTemplate from './CalcTabTemplate';
import { Anchor_Bolt_InputData, Anchor_Bolt_OutputData, Base_Ring_InputData, Base_Ring_OutputData, Deflection_InputData, Deflection_OutputData, Leg_Support_InputData, Leg_Support_OutputData, Lug_Support_InputData, Lug_Support_OutputData, Seismic_Load_InputData, Seismic_Load_OutputData, Skirt_Support_InputData, Skirt_Support_OutputData, Wind_Load_InputData, Wind_Load_OutputData } from './inputFields/otherTabsInput';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            width="100%"
            style={{ width: '90%' }}
        >
            {value === index && (
                <Box sx={{ p: 3, minHeight: "100vh" }} width="inherit">
                    <div id="tab-div" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {children}
                    </div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const SubmitButtonStyle = {
    width: "fit-content",
    alignSelf: "flex-end"
}

const CalculationTab = (props) => {
    document.title ="Pressure Vessel - Calculation";

    // const { calculationResult, getCalculation } = props
    const [tab, setTab] = React.useState(0); ///value of tab 
    const [calculationResult, setCalculationResult] = React.useState([])
    const { setValue, getValues, handleSubmit } = useFormContext();
    const [openAlert, setOpenAlert] = useState(false)
    const [AlertContent, setAlertContent] = useState({
        severity: "",
        message: ""
    })

    const defaults = {
        "Liquid Head Pressure": 0.1, "Material Specification": "SA 516", "Allowable Stress": 138,
        "Provided Thickness": 12, "Corrosion Allowance": 3
    }

    const getCalc = async (data) => {
        const result = checkRequiredFields(data)
        console.log(result)
        if (result[0] === false) {
            setAlertContent({
                message: "Following values are missing: " + result[1],
                severity: "error",
            })
            setOpenAlert(true)
        }
        else {
            console.log("Sending request")
            const res = await axios.post("/pressure_vessel/calculate", data)
                // .then((response) => {
                //     return response
                // })
                .catch((err) => {
                    console.log(err)
                })
            // console.log(res.data.DataGridView6)
            console.log("res.data", res)
            // setValue("DataGridView6", res.data.DataGridView6)
            // console.log(got it)
            setCalculationResult(res.data)
            // reRender()
            for (let elem of ["Cylindrical", "Elliptical", "Nozzle", "Saddle"]) {
                if (res.data[elem]) {
                    for (const [key, value] of Object.entries(res.data[elem])) {
                        setValue(key, value);
                    }
                }
            }
        }
    }

    //Set Calculation results, values from tables and values from design tab
    useEffect(() => {
        for (let elem of ["Cylindrical", "Elliptical", "Nozzle", "Saddle"]) {
            if (calculationResult[elem]) {
                for (const [key, value] of Object.entries(calculationResult[elem])) {
                    setValue(key, value);
                }
            }
        }
        //values to be fetched from the table
        setValue("txt_LH_CSC", defaults["Liquid Head Pressure"])
        setValue("txt_LH_EHC", defaults["Liquid Head Pressure"])
    }, [])

    const handleChange = (event, newTab) => {
        setTab(newTab);
    };

    console.log("calculationResult", calculationResult)
    return (
        <Box
            sx={{
                flexGrow: 1,
                bgcolor: 'background.paper',
                borderRadius: "15px",
                // bgcolor: 'red',
                display: 'flex',
                width: "90%", marginLeft: 2,
                "& .Mui-disabled": {
                    color: "red",
                    "-webkit-text-fill-color": "#000000"
                }
            }}
        >
            <CustomAlert open={openAlert} setOpen={setOpenAlert} severity={AlertContent.severity} message={AlertContent.message} />
            <Tabs
                orientation="vertical"
                value={tab}
                onChange={handleChange}
                {...verticalTabStyle}
            >
                <Tab label="Cylindrical Shell" />
                <Tab label="Elliptical Head" />
                <Tab label="Nozzle Calculation" />
                <Tab label="Saddle Zick Analysis" />
                {/* <Tab label="Wind Load" />
                <Tab label="Seismic Load" />
                <Tab label="Deflection" />
                <Tab label="Skirt Support" />
                <Tab label="Anchor Bolt" />
                <Tab label="Base Ring" />
                <Tab label="Lug Support" />
                <Tab label="Leg Support" /> */}
            </Tabs>
            <TabPanel value={tab} index={0}>
                {/* Cylindrical Shell */}
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Cylindrical Shell</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CylindricalShellTab calculationResult={calculationResult?.Cylindrical} />
            </TabPanel>
            <TabPanel value={tab} index={1}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Elliptical Head</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <EllipticalHeadTab calculationResult={calculationResult?.Elliptical} />
            </TabPanel>
            <TabPanel value={tab} index={2}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Nozzle</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <NozzleTab calculationResult={calculationResult?.Nozzle} />
            </TabPanel>
            <TabPanel value={tab} index={3}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Saddle Analysis</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <SaddleAnalysisTab calculationResult={calculationResult?.Saddle} />
            </TabPanel>
            <TabPanel value={tab} index={4}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Wind Load</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CalcTabTemplate inputData={Wind_Load_InputData} outputData={Wind_Load_OutputData} />
            </TabPanel>
            <TabPanel value={tab} index={5}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Wind Load</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CalcTabTemplate inputData={Seismic_Load_InputData} outputData={Seismic_Load_OutputData} />
            </TabPanel>
            <TabPanel value={tab} index={6}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Wind Load</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CalcTabTemplate inputData={Deflection_InputData} outputData={Deflection_OutputData} />
            </TabPanel>
            <TabPanel value={tab} index={7}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Wind Load</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CalcTabTemplate inputData={Skirt_Support_InputData} outputData={Skirt_Support_OutputData} />
            </TabPanel>
            <TabPanel value={tab} index={8}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Wind Load</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CalcTabTemplate inputData={Anchor_Bolt_InputData} outputData={Anchor_Bolt_OutputData} />
            </TabPanel>
            <TabPanel value={tab} index={9}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Wind Load</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CalcTabTemplate inputData={Base_Ring_InputData} outputData={Base_Ring_OutputData} />
            </TabPanel>
            <TabPanel value={tab} index={10}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Wind Load</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CalcTabTemplate inputData={Lug_Support_InputData} outputData={Lug_Support_OutputData} />
            </TabPanel>
            <TabPanel value={tab} index={11}>
                <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                    <Typography variant="h6" color="primary" marginLeft="10rem">Wind Load</Typography>
                    <Button variant="contained" sx={{ ...SubmitButtonStyle }} onClick={handleSubmit(getCalc)}>Calculate</Button>
                </Stack>
                <br />
                <CalcTabTemplate inputData={Leg_Support_InputData} outputData={Leg_Support_OutputData} />
            </TabPanel>
            {/* <TabPanel value={tab} index={3}>
          Item Four
        </TabPanel>
        <TabPanel value={tab} index={4}>
          Item Five
        </TabPanel>
        <TabPanel value={tab} index={5}>
          Item Six
        </TabPanel> */}
        </Box >
    );
}

export default CalculationTab