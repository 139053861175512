import { Box, Grid } from "@mui/material";
import React from "react";

export default function ITPRowsTab({ rows }) {
  return (
    <Box>
      {rows.map((row, i) => {
        const val = row.content ? row.content : row;
        const key = row.key ? row.key : i + 1;

        return <Row key={key} id={key} val={val} />;
      })}
    </Box>
  );
}

function Row({ id, val }) {
  return (
    <Grid
      container
      spacing={0}
      columns={24}
      alignItems="center"
      sx={{
        "&:not(:last-child)": {
          borderBottom: "2px solid #ddd",
        },
        p: 1,
      }}
    >
      <Grid item xs={1}>
        {id}
      </Grid>
      <Grid item xs={23}>
        {val}
      </Grid>
    </Grid>
  );
}
