import React from "react";
import { ReactComponent as SpLogo } from "../../images/svgs/sharepoint-icon.svg";
import { Box } from "@mui/material";

export default function SharePointIcon({ size }) {
  return (
    <Box sx={{ height: size, width: size }}>
      <SpLogo />
    </Box>
  );
}
