export const costComparisonColumns = [
    {

        accessorKey: "updatedAt",
        header: "Date",
        size: 60,
        Cell: ({ cell }) => (
            <div>
                {cell.getValue()?.split("T")[0] || ""}
            </div>
        )
    },
    {
        accessorKey: "material.material_code",
        header: "Material Code",
        size: 150,
    },
    {
        accessorKey: "material.description",
        header: "Description",
        size: 200,
    },
    {
        accessorKey: "selected_name",
        header: "Vendor",
        size: 60,
    },
    {
        accessorKey: "selected_cost",
        header: "Cost($)",
        size: 60,
    },
    {
        accessorKey: "selected_time",
        header: "Time(days)",
        size: 60,
    },

]
