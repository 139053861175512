import React from 'react'
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// No value or index here
const TabPanelFormat = (props) => {
    const { children, padding, backgroundColor, ...other } = props;

    return (
        <div
            role="tabpanel"
            {...other}
            backgroundColor={backgroundColor ?? "" ?? ""}
            overflow="auto"
            style={{
                width: "100%",
                boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;"
            }}

        >

            {/* // <Box sx={{ p: 1, backgroundColor: "red" }}> */}
            < Box sx={{
                padding: padding ?? 1,
                width: "100%",
                backgroundColor: backgroundColor ?? "#e5e8f2",
                // maxWidth: 'calc(100vw - 70px)',
                minHeight: "85vh",
                // boxShadow: "rgba(255, 0, 0, 0.16) 0px 3px 6px  , rgba(0, 0, 0, 0.23) 0px 3px 6px;",
                overflow: "auto"
            }
            }>
                <Box >{children}</Box>
            </Box >

        </div >
    );
}

TabPanelFormat.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default TabPanelFormat