import { Autocomplete, Box, Button, Dialog, DialogContent, DialogTitle, Grid, Stack, TextField, Typography } from '@mui/material'
import {MaterialReactTable} from 'material-react-table'
import React from 'react'
import { expandMaterialTable, srvDialogTable } from '../../MCSTables'
import { smallInputField } from '../../../../../styles/muiProps'

// PO Select option
// On select Po, show materials of PO
// MRV fields
// Add to MRV and warehouse

const formItems = [
    { label: "MRV Number", name: "mrv_name" },
    { label: "Issue Date", name: "mrv_issueDate", type: "date" },
    {label: "Approval Status", name: "mrv_approvalStatus"},
    { label: "Notes", name: "mrv_notes" },

]
const MRVDialog = (props) => {
    const { open, onClose, poList, onSelectPo, mrvRequest, onChangeMRVForm, onSubmitMRV } = props
    console.log("req", mrvRequest)

    const handleSaveCell = (cell, value) => {
        console.log("changes",cell.row.original.material.id, value)
        //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here
        mrvRequest.materials[cell.row.index].material.heat_number = value;
        //send/receive api updates here
        // setTableData([...tableData]); //re-render with new data
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={"lg"}>
            <DialogTitle> Material Receiving Voucher</DialogTitle>
            <DialogContent>
                <Grid container >
                    <Grid item xs={8} paddingRight={1}>
                        <Stack gap={1}>
                            <Typography>Select Purchase Order</Typography>
                            <Autocomplete
                                id="purchase_order"
                                options={poList ?? []}
                                getOptionLabel={(option) => option.label}
                                getOptionDisabled={(option) =>
                                    option.hasMrv
                                }
                                value={poList?.find((option) => option.id === mrvRequest.mcsPurchaseOrderId) || null}
                                onChange={onSelectPo}
                                renderInput={(params) => <TextField {...params} label="Select PO" />}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                        <div style={{ fontStyle: option.hasMrv ? 'italic' : 'normal' }}>
                                            {option.label}
                                            {option.hasMrv && <span style={{ marginLeft: '4px', color: 'gray' }}> - Received</span>}
                                        </div>
                                    </li>
                                )}
                            />

                            <MaterialReactTable
                                columns={srvDialogTable}
                                data={mrvRequest.materials ?? []}
                                defaultColumn={{ size: 40 }}
                                enableTopToolbar={false}
                                enableEditing
                                editDisplayMode='table'
                                muiEditTextFieldProps={({ cell }) => ({
                                    //onBlur is more efficient, but could use onChange instead
                                    onBlur: (event) => {
                                        handleSaveCell(cell, event.target.value);
                                    },
                                })}
                            />

                        </Stack>
                    </Grid>
                    <Grid item xs={4} sx={{ backgroundColor: "#eee", padding: 2, display: "FLEX", flexDirection: "column" }}>
                        <Typography textAlign={"center"}>MRV Details</Typography>
                        <br />
                        {
                            formItems.map(field => (
                                <>
                                    <Typography >{field.label}: &emsp;</Typography>
                                    <TextField
                                        size='small'
                                        name={field.name}
                                        fullWidth
                                        onChange={onChangeMRVForm}
                                        value={mrvRequest[field.name]}
                                        variant="outlined"
                                        type={field.type || "string"}
                                        sx={{
                                            '& input': {
                                                bgcolor: '#f5f7fa'
                                            },
                                        }}
                                    />
                                </>
                            ))
                        }
                        <Button variant='contained' sx={{ marginTop: 2 }} onClick={onSubmitMRV}>
                            Create MRV
                        </Button>
                    </Grid>
                </Grid>

            </DialogContent>
        </Dialog >
    )
}

export default MRVDialog