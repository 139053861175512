import axios from "../../../../../api/axios"

export const getCostComparison = async (code) => {
    const url = "/materials/getCosts/" + code
    const { data } = await axios.get(url)
    console.log({ costcomp: data })
    return data
}

export const getMaterialAvailability = async (code) => {
    const url = "/materials/getAvailability/" + code
    const { data } = await axios.get(url)
    console.log({ avail: data })
    return data
}