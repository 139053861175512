import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Divider from '@mui/material/Divider';
import Badge from '@mui/material/Badge';


import Check from '@mui/icons-material/Check';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';

// import { Typography } from '@mui/material';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    display: 'flex',
    textAlign: 'left',
    justifyContent: 'left',
    alignItems: 'center',
    //   maxWidth: "75%",
    minHeight: "6rem",
    color: theme.palette.text.secondary,
}))

//Stepper Icon
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            <div className="QontoStepIcon-circle" />
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};



const dividerStyling = {
    mr: 4, ml: 4, height: "5rem", mt: "2.2rem"
}


const CompletedTasks = (props) => {
    const { getTaskData } = props
    const [tasks,holidayId,trainingId] = getTaskData()
    return (
        <Box sx={{ width: '100%' }}>
            <Stack id="taskStack" spacing={2} sx={{ alignItems: "center" }}>
                {
                    tasks ?
                        tasks.map((taskData, index) => {
                            if (!taskData.projectName) {
                                // if (index == tasks.length - 1) {
                                //     if (!document.getElementById("taskStack")?.textContent)
                                //         return <Item sx={{ width: "60%", textAlign: "center" }}>
                                //             <Typography >No tasks to show</Typography>
                                //         </Item>
                                // }
                                return false
                            }
                            if (taskData.progress !== 100) {
                                return false
                            }
                            return (
                                <Item key={taskData.id} >
                                    <Stack sx={{ alignItems: "center", ml: 2 }}>
                                        <Badge color="success" variant="dot">

                                            <AssignmentIcon sx={{ fontSize: "4rem", color: "#e5e8f2" }} />
                                        </Badge>
                                            {/* <Chip label="Ongoing" variant="outlined" color="primary"  sx={{ fontSize: ".8rem" }}/> */}
                                            <Typography >Completed</Typography>
                                    </Stack>
                                    <Divider orientation="vertical" variant="middle" light flexItem sx={{ ...dividerStyling }} />

                                    {/* <Stack sx={{ width: "20rem" }}> */}
                                    <Stack sx={{ minWidth: "10rem", maxWidth: "25rem" }}>
                                        <Typography sx={{ fontSize: "0.875rem" }}>{taskData.name} </Typography>
                                        <Typography sx={{ mt: "0.2rem", color: "#8c99cc" }}>{taskData.projectName} </Typography>
                                    </Stack>

                                    <Divider orientation="vertical" variant="middle" light flexItem sx={{ ...dividerStyling }} />

                                    <Stepper activeStep={0}
                                        orientation="vertical"
                                        sx={{ width: "10rem" }}
                                    >
                                        <Step >
                                            <StepLabel StepIconComponent={QontoStepIcon}
                                                optional={
                                                    <Typography sx={{ fontSize: 10 }}>Initial Date</Typography>
                                                }>
                                                {taskData.initialDate}
                                            </StepLabel>
                                        </Step>
                                        <Step >
                                            <StepLabel StepIconComponent={QontoStepIcon}
                                                optional={
                                                    <Typography sx={{ fontSize: 10 }}>Due Date</Typography>
                                                }>
                                                {taskData.dueDate || "Not set"}
                                            </StepLabel>
                                        </Step>
                                    </Stepper>

                                    <Divider sx={{ ...dividerStyling }} orientation="vertical" light variant="middle" flexItem />

                                    <Stack sx={{ width: "5rem" }}>
                                        <Typography>Est. Hours</Typography>
                                        <Typography sx={{ fontSize: "1.4rem" }}>{taskData.estimatedHours || 0}</Typography>
                                    </Stack>

                                    <Divider sx={{ ...dividerStyling }} orientation="vertical" light variant="middle" flexItem />

                                    <Stack sx={{ width: "6rem", paddingRight: 4 }}>
                                        <Typography>Hours Spent</Typography>
                                        <Typography sx={{ fontSize: "1.4rem" }}>{taskData.actualHours || 0}</Typography>
                                    </Stack>


                                </Item>
                            )
                        }) :
                        <Item>
                            <Typography>Nothing to show</Typography>
                        </Item>
                }

            </Stack>
        </Box>
    )
}

export default CompletedTasks