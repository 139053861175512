export const projectTableProps = {
    enableColumnActions: false,
    enableColumnFilters: false,
    // enableTopToolbar: false,
    // enableBottomToolbar: false,
    // enablePagination: false,
    enableSorting: false,
    muiTableBodyRowProps: { hover: false },
    muiTableProps: {
        sx: {
            // border: '1px solid rgba(81, 81, 81, .5)',
            // caption: {
            //     captionSide: 'top',
            // },
        },
    },
    muiTableHeadCellProps: {
        sx: {
            border: "0.5px solid rgba(81, 81, 81, .1)",
            // fontStyle: 'italic',
            // fontWeight: "bold",
            padding: "10px",
            backgroundColor: "#f3f1fe",
        },
    },
    muiTableBodyCellProps: {
        sx: {
            border: "1px solid rgba(81, 81, 81, .1)",
            padding: "10px",
        },
    },
}