import React, { useEffect } from 'react'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustomTextField from '../../../../../../components/widgets/CustomTextField';
import { designDetails } from './FieldsData/DesignTabFields';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { Typography } from '@mui/material';
import { Controller, useFormContext } from "react-hook-form";
import { CustomSelect } from '../../../../../../components/widgets/CustomSelect';
import Checkbox from '@mui/material/Checkbox';
import { GridStyle, designTabBoxProps } from '../../../../../../styles/muiProps';


const DesignDetailsGrid = () => {
    const { setValue, control, watch, formState: { errors }, reset } = useFormContext({
        defaultValues: {
            CheckBox1: false,
            ComboBox24: "",
            ComboBox18: "",
            ComboBox20: ""

        },
    });
    const selectValue = watch('ComboBox24'); // Watch the 'select1' field
    console.log({ selectValue })

    useEffect(() => {
        if (selectValue && selectValue.includes('ASME VII DIV')) {
            setValue('TextBox91', 'Edition 2021');
        }
    }, [selectValue]);

    return (
        <Grid item xs={2} sm={2.6} md={3} lg={3} xl={2.6}  >
            <Box
                {...designTabBoxProps}
                noValidate
            >
                {
                    designDetails.map(elem => (
                        <Grid id="inner-container" item container
                            columns={12}
                        >
                            <Grid item sx={{ ...GridStyle }} xs={6}>
                                <Typography fontSize={"0.75rem"} >{elem.label}</Typography>
                            </Grid>
                            < Grid item sx={{ ...GridStyle }} xs={6}>
                                {
                                    elem.select ?
                                        <CustomSelect label="" name={elem.name} required
                                            menuItems={elem.select}
                                        /> :
                                        <CustomTextField label="" id={elem.label} name={elem.name} disabled={elem?.disabled || false} />
                                }
                            </Grid>
                        </Grid>
                    ))
                }
                {
                    <FormControl error={Boolean(errors.SI_Units_RB)}>
                        <FormGroup row >
                            <FormLabel sx={{ fontSize: "0.75rem", mt: 0.6, color: 'black' }} id="units">Units</FormLabel>
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="SI_Units_RB"
                                        control={control}
                                        defaultValue={true}
                                        render={({ field: { value, ref, ...field } }) => (
                                            <Checkbox
                                                row
                                                {...field}
                                                inputRef={ref}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: "0.75rem", ml: 1 } }}
                                                onChange={(e) => {
                                                    field.onChange(e.target.checked)
                                                    setValue("Eng_Units_RB", false)
                                                }}
                                                checked={!!value}
                                            />
                                        )}
                                    />
                                }
                                sx={{ '& .MuiFormControlLabel-label': { fontSize: "0.75rem" } }}

                                label="SI Units"

                            />
                            <FormControlLabel
                                control={
                                    <Controller
                                        name="Eng_Units_RB"
                                        disabled
                                        control={control}
                                        defaultValue={false}
                                        render={({ field: { value, ref, ...field } }) => (
                                            <Checkbox
                                                {...field}
                                                disabled
                                                row
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: "0.75rem" } }}
                                                inputRef={ref}
                                                onChange={(e) => {
                                                    field.onChange(e.target.checked)
                                                    setValue("SI_Units_RB", false)
                                                }}
                                                checked={!!value}
                                            />
                                        )}
                                    />
                                }
                                sx={{ '& .MuiFormControlLabel-label': { fontSize: "0.75rem" } }}
                                label="English Units"
                            />
                        </FormGroup>
                    </FormControl>
                }

            </Box>
        </Grid>
    )
}

export default DesignDetailsGrid