import Typography from '@mui/material/Typography';

export var drawingColumns = [
    {
        accessorKey: "materialCode",
        header: "Material Code "
    },
    {
        accessorKey: "description",
        header: "Description",
        size: 300
    },
    {
        accessorKey: "bom_adder",
        header: "BOM Adder",
        size: 150
    },
    {
        accessorKey: "item",
        header: "Item",
        enableColumnActions: false,
        size: 30
    },
    {
        accessorKey: "quantity",
        header: "Qty",
        enableColumnActions: false,
        size: 30

    },
    // {
    //     accessorKey: "reference",
    //     header: "Ref.",
    //     enableColumnActions: false,
    // },

    {
        accessorKey: "mrqNumber",
        header: "MRQ",
        // Cell: ({ cell }) => (
        //     <Typography>
        //         {cell.getValue()?.map((material) => (
        //             material?.materialRequisition?.mrq_name ?? ""
        //         ))}
        //     </Typography>
        // )
    },
    {
        accessorKey: "poNumber",
        header: "PO",
        size: 120
        // Cell: ({ cell }) => (
        //     <div>
        //         {cell.getValue()?.map((material) => (
        //             material?.mcsPurchaseOrder?.poNumber ?? ""
        //         ))}
        //     </div>
        // )
    },
    {
        accessorKey: "mrvNumber",
        header: "MRV",
        // Cell: ({ cell }) => (
        //     <div>
        //         {cell.getValue()?.map((material) => (
        //             material?.mcsPurchaseOrder?.materialReceivingVouchers
        //             ?.map(mrv => mrv?.mrvNumber)
        //             ?? ""
        //         ))}
        //     </div>
        // )
    },
    {
        accessorKey: "srvNumber",
        header: "SRV",
        // Cell: ({ cell }) => (
        //     <div>
        //         {cell.getValue()?.map((material) => (
        //             material?.mcsPurchaseOrder?.materialReceivingVouchers
        //             ?.map(mrv => mrv?.shopReceivingVouchers
        //                 ?.map(srv=>srv?.srv_name))
        //             ?? ""
        //         ))}
        //     </div>
        // )
    },
]