import AssetManagementTab from "./AssetManagementTab";
// import DCS from "./DCS";
import DCSTab from "./DCSTab";
// import DownloadPage from "./DownloadPage";
import EstimationTab from "./EstimationTab";
import MCS3 from "./MCS3";
// import MCSPage from "./MCSTab";
import PCS from "./PCSTab";
// import PMSTabs from "./PMSTab";
import PMSTab2 from "./PMSTab2";

export const equipMainTabs = [
    // "File",
    // "Data",
    // "Calc.",
    "Est.",
    "MCS",
    "DCS",
    "PCS",
    // "AMS",
    "PMS",
    // "Software",
];

export const CommonTabs = (reRender) => ([
    {
        title: "EstimationTab",
        panel: <EstimationTab reRender={reRender} />,
        // panelProps: { backgroundColor: "transparent", padding: 3 },
    },
    {
        title: "MCSPage",
        // panel: <MCSPage />
        panel: <MCS3 />
    },
    {
        title: "DCSPage",
        panel: <DCSTab reRender={reRender} />
    },
    {
        title: "PCS",
        panel: <PCS />
    },
    // {
    //     title: "AssetManagementTab",
    //     panel: <AssetManagementTab />
    // },
    {
        title: "PMSTab",
        panel: <PMSTab2 />
    },
    // {
    //     title: "DownloadPage",
    //     panel: <DownloadPage />
    // },

])