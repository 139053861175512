import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { MCSContext } from "../../../../MCS.context";
import { getAllVouchers } from "../../../../api";

export default function SelectFromExistedEntities({
  selectedCurrentAction,
  onSelectEntity,
  actionRadioBtnsData,
  onClickRadioBtn,
  currentAction,
}) {
  const { mrqData, setMrqData } = React.useContext(MCSContext);
  const [existedEntities, setExistedEntities] = React.useState([]);

  React.useEffect(() => {
    if (currentAction === "po") {
      setExistedEntities(mrqData?.pos);
    } else {
      const func = async () => {
        const data = await getAllVouchers(currentAction);
        setExistedEntities(data);
        setMrqData({ ...mrqData, [currentAction + "s"]: data });
      };

      func();
    }
  }, []);

  const CurrentRadioForm = () => {
    switch (currentAction) {
      case "po":
        return existedEntities.map((po) => {
          return (
            <FormControlLabel
              checked={actionRadioBtnsData.number === po.poNumber}
              value={po.poNumber}
              control={<Radio />}
              label={po.poNumber}
              onClick={onClickRadioBtn}
            />
          );
        });
      case "mrv":
        return existedEntities.map((mrv) => {
          return (
            <FormControlLabel
              checked={actionRadioBtnsData.number === mrv.mrvNumber}
              value={mrv.mrvNumber}
              control={<Radio />}
              label={mrv.mrvNumber}
              onClick={onClickRadioBtn}
            />
          );
        });

      case "srv":
        return existedEntities.map((srv) => {
          return (
            <FormControlLabel
              checked={actionRadioBtnsData.number === srv.srvNumber}
              value={srv.srvNumber}
              control={<Radio />}
              label={srv.srvNumber}
              onClick={onClickRadioBtn}
            />
          );
        });
      default:
        return <></>;
    }
  };

  return (
    <>
      <FormControl
        sx={{
          width: "100%",
          maxHeight: "250px",
          overflow: "auto",
        }}
      >
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedCurrentAction}
          onChange={onSelectEntity}
          sx={{ pl: 2 }}
        >
          {existedEntities && existedEntities[0] ? (
            <>
              <CurrentRadioForm />
            </>
          ) : (
            <p
              style={{
                color: "#bbb",
                fontSize: "14px",
                textAlign: "center",
                padding: 3,
              }}
            >
              {"No " + currentAction.toUpperCase() + "s Found yet."}
            </p>
          )}
        </RadioGroup>
      </FormControl>
    </>
  );
}
