import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import MoreOptions from "./MoreOptions";
import AddNewForm from "./AddNewForm";

export default function ClientVendorsPageTable({ cols, rows, setValues }) {
  const [newFormOpen, setNewFormOpen] = React.useState(false);

  const onAddNewVendor = () => {
    setNewFormOpen(true);
  };

  return (
    <Paper sx={{ overflow: "hidden", display: "inline-block" }}>
      <TableContainer sx={{ maxHeight: "70vh" }}>
        <Table stickyHeader aria-label="sticky table">
          {!rows[0] ? (
            <caption>
              <Box sx={{ width: "100%", p: 2 }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    m: "auto",
                  }}
                >
                  There is no Vendor you are Dealing with yet!
                </Typography>
              </Box>
            </caption>
          ) : (
            ""
          )}
          <TableHead>
            <TableRow>
              {cols.map((col) => (
                <TableCell
                  key={col.id}
                  align={col.align}
                  style={{
                    minWidth: col.minWidth,
                    fontWeight: "bold",
                    background: "hsla(250, 86%, 97%, 1)",
                  }}
                >
                  {col.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                  {cols.map((col) => {
                    const value = row[col.id];
                    return (
                      <TableCell key={col.id} align={col.align}>
                        {col.id === "id" ? (
                          index + 1
                        ) : col.id === "pos" ? (
                          "-"
                        ) : col.id === "more" ? (
                          <MoreOptions/>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <AddNewForm
        newFormOpen={newFormOpen}
        setNewFormOpen={setNewFormOpen}
        setVendors={setValues}
        vendors={rows}
      />

      <Box sx={{ textAlign: "right", backgroundColor: "#efefef" }}>
        <Button
          variant="contained"
          sx={{
            color: "#fff",
            display: newFormOpen ? "none" : "inline-block",
            m: 1,
            mr: 2,
          }}
          onClick={onAddNewVendor}
        >
          Add New Vendor
        </Button>
      </Box>
    </Paper>
  );
}
