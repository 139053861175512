import React from 'react'
import "../../../styles/home-new.css"

const SectionTemplate = ({ number, title, description, readMoreLink, rightCardContent }) => {
    // const { number, title, description, readMoreLink, rightCardContent } = props


    return (
        <section className={`section-${number}`}>
            <div className="leftcard">
                <div className="leftcard-content">
                    <h1 className="glow-header">
                        {number}
                    </h1>
                    <h2 className='section-title'>{title.split(' ')[0]}
                        <br />
                        <span>{title.split(' ')[1] ?? ""}</span>
                    </h2>
                    <p>
                        {description}
                    </p>
                    <button className="read-more">Read More &#8594;</button>
                </div>
            </div>
            <div className="rightcard">
                <div className="rightcard-content">
                    {rightCardContent}
                </div>
            </div>
        </section >
    )
}

export default SectionTemplate