import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { columns } from "../utils/tableCols";
import { Box } from "@mui/material";

export default function MaterialDBTable({ allData, filter }) {
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    const filterRows = allData.filter((c) =>
      filter.component_nameTab && filter.component_typeTab
        ? filter.component_nameTab.toLowerCase() ===
        c.component_name.toLowerCase() &&
        c.component_type.includes(filter.component_typeTab)
        : filter.component_nameTab
          ? filter.component_nameTab.toLowerCase() ===
          c.component_name.toLowerCase()
          : true
    );
    const result = filterRows.map((m) => {
      if (filter.component_typeTab) m.component_type = filter.component_typeTab;
      m = { ...m, ...m.specs };
      return m;
    });
    setRows(result);
    // console.log({ filterRows });
  }, [filter]);

  // console.log({ rows });
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const specsArray = (m) => {
    const { specs } = m;
    
    const specColsArray = Object.entries(specs).map((e) => {
      // clg
      return {
        id: e[0],
        label: e[0],
        minWidth: 70,
        align: "center",
      };
    });
    return specColsArray;
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 740 }}>
        {rows[0] && (
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns(specsArray(rows[0])).map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      backgroundColor: "hsla(250, 86%, 97%, 1)",
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, i) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                      {columns(specsArray(row)).map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            sx={{ borderRight: "1px solid #ddd" }}
                          >
                            {column.id === "num"
                              ? i + 1
                              : column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        )}
        {!rows[0] && (
          <Box
            sx={{
              fontSize: "20px",
              p: 2,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            No Material exists in this Category.
          </Box>
        )}
      </TableContainer>
      {rows[0] && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={allData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
}
