import React from 'react'

//Drawer imports
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import SaveIcon from '@mui/icons-material/Save';
import HomeIcon from '@mui/icons-material/Home';
// Drawer Header
import { createTheme, styled, useTheme } from '@mui/material/styles';

// import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArchiveIcon from '@mui/icons-material/Archive';
import { Box, Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
const DrawerIcons = [<HomeIcon />, <SaveIcon />, <ArchiveIcon />]

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    backgroundColor: "#eaecee"
}));

const FileDrawer = (props) => {

    const { onSave, openDrawer, setOpenDrawer, toggleDrawer, openRevisionDialog } = props
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    //File menu list
    const list = () => (
        <Box
            sx={{ width: 200 }}
            role="presentation"
            onClick={() => toggleDrawer(false)}
            onKeyDown={() => toggleDrawer(false)}
        >
            <List >
                {/* {['Home', 'New Project', 'Open Project', 'Save'].map((text, index) => ( */}
                {['Home', 'Save', 'Revisions'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton onClick={[, () => onSave(), () => openRevisionDialog()][index]}>
                            <ListItemIcon>
                                {DrawerIcons[index]}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
    return (
        <Drawer
            anchor="left"
            open={openDrawer}
            onClose={() => toggleDrawer(false)}
        >
            <DrawerHeader>
                <Button variant="" startIcon={<ChevronLeftIcon />} onClick={() => setOpenDrawer(false)}>
                    Back
                </Button>
            </DrawerHeader>
            {list()}
        </Drawer>
    )
}

export default FileDrawer