import { Button, Dialog, DialogContent, Typography } from "@mui/material";
import UserList from "./components/UsersList";
import AddUserForm from "./components/AddUserForm";
import { useEffect, useState } from "react";
// import { getAllUsers } from "../../../../../../../api/usersApi";
import { getUsers } from "../api";
import { addUsers } from "../api";

const UserDialog = ({ open, handleClose, type, allUsers, rowId }) => {
  //   const { instance, accounts } = useMsal();
  //   const username = accounts[0]?.name || 'Me';
  const [users, setUsers] = useState([]);
  useEffect(() => {
    console.log("gettting", type);

    getUsers(rowId, type).then((res) => {
      setUsers(res);
    });
  }, [rowId, type]);

  const addUser = (user) => {
    setUsers([...users, user]);
    // console.log('users', users);
  };

  const removeUser = (index) => {
    const updatedUsers = [...users];
    updatedUsers.splice(index, 1);
    setUsers(updatedUsers);
  };
  const saveusers = () => {
    const emails = users.map((u) => u.email);
    console.log("saving users", emails);

    addUsers(rowId, type, emails);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
      <DialogContent>
        <Typography variant="h5" gutterBottom>
          {type === "acc" && "Accountable"}
          {type === "cons" && "Consulted"}
          {type === "inf" && "Informed"}
        </Typography>
        <UserList users={users} removeUser={removeUser} />
        <AddUserForm allUsers={allUsers} addUser={addUser} />
        <Button
          onClick={saveusers}
          sx={{ marginTop: "1rem" }}
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default UserDialog;
