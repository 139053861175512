import { Autocomplete, FormControl, InputLabel, ListSubheader, MenuItem, MenuList, Select, Stack, TextField } from '@mui/material';
import React from 'react'
import { GroupHeader, GroupItems, GroupSubHeader } from '../../../../../styles/muiProps';

const Selection = (props) => {
    const { projects, drawings, equipmentDataRequest, handleEquipmentSelect, handleSheetSelect, materialDataRequest } = props;

    // const groupedData = projects.map((project) => ({
    //     title: project.number,

    //     options: project.equipment.map((equipment) => ({
    //         id: equipment.id,
    //         label: equipment.name,
    //     })),
    // }));

    const expandedProjectData = projects?.map((project) => (
        project.equipment?.map((equipment) => ({
            equipId: equipment.id,
            equipment: equipment.name,
            projectId: project.id,
            project: project.number
        }))
    )).flat()

    const groupedDrawingsData = {};

    // Assuming your data is stored in an array called 'data'
    drawings?.forEach((item) => {
        const { drawing_no, rev_no, sheet_no } = item;

        if (!groupedDrawingsData[drawing_no]) {
            groupedDrawingsData[drawing_no] = {};
        }

        if (!groupedDrawingsData[drawing_no][rev_no]) {
            groupedDrawingsData[drawing_no][rev_no] = [];
        }

        groupedDrawingsData[drawing_no][rev_no].push(sheet_no);
    });

    const orderedDrawingsData = [];

    drawings?.forEach((drawing) => {
        orderedDrawingsData.push({ label: `${drawing.drawing_no}`, group: "drawing" });
        orderedDrawingsData.push({ label: `Rev: ${drawing.rev_no}`, group: "rev" });
        orderedDrawingsData.push({
            label: `Sheet: ${drawing.sheet_no}`,
            group: "sheet",
            value: drawing.id,
        });
    });



    console.log("data: ", expandedProjectData, orderedDrawingsData)

    return (
        <Stack direction={"row"} gap={2}>
            <Autocomplete
                id="grouped"
                name="mainsheet"
                disableCloseOnSelect
                options={expandedProjectData ?? []}
                forcePopupIcon={true}
                defaultMenuIsOpen={true}
                groupBy={(option) => option.project}
                getOptionLabel={(option) => option.equipment}
                // value={equipmentDataRequest?.equip}
                value={expandedProjectData?.find((option) => option.equipId === equipmentDataRequest?.equipId) || null}
                onChange={handleEquipmentSelect}
                // defaultValue=""
                // onChange={(event, newValue) => {
                //     // setValue(newValue);
                //     console.log("evetn:", event, newValue);
                // }}
                // isOptionEqualToValue={(option, value) => {
                //     console.log(option, value, option.equipId === value.equipId)
                //     return option.equipId === value.equipId
                // }}

                sx={{ width: 300 }}
                size={'small'}
                renderInput={(params) => <TextField {...params} label="Equipment" />}
                renderGroup={(params) => (
                    <li key={params.key}>
                        <GroupHeader>{params.group}</GroupHeader>
                        <GroupItems>{params.children}</GroupItems>
                    </li>
                )}
            />

            <FormControl>
                <InputLabel htmlFor="grouped-select">Drawing</InputLabel>
                <Select
                    size="small"
                    id="grouped-select"
                    label="Grouping"
                    sx={{ fontSize: "inherit", width: "200px" }}
                    onChange={handleSheetSelect}
                    // onChange={(e) => { console.log("vals", e) }}
                    value={materialDataRequest.id ?? ""}
                >
                    {
                        orderedDrawingsData.map((elem, index) => {
                            return (elem.group === "drawing" ?
                                (
                                    <GroupHeader key={index} sx={{ fontSize: "13px" }}>
                                        {elem.label}
                                    </GroupHeader>
                                )
                                : elem.group === "rev" ?
                                    (
                                        <GroupSubHeader key={index} sx={{ fontSize: "13px" }}>
                                            {elem.label}
                                        </GroupSubHeader>
                                    )
                                    :
                                    (
                                        <MenuItem key={index} value={elem?.value}>
                                            {elem.label}
                                        </MenuItem>
                                    )
                            )
                        })
                    }
                    {/* {
                        orderedDrawingsData.map((elem) => {
                            console.log(elem)
                            elem.group === "drawing" ?
                                (<GroupHeader key={elem.label} sx={{ fontSize: "13px" }}>{elem.label}</GroupHeader>)
                                : elem.group === "rev" ?
                                    (<GroupHeader key={elem.label} sx={{ fontSize: "13px" }}>{elem.label}</GroupHeader>)
                                    : (<MenuItem key={elem.label} value={elem?.value}>
                                        Sheet: {elem.label}
                                    </MenuItem>)
                        })
                    } */}
                </Select>
            </FormControl>
        </Stack >

    )
}

export default Selection