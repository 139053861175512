import { Box } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import EquipPageSideNav from "./EquipPageSideNav";
import DataPanel from "./DataPanel";

export default function EquipmentPage() {
  const { state } = useLocation();

  const [tab, setTab] = React.useState(2); ///value of tab

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{ display: "inline-block" }}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </div>
    );
  }

  const EquipmentPanelsComponentsArray = [<DataPanel />];

  return (
    <Box sx={{ ml: 2, mt: 1 }}>
      <EquipPageSideNav tab={tab} setTab={setTab} state={state} />
      {/* ---------------------- panels-------------- */}
      {EquipmentPanelsComponentsArray.map((component, index) => {
        return (
          <TabPanel value={tab} index={index + 2} key={index}>
            {component}
          </TabPanel>
        );
      })}
    </Box>
  );
}
