import { mainMechTabs } from "./mechCategories";
import { mainPipingTabs } from "./pipingCategories";

export const getCurrentEquipTabs = (eng_category) => {
  switch (eng_category) {
    case 1:
      return mainMechTabs();
    case 2:
      return mainPipingTabs();
    default:
      return ["TODO"];
  }
};
