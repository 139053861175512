import { Controller, useFormContext } from "react-hook-form";

import TextField from "@mui/material/TextField";
import pairFields from "../../pages/client/AllEquipments/PressureVessel/PressureVesselData/pairFields";

const fontStyle = {
  style: { fontSize: "0.700rem", height: "18px" },
};

function InputBox(props) {
  const {
    required,
    id,
    label,
    name,
    textfieldProps,
    inputProps,
    type,
    hiddenLabel,
    pairField,
    disabled,
    fullWidth,
    defValue
  } = props;

  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  // console.log({ name }) //eg: id = txtVIDIA

  const setPairFieldsValues = (value, id) => {
    console.log("PairId: ", id);
    pairFields.forEach((field) => {
      if (field.pairs.includes(id)) {
        field.pairs.forEach((pair) => {
          //If the pair elements are among a datagrid then loop and setValues.
          if (pair.includes("DataGridView5")) {
            let datagrid = "DataGridView5";
            let fields = getValues("DataGridView5");
            //for change in vessel length (S-S), change Can length if component is a shell
            if (pair.includes("DataGridView5_Can")) {
              fields.forEach((field, index) => {
                console.log(`${datagrid}.${index}.Can Length`);
                if (getValues(`${datagrid}.${index}.Component`).includes("Shell"))
                  setValue(`${datagrid}.${index}.Can Length`, value);
              });
            }
            //for change in parifields of Diametere and provided thickness, set all rows to the same value
            else {
              let column = pair.includes("DataGridView5_Dia")
                ? "Diameter"
                : "Provided Thickness";
              fields.forEach((field, index) => {
                console.log(`${datagrid}.${index}.${column}`);
                setValue(`${datagrid}.${index}.${column}`, value);
              });
            }
          }
          //if field is part of pairfields, set value of the others to the same value
          else {
            console.log("settig", pair, value);
            //check if pairfields are registered, if not register and then setValue. setValue cant be used on unregistered names.
            const isRegistered = Object.keys(errors).some(
              (error) => error === pair
            );
            if (!isRegistered) {
              register(pair);
            }
            setValue(pair, value);
          }
          console.log(getValues());
        });
        // return
      }
    });
  };

  // if (pairField)
  //     console.log("values gotten: ", name, ":", pairField ? getValues(pairField) : "")
  // console.log("errors:",errors[name])
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required || false }}
      defaultValue={defValue !== '' ? defValue : ""}
      render={({ field }) => {
        return (
          <TextField
            {...field}
            variant="outlined"
            disabled={disabled || false}
            type={type || "string"}
            InputProps={{ ...fontStyle, ...inputProps }}
            InputLabelProps={{ shrink: true }}
            id={id}
            label={label}
            size="small"
            margin="none"
            required={required || false}
            defaultValue={defValue ? defValue : ""}
            // {...designInputs(name, required ? { required: `${label} is required.` } : {})}
            onChange={(e) => {
              field.onChange(e.target.value);
              if (pairField) {
                console.log("PariFIEdl found");
                setPairFieldsValues(e.target.value, id);
                // setValue(pairField, e.target.value)
              }
            }}
            // value={field.value}
            error={Boolean(errors[name])}
            helperText={errors[name]?.message}
            name={name}
            hiddenLabel={hiddenLabel || false}
            fullWidth={fullWidth || true}
          />
        );
      }}
    />
  );
}

export default InputBox;
