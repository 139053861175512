import { MaterialReactTable } from 'material-react-table'
import React from 'react'
import { table4d5dColumns } from './columns'
import { scheduleData } from './dummyData'
import { Box, Button, MenuItem, Stack } from '@mui/material'
import TimelineComponent from './TimelineComponent'
import { useParams } from 'react-router-dom'
// import { getEquip4d5d } from '../../PMSTab/4d5d/api'
// import Project4d5d from '../../PMSTab/4d5d'
import { getSchedule, getTrackItem, updateSchedule } from './api'
import { tableWithBorderedCells } from '../../../../../../styles/muiProps'
import TrackItemDialog from './TrackItemDialog'
// import Gantt from 'frappe-gantt'

const Project4d = () => {

  const { equipId } = useParams()
  const [rows, setRows] = React.useState([]);
  const [trackItemDialog, setTrackItemDialog] = React.useState(dialogInitalState);

  const handleSaveCell = (cell, value) => {
    console.log(cell, value,)
    let id = cell.row.original.id
    let column = cell.column.id
    const updatedRows = rows?.map((row) =>
      row.id === id ? { ...row, [column]: value } : row
    );
    setRows(updatedRows);
    // reRender()
  }

  const onSave = async () => {
    updateSchedule({ scheduleTasks: rows })
  }

  const onTrackItem = async (row) => {
    console.log(row)
    const result = await getTrackItem(row.original.id)
      .then(res => setTrackItemDialog(prev => ({ ...prev, open: true, item: row.original, data: res })))
  }

  const onClose = async () => {
    setTrackItemDialog(dialogInitalState)
  }

  React.useEffect(() => {
    const func = async () => {
      const data = await getSchedule(equipId);
      setRows(data);
    };
    func();
  }, []);

  return (
    <Box p={2}>
      <Stack
        sx={{ paddingBottom: '2rem' }}
        direction={'row'}
        justifyContent="space-between"
        height={'3rem'}
        alignItems="center"
      >
        <h2>Schedule</h2>
      </Stack>
      <Stack direction={"row"}>
        <Box width={"44vw"}>
          {/* <Project4d5d /> */}
          <MaterialReactTable
            columns={table4d5dColumns}
            data={rows}
            enablePagination={false}
            enableEditing={true}
            enableColumnActions={false}
            enableSorting={false}
            editDisplayMode='cell'
            enableBottomToolbar={false}
            enableRowActions={true}
            positionActionsColumn='last'
            {...tableWithBorderedCells}
            renderRowActionMenuItems={({ row }) => [
              <MenuItem key="edit" onClick={() => onTrackItem(row)}>
                Track Item
              </MenuItem>
            ]}
            // muiTableContainerProps={{ sx: { maxHeight: '70vh' } }}
            muiEditTextFieldProps={({ cell }) => ({
              onBlur: (event) => {
                handleSaveCell(cell, event.target.value);
              },
            })}
            renderTopToolbarCustomActions={(table) =>
              <Button
                variant="contained"
                sx={{ alignSelf: "end", width: "fit-content" }}
                onClick={onSave}
              >
                Save
              </Button>
            }
            initialState={{ columnPinning: { left: ["name"] } }}
          />
        </Box>
        <Box width={"40vw"}>
          <TimelineComponent equipId={equipId} rows={rows} />
        </Box>
      </Stack>
      <TrackItemDialog
        open={trackItemDialog.open}
        onClose={onClose}
        item={trackItemDialog.item}
        data={trackItemDialog.data}
      />
    </Box>

  )
}
export default Project4d

const dialogInitalState = {
  open: false,
  data: [],
  item: {}
}
