import { Box, Paper, Typography } from "@mui/material";
import React from "react";
import EquipmentsPage from "../ProjectNestedPages/EquipmentsPage";
import MaterialsDBPage from "../../../MaterialsDBPage";

export default function ProjectPageMainArea({ project, tab }) {
  const nestedPage = (tab) => {
    switch (tab) {
      case "equipments":
        return <EquipmentsPage project={project} />;

      case "materials":
        return (
          // <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          //   TODO: inject the Material database UI here
          // </Typography>
          <MaterialsDBPage project={project} drawersNum={2} />
        );
      // case "4d5d":
      //   return <Project4d5d project={project} />;

      default:
        return (
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Wrong Page, Choose another Field
          </Typography>
        );
    }
  };
  return (
    <Box sx={{ padding: 0 }}>
      {/* <Typography variant="h5" sx={{ fontWeight: "bold", paddingBottom: 2 }}>
        Projects {tab} :
      </Typography> */}
      {nestedPage(tab)}
      {/* <EquipmentsPage project={project} /> */}
    </Box>
  );
}
