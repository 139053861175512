import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const UserList = ({ users, removeUser }) => {
  return (
    users && (
      <List>
        {users.map((user, index) => (
          <ListItem key={index}>
            <ListItemText primary={user.name} />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={() => removeUser(index)}>
                <Delete />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    )
  );
};

export default UserList;
