const { secondAxios } = require("../../../../api/axios");

const verifyVendorRoute = "/mrq/verify-vendor/";
const addQuotationDataRoute = "/bid";

export const verifyVendor = async (token) => {
  const { data } = await secondAxios.get(verifyVendorRoute + token);
  return data;
};

export const addQuotationData = async ({ vendorData }) => {
  const { data } = await secondAxios.post(addQuotationDataRoute, vendorData);
  return data;
};
