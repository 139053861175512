import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  CircularProgress,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AutodeskViewer from "../../../PMSTab/AutodeskViewer";
// import {
//   initViewer,
//   listModels,
//   loadModel,
// } from "https://aps-codepen.autodesk.io/utils.js";
import { initViewer, loadModel } from "../../../PMSTab/AutodeskViewer/viewer";

const ForgeViewerModal = ({ open, onClose, urn }) => {
  useEffect(() => {
    const viewer = initViewer(document.getElementById("viewer"), {
      extensions: ["Autodesk.DocumentBrowser"],
    });
    loadModel(viewer, urn);
  }, []);
  // const models = await listModels();
  // const dropdown = document.getElementById('models');
  // dropdown.innerHTML = models.map(m => `<option value="${m.urn}">${m.name}</option>`).join('');
  // dropdown.onchange = () => dropdown.value && loadModel(viewer, dropdown.value);

  // dropdown.onchange();

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="forge-viewer-modal-title"
      aria-describedby="forge-viewer-modal-content"
    >
      <Box sx={{ width: "75%", bgcolor: "background.paper", borderRadius: 2 }}>
        <ModalHeader onClose={onClose}>
          <Typography
            alignSelf={"center"}
            variant="h5"
            id="forge-viewer-modal-title"
          >
            Viewer
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent>
          {/* <div id="viewer"></div> */}
          <AutodeskViewer prepUrn={urn} />
        </ModalContent>
      </Box>
    </Modal>
  );
};

const ModalHeader = ({ children, onClose }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "16px",
      borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
    }}
  >
    {children}
  </Box>
);

const ModalContent = ({ children }) => (
  <Box sx={{ padding: "16px" }}>{children}</Box>
);

export default ForgeViewerModal;
