import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Box, List, ListSubheader, Tooltip } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { StyleContext } from "../../../context/styleContext";

export default function SideNavList({ listContent, bottom }) {
  const { sideNavOpen } = useContext(StyleContext);

  const bottomWidth = sideNavOpen ? "240px" : "65px";
  const header =
    listContent.header && sideNavOpen ? (
      <ListSubheader component="div" id="nested-list-subheader">
        {listContent.header}
      </ListSubheader>
    ) : (
      <></>
    );

  return (
    <>
      {bottom ? (
        <Box sx={{ position: "fixed", bottom: 0, left: 0, width: bottomWidth }}>
          <List
            subheader={header}
            sx={{
              padding: 0
            }}
          >
            <ListContent listContent={listContent} />
          </List>
        </Box>
      ) : (
        <List subheader={header} sx={{ padding: 0 }}>
          <ListContent listContent={listContent} />
        </List>
      )}
    </>
  );
}

const ListContent = ({ listContent }) => {
  const { sideNavOpen } = useContext(StyleContext);
  const navigate = useNavigate();

  const navigateTo = (path) => {
    path && navigate(path);
  };

  return (
    <>
      {listContent.array.map(({ label, icon, path }) => (
        <ListItem key={label} disablePadding sx={{ display: "block" }}>
          <Tooltip title={sideNavOpen ? "" : label} placement="right">
            <a style={{ "text-decoration": "none", "color":"black" }}>
              <ListItemButton
                sx={{
                  // minHeight: 48,
                  justifyContent: sideNavOpen ? "initial" : "center",
                  px: 2.5,
                }}
                onClick={() => {
                  navigateTo(path);
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: sideNavOpen ? 3 : "auto",
                    justifyContent: "center",
                    '& .MuiSvgIcon-root': {
                      fontSize: "1.5rem",
                    }
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    fontSize: "0.875rem",
                  }}
                  sx={{ opacity: sideNavOpen ? 1 : 0 }}
                />
              </ListItemButton>
            </a>
          </Tooltip>
        </ListItem >
      ))}
    </>
  );
};
