import { React, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import sideImg from '../../images/auth_form_eng.jpg'
import axios from "../../api/axios"
import {StaffConfirmMailRoute} from "../../routes"

const Register = () => {
  const [input, setInput] = useState({
    email: "",
    username: "",
    // password: "",
    // confirm: "",
    role: "staff"
  })

  const [error, setError] = useState("")

  const navigate = useNavigate()

  //Handle Changes in form
  function changeHandler(event) {
    const { name, value } = event.target
    setInput(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  //Handle submit of form
  const submitHandler = async (event) => {
    event.preventDefault()
    const email_format = new RegExp(".*@enerfacprojects.com")
    // //console.log(event.target.form)
    if (!input.email)
      setError("Enter your Email!")
    else if (!email_format.test(input.email)) {
      // console.log("Test failed")
      setError("Enter a Valid Email Address")
    }
    // else if (!input.password)
    //   setError("Enter your Password!")
    // else if (input.password.length < 8) {
    //   setError("Password should be atleast 8 characters long!")
    // }
    // else if (input.confirm !== input.password)
    //   setError("Passwords do not match!")
    else {
      setError("")
      try {
        await axios.post(StaffConfirmMailRoute, input)
        setError("Mail Sent Successfully!")
        // navigate("/dev/login")
      } catch (err) {
        //console.log("ERror: ", err)
        setError(err.response.data.error)
        // //console.log(err)
      }
    }

  }

  return (
    <div className="auth-container ">
      <img src={sideImg}></img>
      <div className="auth register">
        <h1>Register</h1>
        <form>
          <p>{error}</p>
          <input required type="email"
            name="email"
            value={input.email}
            placeholder="Email"
            onChange={changeHandler} />
          <input required type="text"
            name="username"
            value={input.username}
            placeholder="Username"
            onChange={changeHandler} />
          {/* <input required type="password"
            name="password"
            placeholder="Password"
            onChange={changeHandler} />
          <input required type="password"
            name="confirm"
            placeholder="Confirm password"
            onChange={changeHandler} /> */}
          <label htmlFor='role'>User Role</label>
          <select id = "role" value = {input.role} onChange={changeHandler} name = "role">
              <option value = "staff">Regular Staff</option>
              <option value = "admin">Admin</option>
              <option value = "owner">Owner</option>
            </select> 
          <button onClick={submitHandler}>Register</button>
        </form>
      </div>
    </div>
  )
}

export default Register