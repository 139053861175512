import { mechCategories } from "./mechCategories";
import { pipingCategories } from "./pipingCategories";

export const getCurrentEquipComponents = (eng_category) => {
  switch (eng_category) {
    case 1:
      return mechCategories;
    case 2:
      return pipingCategories;
    default:
      return ["TODO"];
  }
};
