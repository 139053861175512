import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 450,
    },
  },
};

export default function MultiSelectCell({ label, choices, initialState }) {
  // initialState = [array]

  const [selectedItems, setSelectedItems] = React.useState([]);

  React.useEffect(() => {
    initialState && initialState[0] && setSelectedItems(initialState);
  }, []);

  const handleChange = (event) => {
    console.log({ first: event.target.value });
    const {
      target: { value },
    } = event;
    setSelectedItems(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <FormControl
      variant="standard"
      sx={{
        width: "100%",
        transform: "translateY(3px)",
      }}
    >
      <Select
        multiple
        value={selectedItems}
        onChange={handleChange}
        renderValue={(selected) => {
          let val = selected.sort((a, b) => a - b).join(", ");
          return val;
        }}
        MenuProps={MenuProps}
        sx={{ "& .MuiSelect-select ": { pl: 1, textAlign: "center" } }}
      >
        {choices.map((note, i) => (
          <MenuItem key={note} value={i + 1}>
            <Checkbox checked={selectedItems.indexOf(i + 1) > -1} />
            <ListItemText primary={i + 1 + "- " + note} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
