import React from "react";
import { masterMaterialsColumns } from "../../MCSTables";
import { MaterialReactTable } from "material-react-table";
import { getMaterialMasterData } from "./api";

const MasterMaterialTab = (props) => {
  const { poList, poData } = props;

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    const func = async () => {
      const data = await getMaterialMasterData();
      console.log(data);
      setData(data);
    };
    func();
  }, []);
  return (
    <div>
      <MaterialReactTable
        columns={masterMaterialsColumns}
        enableGrouping
        enableColumnDragging={false}
        // initialState={{ grouping: ["materialCode"] }}
        data={data ?? []}
        defaultColumn={{ minSize: 40 }}
      />
    </div>
  );
};

export default MasterMaterialTab;
