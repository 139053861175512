import { IconButton, Stack, Tooltip } from "@mui/material";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import TaskOutlinedIcon from "@mui/icons-material/Task";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import PreviewIcon from "@mui/icons-material/Preview";
import SummarizeIcon from "@mui/icons-material/Summarize";

import EventNoteIcon from "@mui/icons-material/EventNote";
import { TypesIcon } from "../../../../../components/CustomIcons/EquipsTypesIcons";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";

export const columns = (onClick, urnLoaded) => [
  {
    accessorKey: "name",
    header: "Project No.",
    // size:true
    grow: true,
  },
  {
    accessorKey: "members",
    header: "Members",
    enableSorting: false,
    enableColumnActions: false,
  },
  {
    accessorKey: "project",
    header: "Equipment",
    size: 90,
    grow: false,
    Cell: ({ cell }) => {
      return (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={0}
        >
          {cell.getValue()?.map((equip) => {
            return (
              <Tooltip key={equip.id} title={equip.name} placement="top">
                <TypesIcon type={equip.type} />
              </Tooltip>
            );
          })}
        </Stack>
      );
    },
  },
  {
    accessorKey: "dcs",
    header: "Docs",
    enableSorting: false,
    enableColumnActions: false,
    // muiTableHeadCellProps: { align: "center" },
    Cell: ({ cell, row }) => {
      console.log(row.original, row.original.projects?.[0]?.id);
      return (
        <IconButton
          onClick={() =>
            onClick(
              row.original.id,
              row.original.projects?.[0],
              "#main-nav=DCS"
            )
          }
        >
          <FolderOpenIcon />
        </IconButton>
      );
    },
    grow: false,
    enableEditing: false,
    size: 60,
  },
  {
    accessorKey: "task",
    header: "Tasks",
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      return (
        <IconButton
          onClick={() =>
            onClick(
              row.original.id,
              row.original.projects?.[0],
              "#main-nav=PCS&pcs-tab=Task%20List"
            )
          }
        >
          <TaskOutlinedIcon />
        </IconButton>
      );
    },
    grow: false,
    enableEditing: false,
    size: 60,
  },
  {
    accessorKey: "raci",
    header: "2D",
    // muiTableHeadCellProps: { align: "center" },
    enableSorting: false,
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      return (
        <IconButton
          onClick={() =>
            onClick(
              row.original.id,
              row.original.projects?.[0],
              "#main-nav=DCS&dcs-tab=RACI"
            )
          }
        >
          <AssignmentIndIcon />
        </IconButton>
      );
    },
    grow: false,
    enableEditing: false,
    size: 60,
  },
  {
    accessorKey: "drawing",
    header: "BOM status",
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
    enableColumnActions: false,
    Header: (
      <div>
        <div>BOM</div>
        <div>Status</div>
      </div>
    ),
    Cell: ({ cell, row }) => {
      return (
        <IconButton
          onClick={() =>
            urnLoaded &&
            onClick(
              row.original.id,
              row.original.projects?.[0],
              "#main-nav=MCS&mcs-tab=BOM%20status"
            )
          }
        >
          <SummarizeIcon />
        </IconButton>
      );
    },
    grow: false,
    enableEditing: false,
    size: 60,
  },
  {
    accessorKey: "mrq",
    header: "MRQ",
    // muiTableHeadCellProps: { align: "center" },
    enableSorting: false,
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      return (
        <IconButton
          onClick={() =>
            onClick(
              row.original.id,
              row.original.projects?.[0],
              "#main-nav=MCS&mcs-tab=MRQ"
            )
          }
        >
          <RequestQuoteIcon />
        </IconButton>
      );
    },
    grow: false,
    enableEditing: false,
    size: 60,
  },
  {
    accessorKey: "preview",
    header: "3D",
    // muiTableHeadCellProps: { align: "center" },
    enableSorting: false,
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      return (
        <IconButton
          onClick={() =>
            onClick(row.original.id, row.original.projects?.[0], "preview")
          }
        >
          {urnLoaded ? <PreviewIcon /> : <HourglassBottomIcon />}
        </IconButton>
      );
    },
    grow: false,
    enableEditing: false,
    size: 60,
  },
  {
    accessorKey: "4d",
    header: "4D",
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      return (
        <IconButton
          onClick={() =>
            onClick(
              row.original.id,
              row.original.projects?.[0],
              "#main-nav=PMS&pms-tab=4D"
            )
          }
        >
          <EventNoteIcon />
        </IconButton>
      );
    },
    grow: false,
    enableEditing: false,
    size: 60,
  },
  {
    accessorKey: "5d",
    header: "5D",
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
    enableColumnActions: false,
    Cell: ({ cell, row }) => {
      return (
        <IconButton
          onClick={() =>
            onClick(
              row.original.id,
              row.original.projects?.[0],
              "#main-nav=PMS&pms-tab=5D"
            )
          }
        >
          <MonetizationOnIcon />
        </IconButton>
      );
    },
    grow: false,
    enableEditing: false,
    size: 60,
  },
];
