import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { AuthContextProvider } from "./context/authContext";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./utils/msal";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <MsalProvider instance={msalInstance}>
          <App />
        </MsalProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
