import React from "react";

import Tab from "@mui/material/Tab";
import { Tabs } from "@mui/material";

export default function HorizontalTabs({ name, tabs, value, setValue }) {
  const handleChange = (event, newValue) => {
    setValue({ ...value, [name]: newValue });
  };

  return (
    <Tabs
      value={value[name] > tabs.length ? 0 : value[name]}
      onChange={handleChange}
      aria-label="wrapped label tabs example"
    >
      {tabs.map((tab, i) => {
        return <Tab key={`${name}-${i}`} wrapped label={tab} value={i} />;
      })}
    </Tabs>
  );
}
