import React from 'react'
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import TimesheetFormDialog from '../../components/Dialogs/TimesheetFormDialog';
import DeleteDialog from '../../components/Dialogs/DeleteDialog';

// function createData(date, task, project, hours, progress, remarks) {
//     return {
//         date, task, project, hours, progress, remarks
//     };
// }

// const rows = [
//     createData('26/12/2022', " Scope of the work", "Project Name", 7, 14, "None"),
//     createData('27/12/2022', " Scope of the work", "Project Name", 1, 14, "None"),
//     createData('28/12/2022', " Scope of the work", "Project Name", 4, 16, "None"),
//     createData('29/12/2022', " Scope of the work", "Project Name", 4, 14, "None"),
//     createData('02/12/2022', " Scope of the work", "Project Name", 9, 13, "None"),
//     createData('04/12/2022', " Scope of the work", "Project Name", 7, 16, "ZEro"),
//     createData('26/12/2022', " Scope of the work", "Project Name", 7, 14, "None"),
//     createData('26/12/2022', " Scope of the work", "Project Name", 4, 10, "None"),
//     createData('26/12/2022', " Scope of the work", "Project Name", 5, 17, "None"),
//     createData('26/12/2022', " Scope of the work", "Project Name", 8, 10, "None"),
//     createData('26/12/2022', " Scope of the work", "Project Name", 1, 12, "None"),
//     createData('26/12/2022', " Scope of the work", "Project Name", 5, 37, "None"),
//     createData('26/12/2022', " Scope of the work", "Project Name", 3, 40, "None"),
// ];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    //console.log("concoslign:", order, orderBy)
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't


const headCells = [
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'task',
        numeric: false,
        disablePadding: false,
        label: 'Task ',
    },
    {
        id: 'project',
        numeric: false,
        disablePadding: false,
        label: 'Project  ',
    },
    {
        id: 'hours',
        numeric: true,
        disablePadding: false,
        label: 'Hours',
    },
    {
        id: 'progress',
        numeric: true,
        disablePadding: false,
        label: 'Progress (%)',
    },
    {
        id: 'remarks',
        numeric: false,
        disablePadding: false,
        label: 'Remarks',
    },
    {
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Actions',
    },
];

function EnhancedTableHead(props) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">

                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell sx={{ ...HeaderFont }}
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    // numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
    return (
        <Toolbar
            sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
            }}
        >
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                Timesheets
            </Typography>

            <Tooltip title="Filter list">
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Tooltip>
        </Toolbar>
    );
}

const HeaderFont = {
    fontSize: 12
}

const RowFont = {
    fontSize: 11.5
}

const MyTimesheets = (props) => {
    const { timesheets, editTimesheetMutation, deleteTimesheetMutation, geteditTaskProgress } = props
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('project');
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    //edit timesheet form
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState("")
    const [dialogValues, setDialogValues] = React.useState({
        title: "",
        progress: 0,
        prevprogress: 0
    })
    const initialData = {
        date: "",
        progress: 0,
        hours: 0,
        remarks: "",
        timesheetId: 0
    }
    const [timesheetForm, setTimesheetForm] = React.useState(initialData)

    //Confirm Delete Alert
    const [openAlert, setOpenAlert] = React.useState(false)
    const [closeAlert, setCloseAlert] = React.useState(false)
    const [deleteId, setDeleteId] = React.useState(0)

    const formatTimesheets = timesheets?.map(timesheet => ({
        id: timesheet.id,
        date: timesheet.date,
        task: timesheet.UserTask.subCategory.name,
        project: timesheet.UserTask.subCategory?.category?.mcsProject?.number,
        subCategoryId: timesheet.UserTask.subCategoryId,
        hours: timesheet.hours,
        progress: timesheet.progress,
        remarks: timesheet.remarks
    }))

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    //For the edit form
    const handleClickOpen = (e, timesheetId, date, progress, hours, remarks, title, subCategoryId) => {
        let totalProgress = geteditTaskProgress(subCategoryId)
        // console.log("Total Progress: " + totalProgress, subCategoryId)
        setTimesheetForm({
            date: ((date + "").split("T")[0]),
            progress: progress,
            hours: hours,
            remarks: remarks,
            timesheetId: timesheetId
        })
        setDialogValues({
            title: title,
            progress: totalProgress,
            prevprogress: progress
        })
        setOpen(true);
    };

    // console.log("Form", timesheetForm)
    const handleClose = () => {
        setTimesheetForm(initialData)
        setError("")
        setOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target
        setTimesheetForm({
            ...timesheetForm,
            [name]: value
        })
    }

    const handleSliderChange = (event, newValue) => {
        setTimesheetForm({
            ...timesheetForm,
            progress: newValue
        })
    };

    const submitHandler = () => {
        if (!timesheetForm.date || !timesheetForm.hours || !timesheetForm.progress || !timesheetForm.remarks)
            setError("Please fill in all fields")
        else if (!timesheetForm.timesheetId)
            setError("Failed to fetch user task")
        else {
            editTimesheetMutation.mutate(timesheetForm)
            handleClose()
        }
    }

    const nonTaskSubmitHandler = () => {
        if (!timesheetForm.date)
            setError("Please fill in the date")
        else if (!timesheetForm.timesheetId)
            setError("Failed to fetch user task")
        else {
            editTimesheetMutation.mutate(timesheetForm)
            handleClose()
        }
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - formatTimesheets?.length) : 0;
    // const editTimesheet = () => {
    //     editTimesheetMutation.mutate(timesheetForm)
    // }

    const deleteTimesheet = (timesheetId) => {
        deleteTimesheetMutation.mutate(timesheetId)
    }

    const handleDelete = (timesheetId) => {
        setDeleteId(timesheetId)
        setOpenAlert(true)

        // deleteTimesheet(timesheetId)
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2 }}>
                <EnhancedTableToolbar />
                <TableContainer>
                    <Table
                        sx={{ minWidth: 750 }}
                        aria-labelledby="tableTitle"
                        size={dense ? 'small' : 'medium'}
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={formatTimesheets?.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.sort(getComparator(order, orderBy)).slice() */}
                            {formatTimesheets?.sort(getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((timesheet, index) => {
                                    const labelId = `enhanced-table-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={timesheet.id}
                                        >
                                            <TableCell padding="checkbox">

                                            </TableCell>
                                            <TableCell
                                                sx={{ ...RowFont }}
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {(timesheet.date + "").split("T")[0]}
                                            </TableCell>
                                            {/* <TableCell sx={{...RowFont}} >{timesheet.remarks}</TableCell> */}
                                            <TableCell sx={{ ...RowFont }} >{timesheet.task}</TableCell>
                                            <TableCell sx={{ ...RowFont, color: !timesheet.project ? "grey" : "black" }} >{timesheet.project || "Project/Category removed"}</TableCell>
                                            <TableCell sx={{ ...RowFont }} align="right">{timesheet.hours}</TableCell>
                                            <TableCell sx={{ ...RowFont }} align="right">{timesheet.progress}</TableCell>
                                            <TableCell sx={{ ...RowFont }} >{timesheet.remarks}</TableCell>
                                            <TableCell sx={{ ...RowFont }} >
                                                <IconButton
                                                    aria-label="edit"
                                                    disabled={!timesheet.project ? true : false}
                                                    onClick={e =>
                                                        handleClickOpen(e, timesheet.id, timesheet.date, timesheet.progress, timesheet.hours, timesheet.remarks, timesheet.project, timesheet.subCategoryId)
                                                    }
                                                >
                                                    <EditIcon sx={{ color: "red" }} />
                                                </IconButton>
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleDelete(timesheet.id)}
                                                >
                                                    <DeleteIcon sx={{ color: "red" }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={formatTimesheets?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <TimesheetFormDialog
                    open={open}
                    handleClose={handleClose}
                    error={error}
                    timesheetData={timesheetForm}
                    handleChange={handleChange}
                    handleSliderChange={handleSliderChange}
                    submitHandler={submitHandler}
                    dialogTitle={dialogValues}
                    // nonTaskId = {nonTasks}
                    nonTaskSubmitHandler={nonTaskSubmitHandler}
                    edit={true}
                >
                </TimesheetFormDialog>
                <DeleteDialog
                    open={openAlert}
                    handleClose={() => setOpenAlert(false)}
                    deleteTimesheet={() => deleteTimesheet(deleteId)}
                />
            </Paper>
            {/* <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        /> */}
        </Box>
    );
}

export default MyTimesheets