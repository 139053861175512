import React from "react";
import VendorMrqForm from "./VendorMrqForm";
import { Box } from "@mui/material";

export default function VendorMrqs() {
  return (
    <Box sx={{ m: 2 }}>
      <VendorMrqForm />
    </Box>
  );
}
