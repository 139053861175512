import { secondAxios } from "../../../../../../api/axios";

export const getProjectRACI = async (projectId) => {
  const response = (await secondAxios.get(`/raci/project/${projectId}`)).data;
  console.log("raci", response);
  return response;
};

// export const getRACI = async () => {
//   const raciData = await secondAxios.get("/raci");
//   return raciData;
// };
export const updateRaci = async (id, updatedRaci) => {
  // console.log("updated", updatedRaci);
  const updateRaciDto = {
    document: updatedRaci.document,
    revNum: updatedRaci.revNum,
    docLink: updatedRaci.docLink,
    responsible: updatedRaci.responsible,
  };
  await secondAxios.patch(`/raci/${id}`, updateRaciDto);
};

export const createRaci = async (projectId, newRaci) => {
  console.log("new raci", newRaci);
  const resp = await secondAxios.post(`/raci/${projectId}`, newRaci);
};

export const deleteRaci = async (id) => {
  try {
    await secondAxios.delete(`/raci/:${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const addUsers = async (id, type, usersEmails) => {
  const resp = await secondAxios.post(
    `raci/add-users/${id}/${type}`,
    usersEmails
  );
};

export const getUsers = async (id, type) => {
  const resp = await secondAxios.get(`/raci/get-users/${id}/${type}`);
  return resp.data;
};
