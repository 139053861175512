import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";

import { StyleContext } from "../../../context/styleContext";
import SideNavHeader from "./SideNavHeader";
import SideNavList from "./SideNavList";
import {
  SharePointSideNavList,
  addNewProjectSideNavList,
  bottomSideNavList,
  myCompanyList,
  vendorList,
} from "./ContentArrays";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.enteringScreen,
  }),
  border: "none",
  "& .css-1r9jet7": {
    minHeight: "50px"
  },
  backgroundColor: "hsla(250, 86%, 97%, 1)",
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    duration: theme.transitions.duration.leavingScreen,
  }),
  border: "none",
  "& .css-1r9jet7": {
    minHeight: "50px"
  },
  backgroundColor: "hsla(250, 86%, 97%, 1)",
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),

  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
export default function SideNav() {
  const { sideNavOpen } = useContext(StyleContext);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={sideNavOpen}>
        <SideNavHeader />
        <Divider />
        <Divider />
        <SideNavList listContent={addNewProjectSideNavList} />
        <Divider />
        <SideNavList listContent={myCompanyList} />
        <Divider />
        <SideNavList listContent={vendorList} />
        <Divider />
      </Drawer>
        {/* <SideNavList listContent={SharePointSideNavList} /> */}
        {/* <SideNavList listContent={bottomSideNavList} bottom /> */}
    </Box>
  );
}
