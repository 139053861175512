import React from "react";
import pv_icon from "../../images/projects_icons/pv_icon2.png";
import tank_icon from "../../images/projects_icons/tank_icon.png";
import structural_icon from "../../images/projects_icons/structural_icon.webp";
import instrumentation_icon from "../../images/projects_icons/instrumentation_icon.png";
import piping_icon from "../../images/projects_icons/piping_icon.webp";
import skid_icon from "../../images/projects_icons/skid_icon.jpg";
import add_icon from "../../images/projects_icons/add_icon.webp";
import { Box } from "@mui/material";

export const equipTypesIcons = [
  { category: "Pressure Vessel", icon: pv_icon, link: "main_pv" },
  { category: "Tank", icon: tank_icon, link: "main_tank" },
  { category: "Piping", icon: piping_icon, link: "piping" },
  { category: "Structural", icon: structural_icon, link: "structural" },
  {
    category: "Instrumentation",
    icon: instrumentation_icon,
    link: "instrumentation",
  },
  { category: "Skid Package", icon: skid_icon, link: "skid-package" },
  { category: "Other", icon: add_icon, link: "" },
];

export const getIcon = (type) => {
  const icon = equipTypesIcons.find((item) => item.category === type);
  return icon ? icon.icon : "";
};

export const getLink = (type) => {
  const icon = equipTypesIcons.find((item) => item.category === type);
  return icon ? icon.link : "";
};

export function TypesIcon({ type }) {
  return (
    <Box
      sx={{
        display: "inline-block",
        marginRight: 1,
        transform: "translateY(4px)",
      }}
    >
      <img src={getIcon(type)} alt={type} style={{ maxWidth: 20 }} />
    </Box>
  );
}
