import React from 'react'

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CustomTextField from '../../../../../../components/widgets/CustomTextField';
import { Typography } from '@mui/material';
import { projectDetails } from './FieldsData/DesignTabFields';
import { GridStyle, designTabBoxProps } from '../../../../../../styles/muiProps';

const NamesGrid = () => {
    return (
        <Grid xs={2} sm={2.6} md={3} lg={3} xl={2.6} >
            <Box
                {...designTabBoxProps}
                ml={1} mt={1}
                noValidate
            >
                {
                    projectDetails.map(elem => (
                        <Grid id="inner-container" item container
                            columns={12}
                        >
                            <Grid item sx={{ ...GridStyle }} xs={6}>
                                <Typography fontSize={"0.75rem"} >{elem.label}</Typography>
                            </Grid>
                            < Grid item sx={{ ...GridStyle }} xs={6}>
                                <CustomTextField label="" id={elem.name} name={elem.name} />
                            </Grid>
                        </Grid>
                    )
                    )
                }
            </Box>
        </Grid>
    )
}

export default NamesGrid