export const onClickCell = ({
  row,
  col,
  BidTableRows,
  setBidTableRows,
  mrqData,
  selectedBidVendors,
}) => {
  console.log({ selectedBidVendors });
  if (col.id === row.selected?.name) {
    setBidTableRows(
      BidTableRows.map((r) => {
        if (row.mrqMaterialId === r.mrqMaterialId) {
          return { ...r, selected: {}, po: "", vendorId: "" };
        }
        return r;
      })
    );
  } else {
    if (selectedBidVendors.includes(col.id)) {
      let selected = {
        name: col.id,
        time: row[col.id].time,
        cost: row[col.id].cost,
      };

      let po = `PO-${mrqData.mrqNumber}-0${
        selectedBidVendors.indexOf(col.id) + 1
      }`;
      let vendorId = row[col.id].vendorId;

      const editedRow = { ...row, selected, po, vendorId };
      setBidTableRows(
        BidTableRows.map((r) => {
          if (editedRow.mrqMaterialId === r.mrqMaterialId) {
            return editedRow;
          }
          return r;
        })
      );
    }
  }
};
