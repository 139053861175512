import { Box } from "@mui/material";
import React from "react";
import MrqSelector from "./components/MrqSelector";
import MrqDetails from "./components/MrqDetails";
import MrqMaterialsTable from "./components/MrqMaterialsTable/index";
import LabelledBox from "../../../../../../components/widgets/LabelledBox";
import { MCSContext } from "../MCS.context";

const MRQTab = () => {
  const { mrqData } = React.useContext(MCSContext);

  console.log(mrqData.pos);
  return (
    <Box>
      <LabelledBox label="MRQ Manager">
        <MrqSelector />
      </LabelledBox>

      <MrqDetails />

      <MrqMaterialsTable
        data={mrqData?.mrqMaterials || []}
        pos={mrqData?.pos || []}
      />

      {/* <AddDataDialog open={dialog.open} onClose={onClose} /> */}
    </Box>
  );
};

export default MRQTab;
