import React, { useContext } from "react";
import "../../../styles/home-new.css";
import logo from "../../../images/chinook_white.png";
import form_img from "../../../images/home_page/form.png";
import cube_img from "../../../images/home_page/3d.png";
import { AuthContext } from "../../../context/authContext";
import { useNavigate } from "react-router-dom";
import paths from "../../../api/paths.api";
import Wheel from "./Wheel";
import { Button } from "@mui/material";
import SectionTemplate from "./SectionTemplate";
import HeroSection from "./HeroSection2";

const Home2 = () => {
  // const username = accounts[0]?.name;

  const { auth, logout } = useContext(AuthContext);

  const currentUser = auth;
  //console.log("auth", auth)
  const navigate = useNavigate();

  const logoutAndRedirect = () => {
    logout();
    navigate("/");
  };

  const loginRedirect = () => {
    navigate("/login");
  };

  const goToProjects = () => {
    navigate(paths.myProjects);
  };
  const gotoSolutions = () => {
    navigate("/solutions");
  };

  const texts = ["Text 1", "Text 2", "Text 3"]; // Array of texts to cycle through
  let currentIndex = 0;

  // const handleScroll = () => {
  //     const cubeImg = document.querySelector('.cube_img');
  //     const scrollPosition = window.scrollY + window.innerHeight;
  //     const revealTrigger = cubeImg.offsetTop + cubeImg.offsetHeight * 0.3;

  //     if (scrollPosition > revealTrigger) {
  //         cubeImg.style.animation = 'revealAnimation 2s ease-in-out forwards';
  //     }
  // };

  // // Attach the scroll event listener
  // window.addEventListener('scroll', handleScroll);

  return (
    <div className="homePage-container">
      <header>
        <img src={logo} alt="" className="logo" />
        {
          currentUser?.role ? (
            <div>
              <a className="navElem" onClick={gotoSolutions}>
                Solutions
              </a>
              <a className="navElem" onClick={goToProjects}>
                Projects
              </a>
              <a className="login-button" onClick={logoutAndRedirect}>
                Logout
              </a>
            </div>
          ) : (
            // <a className="navElem login" href="/dev/login">Login</a>
            <div>
              <a className="navElem" onClick={gotoSolutions}>
                Solutions
              </a>

              <button className="login-button" onClick={loginRedirect}>
                Login
              </button>
            </div>
          )
          // <a className="navElem login" href="/guest/login">Login</a>
        }
      </header>
      <section className="homePage-new">
        <HeroSection />
      </section>
      <section className="second-section">
        <div className="leftcard">
          <div className="leftcard-content">
            <h1 className="glow-header">1.</h1>
            <h2 className="section-title">
              Design
              <br />
              <span>Automation</span>
            </h2>
            <p>
              Save Time and Cost by Automating the Creation of 3d Drawings and
              2d Drawings from Data
            </p>
            <button className="read-more">Read More &#8594;</button>
          </div>
        </div>
        <div className="rightcard">
          <div className="rightcard-content">
            <img className="form_img" src={form_img} />
            <img className="cube_img reveal" src={cube_img} />
            <div className="arrow-box"></div>
          </div>
        </div>
      </section>

      <section className="third-section">
        <div className="leftcard">
          <div className="leftcard-content">
            <h1 className="glow-header">2.</h1>
            <h2 className="section-title">
              Task
              <br />
              <span>Management</span>
            </h2>
            <p>
              Streamline and organize tasks, deadlines, and team collaboration,
              enhancing productivity and ensuring efficient project execution.
            </p>
          </div>
        </div>
        <div className="rightcard">
          <div className="rightcard-content">
            <div className="tasklist">
              <div className="task">
                <div className="checked"></div>
                <div className="line"></div>
              </div>
              <div className="task">
                <div className="checked"></div>
                <div className="line"></div>
              </div>
              <div className="task">
                <div className="unchecked"></div>
                <div className="line"></div>
              </div>
            </div>

            {/* <img className="task_img" src={form_img} /> */}
          </div>
        </div>
      </section>
      <SectionTemplate
        number="3"
        title="Project Management"
        description="Seamlessly coordinating processes with automation and collaboration to ensure precision, consistency, and productivity from proposal to construction"
      />
    </div>
  );
};

export default Home2;
