import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React from "react";

const inspectionLevels = ["Level 1", "Level 2", "Level 3", "Level 4"];

export default function GeneralTab({ generalData }) {
  const [data, setData] = React.useState({
    "Job Number": "",
    "Document Number": "",
    "Clint/Project Name/No.": "",
    "Manufacturing Location": "",
    "End User": "",
    "Client PO Number": "",
    "Tag Number": "",
    "Equipment Name": "",
    "Project Manager": "",
    "Project Engineer": "",
    "Scope of work Description": "",
  });
  const [value, setValue] = React.useState(inspectionLevels[0]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <>
      <Grid container spacing={2} sx={{ px: 2 }}>
        {Object.entries(data).map((cell) => {
          const key = cell[0];
          const value = cell[1];

          return (
            <Grid item xs={6} key={key}>
              {key} : {value}
            </Grid>
          );
        })}
      </Grid>
      <Grid container spacing={2} sx={{ px: 2, pt: 1 }}>
        <Grid item xs={12}>
          Inspection Level :{"     "}
          <RadioGroup
            row
            value={value}
            onChange={handleChange}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{ display: "inline-block" }}
          >
            {inspectionLevels.map((option) => {
              return (
                <FormControlLabel
                  value={option}
                  control={<Radio />}
                  label={option}
                  sx={{ mx: 1 }}
                  key={option}
                />
              );
            })}
          </RadioGroup>
        </Grid>
      </Grid>
    </>
  );
}
