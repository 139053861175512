import React, { useState } from 'react'
import axios from '../api/axios'
import CustomAlert from '../components/widgets/CustomAlert'
const requiredFields = [
  "txt_DP_CSC",
  "txt_AS_CSC",
  "txt_JE_CSC",
  "txt_ID_CSC",
  "txt_PT_CSC", //
  "txt_CA_CSC",
  "txt_DL_CSC",
  "TextBox30",
  "TextBox66",
  "ComboBox7",
]

const fieldNames =
{
  "txt_DP_CSC": "Design Pressure",
  "txt_AS_CSC": "Allowable Stress",
  "txt_JE_CSC": "Joint Efficiency",
  "txt_ID_CSC": "Diameter",
  "txt_PT_CSC": "Provided Thickness", //
  "txt_CA_CSC": "Corrosion Allowance",
  "txt_DL_CSC": "Design Length",
  "TextBox30": "Liquid Density",
  "TextBox66": "Liquid Height",
  "ComboBox7": "Diameter type: (Inner/Outer) "
}

const checkRequiredFields = (data) => {



  //Check if required fields are filled, if not show error dialog
  const check1 = () => {
    let missingFields = []
    console.log(Object.keys(data))

    requiredFields.forEach((field) => {
      //Checks if required field exists and if its not empty and appends them to show back error message
      if (!Object.keys(data).includes(field) || !data[field]) {
        missingFields.push(fieldNames[field])
        return
      }
    })

    //Separate check for datagrid
    if (Object.keys(data).includes("DataGridView5")) {
      // console.log()
      if (!data["DataGridView5"].length) {
        missingFields.push("Shell Table")
      }
      else if (data["DataGridView5"].length) {
        data["DataGridView5"].forEach(elem => {
          for (const [key, value] of Object.entries(elem)) {
            if (["Component", "Material", "Diameter", "Provided Thickness"].includes(key) && !value) {
              missingFields.push("Shell Table: "+key)
            }
          }
        })
      }

    }

    if (!missingFields.length) {
      // const result = ""
      // const result = await sendCalcRequest()
      // console.log(result)
      return [true, ""]
    }
    else {
      return [false, missingFields]
    }
  }

  let datagrid6 = check1()
  return datagrid6

}

export default checkRequiredFields