import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { MCSContext } from "../../../../MCS.context";
import { addMaterialsTo } from "../../../../api";
import SelectedMaterialsBox from "./SelectedMaterialsBox";
import AddNewEntity from "./AddNewEntity";
import SelectFromExistedEntities from "./SelectFromExistedEntities";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 2,
};
const initialActionData = { number: "", date: "" };

export default function ActionDialog({
  open,
  setOpen,
  currentAction,
  selectedRows,
}) {
  const { mrqData, setMrqData, selectedBidVendors } =
    React.useContext(MCSContext);
  const [selectedCurrentAction, setSelectedCurrentAction] = React.useState("");

  const [actionTextData, setActionTextData] = React.useState(initialActionData);

  const [actionRadioBtnsData, setActionRadioBtnsData] =
    React.useState(initialActionData);

  const handleClose = () => setOpen(false);

  const onSelectEntity = (event) => {
    setSelectedCurrentAction(event.target.value);
  };

  const selectedMaterialsId = [];
  selectedRows?.rows?.forEach((row) => {
    selectedMaterialsId.push(...row.original?.id);
  });

  const onClickRadioBtn = (e) => {
    const selectedNumber = e.target.value;
    setActionTextData(initialActionData);
    setActionRadioBtnsData({
      number: selectedNumber,
      date: mrqData?.[currentAction + "s"]?.filter(
        (a) => a.number === selectedNumber
      )[0]?.date,
    });
  };
  const onTextChange = (e) => {
    e.preventDefault();
    setActionRadioBtnsData(initialActionData);
    setActionTextData({ ...actionTextData, [e.target.name]: e.target.value });
  };

  const onSubmit = async () => {
    const result = await addMaterialsTo({
      to: currentAction,
      body: {
        number: actionRadioBtnsData.number || actionTextData.number,
        date: actionRadioBtnsData.date || actionTextData.date,
        mrqId: mrqData?.id,
        mrqMaterialsIds: selectedMaterialsId,
        selectedBidVendors,
      },
    });
    if (result.id) {
      setMrqData(result);
      setOpen(false);
      setActionTextData(initialActionData);
      setActionRadioBtnsData(initialActionData);
    } else {
      alert("failed");
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
          Add to {currentAction}
        </Typography>
        <Grid container>
          <Grid item xs={6} sx={{ p: 1 }}>
            <Typography variant="h6">Current {currentAction}s :</Typography>

            <SelectFromExistedEntities
              selectedCurrentAction={selectedCurrentAction}
              onSelectEntity={onSelectEntity}
              actionRadioBtnsData={actionRadioBtnsData}
              onClickRadioBtn={onClickRadioBtn}
              currentAction={currentAction.toLowerCase()}
            />

            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              <span style={{ fontSize: "14px" }}>or</span> Create new{" "}
              {currentAction} :
            </Typography>

            <Box sx={{ px: 3 }}>
              <AddNewEntity
                currentAction={currentAction}
                actionTextData={actionTextData}
                onTextChange={onTextChange}
              />
            </Box>
          </Grid>

          <Grid item xs={6} sx={{ p: 1, maxHeight: "450px", overflow: "auto" }}>
            <SelectedMaterialsBox selectedRows={selectedRows} />
          </Grid>
        </Grid>

        <Box>
          {/* number:{actionRadioBtnsData.number || actionTextData.number} date:
          {actionRadioBtnsData.date || actionTextData.date} */}
          <Button
            variant="contained"
            sx={{ float: "right" }}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
