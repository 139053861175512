import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import CylindricalShellTab from '../calculation_tabs/CylindricalShellTab';
import { useFormContext } from "react-hook-form";
// import EllipticalHeadTab from '../calculation_tabs/EllipticalHeadTab';
import Button from '@mui/material/Button';

import axios from '../../../../../api/axios'
import checkRequiredFields from '../../../../../utils/checkRequiredFields';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
            width="100%"
            style={{ width: '90%' }}
        >
            {value === index && (
                <Box sx={{ p: 3, minHeight: "100vh" }} width="inherit">
                    <div id="tab-div" style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        {children}
                    </div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const SubmitButtonStyle = {
    width: "fit-content",
    alignSelf: "flex-end"
}

const CalculationTab = (props) => {
    // const { calculationResult, getCalculation } = props
    const [tab, setTab] = React.useState(0); ///value of tab 
    const [calculationResult, setCalculationResult] = React.useState([])
    const { setValue, getValues, handleSubmit } = useFormContext();
    const [openAlert, setOpenAlert] = useState(false)
    const [AlertContent, setAlertContent] = useState({
        severity: "",
        message: ""
    })

    const defaults = {
        "Liquid Head Pressure": 0.1, "Material Specification": "SA 516", "Allowable Stress": 138,
        "Provided Thickness": 12, "Corrosion Allowance": 3
    }

    const getCalc = async (data) => {
        const result = checkRequiredFields(data)
        console.log(result)
        if (result[0] === false) {
            setAlertContent({
                message: "Following values are missing: " + result[1],
                severity: "error",
            })
            setOpenAlert(true)
        }
        else {
            console.log("Sending request")
            const res = await axios.post("/pressure_vessel/calculate", data)
                // .then((response) => {
                //     return response
                // })
                .catch((err) => {
                    console.log(err)
                })
            // console.log(res.data.DataGridView6)
            console.log("res.data", res)
            // setValue("DataGridView6", res.data.DataGridView6)
            // console.log(got it)
            setCalculationResult(res.data)
            // reRender()
            for (let elem of ["Cylindrical", "Elliptical", "Nozzle", "Saddle"]) {
                console.log("elem alone", elem)
                if (res.data[elem]) {
                    console.log("res.data.elem", res.data[elem])
                    for (const [key, value] of Object.entries(res.data[elem])) {
                        console.log(`KeY11123: ${key}, value: ${value}`);
                        setValue(key, value);
                    }
                }
            }

            // for (const [key, value] of Object.entries(res.data.Cylindrical)) {
            //     // console.log(`KeY: ${key}, value: ${value}`);
            //     setValue(key, value);
            // }
            // for (const [key, value] of Object.entries(res.data.Elliptical)) {
            //     // console.log(`KeY: ${key}, value: ${value}`);
            //     setValue(key, value);
            // }
            // for (const [key, value] of Object.entries(res.data.Nozzle)) {
            //     console.log(`KeY: ${key}, value: ${value}`);
            //     setValue(key, value);
            // }
            // for (const [key, value] of Object.entries(res.data.Saddle)) {
            //     console.log(`KeY: ${key}, value: ${value}`);
            //     setValue(key, value);
            // }
        }
    }

    // const getCalculation = async (data) => {
    //     const result = await axios.post("/pressure_vessel/calculate", data)
    //         .then((response) => {
    //             setCalculationResult(response.data)
    //             // reRender()
    //             for (const [key, value] of Object.entries(response.data.Cylindrical)) {
    //                 console.log(`KeY: ${key}, value: ${value}`);
    //                 setValue(key, value);
    //             }
    //             for (const [key, value] of Object.entries(response.data.Elliptical)) {
    //                 console.log(`KeY: ${key}, value: ${value}`);
    //                 setValue(key, value);
    //             }
    //             console.log(response)
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })

    // }

    //Set Calculation results, values from tables and values from design tab
    useEffect(() => {
        for (let elem of ["Cylindrical", "Elliptical", "Nozzle", "Saddle"]) {
            if (calculationResult[elem]) {
                for (const [key, value] of Object.entries(calculationResult[elem])) {
                    console.log(`KeY00: ${key}, value: ${value}`);
                    setValue(key, value);
                }
            }
        }
        //values to be fetched from the table
        setValue("txt_LH_CSC", defaults["Liquid Head Pressure"])
        setValue("txt_LH_EHC", defaults["Liquid Head Pressure"])
        // setValue("txt_MS_CSC", defaults["Material Specification"])
        // setValue("txt_MS_EHC", defaults["Material Specification"])
        // setValue("txt_AS_CSC", defaults["Allowable Stress"])
        // setValue("txt_AS_EHC", defaults["Allowable Stress"])
        // setValue("txt_NAS_NC1", defaults["Allowable Stress"])
        // setValue("txt_PT_CSC", defaults["Provided Thickness"])
        // setValue("txt_PT_EHC", defaults["Provided Thickness"])
        // setValue("txt_CA_CSC", defaults["Corrosion Allowance"])
        // setValue("txt_CA_EHC", defaults["Corrosion Allowance"])
    }, [])

    const handleChange = (event, newTab) => {
        setTab(newTab);
    };

    console.log("calculationResult", calculationResult)
    return (

        <Box
            sx={{
                flexGrow: 1,
                bgcolor: 'background.paper',
                borderRadius: "15px",
                // bgcolor: 'red',
                display: 'flex',
                width: "90%", marginLeft: 2,
                "& .Mui-disabled": {
                    color: "red",
                    "-webkit-text-fill-color": "#000000"
                }
            }}
        >

        </Box >
    );
}

export default CalculationTab