import React from "react";
import {MaterialReactTable} from "material-react-table";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import { Button, ListItemIcon } from "@mui/material";
import { Delete as DeleteIcon, Watch } from "@mui/icons-material";
import { columns, setPipeValue } from "./columnsData/columns";
import { useFieldArray, useFormContext } from "react-hook-form";
import { compactTableProps } from "../../../../../../styles/muiProps";
import { getValue } from "@testing-library/user-event/dist/utils";

const InputTable = (props) => {
  const { reRender, isLoading } = props;
  const {
    register,
    control,
    setValue,
    getValues,
    formState: { errors },
    resetField,
  } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "DataGridView1",
  });

  //save cell changes in table
  const handleSaveCell = (cell, value) => {
    // console.log('the cell is ',cell);
    // console.log('the value is',value)
    // // Changes in Diameter applied throughout Table. And in the other tabs
    // const specialValues = ['Flange Threaded',
    // 'Flange Blind']
    // if (specialValues.includes(value)){
    //   console.log('rerenderrrrrrrrrrrrrrrrrrrrrrrrrr')
    // }
  };

  //delete row
  const handleDeleteRow = (index) => {
    remove(index);
  };

  //create new row in table
  const handleCreateNewRow = (values) => {
    append({
      Index: fields.length,
      Component: "",
      Diameter: getValues("txtVIDia"),
      Material: "",
      // "Can Length": 0,
      // "Long Seam Angle": 0,
      // "Provided Thickness": getValues("txt_PT_CSC"),
      // "Small Diameter": 0,
    });
  };
  const handleInputsChnage = (cell, value) => {
    console.log('the cell is ',cell);
    console.log('the value is',value)
    setPipeValue(value);
    // setValue(`DataGridView1.${cell.row.index}.Requirements`, "NOT");
    update(cell.row.id);
    reRender();
  };
  return (
    <Grid item xs={12}>
      <MaterialReactTable
        columns={columns}
        data={fields}
        editDisplayMode="table"
        {...compactTableProps}
        enableEditing
        enableColumnActions={false}
        enableRowActions
        positionActionsColumn="last"
        state={{
          isLoading,
          // showProgressBars: isLoading ? true : false
        }}
        muiEditTextFieldProps={({ cell }) => ({
          // //onBlur is more efficient, but could use onChange instead
          onBlur: (event) => {
            handleSaveCell(cell, event.target.value);
            handleInputsChnage(cell, event.target.value);
          },
          // onChange: (event) => {
            
          // },
          // variant: 'outlined',
          sx: {
            "& .MuiInputBase-input": {
              padding: "8.5px 8px",
            },
          },
        })}
        renderBottomToolbarCustomActions={() => (
          <Button
            color="secondary"
            size="small"
            onClick={() => handleCreateNewRow()}
            variant="contained"
          >
            Add row
          </Button>
        )}
        renderRowActionMenuItems={({ row }) => [
          <MenuItem
            key={0}
            sx={{ m: 0 }}
            onClick={() => handleDeleteRow(row.index)}
          >
            <ListItemIcon>
              <DeleteIcon />
            </ListItemIcon>
            Delete
          </MenuItem>,
        ]}
      />
    </Grid>
  );
};

export default InputTable;
