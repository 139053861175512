import { Box } from "@mui/material";
import React from "react";

import materialDBIcon from "../../images/materials_icons/materialDBIcon.png";

import shellIcon from "../../images/materials_icons/shellIcon.png";
import headIcon from "../../images/materials_icons/headIcon.png";
import pipeIcon from "../../images/materials_icons/pipeIcon.png";
import flangeIcon from "../../images/materials_icons/flangeIcon.png";
import plateIcon from "../../images/materials_icons/plateIcon.png";
import miscellaneousIcon from "../../images/materials_icons/miscellaneousIcon.png";

export default function Icon({ iconName, size, sx }) {
  const icons = {
    materialDBIcon,
    shellIcon,
    headIcon,
    pipeIcon,
    flangeIcon,
    plateIcon,
    miscellaneousIcon,
  };

  return (
    <Box
      sx={{
        display: "inline-block",
        marginRight: 1,
        transform: "translate(4px,2px)",
        ...sx,
      }}
    >
      <img
        src={icons[iconName]}
        alt={iconName}
        style={{ maxWidth: size ? size : 22 }}
      />
    </Box>
  );
}

// D:\code\enerfac\frontend\src\images\materials_icons\flangeIcon.png
// ../../images/materials_icons/flangeIcon.png
