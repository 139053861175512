import React, { useState, useEffect } from 'react'
import {MaterialReactTable} from 'material-react-table';
import Button from '@mui/material/Button';
import axios from '../../../../../api/axios'
import { IconButton, Tooltip } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
    QueryClient,
    QueryClientProvider,
    useQuery,
} from '@tanstack/react-query';
import { Box } from '@mui/system';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// const columns = [
//     {
//         accessorKey: 'firstName',
//         header: 'First Name',
//     },
//     {
//         accessorKey: 'lastName',
//         header: 'Last Name',
//     },
//     {
//         accessorKey: 'address',
//         header: 'Address',
//     },
//     {
//         accessorKey: 'state',
//         header: 'State',
//     },
//     {
//         accessorKey: 'phoneNumber',
//         header: 'Phone Number',
//     },
// ]

const tableProps = {
    muiTableHeadCellProps: {
        sx: {
            borderRight: '1px solid rgba(224,224,224,1)',
            borderTop: '1px solid rgba(224,224,224,1)',
            "& .Mui-TableHeadCell-Content-Wrapper": {
                whiteSpace: "normal",
                justifyContent: "center",
                marginBottom: "0.4px"
            },
            fontSize: "0.7rem",
            verticalAlign: "middle",
            height: "80px"
        }
    },
    muiTableProps: {
        size: "small",
        sx: {
            "& .MuiInputBase-root": {
                fontSize: '0.7rem',
                borderRadius: 0,

            },
            "& .MuiButtonBase-root": {
                fontSize: '0.7rem'
            },

        }
    },
    muiBottomToolbarProps: {
        sx: {
            // padding: "0rem",
            "& .css-1llu0od": {
                padding: "0rem 0.5rem",

            }
        }
    },
    muiTableBodyCellProps: {
        sx: {
            borderRight: '1px solid rgba(224,224,224,1)',
            padding: "0.2rem",
            paddingLeft: "5px",
            height: "36.4px"
        },
    },
    initialState: { density: 'compact' }
}

const TableTab = () => {
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    // const [pagination, setPagination] = useState({
    //     pageIndex: 0,
    //     pageSize: 10,
    // });

    const [table, setTable] = React.useState('');

    const handleChange = (event) => {
        setTable(event.target.value);
    };

    const { data, isError, isSuccess, isFetching, isLoading, refetch } = useQuery({
        queryKey: [
            'table-data',
            table
            // columnFilters, //refetch when columnFilters changes
            // globalFilter, //refetch when globalFilter changes
            // pagination.pageIndex, //refetch when pagination.pageIndex changes
            // pagination.pageSize, //refetch when pagination.pageSize changes
            // sorting, //refetch when sorting changes
        ],
        queryFn: async () => { return await axios.get("/pressure_vessel_table/" + table) },
        keepPreviousData: true,
    });

    const tablesQuery = useQuery({
        queryKey: ['all_tables'],
        queryFn: async () => {
            let allTables = await axios.get("/pressure_vessel_table/all")
            return allTables.data
        },
        keepPreviousData: true,
    })
    const { data: allTablesData } = tablesQuery


    let columns = []
    if (isSuccess) {
        const keys = (Object.keys(data.data?.[0]))
        console.log(keys, (data))
        console.log(data.data)
        columns = keys.map(key => {
            let tempObject = {}
            // accessorFn: (row) => row.username,
            tempObject["accessorKey"] = [key]
            tempObject["header"] = key
            return (tempObject)
        })
    }

    if (tablesQuery.isSuccess) {
        console.log("all tables", allTablesData)
    }
    useEffect(() => {
        if (tablesQuery.isSuccess) {
            setTable(allTablesData[0]["TABLE_NAME"])
        }
    }, [allTablesData])


    const getTable = async (data) => {
        const result = await axios.get("/pressure_vessel_table/all")
            .then((response) => {
                console.log(response)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div style={{ display: "flex", }}>
            {/* <Button color="secondary"
                // marginBottom="20px"
                onClick={() => getTable()}
                variant="contained"> Get Table </Button> */}
            <FormControl sx={{ m: 1, minWidth: 120 }} variant="filled" size="small">
                <InputLabel id="select-label">Table</InputLabel>
                <Select
                    labelId="select-label"
                    id="select"
                    sx={{ backgroundColor: "white" }}
                    value={table}
                    label="Table"
                    onChange={handleChange}
                >

                    {
                        allTablesData?.map(elem => (
                            <MenuItem value={elem.TABLE_NAME}>{elem.TABLE_NAME}</MenuItem>
                        ))
                    }
                    {/* <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
            </FormControl>
            <Box maxWidth="80%">
                <MaterialReactTable
                    columns={columns ?? []}
                    data={data?.data ?? []} //data is undefined on first render
                    // initialState={{ showColumnFilters: true }}
                    // manualFiltering
                    // manualPagination
                    // manualSorting
                    initialState={{ pagination: { pageSize: 15 } }}
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: 'error',
                                children: 'Error loading data',
                            }
                            : undefined
                    }
                    {...tableProps}
                    // onColumnFiltersChange={setColumnFilters}
                    // onGlobalFilterChange={setGlobalFilter}
                    // onPaginationChange={setPagination}
                    // onSortingChange={setSorting}
                    enableColumnActions={false}
                    renderTopToolbarCustomActions={() => (
                        <Tooltip arrow title="Refresh Data">
                            <IconButton onClick={() => refetch()}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                    defaultColumn={{
                        minSize: 20, //allow columns to get smaller than default
                        maxSize: 80, //allow columns to get larger than default
                        size: 40, //make columns wider by default
                    }}
                    // rowCount={data?.meta?.totalRowCount ?? 0}
                    state={{
                        // columnFilters,
                        // globalFilter,
                        isLoading,
                        // pagination,
                        showAlertBanner: isError,
                        showProgressBars: isFetching,
                        // sorting,
                    }}
                />
            </Box>

        </div>
    )
}

export default TableTab