import React, { useEffect } from "react";
import TabPanel from "../../../../components/widgets/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DownloadPage from "../CommonFeatures/DownloadPage";
import CalculationTab from "./calculation_tabs";

// Form provider
import { useForm, FormProvider, useFormContext } from "react-hook-form";
// import MCSPage from './ParentTabs/MCS';
// import EstimationTab from "./ParentTabs/EstimationTab";
import { ThemeProvider } from "@emotion/react";
import NewViewProject from "../../../admin/ViewProject2";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import axios from "../../../../api/axios";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import FileDrawer from "../../../../components/Drawers/FileDrawer";
import { theme } from "../../../../styles/theme";
import DataTabs from "./PressureVesselData";
import { tabProps, tabScrollerProps } from "../../../../styles/muiProps";
import { getProjectWithEquip, postMaterials } from "./api";
import CustomAlert from "../../../../components/widgets/CustomAlert";
import RevisionsDialog from "../CommonFeatures/RevisionsDialog";
import { getHashdata } from "./utils";
import { useState } from "react";
import { CommonTabs, equipMainTabs } from "../CommonFeatures/utils";

// const equipMainTabs = [
//   "File",
//   "Data",
//   "Calc.",
//   "Est.",
//   "BOM",
//   "DCS",
//   "PCS",
//   "AMS",
//   "PMS",
//   "Software",
// ];

const navLabel = "main-nav";
//changed routes to get and post from and to sharepoint
const MainPressureVessel = (props) => {
  document.title = "Pressure Vessel";
  const [open, setOpen] = React.useState(false); //alert
  const [uiData, setUiData] = React.useState({
    currentTab: 1,
    openDrawer: false,
    openRevisionDialog: false,
  });
  // const [search, setSearch] = useSearchParams();

  const [revisions, setRevisions] = React.useState({});
  const [selectedRevision, setSelectedRevision] = React.useState("");
  const [alert, setAlert] = React.useState({
    //make it global?
    open: false,
    message: "",
    severity: "",
  });

  const [key, setKey] = React.useState(Math.random());
  // const [projectId, setProjectId] = React.useState();
  const [previousData, setPreviousData] = React.useState({});

  const [state, setState] = React.useState({});
  const { id: projectId, equipId } = useParams();
  // console.log({ equipId });
  const [subAssemblyRoute, setSubAssemblyRoute] = useState("");

  useEffect(() => {
    console.log({
      hash: window.location.hash,
      sliced: window.location.hash.slice(1),
    });
    // const hashValue = window.location.hash.slice(1);
    // const tabValue = search.get(navLabel)
    const hashValue = getHashdata(window.location.hash, navLabel);
    // console.log({ hashValue })
    const tabIndex = equipMainTabs.findIndex((tab) => tab === hashValue);
    console.log({ tabIndex });
    if (tabIndex !== -1) {
      setUiData((prev) => ({ ...prev, currentTab: tabIndex }));
    } else window.location.hash = navLabel + "=" + equipMainTabs[1];

    const getProjectFile = async (subAssemblyRoute) => {
      const read = await axios
        .get("/pressure_vessel/getFile/" + subAssemblyRoute)
        .then((response) => {
          console.log("retrieved:", response.data);
          let prevData = response.data.data;
          setRevisions(response?.data?.revision);
          methods.reset(prevData);
          setPreviousData({ ...prevData });
        })
        .catch((err) => {
          console.log("error", err);
        });
    };

    const getState = async () => {
      await getProjectWithEquip(projectId, equipId).then((res) => {
        console.log({ res });
        setState(res);
        let equipment = res.equipment;
        setSubAssemblyRoute(
          res?.number + "/" + equipment?.type + "/" + equipment?.name + "/0"
        );
        console.log("", subAssemblyRoute);
        getProjectFile(
          res?.number + "/" + equipment?.type + "/" + equipment?.name + "/0"
        );
      });
    };
    getState();
  }, []);

  const { equipment, ...project } = state;

  //form handler
  const methods = useForm();

  //opening the file menu
  const toggleDrawer = (state) => {
    setUiData((prev) => ({ ...prev, openDrawer: state }));
  };

  const toggleRevisionDialog = (state) => {
    setUiData((prev) => ({ ...prev, openRevisionDialog: state }));
  };

  //handles changes in tab
  const handleTabChange = (event, newValue) => {
    if (newValue === 0) {
      toggleDrawer(true);
      return;
    }
    window.location.hash = navLabel + "=" + equipMainTabs[newValue];
    setUiData((prev) => ({ ...prev, currentTab: newValue }));
    // setCurrentTab(newValue);
  };

  const reRender = () => {
    setKey(Math.random());
  };

  useEffect(() => {}, []);

  // useEffect to work with oldrevisions
  useEffect(() => {
    console.log({ selectedRevision, revisions });
    const oldObj = reconstructOldObject(
      revisions[selectedRevision],
      previousData
    );
    console.log({ oldObj });
  }, [selectedRevision]);
  console.log({ previousData });

  // for revision - finding the changes.
  const findObjectDifferences = (oldObj, newObj, result = {}) => {
    for (const key in newObj) {
      if (key !== "projectId" || key !== "equipId")
        if (
          typeof newObj[key] === "object" &&
          typeof oldObj[key] === "object"
        ) {
          const nestedDiff = findObjectDifferences(oldObj[key], newObj[key]);
          if (Object.keys(nestedDiff).length > 0) {
            result[key] = nestedDiff;
          }
        } else if (oldObj[key] !== newObj[key]) {
          // result[key] = `from:${oldObj[key]};to:${newObj[key]}`;
          result[key] = { prev: oldObj[key], new: newObj[key] };
        }
    }
    for (const key in oldObj) {
      if (!newObj.hasOwnProperty(key)) {
        result[key] = { prev: oldObj[key], new: undefined }; // Indicates the property was deleted
      }
    }
    return result;
  };

  const reconstructOldObject = (revision, currentData) => {
    const oldObject = { ...currentData }; // Create a copy of the new object

    for (const key in revision) {
      const diff = revision[key];
      if (diff.new !== undefined) {
        // If the property exists in the old object and has a "prev" value in the diff
        oldObject[key] = diff.new;
      } else {
        // If the property should be deleted in the old object
        delete oldObject[key];
      }
    }

    console.log({ oldObject });
    return oldObject;
  };

  // saving the data to the txt file
  const onSave = async () => {
    const data = methods.getValues();
    console.log("data", data);
    const shellData = data.DataGridView5;
    console.log({ data, shellData, previousData });
    const changes = findObjectDifferences(previousData, data);
    const dataWithProjectId = {
      ...data,
      projectId: projectId,
      equipId: equipId,
      changes: changes,
    };
    console.log({ subAssemblyRoute });
    const result = await axios
      .post("/pressure_vessel/create/" + subAssemblyRoute, dataWithProjectId)
      .catch((err) => {
        console.log(err);
      });
    setPreviousData({ ...data, projectId: projectId, equipId: equipId });

    console.log("dien");
    await postMaterials({ projectId: projectId, equipId: equipId, data })
      .then((response) => {
        setOpen(true);
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log("dien2");
  };

  return (
    <ThemeProvider theme={theme}>
      <FormProvider {...methods}>
        {" "}
        {/*  pass all methods into the context */}
        <Box sx={{ width: "100%" }}>
          <Box {...tabScrollerProps}>
            <CustomAlert
              open={open}
              setOpen={setOpen}
              severity="success"
              message="File created successfully!"
            />

            <Tabs
              value={uiData.currentTab}
              onChange={handleTabChange}
              {...tabProps}
            >
              {equipMainTabs.map((tabName) => {
                return <Tab key={tabName} label={tabName} to={`${tabName}`} />;
              })}

              <Typography variant="h6" color={"secondary"} m={"auto 30px"}>
                {props.isSkid
                  ? `Pressure Vessel`
                  : `Project No. ${project?.number} ${equipment?.name}`}
              </Typography>
            </Tabs>

            <FileDrawer
              onSave={() => onSave()}
              openDrawer={uiData.openDrawer}
              toggleDrawer={() => toggleDrawer()}
              openRevisionDialog={() => toggleRevisionDialog(true)}
              // setOpenDrawer={() => setOpenDrawer()}
              // revisions={revisions}
            />

            <TabPanel value={uiData.currentTab} index={1} padding={0}>
              <DataTabs
                project={project}
                equip={equipment}
                projectId={projectId || ""}
              />
            </TabPanel>

            <TabPanel value={uiData.currentTab} index={2} padding={1}>
              <CalculationTab
                key={123456}
                calculationResult={1}
                getCalculation={1}
              />
            </TabPanel>

            {CommonTabs().map((tab, index) => (
              <TabPanel
                value={uiData.currentTab}
                index={3 + index}
                padding={tab.panelProps?.padding ?? 0}
              >
                {tab.panel}
              </TabPanel>
            ))}
          </Box>

          <RevisionsDialog
            onClose={() => toggleRevisionDialog(false)}
            selectedValue={selectedRevision}
            setSelectedValue={setSelectedRevision}
            open={uiData.openRevisionDialog}
            revisions={revisions}
          />
        </Box>
      </FormProvider>
    </ThemeProvider>
  );
};

export default MainPressureVessel;
