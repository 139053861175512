import { secondAxios } from "../../api/axios";

export const loginVerify = async ({ token }) => {
  console.log({ token });
  const response = await secondAxios.post("/auth/login", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(response);

  return response;
};
