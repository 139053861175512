import { Box, Tab, Tabs } from '@mui/material';
import React from 'react'
import VerticalTabPanel from '../../../../../../components/widgets/VerticalTabPanel';
import { tabProps } from '../../../../../../styles/muiProps';
import SaddleTab from './SaddleTab';

const tabs = ["Saddle", "Manway", "Lifting Lug", "Vortex Breaker"]
const AttachmentTabs = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', padding: "0rem" }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', padding: "0rem" }}>
                <Tabs value={value} onChange={handleChange} {...tabProps}>
                    {
                        tabs.map((tab) => (
                            <Tab label={tab} />
                        ))
                    }
                </Tabs>
            </Box>
            <VerticalTabPanel value={value} index={0}>
                <SaddleTab />
            </VerticalTabPanel>
            <VerticalTabPanel value={value} index={1}>

            </VerticalTabPanel>
            <VerticalTabPanel value={value} index={2}>

            </VerticalTabPanel>
        </Box>
    )
}

export default AttachmentTabs