import { secondAxios } from "../../../../../../api/axios";

export const getFabrication = async (projectId) => {
    const response = await secondAxios.get(`/fabrication/${projectId}`);
    return response.data;
};

export const createFabrication = async (parentId, fabrication) => {
    console.log(fabrication)
    // TODO: Add parentId to fabrication details here.
    await secondAxios.post(`/fabrication/${parentId}`, fabrication);
};

export const createBulkFabrication = async (parentId, fabrication) => {
    console.log(parentId, fabrication)
    // TODO: Add parentId to fabrication details here.
    await secondAxios.post(`/fabrication/${parentId}`, fabrication);
};

export const updateFabrication = async (fabrication) => {
    console.log({ fabrication })
    await secondAxios.patch(`/fabrication/${fabrication.id}`, fabrication);
};

export const deleteFabrication = async (fabricationId) => {
    await secondAxios.delete(`/fabrication/${fabricationId}`);
};
