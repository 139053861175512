export const Wind_Load_InputData = [
    {
        label: "D Diameter",
        variable: "D",
        txtFieldName: "D_Diameter_WL",
        required: true,
        type: "number",
    },
    {
        label: "D1 Diameter",
        variable: "D1",
        txtFieldName: "D1_Diameter_WL",
        required: true,
        type: "number",
    },
    {
        label: "Joint Efficiency",
        variable: "E ",
        txtFieldName: "E_Joint_Efficiency_WL",
        required: true,
        type: "number",
    },
    {
        label: "Height",
        variable: "H",
        txtFieldName: "H_Height_WL",
        required: true,
        type: "number",
    },
    {
        label: "HT Height",
        variable: "HT",
        txtFieldName: "HT_Height_WL",
        required: true,
        type: "number",
    },
    {
        label: "Pressure",
        variable: "P",
        txtFieldName: "P_Pressure_WL",
        required: true,
        type: "number",
    },
    {
        label: "Pressure",
        variable: "PW",
        txtFieldName: "PW_Pressure_WL",
        required: true,
        type: "number",
    },
    {
        label: "Allowable Stress",
        variable: "S",
        txtFieldName: "S_Allowable_Stress_WL",
        required: true,
        type: "number",
    },
]

export const Wind_Load_OutputData = [
    {
        label: "Required Thickness",
        variable: "t",
        txtFieldName: "t_req_thickness_WL",
        required: true,
        type: "number",
    },
    {
        label: "Required Thickness 1",
        variable: "t1",
        txtFieldName: "t_req_thickness_1_WL",
        required: true,
        type: "number",
    },
    {
        label: "Height",
        variable: "H1",
        txtFieldName: "H1_Height_WL",
        required: true,
        type: "number",
    },
    {
        label: "Total Shear",
        variable: "V",
        txtFieldName: "V_Total_Shear_WL",
        required: true,
        type: "number",
    },
    {
        label: "Moment",
        variable: "M",
        txtFieldName: "M_Moment_WL",
        required: true,
        type: "number",
    },
    {
        label: "Moment Bottom",
        variable: "Mt",
        txtFieldName: "Mt_Moment_Bottom_WL",
        required: true,
        type: "number",
    },
    {
        label: "Required Thickness",
        variable: "Treq",
        txtFieldName: "T_Required_Thickness_WL",
        required: true,
        type: "number",
    },
    {
        label: "Total Thickness",
        variable: "TH",
        txtFieldName: "Total_Thickness_WL",
        required: true,
        type: "number",
    },
]

export const Seismic_Load_InputData = [
    {
        label: "Seismic zone factor",
        variable: "Z",
        txtFieldName: "Z_Seismic_zone_factor_SL",
        required: true,
        type: "number",
    },
    {
        label: "Diameter",
        variable: "D",
        txtFieldName: "D_Diameter_SL",
        required: true,
        type: "number",
    },
     {
        label: "Distance",
        variable: "X",
        txtFieldName: "X_Distance_SL",
        required: true,
        type: "number",
    }, {
        label: "Length",
        variable: "H",
        txtFieldName:"H_Length_SL",
        required: true,
        type: "number",
    }, {
        label: "Weight",
        variable: "W",
        txtFieldName:"W_Weight_SL",
        required: true,
        type: "number",
    }, {
        label: "Numerical Coefficient",
        variable: "Ct",
        txtFieldName: "Ct_Numerical_Coefficient_SL",
        required: true,
        type: "number",
    }, {
        label: "Importance coefficient",
        variable: "I",
        txtFieldName: "I_importance_coefficient_SL",
        required: true,
        type: "number",
    }, {
        label: "Site Coefficient",
        variable: "S",
        txtFieldName: "S_Site_Coefficient_SL",
        required: true,
        type: "number",
    }, {
        label: "Numerical Coefficient",
        variable: "RW",
        txtFieldName: "RW_Numerical_Coefficient_SL",
        required: true,
        type: "number",
    },
]

export const Seismic_Load_OutputData = [
    {
        label: "Vibration",
        variable: "T",
        txtFieldName: "T_Vibration_SL",
        required: true,
        type: "number",
    },
    {
        label: "Numerical Coefficient",
        variable: "C",
        txtFieldName: "C_Numerical_Coefficient_SL",
        required: true,
        type: "number",
    },
    {
        label: "Seismic Shear",
        variable: "V",
        txtFieldName: "V_Seismic_Shear_SL",
        required: true,
        type: "number",
    },
    {
        label: "Seismic Force",
        variable: "Ft",
        txtFieldName: "Ft_Hor_Seismic_Force_SL",
        required: true,
        type: "number",
    },
    {
        label: "Moment",
        variable: "M",
        txtFieldName: "M_Moment_SL",
        required: true,
        type: "number",
    },
]

export const Deflection_InputData = [
    {
        label: "Diameter",
        variable: "D1",
        txtFieldName: "D1_Diameter_Df",
        required: true,
        type: "number",
    },
    {
        label: "Modulus of elasticity",
        variable: "E ",
        txtFieldName: "E_modulus_of_elasticity_Df",
        required: true,
        type: "number",
    },
    {
        label: "Length",
        variable: "H",
        txtFieldName: "H_Length_Df",
        required: true,
        type: "number",
    },{
        label: "Pressure",
        variable: "PW",
        txtFieldName: "PW_Pressure_Df",
        required: true,
        type: "number",
    },
    {
        label: "Mean Radius",
        variable: "R",
        txtFieldName: "R_Mean_Radius_Df",
        required: true,
        type: "number",
    },
    {
        label: "Thickness",
        variable: "T",
        txtFieldName: "T_Thickness_Df",
        required: true,
        type: "number",
    },
]

export const Deflection_OutputData = [
    {
        label: "Maximum Deflection",
        variable: "M",
        txtFieldName: "M_Maximum_Deflection_Df",
        required: true,
        type: "number",
    },
]

export const Skirt_Support_InputData = [
    {
        label: "Diameter",
        variable: "D",
        txtFieldName: "D_Diameter_SS",
        required: true,
        type: "number",
    },
    {
        label: "Allowable Stress",
        variable: "S",
        txtFieldName: "S_Allowable_Stress_SS",
        required: true,
        type: "number",
    },
    {
        label: "Joint Efficiency",
        variable: "E",
        txtFieldName: "E_Joint_Efficiency_SS",
        required: true,
        type: "number",
    },
    {
        label: "Moment Bottom",
        variable: "Mt",
        txtFieldName: "Mt_Moment_Bottom_SS",
        required: true,
        type: "number",
    },
    {
        label: "Weight",
        variable: "W",
        txtFieldName: "W_Weight_SS",
        required: true,
        type: "number",
    },
]

export const Skirt_Support_OutputData = [
    {
        label: "Thickness Wind",
        variable: "Twind",
        txtFieldName: "T_Thickness_Wind_SS",
        required: true,
        type: "number",
    },
    {
        label: "Thickness Weight",
        variable: "Tweight",
        txtFieldName: "T_Thickness_Weight_SS",
        required: true,
        type: "number",
    },
    {
        label: "Total Thickness",
        variable: "TH",
        txtFieldName: "Total_Thickness_SS",
        required: true,
        type: "number",
    },
]

export const Anchor_Bolt_InputData = [
    {
        label: "Bolt Diameter",
        variable: "D",
        txtFieldName: "D_Bolt_Dia_AB",
        required: true,
        type: "number",
    },
    {
        label: "Radius",
        variable: "R",
        txtFieldName: "R_Radius_AB",
        required: true,
        type: "number",
    },
    {
        label: "Area of Bolt",
        variable: "Ab",
        txtFieldName: "Ab_Area_of_Bolt_AB",
        required: true,
        type: "number",
    },
    {
        label: "Circumference",
        variable: "Cb",
        txtFieldName: "Cb_Circumference_AB",
        required: true,
        type: "number",
    },
    {
        label: "Moment",
        variable: "M",
        txtFieldName: "M_Moment_AB",
        required: true,
        type: "number",
    },
    {
        label: "Weight",
        variable: "W",
        txtFieldName: "W_Weight_AB",
        required: true,
        type: "number",
    },
    {
        label: "Allowable Stress",
        variable: "S",
        txtFieldName: "S_Allowable_Stress_AB",
        required: true,
        type: "number",
    },
    {
        label: "N_No_of_Holes",
        variable: "N",
        txtFieldName: "N_No_of_Holes_AB",
        required: true,
        type: "number",
    },
]

export const Anchor_Bolt_OutputData = [
    {
        label: "Maximum tension",
        variable: "T",
        txtFieldName: "T_Maximum_tension_AB",
        required: true,
        type: "number",
    },
    {
        label: "Area of Bolt",
        variable: "Ba",
        txtFieldName: "Ba_Area_of_Bolt_AB",
        required: true,
        type: "number",
    },
    {
        label: "Stress in Anchor Bolt",
        variable: "Sb",
        txtFieldName: "Sb_Stress_in_Anchor_Bolt_AB",
        required: true,
        type: "number",
    },
]

export const Base_Ring_InputData = [
    {
        label: "Moment",
        variable: "M",
        txtFieldName: "M_Moment_BR",
        required: true,
        type: "number",
    },
    {
        label: "Safe Bearing",
        variable: "Fb",
        txtFieldName: "Fb_Safe_Bearing_BR",
        required: true,
        type: "number",
    },
    {
        label: "Diameter of Skirt",
        variable: "D",
        txtFieldName: "D_Dia_of_Skirt_BR",
        required: true,
        type: "number",
    },
    {
        label: "Radius",
        variable: "R",
        txtFieldName: "R_Radius_BR",
        required: true,
        type: "number",
    },
    {
        label: "Area of Bolt",
        variable: "Ab",
        txtFieldName: "Ab_Area_of_Bolt_BR",
        required: true,
        type: "number",
    },
    {
        label: "Circumference",
        variable: "Cb",
        txtFieldName: "Cb_Circumference_BR",
        required: true,
        type: "number",
    },
    {
        label: "Weight",
        variable: "W",
        txtFieldName: "W_Weight_BR",
        required: true,
        type: "number",
    },
]

export const Base_Ring_OutputData = [
    {
        label: "Max Compression",
        variable: "Pc",
        txtFieldName: "Pc_Max_Compression_BR",
        required: true,
        type: "number",
    },
    {
        label: "Width of Base Ring",
        variable: "L",
        txtFieldName: "L_width_Base_Ring_BR",
        required: true,
        type: "number",
    },
    {
        label: "Thickness of Base Ring",
        variable: "Tb",
        txtFieldName: "Tb_Thickness_Base_Ring_BR",
        required: true,
        type: "number",
    },
    {
        label: "S1 Bearing Stress",
        variable: "S1",
        txtFieldName: "S1_Bearing_Stress_BR",
        required: true,
        type: "number",
    },
    {
        label: "S2 Bearing Stress",
        variable: "S2",
        txtFieldName: "S2_Bearing_Stress_2_BR",
        required: true,
        type: "number",
    },
]

export const Lug_Support_InputData = [
    {
        label: "Weight of vessel pounds",
        variable: "Wt",
        txtFieldName: "W_Weight_of_vessel_pounds_Lug",
        required: true,
        type: "number",
    },
    {
        label: "number of legs",
        variable: "n",
        txtFieldName: "n_number_of_legs_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Load on One Leg",
        variable: "Q",
        txtFieldName: "Q_Load_on_One_Leg_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Radius of Head",
        variable: "R",
        txtFieldName: "R_Radius_of_Head_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Lever arm of Load",
        variable: "H",
        txtFieldName: "H_Lever_arm_of_Load_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Dimension wear plate 2A",
        variable: "2A",
        txtFieldName: "Dim_wear_plate_2A_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Wear plate A",
        variable: "A",
        txtFieldName: "wear_plate_A_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Dimension wear plate 2B",
        variable: "2B",
        txtFieldName: "Dim_wear_plate_2B_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Wear plate B",
        variable: "B",
        txtFieldName: "wear_plate_B_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Stress",
        variable: "S",
        txtFieldName: "S_Stress_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Design Pressure",
        variable: "P",
        txtFieldName: "P_Design_Pressure_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Joint Efficiency",
        variable: "E",
        txtFieldName: "E_Joint_Efficiency_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Wall thickness",
        variable: "t",
        txtFieldName: "t_wall_thickness_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Yield Point",
        variable: "σ",
        txtFieldName: "Yield_Point_Lug",
        required: true,
        type: "number",
    },
]

export const Lug_Support_OutputData = [
    {
        label: "S1 Maximum tensile stress Long",
        variable: "S1",
        txtFieldName: "S1_Maximum_tensile_stress_Long_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Stress",
        variable: "S",
        txtFieldName: "Stress_Lug",
        required: true,
        type: "number",
    },
    {
        label: "Sum of Stress",
        variable: "S + S1",
        txtFieldName: "Sum_of_Stress_Lug",
        required: true,
        type: "number",
    },
    {
        label: "S2 Maximum Circumferential stress Long",
        variable: "S2",
        txtFieldName: "S2_Maximum_Circumferential_stress_Long_Lug",
        required: true,
        type: "number",
    },
]

export const Leg_Support_InputData = [
    {
        label: "Weight of vessel pounds",
        variable: "W",
        txtFieldName: "W_Weight_of_vessel_pounds_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Number of legs",
        variable: "n",
        txtFieldName: "n_number_of_legs_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Load on One Leg",
        variable: "Q",
        txtFieldName: "Q_Load_on_One_Leg_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Radius of Head",
        variable: "R",
        txtFieldName: "R_Radius_of_Head_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Lever arm of Load",
        variable: "H",
        txtFieldName: "H_Lever_arm_of_Load_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Dim wear plate 2A",
        variable: "2A",
        txtFieldName: "Dim_wear_plate_2A_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Wear plate A",
        variable: "A",
        txtFieldName: "wear_plate_A_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Dimension wear plate 2B",
        variable: "2B",
        txtFieldName: "Dim_wear_plate_2B_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Wear plate B",
        variable: "B",
        txtFieldName: "wear_plate_B_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Stress",
        variable: "S",
        txtFieldName: "S_Stress_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Design Pressure",
        variable: "P",
        txtFieldName: "P_Design_Pressure_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Joint Efficiency",
        variable: "E",
        txtFieldName: "E_Joint_Efficiency_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Wall thickness",
        variable: "t",
        txtFieldName: "t_wall_thickness_Leg",
        required: true,
        type: "number",
    },
]

export const Leg_Support_OutputData = [
    
    {
        label: "C Radius of circular wear plate",
        variable: "C",
        txtFieldName: "C_Radius_of_circular_wear_plate_Leg",
        required: true,
        type: "number",
    },
    {
        label: "D Value",
        variable: "D",
        txtFieldName: "D_Value_Leg",
        required: true,
        type: "number",
    },
    {
        label: "S1 Maximum tensile stress Long",
        variable: "SL1",
        txtFieldName: "S1_Maximum_tensile_stress_Long_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Stress",
        variable: "St",
        txtFieldName:"Stress_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Sum of Stress1",
        variable: "SL1 + St1",
        txtFieldName: "Sum_of_Stress1_Leg",
        required: true,
        type: "number",
    },
    {
        label: "S1 Maximum compressional stress Long",
        variable: "SC1",
        txtFieldName: "S1_Maximum_compressional_stress_Long_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Sum of Stress2",
        variable: "SC1 + St",
        txtFieldName: "Sum_of_Stress2_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Maximum tensile stress Cir",
        variable: "SC2",
        txtFieldName: "S2_Maximum_tensile_stress_Cir_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Sum of Stress3",
        variable: "SC2 + St",
        txtFieldName: "Sum_of_Stress3_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Maximum compressional stress Cir",
        variable: "SL2",
        txtFieldName: "S2_Maximum_compressional_stress_Cir_Leg",
        required: true,
        type: "number",
    },
    {
        label: "Sum of Stress4",
        variable: "SL2 + St",
        txtFieldName: "Sum_of_Stress4_Leg",
        required: true,
        type: "number",
    },
]


