import React from "react";
import { ResultColumns } from "./columnNames/NozzleColumns";
import { compactTableProps, greyTable } from "../../../../../../styles/muiProps";
import { useFieldArray, useFormContext } from "react-hook-form";
import {MaterialReactTable} from "material-react-table";

const ResultTable = (props) => {
  const { isLoading, isError } = props;
  const { control } = useFormContext();
  const { fields: resultFields } = useFieldArray({
    control,
    name: "DataGridView3",
  });

  return (
    <MaterialReactTable
      columns={ResultColumns ?? []}
      data={resultFields ?? []}
      {...compactTableProps}
      {...greyTable}
      enableSorting={false}
      defaultColumn={{ minSize: 20, size: 40 }}
      enableColumnActions={false}
      muiToolbarAlertBannerProps={
        isError
          ? {
              color: "error",
              children: "Calculation failed. Please check Input Data",
            }
          : undefined
      }
      state={{
        isLoading,
        showAlertBanner: isError,
      }}
      muiTableHeadCellProps={({ cell }) => ({
        sx: {
          borderRight: "1px solid rgba(224,224,224,1)",
          backgroundColor: "hsla(250, 86%, 97%, 1)",
          // fontSize: "16px",
          // display: "flex",
          // alignItems: "center",
          // px: 0,
          pt: 2,
          height: "95px",
          "& .Mui-TableHeadCell-Content-Wrapper": {
            whiteSpace: "normal",
            justifyContent: "center",
          },
        },
      })}
      muiEditTextFieldProps={({ cell }) => ({
        sx: {
          "& .MuiInputBase-input": {
            padding: "8.5px 8px",
            textAlign: "center",

            borderRight: "1px solid rgba(224,224,224,1)",
          },
        },
      })}
    />
  );
};

export default ResultTable;
