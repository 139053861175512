import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useQuery } from "@tanstack/react-query";
import { getAllUsers } from "../../../../../../api/usersApi";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export default function AssignedEngsForm() {
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const {
    isLoading,
    isError,
    isFetching,
    isSuccess,
    data: user_data,
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => getAllUsers(),
    // onSuccess: //console.log("Fetched user data..."),
    refetchOnWindowFocus: false,
  });

  // console.log("user_data", user_data)
  // if (isSuccess)
  return (
    <div>
      <FormControl size="small" sx={{ m: 1, width: 300 }}>
        <InputLabel id="demo-multiple-checkbox-label">Assigned Eng.</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Assigned Eng." />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {user_data
            ? user_data.map((user) => (
                <MenuItem key={user.id} value={user.username}>
                  <Checkbox checked={personName.indexOf(user.username) > -1} />
                  <ListItemText primary={user.username} />
                </MenuItem>
              ))
            : []}
        </Select>
      </FormControl>
    </div>
  );
}
