import axios from "./axios"

const GetUserTaskRoute = "tasks/getTasks"
const createShellTasksUrl = "pressure_vessel/createShellTasks"

const getTotalProgressRoute = "tasks/getTotalProgress"

export const getUserTasks = async () => {
    const response = await axios.get(GetUserTaskRoute)
    //console.log("USerTAsks: ", response)
    return response.data.userTasks
}

export const getTotalProgress = async (totalProgress) => {
    return await axios.get(getTotalProgressRoute)
}

export const createShellTasks = async (input, estimation, projectId, equipId) => {
    //////////////////////////add shell task stuff, add it to the estimatoin table page
    return await axios.post(createShellTasksUrl, { input, estimation, projectId, equipId })
}