import { React, useState, useContext } from 'react'
import { Link, useNavigate, Navigate } from 'react-router-dom'
import sideImg from '../../../images/auth_form_eng.jpg'
import { AuthContext } from '../../../context/authContext'

const StaffLogin = () => {
  const [input, setInput] = useState({
    email: "",
    password: "",
  })

  const [error, setError] = useState("")

  const navigate = useNavigate()

  const { login, auth } = useContext(AuthContext)
  const currentUser = auth.role
  // console.log("currentUser",currentUser)
  //Handle Changes in form
  function changeHandler(event) {
    const { name, value } = event.target
    setInput(prevData => ({
      ...prevData,
      [name]: value
    }))

  }

  {/**Might not be required with form 'required'? */ }
  const submitHandler = async (event) => {
    event.preventDefault()
    // //console.log(event)
    if (!input.email) {
      setError("Email required!")
    }
    else if (!input.password) {
      setError("Password required!")
    }
    else {
      try {
        await login(input)
        // navigate("/")
      } catch (err) {
        setError(err.response.data.error)
      }
    }
  }

  //console.log(input)

  if (currentUser)
    //  return <Navigate to ="/main_projects"/>
    return <Navigate to="/projects" />
  else
    return (
      <div className="auth-container">
        <img src={sideImg}></img>
        <div className="auth">
          <h1>Login</h1>
          <form>
            <p className="error">{error}</p>
            <input required type="text"
              placeholder="Email"
              name="email"
              value={input.email}
              onChange={changeHandler} />
            <input required type="password"
              placeholder="Password"
              name="password"
              value={input.password}
              onChange={changeHandler}
            />
            <span className="forgotpwd"><Link to="/dev/resetPassword">Forgot Password?</Link></span>
            <button onClick={submitHandler}>Login</button>
            {/* <span>Don't have an Account? <Link to="/register">Sign Up!</Link></span> */}
          </form>
        </div>
      </div>
    )
}

export default StaffLogin