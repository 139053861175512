import { Box, Stack } from "@mui/material";

export const columns = [
  {
    accessorKey: "Material Code",
    header: "Material Code",
    size: 120,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  {
    accessorKey: "Material Description",
    header: "Material Description",
    size: 290,
    enableHiding: false,
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
  },
  // {
  //     accessorKey: "BOM Adder",
  //     header: "BOM Adder",
  //     size: 200,
  //     enableSorting: false,
  // muiTableHeadCellProps: { align: "center" },
  // },
  {
    accessorKey: "Estimated Weight", //!!This is the value from estimation. Needs to be renamed to esimated weight.!
    header: "Est. Weight",
    Header: (
      <div>
        <div>Estimated</div>
        <div>Weight </div>
        <div>(kg)</div>
      </div>
    ),
    size: 80,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    muiTableHeadCellProps: { align: "left" },
  },
  {
    accessorKey: "Actual Weight", //actual weight from est
    header: "Actual. Weight",
    Header: (
      <div>
        <div>Actual</div>
        <div>Weight </div>
        <div>(kg)</div>
      </div>
    ),
    size: 80,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    muiTableHeadCellProps: { align: "left" },
  },
  // {
  //     accessorKey: "Qty",
  //     header: "Qty",
  //      size: 80,
  //     enableSorting: false,
  // muiTableHeadCellProps: { align: "center" },
  // },
  // {
  //     accessorKey: "Unit",
  //     header: "Unit",
  //     size: 70,
  //     enableSorting: false,
  // muiTableHeadCellProps: { align: "center" },
  // },
  // {
  //     accessorKey: "Unit Cost",
  //     header: "Unit Cost",
  //     size: 70,
  //     enableSorting: false,
  // muiTableHeadCellProps: { align: "center" },
  // },
  // {
  //     accessorKey: "Total Cost",
  //     header: "Total Cost",
  //     enableSorting: false,
  // muiTableHeadCellProps: { align: "center" },
  //     size: "50",
  // },
  {
    accessorKey: "Total Cost",
    header: "Est. Material Cost $",
    Header: (
      <div>
        <div>Estimated</div>
        <div>Material Cost</div>
        <div>($)</div>
      </div>
    ),
    size: 90,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
    Footer: () => (
      <Stack>
        <Box color="warning.main" sx={{ textAlign: "center" }}>
          {/* {getValues("totalMaterialCost") || 0} */}
        </Box>
      </Stack>
    ),
  },
  {
    accessorKey: "actual_cost",
    header: "Actual Material Cost $",
    Header: (
      <div>
        <div>Actual</div>
        <div>Material Cost</div>
        <div>($)</div>
      </div>
    ),
    size: 90,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
    Footer: () => (
      <Stack>
        <Box color="warning.main" sx={{ textAlign: "center" }}>
          {/* {getValues("totalMaterialCost") || 0} */}
        </Box>
      </Stack>
    ),
  },
  {
    accessorKey: "Manhours",
    header: "Estimated FAB Hrs",
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
    Header: (
      <div>
        <div>Est.</div>
        <div>FAB Hours</div>
        {/* <div>Hours</div> */}
      </div>
    ),
    Cell: ({ cell }) => (
      <>{cell.getValue() ? parseFloat(cell.getValue()).toFixed(2) : ""}</>
    ),
    size: 80,
    enableSorting: false,
  },
  {
    accessorKey: "actual_hours",
    header: "Actual FAB Hrs",
    Header: (
      <div>
        <div>Actual</div>
        <div>FAB Hours</div>
        {/* <div>Hours</div> */}
      </div>
    ),
    size: 90,
    enableSorting: false,
    muiTableBodyCellProps: { align: "right" },
    // muiTableHeadCellProps: { align: "center" },
    // Cell: ({ cell }) => (<>{cell.getValue()?.split(".")[0]}</>),
    Footer: () => (
      <Stack>
        <Box color="warning.main" sx={{ textAlign: "center" }}>
          {/* {getValues("totalHours") || 0} */}
        </Box>
      </Stack>
    ),
  },
  {
    accessorKey: "Remarks",
    header: "Remarks",
    enableSorting: false,
    // muiTableHeadCellProps: { align: "center" },
    size: 100,
  },
  // ...othercolumns
];
