import axios from "./axios.js"

const base = "/drawings"
const getDrawingsUrl = "/drawings/all/"
const getRevisionsUrl = "/drawings/revs/"
const getSheetsUrl = "/drawings/sheets"
const modifyMRQUrl = "/drawings/sheets"

const getMaterialsUrl = "/materials/all"

export const getDrawings = async (equipId) => {
    console.log("getting: " + equipId)
    const resp = await axios.get(getDrawingsUrl + equipId)
    return resp
}

// export const getRevisions = async (dwg) => {
//     return await axios.get(getRevisionsUrl + dwg)
// }

// export const getSheets = async (rev, dwg) => {
//     //------- return sheets along with drawing Id
//     return await axios.get(`${getSheetsUrl}/${dwg}/${rev}`)
// }

export const getBOM = async (request) => {
    //-------- send drawing Id and get all materials of that drawing ID - sheet contains value of id
    console.log("gettign BOM", request.id)
    const result = await axios.get(`${getMaterialsUrl}/${request.id}`)
    console.log("result", result.data)
    return result.data
}

// export const updateMRQ = async (request) => {
//     // console.log("Endpoint:", `${base}/${request.drawing}/${request.rev}/${request.sheet}`)
//     // const result = await axios.put(`${base}/update/mrq`, request)
//     const result = await axios.post(`${base}/update/mrq`, request)
//     console.log("update: ", result)
//     return result
// }

export const updatePO = async (request) => {
    // console.log("Endpoint:", `${base}/${request.drawing}/${request.rev}/${request.sheet}`)
    const result = await axios.put(`${base}/update/po`, request)
    console.log("update: ", result)
    return result
}

export const updateMRV = async (request) => {

    // console.log("Endpoint:", `${base}/${request.drawing}/${request.rev}/${request.sheet}`)
    const result = await axios.put(`${base}/update/mrv`, request)
    console.log("update: ", result)
    return result
}

export const updateSRV = async (request) => {

    // console.log("Endpoint:", `${base}/${request.drawing}/${request.rev}/${request.sheet}`)
    const result = await axios.put(`${base}/update/srv`, request)
    console.log("update: ", result)
    return result
}