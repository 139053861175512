import React, { useContext } from "react";
import { StyleContext } from "./context/styleContext";
import { RouterProvider } from "react-router-dom";
import { router } from "./routing/router";
import { AuthContext } from "./context/authContext";

export default function AppMainArea() {
  const { auth } = React.useContext(AuthContext);

  const { sideNavOpen } = useContext(StyleContext);

  const style = auth.role
    ? sideNavOpen
      ? "main-area-side-nav-opened"
      : "main-area-side-nav-closed"
    : "";
  return (
    <div className={`container`}>
      <RouterProvider router={router} />
    </div>
  );
}
