import React from "react";
import { createBrowserRouter, Outlet } from "react-router-dom";

//AuthHandlers

//--------Pages-------
// import Home3 from "../../pages/public/Home3";
import Home2 from "../../pages/public/Home/Home2";
import Error from "../../pages/public/Error";
import Navbar from "../../components/MainNavs/Navbar2";
import Footer from "../../components/Footer";

import MyTasks from "../../pages/staff/MyTasks2";

//--------Admin-------
import { AuthProtected } from "../AuthProtectedOutlet";
import { AdminProtected } from "../AdminProtectedOutlet";
import SideNav from "../../components/MainNavs/SideNav";
import { mainRoutes } from "./mainRoutes";
import { adminProtectedRoutes } from "./adminProtectedRoutes";
import { AuthContext } from "../../context/authContext";
import PersistLogin from "../../components/RoutesHandler/PersistLogin";
import { vendorRoutes } from "./vendorRoutes";
import HorizontalNavbar from "../../components/MainNavs/HorizontalNavbar";
import { ThemeProvider } from "@mui/material";
import { theme } from "../../styles/theme";

//--------Client--------

//--------Test--------

const Layout = () => {
  const { auth } = React.useContext(AuthContext);
  return (
    <>
      <ThemeProvider theme={theme}>
        <PersistLogin>
          <HorizontalNavbar />
          {/* <Navbar /> */}
          {/* {auth.role && <SideNav />} */}
          <Outlet />
        </PersistLogin>
      </ThemeProvider>

      {/* <Footer /> */}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      ...mainRoutes,
      {
        path: "",
        element: <AuthProtected />,
        children: [
          {
            path: "/mytasks",
            element: <MyTasks />,
          },
          {
            path: "",
            element: <AdminProtected />,
            children: [...adminProtectedRoutes],
          },
        ],
      },
    ],
    errorElement: <Error error="404 page not found" />,
  },
  {
    path: "/home",
    // element: <Home3 />,
    element: <Home2 />,
  },
  ...vendorRoutes,
]);
