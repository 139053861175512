import { Button, Typography } from "@mui/material";
import React from "react";
import ActionDialog from "./ActionDialog";
import { getAvailableActions } from "./utils";

const actions = ["PO", "MRV", "SRV"];

export default function ActionBtns({ selectedRows }) {
  const [open, setOpen] = React.useState(false);

  const [currentAction, setCurrentAction] = React.useState("");

  const isDisabled = () => {
    if (selectedRows?.rows[0]) {
      return false;
    }
    return true;
  };

  const onClick = (from) => {
    setCurrentAction(from);
    setOpen(true);
  };

  return (
    <>
      <Typography
        variant="h6"
        sx={{
          transform: "translateY(3px)",
          color: isDisabled() ? "#bbb" : "#000",
        }}
      >
        Add to :
      </Typography>
      {actions.map((type) => {
        // {getAvailableActions(selectedRows).map((type) => {
        return (
          <Button
            variant="contained"
            disabled={isDisabled()}
            onClick={() => onClick(type)}
          >
            {type}
          </Button>
        );
      })}
      <ActionDialog
        open={open}
        setOpen={setOpen}
        currentAction={currentAction}
        selectedRows={selectedRows}
      />
    </>
  );
}
