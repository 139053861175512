import React from 'react'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Badge from '@mui/material/Badge';

import Check from '@mui/icons-material/Check';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import NightShelterIcon from '@mui/icons-material/NightShelter';
import BookIcon from '@mui/icons-material/Book';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect } from 'react';

import TimesheetFormDialog from '../../components/Dialogs/TimesheetFormDialog';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0),
  paddingLeft: theme.spacing(1),
  display: 'flex',
  textAlign: 'left',
  justifyContent: 'left',
  alignItems: 'center',
  width: "fit-content",
  minHeight: "6rem",
  color: theme.palette.text.secondary,
}))


function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', mr: 4 }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#7a8bd0' : '#308fe8',
  },
}));

//Linear Progress With Label
function LinearProgressWithLabel(props) {
  return (
    <Stack sx={{ display: 'flex', alignItems: 'flex-start' }}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: 12, wordSpacing: 4, ml: 0.2, mb: 0.5 }}>
          {`${Math.round(
            props.value,
          )}% Complete`}</Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress variant="determinate" {...props} />
      </Box>
    </Stack>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};


//Stepper Icon
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  '& .MuiStepConnector-line': {
    minHeight: "10px"
  }
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      <div className="QontoStepIcon-circle" />
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#e5e8f2",
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const dividerStyling = {
  mr: 3, ml: 3, height: "5rem", mt: "2.2rem"
}

const actions = [
  { icon: <WorkOffIcon />, name: 'Day Off' },
  { icon: <MenuBookIcon />, name: 'Training' },
];



const ActiveTasks = (props) => {
  const { getTasks, addTimesheetMutation, totalProgressData, groupBy, getTaskData, getTotalProgress } = props
  const [tasks, holidayId, trainingId] = getTaskData()
  const todayDate = new Date().toJSON().slice(0, 10)
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState("")
  const [dialogTitle, setDialogTitle] = React.useState({
    title: "",
    progress: 0
  })

  // console.log(tasks)
  const initialData = {
    date: "",
    progress: 0,
    hours: 0,
    remarks: "",
    UserTaskId: 0
  }
  const [timesheetForm, setTimesheetForm] = React.useState(initialData)

  const handleClickOpen = (e, UserTaskId, title, progress) => {

    setTimesheetForm({
      ...timesheetForm,
      UserTaskId: UserTaskId
    })
    setDialogTitle({
      title: title,
      progress: progress
    })
    setOpen(true);
  };

  const handleClose = () => {
    setTimesheetForm(initialData)
    setError("")
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target
    setTimesheetForm({
      ...timesheetForm,
      [name]: value
    })
  }

  const handleSliderChange = (event, newValue) => {
    setTimesheetForm({
      ...timesheetForm,
      progress: newValue
    })
  };

  const submitHandler = () => {
    if (!timesheetForm.date || !timesheetForm.hours || !timesheetForm.progress || !timesheetForm.remarks)
      setError("Please fill in all fields")
    else if (!timesheetForm.UserTaskId)
      setError("Failed to fetch user task")
    else {
      addTimesheetMutation.mutate(timesheetForm)
      handleClose()
    }
  }

  const nonTaskSubmitHandler = () => {
    if (!timesheetForm.date)
      setError("Please fill in the date")
    else if (!timesheetForm.UserTaskId)
      setError("Failed to fetch user task")
    else {
      addTimesheetMutation.mutate(timesheetForm)
      handleClose()
    }
  }


  const getStatus = (dueDate, estimatedHours, actualHours, progress) => {
    if (todayDate > dueDate)
      return "OverDue"

    if (progress < ((actualHours / estimatedHours) * 100))
      return "Behind schedule"
    return "Ongoing"
  }

  const getStatusColor = (status) => {
    if (status === "Ongoing")
      return "primary"
    else if (status === "OverDue")
      return "error"
    else if (status === "Behind schedule")
      return "warning"
  }


  return (
    <Box 
    id="taskBox"
    sx={{
      width: {
        md: 850,
        lg: 1140,
        xl: 1300
      },
      maxHeight: "100%"
    }}>
      <Stack id="taskStack" spacing={1} sx={{ alignItems: "center" }}>
        {
          tasks?.length ?
            tasks.map((taskData, index) => {
              if (!taskData.projectName) {
                return false
              }

              // if (index == tasks.length - 1) {
              //   if (!document.getElementById("taskStack")?.textContent)
              //     return <Item sx={{ width: "60%", textAlign: "center" }}>
              //       <Typography >No tasks to show</Typography>
              //     </Item>
              // }

              if (taskData.progress === 100 || taskData.holidayId || taskData.trainingId) {
                return false
              }

              const status = getStatus(taskData.dueDate, taskData.estimatedHours, taskData.actualHours,
                taskData.progress)
              // console.log(status)

              return (
                <Item key={taskData.id}>
                  <Stack sx={{ alignItems: "center", ml: 2, width: "30px" }}>
                    <Badge color={getStatusColor(status)} variant="dot">
                      {/* <Badge color="primary" variant="dot"> */}
                      <AssignmentIcon sx={{ fontSize: "3rem", color: "#e5e8f2" }} />
                    </Badge>
                    {/* <Chip label="Ongoing" variant="outlined" color="primary"  sx={{ fontSize: ".8rem" }}/> */}
                    <Typography fontSize={11}>{status}</Typography>
                  </Stack>
                  <Divider orientation="vertical" variant="middle" light flexItem sx={{ ...dividerStyling }} />

                  {/* <Stack sx={{ width: "20rem" }}> */}
                  {/* <Stack sx={{ minWidth: "10rem", maxWidth: "25rem" }}> */}
                  <Stack sx={{
                    width: {
                      xs: 100,
                      sm: 150,
                      md: 200,
                      // lg: 250,
                      // xl: 400
                    },
                  }}>
                    <Typography sx={{ fontSize: "0.875rem" }}>{taskData.name} </Typography>
                    <Typography sx={{ mt: "0.2rem", color: "#8c99cc" }}>Project: {taskData.projectName} </Typography>
                  </Stack>

                  <Divider orientation="vertical" variant="middle" light flexItem sx={{ ...dividerStyling }} />

                  <Stepper activeStep={(taskData.dueDate > (new Date().toJSON().slice(0, 10))) ? 0 : 1}
                    orientation="vertical"
                    // sx={{ width: "7rem" }}
                    sx={{
                      width: "10%", '& .MuiStepConnector-line': {
                        minHeight: "10px"
                      }
                    }}
                  >
                    <Step >
                      <StepLabel StepIconComponent={QontoStepIcon}
                        optional={
                          <Typography sx={{ fontSize: 10 }}>Initial Date</Typography>
                        }>
                        {taskData.initialDate}
                      </StepLabel>
                    </Step>
                    <Step >
                      <StepLabel StepIconComponent={QontoStepIcon}
                        optional={
                          <Typography sx={{ fontSize: 10 }}>Due Date</Typography>
                        }>
                        {taskData.dueDate}
                      </StepLabel>
                    </Step>
                  </Stepper>

                  <Divider sx={{ ...dividerStyling }} orientation="vertical" light variant="middle" flexItem />

                  {/* <Stack sx={{ width: "5rem" }}> */}
                  <Stack sx={{ width: "5%" }}>
                    <Typography>Est. Hours</Typography>
                    <Typography sx={{ fontSize: "1rem" }}>{taskData.estimatedHours || 0}</Typography>
                  </Stack>

                  <Divider sx={{ ...dividerStyling }} orientation="vertical" light variant="middle" flexItem />

                  {/* <Stack sx={{ width: "6rem" }}> */}
                  <Stack sx={{ width: "5%" }}>
                    <Typography>Hours Spent</Typography>
                    <Typography sx={{ fontSize: "1rem" }}>{taskData.actualHours}</Typography>
                  </Stack>

                  <Divider sx={{ ...dividerStyling }} orientation="vertical" light variant="middle" flexItem />
                  {/* <Box sx={{ width: "12rem" }}> */}
                  <Box sx={{ width: "12%" }}>
                    <LinearProgressWithLabel sx={{ width: "100%" }} value={taskData.progress} />
                  </Box>

                  <Divider sx={{ ...dividerStyling }} orientation="vertical" light variant="middle" flexItem />

                  {
                    taskData.progress == 100 ?
                      <IconButton
                        disabled
                        sx={{ mr: 3 }}
                      >
                        <PostAddOutlinedIcon sx={{ fontSize: "3rem", color: "#c4bcd0" }} />
                      </IconButton> :
                      <>
                        <LightTooltip title="Add Timesheet" >
                          <IconButton
                            aria-label="expand row"
                            sx={{ mr: 3 }}
                            onClick={e => handleClickOpen(e, taskData.id, taskData.name, taskData.progress)}
                          >
                            <PostAddOutlinedIcon sx={{ fontSize: "2rem", color: "#4e5fa2" }} />

                          </IconButton>
                        </LightTooltip>
                        <LightTooltip title={taskData.remarks} >
                          {/* <IconButton
                            aria-label="expand row"
                            sx={{ mr: 3 }}
                          >
                             */}
                          <InfoIcon sx={{ fontSize: "1.5rem", color: "#4e5fa2", mr: 3 }} />

                          {/* </IconButton> */}
                        </LightTooltip>
                      </>
                  }

                </Item>
              )
            }) :
            <Item>
              <Typography>Nothing to show</Typography>
            </Item>
        }

        <TimesheetFormDialog
          open={open}
          handleClose={handleClose}
          error={error}
          timesheetData={timesheetForm}
          handleChange={handleChange}
          handleSliderChange={handleSliderChange}
          submitHandler={submitHandler}
          dialogTitle={dialogTitle}
          // nonTaskId = {nonTasks}
          nonTaskSubmitHandler={nonTaskSubmitHandler}
        >
        </TimesheetFormDialog>
      </Stack>

      <SpeedDial
        ariaLabel="Extra actions"
        // sx={{ position: "relative", bottom: "14rem", left: "auto", right: 20, top: "auto", }}
        // sx={{ position: "sticky"}}
        sx={{ position: "relative", top: 10, justifyContent: "center", right: "5rem", }}
        icon={<SpeedDialIcon />}
        direction="left"
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            // onClick={e => handleClickOpen(e, action.name == "Training" ? nonTasks.trainingId : nonTasks.holidayId,
            onClick={e => handleClickOpen(e, action.name == "Training" ? trainingId : holidayId,
              action.name, 0)}
          />
        ))}
      </SpeedDial>
    </Box >
  )
}

export default ActiveTasks