import React from 'react'
import { styled, createTheme } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import ActiveTasks from './ActiveTasks'
import CompletedTasks from './CompletedTasks'
import MyTimesheets from './MyTimesheets'

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Container } from '@mui/system';

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { getUserTasks, getTotalProgress } from "../../api/tasksApi"
import { getTimesheets, createTimesheet, editTimesheet, deleteTimesheet } from "../../api/timesheetApi"

import { ThemeProvider } from '@emotion/react';

const StyledTabs = styled((props) => (
    <Tabs
        // sx={{display: "flex", flexWrap: "wrap", justifyContent: "center", alignItems: "center"}}
        // sx={{ alignItems: "center"}}
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: '#635ee7',
    },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        fontWeight: theme.typography.fontWeightRegular,
        fontSize: theme.typography.pxToRem(20),
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),

        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',

        color: 'rgba(0,0,0, 0.7)',
        '&.Mui-selected': {
            color: '#7a8bd0',
        },
        '&.Mui-focusVisible': {
            backgroundColor: 'rgba(100, 95, 228, 0.32)',
        },
    }),
);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};


const theme = createTheme({
    typography: {
        // In Chinese and Japanese the characters are usually larger,
        // so a smaller fontsize may be appropriate.
        fontSize: 12,
    },
});

const NewTimesheets = () => {
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const queryClient = useQueryClient()

    const { isSuccess, isLoading, isError, data: userTasks } = useQuery({
        queryKey: ['user_tasks'],
        queryFn: getUserTasks,
        // select: data => data.sort((a, b) => b. - a.id)

    })

    // if(isSuccess) {
    //     console.log("usetaks",userTasks)
    // }
    const timesheetQuery = useQuery({
        queryKey: ['timesheets'],
        queryFn: getTimesheets,
    })
    const { data: timesheetData } = timesheetQuery

    const totalProgressQuery = useQuery({
        queryKey: ['totalProgress'],
        queryFn: getTotalProgress,
    })
    const { data: totalProgressData } = totalProgressQuery

    const getTaskProgress = (data, subcatId) => {
        let totalHours = 0
        let totalProgress = 0
        // const subCategory = data[subcatId]
        //concat all timesheets array under a subcategory into one array
        let timesheets = data?.[subcatId]?.reduce((acc, curr) => acc.concat(curr), [])
        let totalHours2 = timesheets?.reduce((accumulator, currentValue) => accumulator + currentValue.hours, 0) || 0
        let totalProgress2 = timesheets?.reduce((accumulator, currentValue) => accumulator + currentValue.progress, 0) || 0
        // console.log("frmo getTaskProgress", timesheets, totalHours2, totalProgress2)
        //console.log("indie:", data, "subcatID: ", subcatId)
        // subCategory?.forEach(usertask => {
        //     usertask.forEach(item => {
        //         totalHours += parseInt(item.totalHours)
        //         totalProgress += parseInt(item.totalProgress)
        //     })
        // })
        return [totalHours2, totalProgress2]
    }

    //To group all the user progress under one subcategory
    const groupBy = (data, property) => {
        return data?.reduce((accumulator, item) => {
            const key = item[property];
            const currentGroup = accumulator[key] ?? []
            return {
                ...accumulator,
                [key]: [
                    ...currentGroup,
                    item.timesheets
                ]
            }
        }, {})
    }



    const addTimesheetMutation = useMutation({
        mutationFn: createTimesheet,
        onSuccess: () => {
            queryClient.invalidateQueries("timesheets")
        }
    })

    const editTimesheetMutation = useMutation({
        mutationFn: editTimesheet,
        onSuccess: () => {
            queryClient.invalidateQueries("timesheets")
        }
    })

    const deleteTimesheetMutation = useMutation({
        mutationFn: deleteTimesheet,
        onSuccess: () => {
            queryClient.invalidateQueries("timesheets")
        }
    })

    if (totalProgressQuery.isSuccess) {
        // const totalProgressData = totalProgressQuery?.data
        const totalProgressData = totalProgressQuery?.data?.data
        let newObject = {}
        const subCategoryProgressData = groupBy(totalProgressData, "subCategoryId")
        for (const [key, value] of Object.entries(subCategoryProgressData)) {
            // u got {5:[ [],[],[]]}
            //concat all timesheet array into one
            let timesheets = value?.reduce((acc, curr) => acc.concat(curr), [])
            let totalHours = timesheets?.reduce((accumulator, currentValue) => accumulator + currentValue.hours, 0)
            let totalProgress = timesheets?.reduce((accumulator, currentValue) => accumulator + currentValue.progress, 0)
            // timesheets.map
            newObject[key] = { totalHours: totalHours, totalProgress: totalProgress }
            // console.log("timesheets", timesheets)
        }
        // let x = subCategoryProgressData.reduce((acc, curr) => acc.concat(curr), [])
        // console.log(totalProgressData, subCategoryProgressData, newObject)
        // [totalHours, totalProgress]


    }

    const getTaskData = () => {
        const tasks = userTasks.UserTasks.map(task => {
            return ({
                id: task.id,
                task: task.subCategory,
            })
        })
        const subCategoryProgressData = groupBy(totalProgressData?.data, "subCategoryId")
        let holiday = 0
        let training = 0
        const taskData = tasks.map((task) => {
            if (task.task.name.toLowerCase() === "training") {
                training = task.id
            } // could just return holidayId or not
            if (task.task.name.toLowerCase() === "day off") // could just return holidayId or not
                holiday = task.id
            return {
                id: task.id,
                name: task.task.name,
                projectName: task.task.category?.mcsProject?.number,
                initialDate: (task.task.createdAt + "").split("T")[0],
                dueDate: task.task.targetDate != null ? (task.task.targetDate + "").split("T")[0] : "Not set",
                estimatedHours: task.task.estimatedHours,
                actualHours: getTaskProgress(subCategoryProgressData, task.task.id)[0],
                progress: getTaskProgress(subCategoryProgressData, task.task.id)[1],
                remarks: task.task.remarks || "No Remarks",
                holidayId: task.task.name.toLowerCase() === "day off" ? task.id : 0,
                trainingId: task.task.name.toLowerCase() === "training" ? task.id : 0,
            }
        })
        return [taskData, holiday, training]
    }

    //For getting only the total progress while editing a timesheet
    const geteditTaskProgress = (subCategoryId) => {
        const subCategoryProgressData = groupBy(totalProgressData?.data, "subCategoryId")
        const progress = getTaskProgress(subCategoryProgressData, subCategoryId)[1]
        return progress
    }

    if (isSuccess) {
        //console.log("data", userTasks.UserTasks)
    }

    // if (!isSuccess || !timesheetQuery.isSuccess || !totalProgressQuery.isSuccess) {
    //     return (
    //         <Box width="100%" height="450px" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    //             <CircularProgress />
    //         </Box>
    //     )
    // }

    if (isError) {
        return (
            <h1>Error faced...</h1>
        )
    }

    if (isSuccess)
        return (
            <ThemeProvider theme={theme}>
                <Container minWidth="sm">
                    <Box id="mytasks" sx={{ width: "100%" }}>
                        <Box aria-label="Box2" sx={{ display: "flex", justifyContent: "center" }}>
                            <StyledTabs
                                value={value}
                                onChange={handleTabChange}
                                aria-label="styled tabs example"
                            >
                                <StyledTab label="Active Tasks" />
                                <StyledTab label="Completed Tasks" />
                                <StyledTab label="Timesheets" />
                            </StyledTabs>
                            {/* <Box sx={{ p: 3 }} /> */}
                        </Box>
                        <TabPanel value={value} index={0}>
                            {!userTasks?.UserTasks ?
                                <Box>
                                    <Typography>You have not been assigned any tasks yet</Typography>
                                </Box> :
                                <ActiveTasks
                                    getTaskData={getTaskData}
                                    addTimesheetMutation={addTimesheetMutation}
                                />
                            }
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <CompletedTasks
                                getTaskData={getTaskData}
                            />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <MyTimesheets
                                timesheets={timesheetData}
                                geteditTaskProgress={geteditTaskProgress}
                                editTimesheetMutation={editTimesheetMutation}
                                deleteTimesheetMutation={deleteTimesheetMutation}
                            />
                        </TabPanel>
                    </Box >
                </Container>
            </ThemeProvider>
        )
}

export default NewTimesheets