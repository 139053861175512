import EngineeringIcon from "@mui/icons-material/Engineering";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import ConstructionIcon from "@mui/icons-material/Construction";
import SharePointIcon from "../../../../../../components/CustomIcons/SharePointIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import ArchiveIcon from "@mui/icons-material/Archive";
import Icon from "../../../../../../components/Icons";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

export const ProjectSpecsOpts = [
  {
    label: "Equipments",
    icon: <ConstructionIcon />,
    path: "equipments",
  },
  {
    label: "Pr. Mat. DB",
    icon: <Icon iconName="materialDBIcon" size={26} sx={{ opacity: 0.6 }} />,
    path: "materials",
  },
  {
    label: "Engineers",
    icon: <EngineeringIcon />,
    path: "",
  },
  {
    label: "Tasks",
    icon: <AssignmentIcon />,
    path: "",
  },
  // {
  //   label: "Consolidate Bom",
  //   icon: <AddBusinessIcon />,
  //   path: "",
  // },
  // {
  //   label: "Vouchers",
  //   icon: <ReceiptLongIcon />,
  //   path: "",
  // },
];
export const ProjectSecondOpts = [
  {
    label: "Open in SharePoint",
    icon: <SharePointIcon size={23} />,
    path: "",
  },
];

export const moreOpts = [
  {
    label: "Archive",
    icon: <ArchiveIcon />,
    path: "",
  },
  // {
  //   label: "Favorite",
  //   icon: <StarBorderIcon />,
  //   path: "",
  // },
  {
    label: "Delete",
    icon: <DeleteIcon color="error" />,
    path: "",
  },
];
