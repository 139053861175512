import paths from "../../api/paths.api";
import VendorBidChat from "../../pages/Vendor/BidChat";
import VendorMrqs from "../../pages/Vendor/Mrqs";

export const vendorRoutes = [
  {
    path: paths.vendorMrqs,
    element: <VendorMrqs />,
  },
  {
    path: paths.vendorBidChat,
    element: <VendorBidChat />,
  },
];
