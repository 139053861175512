import React, { useMemo, useState } from "react";
import {MaterialReactTable} from "material-react-table";
// import { data } from './copy tabs/makeData';
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import {
  useForm,
  useFieldArray,
  useFormContext,
  Controller,
  useWatch,
} from "react-hook-form";
import axios from "../../../../../../api/axios";
import checkRequiredFields from "../../../../../../utils/checkRequiredFields";
import CustomAlert from "../../../../../../components/widgets/CustomAlert";
import { Stack, ListItemIcon, Box, Grid } from "@mui/material";
import InputTable from "./InputTable";
import ResultTable from "./ResultTable";
import EstimationTable from "./EstimationTable";

//The Main Function
const ShellTab = (props) => {
  const { reRender, projectId, equipId } = props;
  const [resultVisibility, setresultVisibility] = useState(false);
  const [estimationVisibility, setEstimationVisibility] = useState(false);
  const { control, setValue, getValues } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [showProgressBars, setShowProgressBars] = useState(false);

  const { fields: estimationFields } = useFieldArray({
    control,
    name: "DataGridView7",
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [AlertContent, setAlertContent] = useState({
    severity: "",
    message: "",
  });

  //calculation results
  const showResults = async () => {
    const data = getValues();
    console.log(data);
    setEstimationVisibility(false);

    const result = checkRequiredFields(data);
    console.log(result);
    if (result[0] === false) {
      setAlertContent({
        message: "Following values are missing: " + result[1],
        severity: "error",
      });
      setOpenAlert(true);
    } else {
      setresultVisibility(true);
      setIsLoading(true);
      const res = await axios
        .post("/pressure_vessel/calculateShell", data)
        .then((response) => {
          return response;
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(false);
      setValue("DataGridView6", res.data.DataGridView6);
    }
  };

  const showEstimation = async () => {
    const data = getValues();
    setIsLoading(true);
    setShowProgressBars(true);
    setresultVisibility(false);
    setEstimationVisibility(true);
    const result = await axios
      .post("/pressure_vessel/estimate", data)
      .then((response) => {
        console.log(response.data);
        setValue("DataGridView7", response.data.DataGridView7);
        setValue("DataGridView5", response.data.DataGridView5);
        setIsLoading(false);
        setShowProgressBars(false);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        minWidth: "610px",
        backgroundColor: "transparent",
      }}
    >
      <CustomAlert
        open={openAlert}
        setOpen={setOpenAlert}
        severity={AlertContent.severity}
        message={AlertContent.message}
      />
      <br />
      <Stack spacing={2} direction="row">
        <Button
          color="secondary"
          onClick={() => showResults()}
          variant="contained"
        >
          Calculate
        </Button>
        <Button
          color="secondary"
          onClick={() => showEstimation()}
          variant="contained"
        >
          Estimate
        </Button>
      </Stack>
      <br />
      <Box sx={{ width: "1340px" }}>
        <Grid container rowSpacing={1} spacing={1} columns={12}>
          {/* Input Grid */}
          <Grid item xs={6.5}>
            <InputTable reRender={reRender} isLoading={isLoading} />
          </Grid>
          {/* Result - Calculate Grid */}
          {
            resultVisibility ?
              <Grid item xs={5}>
                <ResultTable isLoading={isLoading} />
              </Grid>
              : <></>
          }
          {estimationVisibility ?
            <Grid item xs={5}>
              <EstimationTable
                isLoading={isLoading}
                projectId={projectId}
                equipId={equipId}
              />
            </Grid>
            : <></>
          }
        </Grid>
      </Box>
    </div>
  );
};

export default ShellTab;
