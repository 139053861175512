import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAtYJuZvCiJ-Ml5QBGVv31r_aI3SUV2mcM",
  authDomain: "enerfaciot.firebaseapp.com",
  databaseURL: "https://enerfaciot-default-rtdb.firebaseio.com",
  projectId: "enerfaciot",
  storageBucket: "enerfaciot.appspot.com",
  messagingSenderId: "810793975251",
  appId: "1:810793975251:web:4a26f5036084aaeb248326",
  measurementId: "G-TKF60XJCH5",
};

const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;
