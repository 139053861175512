import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material'
import React from 'react'


function GradientCircularProgress() {
    return (
        <React.Fragment>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" stopColor="#e01cd5" />
                        <stop offset="100%" stopColor="#1CB5E0" />
                    </linearGradient>
                </defs>
            </svg>
            <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
        </React.Fragment>
    );
}

const DownloadingCard = () => {
    return (
        <Dialog open={true}>
            <DialogTitle  />
            <DialogContent sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <GradientCircularProgress />
                <br/>
                <Typography>Please wait while we download the software</Typography>
            </DialogContent>
        </Dialog>
    )
}

export default DownloadingCard