import React from "react";
import { Box, Button, TextField } from "@mui/material";
import { formCols } from "./utils";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { useForm } from "../../../hooks/formHook";
import { addNewVendor } from "./api";

export default function AddNewForm({
  newFormOpen,
  setNewFormOpen,
  setVendors,
  vendors,
}) {
  const { onChange, onSubmit, values } = useForm(addVendorCallback, {
    name: "",
    email: "",
  });

  async function addVendorCallback() {
    console.log("DATA:", values);
    const res = await addNewVendor(1, values);
    setVendors([...vendors, res[0]]);
  }

  const onAddVendor = (e) => {
    onSubmit(e);
    setNewFormOpen(false);
  };

  return (
    <Box
      sx={{
        textAlign: "left",
        px: 2,
        display: newFormOpen ? "flex" : "none",
        alignItems: "center",
        // border: "1px solid red",
        backgroundColor: "#efefef",
      }}
    >
      {formCols.map((formCol) => {
        return (
          <Box
            sx={{
              minWidth: formCol.minWidth,
              textAlign: formCol.align,
              display: "inline-block",
            }}
            key={formCol.id}
          >
            {formCol.id === "name" ? (
              <TextField
                id="name"
                name="name"
                label="Name"
                autoComplete="off"
                onChange={onChange}
                value = {values.name}
                variant="standard"
                sx={{ m: 1, mt: 0 }}
              />
            ) : formCol.id === "email" ? (
              <TextField
                id="email"
                name="email"
                label="Email"
                autoComplete="off"
                onChange={onChange}
                value = {values.email}
                variant="standard"
                sx={{ m: 1, mt: 0 }}
              />
            ) : formCol.id === "actions" ? (
              <Button
                variant="outlined"
                endIcon={<PersonAddAlt1Icon />}
                onClick={(e) => onAddVendor(e)}
              >
                Add Vendor
              </Button>
            ) : (
              ""
            )}
          </Box>
        );
      })}
    </Box>
  );
}
