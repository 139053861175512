import React from "react";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function AccordionGroup({
  keys,
  panels,
  defaultExpandedArray,
  keysAsComponents,
}) {
  const [expanded, setExpanded] = React.useState(
    defaultExpandedArray && defaultExpandedArray[0]
      ? defaultExpandedArray
      : [keys[0]]
  );

  const handleChange = (panel) => (event, newExpanded) => {
    const clickedPanel = event.target.innerText;

    expanded.includes(clickedPanel)
      ? setExpanded(expanded.filter((e) => e !== clickedPanel))
      : setExpanded([...expanded, clickedPanel]);
  };

  return (
    <div style={{ overflow: "hidden", width: "100%" }}>
      {keys.map((panelKey, i) => {
        return (
          <Accordion
            expanded={expanded.includes(panelKey)}
            onChange={handleChange(panelKey)}
            key={panelKey}
          >
            <AccordionSummary
              aria-controls={panelKey + "control"}
              id={panelKey}
            >
              {keysAsComponents && keysAsComponents[panelKey] ? (
                keysAsComponents[panelKey]
              ) : (
                <Typography sx={{ fontWeight: "bold" }}>{panelKey}</Typography>
              )}
            </AccordionSummary>
            <AccordionDetails>{panels[i]}</AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
