import React, { useEffect, useState } from 'react'
import { useForm, Controller, useFormContext } from "react-hook-form";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";

import "@fontsource/roboto/300.css";

import NamesGrid from './NamesGrid';
import DesignDetailsGrid from './DesignDetailsGrid';
import HeadingInputsGrid from './HeadingInputsGrid';
import GeometricParamsGrid from './GeometricParamsGrid';
import MaterialsGrid from './MaterialsGrid';
import axios from '../../../../../../api/axios';


const GridStyle = {
  "&.MuiGrid-item": { p: "0px 0px 0px 0px" },
};

const DesignTab = (props) => {
const { setValue, watch,getValues } = useFormContext();

useEffect(()=>{
    props.pidState===2 &&  handleGetPID() 
    props.pidState===4 &&  handleSavePID() 
},[props])

    const handleGetPID = async ()=>{
        const result = await axios.get('/pressure_vessel_table/vessel-pid')
        console.log(result.data)
        setValue('TextBox11',result.data.dataVessel.PnPID)
        setValue('TextBox92',result.data.dataVessel.Designextpressure)
        setValue('ComboBox7',result.data.dataVessel.DiameterBasis)
        setValue('ComboBox3',result.data.dataVessel.HeadType)
        setValue('ComboBox1',result.data.dataVessel.SupportType)
        setValue('TextBox66',result.data.dataVessel.LiquidHeight)
        setValue('TextBox30',result.data.dataVessel.LiquidDensity)
        setValue('TextBox29',result.data.dataVessel.InsulationDensity)
        setValue('TextBox29',result.data.dataVessel.InsulationDensity)
        setValue('ComboBox6','NO')
        setValue('ComboBox8',result.data.dataVessel.HydrotestDesign)
        setValue('TextBox67',result.data.dataVessel.HydrotestPressure)
        setValue('TextBox32',result.data.dataVessel.JointEfficiencyCirc)
        setValue('TextBox31',result.data.dataVessel.JointEfficiencyLong)
        setValue('TextBox94',result.data.dataVessel.MDMT)
        setValue('ComboBox5',result.data.dataVessel.Orientation)
        setValue('TextBox_Seismic_Spec',result.data.dataVessel.SeismicDesign)
        setValue('TextBox49',result.data.dataVessel.WindSpeed)
        setValue('TextBox50',result.data.dataVessel.WindDesignCode)
        setValue('ComboBox20',result.data.dataVessel.Radiography)
        setValue('ComboBox18','NO')
        setValue('TextBox48',result.data.dataEquip.Tag)
        setValue('txtVIDia',result.data.dataTank.Diameter)
        setValue('txtVLenTT',result.data.dataTank.Height)
        setValue('txt_SF',result.data.dataTank.Width)
        setValue('TextBox80',result.data.dataTank.Insulation)
        setValue('txtDPressure',result.data.dataTank.DesignPressure)
        setValue('txtDTemp',result.data.dataTank.DesignTemperature)
        setValue('txtVCA',result.data.dataVessel.CorrosionAllowance)
        setValue('TextBox28',result.data.dataVessel.CorrosionAllowanceNozzle)
        setValue('TextBox52',result.data.dataVessel.FlangeRating)
        setValue('TextBox64',result.data.dataVessel.ASMEB165Group)
      }
      const handleSavePID = ()=>{
        console.log('test button');
        console.log(getValues('TextBox80'))
        let dataVessel ={};
        let dataTank ={};
        let dataEquip={}
        dataVessel.PnPID=getValues('TextBox11')
        dataVessel.Designextpressure=getValues('TextBox92')
        dataVessel.DiameterBasis=getValues('ComboBox7')
        dataVessel.HeadType=getValues('ComboBox3')
        dataVessel.SupportType=getValues('ComboBox1')
        dataVessel.LiquidHeight=getValues('TextBox66')
        dataVessel.LiquidDensity=getValues('TextBox30')
        dataVessel.InsulationDensity=getValues('TextBox29')
        dataVessel.ImpactTest=getValues('ComboBox6')
        dataVessel.HydrotestDesign=getValues('ComboBox8')
        dataVessel.HydrotestPressure=getValues('TextBox67')
        dataVessel.JointEfficiencyCirc=getValues('TextBox32')
        dataVessel.JointEfficiencyLong=getValues('TextBox31')
        dataVessel.MDMT=getValues('TextBox94')
        dataVessel.Orientation=getValues('ComboBox5')
        dataVessel.SeismicDesign=getValues('TextBox_Seismic_Spec')
        dataVessel.WindSpeed=getValues('TextBox49')
        dataVessel.WindDesignCode=getValues('TextBox50')
        dataVessel.Radiography=getValues('ComboBox20')
        dataVessel.PWHT=getValues('ComboBox18')
        dataEquip.Tag=getValues('TextBox48')
        dataTank.Diameter=getValues('txtVIDia')
        dataTank.Height=getValues('txtVLenTT')
        dataTank.Width=getValues('txt_SF')
        dataTank.Insulation=getValues('TextBox80')
        dataTank.DesignPressure = getValues('txtDPressure')
        dataTank.DesignTemperature = getValues('txtDTemp')
        dataVessel.CorrosionAllowance = getValues('txtVCA')
        dataVessel.CorrosionAllowanceNozzle = getValues('TextBox28')
        dataVessel.FlangeRating = getValues('TextBox52')
        dataVessel.ASMEB165Group = getValues('TextBox64')
        axios.post('/pressure_vessel_table/vessel-pid',{dataVessel,dataTank,dataEquip})
      }
    

    return (
        <Box
            backgroundColor="#E5E8F2"
            width="80%"
            sx={{ display: "flex", alignSelf: "center", justifySelf: "center" }}
            overflow="auto"
        >
            <Grid
                container
                marginTop={0.2}
                spacing={1} columns={{ xs: 5, sm: 7, md: 9, lg: 11, xl: 11 }}
                direction="row"
                rowSpacing={0.7}
                alignItems="flex-start"
            >
                {/* Names Grid */}
                {/* Client, Project No., Vessel, Tag No., Rev No. */}

                <NamesGrid />

                {/* Design Details Grid */}

                <DesignDetailsGrid />

        {/* empty grid */}
        <Grid item xs={1} sm={1} md={2} lg={4} xl={5}></Grid>

        {/* Heading Inputs and Design Inputs Grid */}
        {/* <Grid item xs={1.5} sm={2.6} md={2.8} lg={2.9} xl={3} > */}
        <HeadingInputsGrid />

        {/* Geometric Parameters Input Grid */}
        <GeometricParamsGrid />
        {/* empty grid */}
        <Grid item xs={1} lg={5}></Grid>

        {/* {Last Excel box} */}
        <MaterialsGrid />
      </Grid>
    </Box>
  );
};

export default DesignTab;
