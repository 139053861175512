import React from 'react'
import { GridStyle, designTabBoxProps } from '../../../../../../styles/muiProps';
import { designInputs } from './FieldsData/DesignTabFields';
import { CustomSelect } from '../../../../../../components/widgets/CustomSelect';
import CustomTextField from '../../../../../../components/widgets/CustomTextField';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';

const HeadingInputsGrid = () => {
    return (
        <Grid item xs={2} sm={2.6} md={3} lg={3} xl={2.6} >
            {/* Design Inputs Box */}
            <Box
                {...designTabBoxProps}
                noValidate
            >
                {
                    designInputs.map(elem => (
                        <Grid id="inner-container" item container
                            columns={12}
                        >
                            <Grid item sx={{ ...GridStyle }} xs={6}>
                                <Typography fontSize={"0.75rem"}>{elem.label}</Typography>
                            </Grid>
                            < Grid item sx={{ ...GridStyle }} xs={6}>
                                {
                                    elem.select ?
                                        <CustomSelect label="" name={elem.name} required
                                            menuItems={elem.select}
                                        /> :
                                        <CustomTextField label="" id={elem.name} name={elem.name} type={elem.type} pairField={elem.pairField}
                                            inputProps={{ endAdornment: <InputAdornment disableTypography sx={{ fontSize: "0.65rem", color: "black", fontFamily: "Roboto" }} position="end">{elem.endAdornment}</InputAdornment> }}
                                        />
                                }
                            </Grid>
                        </Grid>
                    ))
                }
            </Box>
        </Grid>

    )
}

export default HeadingInputsGrid