import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';

import EditIcon from '@mui/icons-material/Edit';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PeopleIcon from '@mui/icons-material/People';

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { getAllUsers, editUser } from "../../api/usersApi.js"

const CategoryRowFont = {
    fontSize: "1.2rem",
    fontFamily: "'Open Sans','Gill Sans', 'Gill Sans MT', Calibri"
}

const SubCategoryRowFont = {
    fontSize: "1.1rem",
    fontFamily: "Open Sans"
    // fontFamily: "Trebuchet"
}

const ManageStaff = () => {
    const queryClient = useQueryClient()

    const initialData = {
        username: "",
        role: "staff",
        id: 0
    }

    const [editForm, setEditForm] = useState(initialData)
    const [formError, setFormError] = useState("")
    const [open, setOpen] = useState(false)
    //fetch data
    const {
        isLoading,
        isError,
        error,
        isSuccess,
        data: user_data
    } = useQuery({
        queryKey: ['users'],
        queryFn: getAllUsers,
    })

    const editUserMutation = useMutation({
        mutationFn: editUser,
        onSuccess: () => {
            queryClient.invalidateQueries("users")
        }
    })

    // console.log("Edit form", editForm)

    // console.log("open", open)

    const changeHandler = (e) => {
        const { value, name } = e.target
        setEditForm({
            ...editForm,
            [name]: value
        })
    }

    const handleOpen = (e, user) => {
        setEditForm({
            username: user.username,
            role: user.role,
            id: user.id
        })
        setOpen(true);

    };

    const handleClose = () => {
        setOpen(false);
        setFormError('')
        setEditForm(initialData)
    };

    const submitHandler = (e) => {
        if (!editForm.role || !editForm.username)
            setFormError("Please fill in all fields")
        else if (!editForm.id)
            setFormError("Failed to load User")
        else {
            editUserMutation.mutate(editForm)
            handleClose()
        }
    }

    if (isSuccess)
        console.log("users: ", user_data)

    if (isLoading)
        return (
            <Box width="100%" height="450px" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
            </Box>
        )

    return (
        <Box width='730px' sx={{ m: 4 }}>
            <Card height="50px" sx={{ mb: 2 }}>
                <CardContent>
                    <Typography>User Roles:</Typography>
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "blue" }}>
                                    <PeopleIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Staff" secondary="Can only view their Assigned Tasks " />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "orange" }}>
                                    <AdminPanelSettingsIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Admin" secondary="Can view tasks - Can View, create and edit projects" />
                        </ListItem>
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: "red" }}>
                                    <LockPersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Owner" secondary="Can view tasks - Can View, create and edit projects - Can register, manage and keep track of Users"/>
                        </ListItem>
                    </List>
                </CardContent>
            </Card>
            <TableContainer component={Paper}>
                <Table sx={{ width: 700 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ ...SubCategoryRowFont, }} >Username</TableCell>
                            <TableCell sx={{ ...SubCategoryRowFont, }} align="left">User Role</TableCell>
                            <TableCell sx={{ ...SubCategoryRowFont, }} align="right" >Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {user_data.map((user) => (
                            <TableRow
                                key={user.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" sx={{ ...SubCategoryRowFont }}>
                                    {user.username}
                                </TableCell>
                                <TableCell align="left" sx={{ ...SubCategoryRowFont }}>
                                    <Stack direction="row" spacing={0} align="right">
                                        {
                                            user.role === 'owner' ? <LockPersonIcon sx={{ color: "red" }} />
                                                : user.role === 'admin' ? <AdminPanelSettingsIcon sx={{ color: "orange" }} />
                                                    : <PeopleIcon sx={{ color: "blue" }} />
                                        }
                                        &nbsp;&nbsp;{user.role}
                                    </Stack>
                                </TableCell>
                                <TableCell align="right" sx={{ ...SubCategoryRowFont }}>
                                    <IconButton aria-label="edit"
                                        onClick={e => handleOpen(e, user)}
                                    >
                                        <EditIcon sx={{ color: "red" }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={open} onClose={handleClose} fullWidth >
                <DialogTitle >Edit User Details</DialogTitle>
                <DialogContent dividers >
                    <Typography sx={{ color: "red", textAlign: "center" }}>
                        {formError}
                    </Typography>

                    {/* <FormControl> */}
                    <TextField
                        required
                        autoFocus
                        margin="dense"
                        name="username"
                        label="Username"
                        value={editForm.username}
                        fullWidth
                        variant="standard"
                        onChange={changeHandler}
                    />
                    {/* </FormControl> */}
                    <Divider sx={{ mb: 3 }} orientation="horizontal" light variant="middle" flexItem />

                    <FormControl fullWidth>
                        <InputLabel>Role</InputLabel>

                        <Select
                            name="role"
                            value={editForm.role}
                            label="Role"
                            fullWidth
                            onChange={changeHandler}
                        >
                            <MenuItem value={"staff"}>staff</MenuItem>
                            <MenuItem value={"admin"}>admin</MenuItem>
                            <MenuItem value={"owner"}>owner</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={submitHandler}>Submit</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default ManageStaff