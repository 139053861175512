import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import BidTableHeader from "./BidTableHeader";
import BidTableBody from "./BidTableBody";

export default function BidTable() {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: "77vh" }}>
        <Table  aria-label="sticky table">
          <BidTableHeader />

          <BidTableBody />
        </Table>
      </TableContainer>
      {/* <TotalCostRow
        totalCols={vendorsCols}
        rows={rows}
        mrq={mrq}
        totalCost={totalCost}
      /> */}
    </Paper>
  );
}
