import { BASE_URL } from "../../../../../api/axios";

export const uploadFolder = async (e) => {
  const files = e.target.files;
  const form = new FormData();

  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    form.append("files", file, file.webkitRelativePath);
  }
  console.log(files, form);



  try {
    const req = await fetch(BASE_URL + "/upload/Project_Folder", {
      method: "post",
      body: form,
    });
    const res = await req.json();
    return res.msg;
  } catch (err) {
    console.log("Error: Folder Upload Failed", { err });
  }
};
