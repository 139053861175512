import React, { useEffect, useState, useContext } from "react";
import axios from "../../api/axios";
import { AuthContext } from "../../context/authContext";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import LoadingPage from "../Loading/LoadingPage";

const PersistLogin = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);

  const { auth, setAuth } = useContext(AuthContext);
  useEffect(() => {
    const verifyAccessToken = async () => {
      await axios
        .get("/dev/verifyToken")
        .then((response) => {
          setIsLoading(false);
          const role = response.data.role;
          setAuth({ role });
        })
        .catch((err) => {
          setIsLoading(false);
        });
    };

    !auth?.role ? verifyAccessToken() : setIsLoading(false);
  }, []);

  return isLoading ? (
    <Box>
      <LoadingPage />
    </Box>
  ) : (
    children
  );
};

export default PersistLogin;
