import axios from "../../../../../api/axios";

const codeRoute = "/pipe/get-codes";

export const getCodes = async (component,size,dimStd,material,schedule,type,requirement) => {
  const { data } = await axios.get(`${codeRoute}?component=${component}&size=${size}&dimstd=${dimStd}&material=${material}&schedule=${schedule}&type=${type}&requirement=${requirement}`);
  // TODO: here we can :
  //    - extend our logic with our response data.
  console.log(data.code );
  return data.code;
};