import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'

const LoadingPage = () => {
    return (
        // <Box width="100%" height="450px" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        //     <CircularProgress />
        // </Box>
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            flexDirection="column"
        >
            <CircularProgress />
            <Typography variant="h6" marginTop={2}>
                Loading...
            </Typography>
        </Box>

    )
}

export default LoadingPage