import { useFormContext } from "react-hook-form";
import Table from "../../../../../components/Tables/NonEditableTable";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";

import EstimationColumns, {
  SummaryColumns,
  NozzleEstimationColumns,
} from "./columns";
import { minimalMuiTableProps } from "../../../../../styles/muiProps";
import { useEffect, useState } from "react";
import AddToFabricationDialog from "./AddToFabricationDialog";
// import { columns } from "../../CommonFeatures/EstimationTab/utils";

export default function EstimationTab({ projectId }) {
  const { getValues } = useFormContext();
  const { DGV_Main_Estimation, Est_Sum_DGV, Fab_Rate_Txt, Proposal_Cost_Txt } =
    getValues();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [assembly, setAssembly] = useState("");
  const [rev, setRev] = useState("");

  // 'Est.' Cant be used as accessorkey, hence renamed.
  const reformatSummaryTable = Est_Sum_DGV?.map((elem) => ({
    ...elem,
    estimated: elem["Est."],
  }));

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };
  const handleAssemblyChange = (e) => {
    setAssembly(e.target.value);
  };
  const handleRevChange = (e) => {
    setRev(e.target.value);
  };

  useEffect(() => {
    console.log("est data", DGV_Main_Estimation);
  }, [isDialogOpen]);

  return (
    <Box sx={{ p: 1 }}>
      <Table
        columns={EstimationColumns()}
        data={assembly && rev && DGV_Main_Estimation ? DGV_Main_Estimation : []}
        additionalProps={{
          renderTopToolbarCustomActions: () => (
            <Box sx={{ minWidth: 500, display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDialogOpen}
                sx={{ minWidth: 150, marginRight: 3 }}
              >
                Add to Fabrication
              </Button>
              <FormControl sx={{ minWidth: 200, marginRight: 1 }}>
                <InputLabel id="assembly-label">Assembly</InputLabel>
                <Select
                  labelId="assembly-label"
                  id="assembly-select"
                  value={assembly}
                  label="Assembly"
                  onChange={handleAssemblyChange}
                >
                  <MenuItem value={1}>Assembly</MenuItem>
                  {/* <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel id="rev-label">Rev</InputLabel>
                <Select
                  labelId="rev-label"
                  id="rev-select"
                  value={rev}
                  label="Rev"
                  onChange={handleRevChange}
                >
                  <MenuItem value={1}>0</MenuItem>
                  {/* <MenuItem value={20}>Twenty</MenuItem>
    <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
              </FormControl>
            </Box>
          ),
        }}
      />

      {/* Add to Fabrication Dialog */}
      <AddToFabricationDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        data={assembly && rev ? DGV_Main_Estimation : ""}
      />

      {/* Summary Box */}
      <Stack direction={"row"} gap={1}>
        <Box
          sx={{ backgroundColor: "white", borderRadius: "5px" }}
          mt={5}
          width={"50%"}
          p={2}
        >
          <Stack pb={1} direction={"row"} alignItems={"center"}>
            <Typography>Fabrication Rate</Typography>

            <TextField
              sx={{ paddingLeft: 3, paddingRight: 3 }}
              id="fab-rate"
              value={assembly && rev && Fab_Rate_Txt ? Fab_Rate_Txt : ""}
              // label="Fabrication Rate"
              variant="outlined"
              size="small"
              contentEditable={false}
            />
            <Typography>$/hr</Typography>
          </Stack>
          <Table
            columns={SummaryColumns()}
            data={
              assembly && rev && reformatSummaryTable
                ? reformatSummaryTable
                : []
            }
            additionalProps={{
              ...minimalMuiTableProps,
              enableTopToolbar: false,
              enableBottomToolbar: false,
            }}
          />
          <Stack pt={1} direction={"row"} alignItems={"center"}>
            <Typography>Proposal Cost</Typography>

            <TextField
              sx={{ paddingLeft: 3, paddingRight: 3 }}
              id="prop-cost"
              size="small"
              value={
                assembly && rev && Proposal_Cost_Txt ? Proposal_Cost_Txt : ""
              }
              // label="Proposal Cost"
              variant="outlined"
              contentEditable={false}
              disabled={true}
            />
            <Typography>$</Typography>
          </Stack>
        </Box>
        {/* <Stack
          // justifyContent={"center"}
          // alignItems={"center"}
          sx={{ backgroundColor: "white" }}
          mt={5}
          width={"50%"}
        >
          <Typography variant="h4" alignSelf={"center"}>
            Nozzle Estimation Data
          </Typography>
          <Table columns={NozzleEstimationColumns()} data={[]} />
        </Stack> */}
      </Stack>
    </Box>
  );
}
