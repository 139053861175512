import PersistLogin from "../components/RoutesHandler/PersistLogin";
import AuthRoutesHandler from "../components/RoutesHandler/AuthRoutesHandler"
import { Outlet } from "react-router-dom";

export const AuthProtected = () => {
    return (
        // <PersistLogin>
            <AuthRoutesHandler >
                <Outlet />
            </AuthRoutesHandler >
        // </PersistLogin>
    )
}