import { secondAxios } from "../../../../../../api/axios";

// TODO: Need to make api for raci comments
export const getComments = async (raciId) => {
  const response = await secondAxios.get('/raci/comments/' + raciId);
  return response.data;
};

export const createComment = async (comment) => {
  console.log({ comment })
  await secondAxios.post('/raci/comments', comment);
};

export const updateComment = async (comment) => {
  await secondAxios.put(`/comments/${comment.id}`, comment);
};

export const deleteComment = async (commentId) => {
  await secondAxios.delete(`/comments/${commentId}`);
};
