import React from "react";

import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const SelectRev = ({ rev, revs, handleRevChange }) => {
  return (
    <Box sx={{ display: "inline-block", width: 100 }}>
      <FormControl fullWidth sx={{ "& div": { p: "5px" } }}>
        <InputLabel id="select-rev">Rev</InputLabel>
        <Select
          labelId="select-rev"
          id="simple-select"
          value={rev}
          label="Rev"
          onChange={handleRevChange}
        >
          {revs.map((rev) => {
            return (
              <MenuItem value={rev} key={rev}>
                {rev}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};
