import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import TabPanel from "../../../../../components/widgets/TabPanelFormat";
// import ShellTab from '../pv_data_tabs/ShellTab';
import TableTab from "./TableTab";
// import AttachmentTab from "../pv_data_tabs/AttachmentTab";
// import McTab from "../pv_data_tabs/McTab";
import DesignTab from "./DesignTabs";
import ShellTab from "./ShellTab";
import NozzleTab from "./NozzleTab";
import PidBox from "../../../pid";
import MaterialsDBPage from "../../../MaterialsDBPage";
import { tabProps } from "../../../../../styles/muiProps";
import CustomAlert from "../../../../../components/widgets/CustomAlert";
import AttachmentTabs from "./AttachmentTabs";
import NavTabs from "../../../../../components/Tabs/NavTabs";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const DataTabs = (props) => {
  document.title = "Pressure Vessel - Data";
  const [currentTab, setCurrentTab] = React.useState(0); //value of the current tab
  const [open, setOpen] = React.useState(false); //for alert open
  const [key, setKey] = React.useState(Math.random());
  const [pidState, setPidState] = useState(0);
  const { projectId, project, equip } = props;

  const reRender = () => {
    setKey(Math.random());
  };

  //handle change of tabs
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const PV_Tabs = [
    "Design",
    "Shell",
    "Nozzles",
    "Attachments",
    "Materials",
    "Notes",
    "Tables",
  ];
  const tabs = [
    {
      label: "Design",
      panel: <DesignTab pidState={pidState} />,
      to: "design",
    },
    {
      label: "Shell",
      panel: (
        <ShellTab
          key={key}
          reRender={reRender}
          projectId={projectId}
          pidState={pidState}
          equipId={equip?.id}
        />
      ),
      to: "shell",
    },
    {
      label: "Nozzles",
      panel: <NozzleTab key={key} reRender={reRender} pidState={pidState} />,
      to: "nozzles",
    },
    {
      label: "Attachments",
      panelProps: { padding: 0 },
      panel: <AttachmentTabs />,
      to: "attachments",
    },
    {
      label: "Materials",
      panelProps: { padding: 0 },
      panel: <MaterialsDBPage project={project} equip={equip} />,
      to: "materials",
    },
    {
      label: "Notes",
      panel: <></>,
      to: "notes",
    },
    {
      label: "Tables",
      panel: <TableTab />,
      to: "tables",
    },
    {
      label: "Change Order",
      panel: <></>,
      to: "change_order",
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <Box>
        <CustomAlert
          open={open}
          setOpen={setOpen}
          severity="success"
          message="File created successfully!"
        />

        <NavTabs tabs={tabs} tabProps={tabProps} navLabel="data-tab" />
        {/* <Tabs
          value={currentTab}
          onChange={handleTabChange}
          textcolor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          {...tabProps}
        >
          {PV_Tabs.map((tab) => {
            return <Tab label={tab} key={tab} />;
          })}
        </Tabs>
          <Tab label="Design" />
          <Tab label="Shell" />
          <Tab label="Nozzles" />
          <Tab label="Attachments" />
          <Tab label="Materials" />
          <Tab label="Notes" />
          <Tab label="Tables" />
        </Tabs> */}
      </Box>
      {currentTab in [0, 1, 2] ? (
        <PidBox pidState={pidState} setPidState={setPidState} />
      ) : null}
      {/* <TabPanel value={currentTab} index={0}>
        <DesignTab pidState={pidState} />
      </TabPanel>
      <TabPanel value={currentTab} index={1}>
        <ShellTab
          key={key}
          reRender={reRender}
          projectId={projectId}
          pidState={pidState}
          equipId={equip?.id}
        />
      </TabPanel>
      <TabPanel value={currentTab} index={2}>
        <NozzleTab key={key} reRender={reRender} pidState={pidState} />
      </TabPanel>
      <TabPanel value={currentTab} index={3} padding={0}>
        <AttachmentTabs />
      </TabPanel>
      <TabPanel value={currentTab} index={4} padding={0}>
        <MaterialsDBPage project={project} equip={equip} />
      </TabPanel>
      <TabPanel value={currentTab} index={5} padding={0}>
      </TabPanel>
      <TabPanel value={currentTab} index={6}>
        <TableTab />
      </TabPanel> */}
      {/* <TabPanel value={currentTab} index={6} padding={1} >
        <McTab />
      </TabPanel> */}
    </Box>
  );
};

export default DataTabs;
